export const Selectors = Object.freeze({
  AppContent: '#amkt-frontend-content',
  InitialState: '#initial-state',
  InitialCSS: '#critical-css',
  HtmlHead: 'head',
  HTML: 'html',
});

export const EMOTION_CACHE_KEY = 'amkt-frontend-ssr';
export const COLOR_MODE = 'light'; // change to 'auto' when MPAC theming is ready
export const SPACING = 'spacing';
export const TYPOGRAPHY = 'typography-adg3';
