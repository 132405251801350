import { getFrontendBootstrap } from '@atlassian/mpac-api-client';
import { getStatsigClientInitializationValues } from '@atlassian/mpac-feature-flags';
import invariant from 'invariant';
import { parse } from 'query-string';

import { getAnalyticsClient, getMockAnalyticsClient } from './analyticsClient';
import { getFeatureFlags } from './featureFlags';

import type {
  ApplicationConfig,
  InitialConfig,
  InitialState,
} from '../application-config/types/BootstrapConfig';
import type { StatsigClientType } from '@atlassian/mpac-feature-flags';

/**
 * An isomorphic function which gets the "InitialConfig" which will be built on the server during
 * SSR and then consumed on the client via `window.__INITIAL_CONFIG__`.
 * If the app is not being server side rendered then on the client the feature flags & amkt
 * bootstrap data will be fetched to build this initial config.
 */
// eslint-disable-next-line max-lines-per-function
export const getInitialConfig = async (options: {
  applicationConfig: ApplicationConfig;
  urlSearch: string;
  /** Optional, in client. Required only during SSR */
  amktBootstrapHeaders?: { [key: string]: string };
  frontendBootstrapHeaders?: { [key: string]: string };
  useFrontendBranch?: string;
  userAgent?: string;
  statsigClient: StatsigClientType;
}): Promise<InitialConfig> => {
  const {
    applicationConfig,
    urlSearch,
    frontendBootstrapHeaders,
    useFrontendBranch,
    statsigClient,
  } = options;
  const queryParams = parse(urlSearch);
  const sourceQueryParam = typeof queryParams.source === 'string' ? queryParams.source : 'unknown';

  // variant is a custom attribute passed to Statsig. It can be used as an overriding rule in Statsig configurations.
  const variantQueryParam = typeof queryParams.variant === 'string' ? queryParams.variant : '';

  // user agent passed in request headers, else fallback to navigator.userAgent which will be of Tesseract during SSR or browser in client
  const userAgent = options.userAgent || navigator.userAgent;

  // during SSR, we need to fetch data, build the entire config & mock the analytics client
  if (__REACT_SSR__) {
    // direct S2S call to amkt for bootstrapping the application on the server
    invariant(
      frontendBootstrapHeaders,
      'frontendBootstrapHeaders must be specified during SSR for S2S auth'
    );
    const frontendBootstrap = await getFrontendBootstrap(frontendBootstrapHeaders, true);

    // no analytics events are fired on the server
    const analyticsClient = getMockAnalyticsClient();

    const { featureFlags, statsigCustomAttributes } = await getFeatureFlags({
      applicationConfig,
      frontendBootstrap,
      analyticsClient,
      useFrontendBranch,
      statsigClient,
      queryParams: {
        source: sourceQueryParam,
        variant: variantQueryParam,
      },
      userAgent,
    });

    const { statsig } = applicationConfig;
    const { launchDarklyUserKey } = frontendBootstrap;

    // This configuration along with init flags values will be used by STATSIG to initialize the client in the browser
    let statsigInitializationValues;
    try {
      statsigInitializationValues = await getStatsigClientInitializationValues({
        clientKey: statsig.clientKey,
        targetApp: statsig.targetApp,
        marketplaceAnonymousId: launchDarklyUserKey,
        customAttributes: statsigCustomAttributes,
      });
    } catch (error) {
      console.error('Error getting SSR statsig initialization values', error);
    }

    return {
      frontendBootstrap,
      featureFlags,
      analyticsClient,
      shouldHydrate: true,
      statsigInitializationValues,
    };
  }

  // when the client is being hydrated after SSR, we re-use the config built on the server
  // @ts-expect-error [MC-2850] - TS2339 - Property '__INITIAL_STATE__' does not exist on type 'Window & typeof globalThis'.
  if (typeof window.__INITIAL_STATE__ !== 'undefined') {
    // @ts-expect-error [MC-2850] - TS2339 - Property '__INITIAL_STATE__' does not exist on type 'Window & typeof globalThis'.
    const { initialConfig } = window.__INITIAL_STATE__ as InitialState;
    const analyticsClient = getAnalyticsClient({ config: applicationConfig });

    // init statsig client with INIT values sent from SSR and flags
    const { statsig, featureFlags } = applicationConfig;

    try {
      await statsigClient.initializeStatsigClientWithValues(
        {
          environment: featureFlags.environment,
          targetApp: statsig.targetApp,

          // from SSR values
          marketplaceAnonymousId: initialConfig.frontendBootstrap.launchDarklyUserKey,
          clientSdkKey: initialConfig?.statsigInitializationValues?.clientSdkKey,
          experimentValues: initialConfig?.statsigInitializationValues?.experimentValues,
          customAttributes: initialConfig?.statsigInitializationValues?.customAttributes,
        },
        'MPAC'
      );
    } catch (error) {
      console.error('Error initializing statsig client with SSR values', error);
    }

    return {
      frontendBootstrap: initialConfig.frontendBootstrap,
      featureFlags: initialConfig.featureFlags,
      shouldHydrate: initialConfig.shouldHydrate,
      analyticsClient,
      statsigInitializationValues: initialConfig?.statsigInitializationValues,
    };
  }

  // when the client has not been rendered on the server at all, we build the entire config
  const frontendBootstrap = await getFrontendBootstrap();
  const analyticsClient = getAnalyticsClient({ config: applicationConfig });
  const { featureFlags } = await getFeatureFlags({
    applicationConfig,
    frontendBootstrap,
    analyticsClient,
    useFrontendBranch,
    statsigClient,
    queryParams: {
      source: sourceQueryParam,
      variant: variantQueryParam,
    },
    userAgent,
  });

  return {
    frontendBootstrap,
    featureFlags,
    analyticsClient,
    shouldHydrate: false,
    statsigInitializationValues: undefined, // these values are generated on server only or init with server response
  };
};
