import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { BaseHandler } from '../../graphql-orchestrator-link';
import SearchFilters from './Filters';
import { transposeFilterError, transposeFilterOptions } from './utils/SearchFilterTransposeUtil';
export class SearchFiltersHandler extends BaseHandler {
  constructor(stargateBaseUrl, ldFeatureFlags, statsigClient) {
    super();
    _defineProperty(this, "stargateBaseUrl", void 0);
    _defineProperty(this, "statsigClient", void 0);
    _defineProperty(this, "ldFeatureFlags", void 0);
    this.stargateBaseUrl = stargateBaseUrl;
    this.ldFeatureFlags = ldFeatureFlags;
    this.statsigClient = statsigClient;
  }
  async getFilterById(filterId) {
    try {
      const options = await SearchFilters({
        ldFeatureFlags: this.ldFeatureFlags,
        filterId,
        stargateBaseUrl: this.stargateBaseUrl,
        statsigClient: this.statsigClient
      });
      return transposeFilterOptions({
        options,
        filterId
      });
    } catch (error) {
      return transposeFilterError({
        message: error.messsage,
        filterId
      });
    }
  }
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    const variables = operation.variables;
    if (typeof variables.filterIds === 'string') {
      const filter = await this.getFilterById(variables.filterIds);
      return [filter];
    }
    const filters = await Promise.all(variables.filterIds.map(filterId => this.getFilterById(filterId)));
    return filters;
  }
  responseTransformer(_operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    return {
      marketplaceFilters: customResponse
    };
  }
}