import { createClient as createContentfulClient } from 'contentful';

export default ({ commonConfig }) =>
  createContentfulClient({
    space: commonConfig.contentfulConfig.spaceId,
    accessToken: commonConfig.contentfulConfig.authToken,
    host: commonConfig.contentfulConfig.previewMode ? 'preview.contentful.com' : undefined,
    retryOnError: true,
    retryLimit: 3,
  });
