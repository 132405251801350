import React from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  BlanketTransitionContainer,
  DropdownMenuTransitionContainer,
  DropdownMenuTransitionContent,
  SlidingMenuTransitionContainer,
  SlidingMenuTransitionContent,
} from './ExpandableMenu.styles';

export const BlanketTransition = ({ isActive, children }) => {
  return (
    <BlanketTransitionContainer>
      <CSSTransition in={isActive} timeout={300} classNames="dropdown-menu-blanket" unmountOnExit>
        {children}
      </CSSTransition>
    </BlanketTransitionContainer>
  );
};

export const DropdownMenuTransition = ({ children, isOpen }) => {
  return (
    <DropdownMenuTransitionContainer>
      <CSSTransition in={isOpen} timeout={300} classNames="dropdown-menu" unmountOnExit>
        <DropdownMenuTransitionContent>{children}</DropdownMenuTransitionContent>
      </CSSTransition>
    </DropdownMenuTransitionContainer>
  );
};

export const SlidingMenuTransition = ({ children, isOpen }) => {
  return (
    <SlidingMenuTransitionContainer>
      <CSSTransition in={isOpen} timeout={300} classNames="sliding-menu" appear={true}>
        <SlidingMenuTransitionContent>{children}</SlidingMenuTransitionContent>
      </CSSTransition>
    </SlidingMenuTransitionContainer>
  );
};
