import { G300, N800, R500, Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 30px 23px;
`;

export const FieldSet = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

export const TitleDiv = styled.div`
  text-align: center;
  margin-bottom: ${token('space.200', '16px')};
  font-family: 'Charlie Display', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: ${N800};
`;

export const FieldsContainerDiv = styled.div`
  max-width: 315px;
`;

export const FieldsDiv = styled.div`
  max-width: 296px;
  margin: auto;
`;

export const CheckboxTitleLabel = styled.label`
  padding-left: 9px;
  display: inline;
  font-size: 13.9px;
`;

export const CkeckboxesContainerDiv = styled.div`
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: ${token('space.200', '16px')};
`;

export const CheckboxDiv = styled.div`
  display: inline-flex;
  align-self: flex-start;
`;

export const InputDiv = styled.input`
  margin: auto;
`;

export const InvalidEmailDiv = styled.div<{ visible?: boolean }>`
  display: ${(props) => (props.visible ? 'inline-flex' : 'none')};
  text-align: left;
`;

export const InvalidEmailTextDiv = styled.div`
  margin: auto;
  padding-left: 5px;
`;

export const SubscribeButtonDiv = styled.div`
  margin-bottom: 25px;
`;

export const GreenDiv = styled.div`
  color: ${G300};
`;

export const RedDiv = styled.div`
  color: ${R500};
`;

export const YellowDiv = styled.div`
  color: ${Y300};
`;

export const FieldTextDiv = styled.div`
  height: 40px;
`;

export const FieldTextWithErrorDiv = styled.div`
  margin-bottom: 27px;
`;

export const CancelButtonDiv = styled.div`
  text-align: center;
`;

export const ModalImage = styled.img`
  width: 179px;
  height: 273px;
`;
