import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Details:
 * This is the default sequence. Only `primary` data is fetched(after optionally transforming the given request).
 * `Custom` call is ignored.
 * The result of primary call is passed back to the `transformResponse` function for data massaging.
 *
 * Use case:
 * Mainly for cases when we don't want to fetch any `dependant` data for primary data.
 *
 * Call sequence:
 * transformRequest -> fetchPrimary -> transformResponse
 */
function primaryOnlySequence(operation, forward, sequenceConfig, handler) {
  const newOperation = handler.requestTransformer(operation, sequenceConfig);
  return handler.fetchPrimary(newOperation, forward).map(data => {
    const primaryResponse = data.data;
    const queryResponse = handler.responseTransformer(operation, newOperation, sequenceConfig, primaryResponse);
    return _objectSpread(_objectSpread({}, data), {}, {
      data: queryResponse
    });
  });
}
export { primaryOnlySequence };