import _defineProperty from "@babel/runtime/helpers/defineProperty";
// A deferred is a promise wrapper that allows us
// to programmatically mark acceptance or rejection of a promise
// Similar to jQuery's $.deferred
export default class Deferred {
  constructor() {
    _defineProperty(this, "resolve", () => {
      /* resolve undefined by default */
    });
    _defineProperty(this, "reject", () => {
      /* reject with undefined by default */
    });
    _defineProperty(this, "promise", new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    }));
    _defineProperty(this, "then", this.promise.then.bind(this.promise));
    _defineProperty(this, "catch", this.promise.catch.bind(this.promise));
  }
}