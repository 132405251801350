import {
  AtlassianNavigation,
  generateTheme,
  PrimaryButton,
  useOverflowStatus,
} from '@atlaskit/atlassian-navigation';
import { LinkItem } from '@atlaskit/menu';
import { B50, B100, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { LinkClickedEvent, useAnalyticsEvents } from '@atlassian/mpac-analytics';
import styled from '@emotion/styled';
import React from 'react';

import { ANALYTICS_EVENT_CONTAINER_PARTNER_QUICK_LINK } from '../../../../../../../../../analytics/Events';
import BootstrapConfig from '../../../../../../../../../bootstrapConfig';
import { usePartnerPermissions } from '../../../../../../../../../hooks/usePartnerPermissions';
import { getQuickLinkItems } from '../../utils/utils';

import type { PrimaryButtonProps } from '@atlaskit/atlassian-navigation';

const VendorName = styled.p`
  padding-right: ${token('space.250', '20px')};
  margin: 0;
  color: ${B50};
`;

const PartnerLinksTag = styled.p`
  padding-left: 14px;
  padding-right: 10px;
  margin: 0;
  color: ${B50};
`;

const partnerQuickLinksTheme = generateTheme({
  name: 'partner-quick-links',
  backgroundColor: N800,
  highlightColor: B100,
});

type Props = {
  partnerId: number;
  partnerName: string;
};

export const NavigationButton = (props: PrimaryButtonProps) => {
  const { isVisible } = useOverflowStatus();
  return isVisible ? (
    <PrimaryButton {...props} />
  ) : (
    <LinkItem href={props.href}>{props.children}</LinkItem>
  );
};

const PartnerQuickLinksContent = (props: Props) => {
  const fireAnalyticsEvent = useAnalyticsEvents();
  const { mpacBaseUrl } = BootstrapConfig.get();

  const { permissions } = usePartnerPermissions(mpacBaseUrl, props.partnerId);

  const trackEventClick = (linkActionSubjectId: string) => {
    const eventAttributes = {
      partnerId: props.partnerId,
      partnerName: props.partnerName,
    };

    fireAnalyticsEvent({
      eventType: LinkClickedEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_PARTNER_QUICK_LINK,
      actionSubjectId: linkActionSubjectId,
      attributes: { ...eventAttributes },
    });
  };

  const primaryItems = (
    items: Array<{
      linkActionSubjectId: string;
      name: string;
      path: string;
    }>
  ) =>
    items.map((item) => (
      <NavigationButton key={item.name} href={item.path} onClick={(_) => onRouteClick(item)}>
        {item.name}
      </NavigationButton>
    ));

  if (!permissions) {
    return null;
  }

  const onRouteClick = (navigationItem: {
    linkActionSubjectId: string;
    name: string;
    path: string;
  }) => {
    trackEventClick(navigationItem.linkActionSubjectId);
  };

  const quickLinkItems = getQuickLinkItems(permissions, props.partnerId);
  return (
    <AtlassianNavigation
      label="Quick Links Navigation"
      renderProductHome={() => <PartnerLinksTag>Partner Links: </PartnerLinksTag>}
      primaryItems={primaryItems(quickLinkItems)}
      theme={partnerQuickLinksTheme}
      renderProfile={() => <VendorName>{props.partnerName}</VendorName>}
    />
  );
};

export default PartnerQuickLinksContent;
