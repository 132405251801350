import { token } from '@atlaskit/tokens';
import { breakpointValues, useResponsiveBreakpoint } from '@atlassian/mpac-primitives';
import { LinkUtils } from '@atlassian/mpac-utils';
import { styled } from '@compiled/react';
import React from 'react';

import { useGlobalNavAnalytics } from '../Analytics/useGlobalNavAnalytics';

import type { History } from 'history';

import DesktopLogoSvg from '../../../../assets/images/marketplace-desktop.svg';
import MobileLogoSvg from '../../../../assets/images/marketplace-mobile.svg';

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const LogoAnchor = styled.a({
  display: 'flex',
  alignItems: 'center',
  padding: `${token('space.100')} ${token('space.050')}`,
  borderRadius: token('border.radius.100', '3px'),
  // Only apply hover styles when device supports hover input (e.g. desktop devices)
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: token('color.background.neutral.hovered'),
    },
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.pressed'),
  },
  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
    outlineOffset: 0,
  },
});

export type MarketplaceLogoProps = {
  history: History;
  closeMenu: () => void;
};

export const MarketplaceLogo = ({ history, closeMenu }: MarketplaceLogoProps) => {
  const { value: breakpoint } = useResponsiveBreakpoint();
  const { fireLogoClickEvent } = useGlobalNavAnalytics();
  const onClick = (e) => {
    closeMenu();
    fireLogoClickEvent();
    if (history) {
      LinkUtils.maybeOpenInNewTab(e, () => {
        history.push({ pathname: '/' });
      });
    }
  };

  const isMobile = breakpoint < breakpointValues.sm;

  return (
    <Container>
      <LogoAnchor onClick={onClick} href="/" aria-label="Atlassian Marketplace home">
        <img
          src={isMobile ? MobileLogoSvg : DesktopLogoSvg}
          alt="Atlassian marketplace home"
          aria-hidden={true}
        />
      </LogoAnchor>
    </Container>
  );
};
