import AkSpinner from '@atlaskit/spinner';
import { AppListingTabs, DEFAULT_TAB } from '@atlassian/mpac-app-listing';
import { HostingQueryParamToHostingType } from '@atlassian/mpac-types';
import { useParams, useValidateQueryParams } from '@atlassian/mpac-ui';
import styled from '@emotion/styled';
import invariant from 'invariant';
import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import SEOFriendlyRedirect from '../../../../../../../common/components/SEOFriendlyRedirect';
import { AllHostingTypeQueryParams, showOnlyPublicQueryParam } from '../../../../../../constants';
import BaseRouteHandler from '../../../common/routeHandlers/BaseRouteHandler';

const LazyAppListingPage = lazyForPaint(
  () =>
    import(/* webpackPrefetch: true, webpackChunkName: "chunk-app-listing" */ './AppListingPage')
);

const APPS_ROUTE_PARAM_VALIDATION = {
  tab: {
    defaultValue: DEFAULT_TAB,
    validate: (value) => AppListingTabs.includes(value),
  },
  hosting: {
    defaultValue: undefined,
    validate: (value) => {
      if (typeof value !== 'undefined') return AllHostingTypeQueryParams.includes(value);
      return true;
    },
  },
  [showOnlyPublicQueryParam]: {
    defaultValue: undefined,
    validate: (value) => {
      if (value === null) return true;
      if (typeof value !== 'undefined') return Boolean(value).toString() === value;
      return true;
    },
  },
} as const;

export const SpinnerContainer = styled.div`
  text-align: center;
  margin-top: 5%;
`;

const AppsRoute = () => {
  const params = useParams();
  const { redirectRoute, queryParams } = useValidateQueryParams(APPS_ROUTE_PARAM_VALIDATION);

  if (redirectRoute) {
    return <SEOFriendlyRedirect to={redirectRoute} statusCode={301} />;
  }

  invariant(params.appId, 'App id is required');

  return (
    <BaseRouteHandler includeSearch includeFooter={false}>
      <LazySuspense
        fallback={
          <SpinnerContainer>
            <AkSpinner size="large" />
          </SpinnerContainer>
        }
      >
        <LazyAppListingPage
          appId={params.appId}
          // @ts-expect-error [MC-2850] - TS2538 - Type '(string | number)[]' cannot be used as an index type. | TS2538 - Type 'null' cannot be used as an index type.
          selectedHosting={HostingQueryParamToHostingType[queryParams.hosting]}
          // @ts-expect-error [MC-2850] - TS2322 - Type 'string | (string | number)[] | null' is not assignable to type 'AppListingTabsType'.
          tab={queryParams.tab}
          showOnlyPublic={queryParams[showOnlyPublicQueryParam] === 'true'}
        />
      </LazySuspense>
    </BaseRouteHandler>
  );
};

export default AppsRoute;
