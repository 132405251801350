import { ToolsIcon } from '@atlassian/mpac-ui';
import React from 'react';

import ErrorPage from '../ErrorPage';

export type ServerErrorProps = {
  homeLink: string;
  statusCode?: number;
};

const ServerError = (props: ServerErrorProps) => {
  const { statusCode = 500, homeLink } = props;

  return (
    <ErrorPage
      homeLink={homeLink}
      heading="*whistles* Nothing to see here!"
      image={ToolsIcon}
      primaryMessage="This is embarrassing... something went wrong"
      statusCode={statusCode}
      title="Server error"
    />
  );
};

export default ServerError;
