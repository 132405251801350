import React from 'react';

export const AtlassianLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="146"
    height="19"
    viewBox="0 0 146 19"
    fill="none"
    data-testid="atlassian-logo"
    aria-hidden={true}
  >
    <path
      d="M5.29346 8.26881C5.02324 7.97156 4.61789 7.99859 4.42873 8.34988L0.0510548 17.1323C-0.111082 17.4836 0.13211 17.8889 0.510429 17.8889H6.61759C6.80674 17.8889 6.99591 17.7808 7.07698 17.5917C8.42811 14.8624 7.61742 10.7279 5.29346 8.26881Z"
      fill="url(#paint0_linear_3640_9301)"
    />
    <path
      d="M8.50881 0.270589C6.04974 4.16187 6.21187 8.45849 7.83324 11.7012C9.45461 14.944 10.6976 17.403 10.7787 17.5922C10.8598 17.7813 11.0489 17.8894 11.2381 17.8894H17.3453C17.7236 17.8894 17.9938 17.4841 17.8046 17.1328C17.8046 17.1328 9.58972 0.702954 9.37354 0.297612C9.23843 -0.0807067 8.75201 -0.107729 8.50881 0.270589Z"
      fill="#7A869A"
    />
    <path
      d="M91.5231 13.5382C91.5231 10.9711 90.1449 9.75505 86.3077 8.91735C84.1729 8.45796 83.6595 7.97155 83.6595 7.29598C83.6595 6.45827 84.4161 6.07995 85.8213 6.07995C87.5237 6.07995 89.1991 6.59339 90.7935 7.323V3.94516C89.6855 3.37768 87.9561 2.94531 85.9294 2.94531C82.1192 2.94531 80.1465 4.5937 80.1465 7.323C80.1465 9.48482 81.1463 11.2143 85.0917 11.9709C87.4426 12.4573 87.929 12.8356 87.929 13.6193C87.929 14.3759 87.4426 14.8624 85.7672 14.8624C83.8486 14.8624 81.5517 14.2138 80.0654 13.3221V16.862C81.2544 17.4565 82.8218 18.1051 85.7402 18.1051C89.8747 18.1051 91.5231 16.2675 91.5231 13.5382Z"
      fill="#7A869A"
    />
    <path
      d="M133.084 3.16211V17.8895H136.219V6.67507L137.543 9.64758L141.974 17.8895H145.92V3.16211H142.785V12.6741L141.596 9.9178L138.029 3.16211H133.084Z"
      fill="#7A869A"
    />
    <path d="M113.168 3.16211H109.736V17.8625H113.168V3.16211Z" fill="#7A869A" />
    <path
      d="M105.764 13.5382C105.764 10.9711 104.386 9.75505 100.549 8.91735C98.4141 8.45796 97.9007 7.97155 97.9007 7.29598C97.9007 6.45827 98.6573 6.07995 100.062 6.07995C101.765 6.07995 103.44 6.59339 105.035 7.323V3.94516C103.927 3.37768 102.197 2.94531 100.171 2.94531C96.3604 2.94531 94.3877 4.5937 94.3877 7.323C94.3877 9.48482 95.3875 11.2143 99.3329 11.9709C101.684 12.4573 102.17 12.8356 102.17 13.6193C102.17 14.3759 101.684 14.8624 100.008 14.8624C98.0898 14.8624 95.7929 14.2138 94.3066 13.3221V16.862C95.4956 17.4565 97.063 18.1051 99.9814 18.1051C104.143 18.1051 105.764 16.2675 105.764 13.5382Z"
      fill="#7A869A"
    />
    <path
      d="M53.5566 3.16211V17.8895H60.5826L61.6905 14.7008H56.9885V3.16211H53.5566Z"
      fill="#7A869A"
    />
    <path
      d="M39.6396 3.16211V6.3508H43.4499V17.8895H46.8817V6.3508H50.9622V3.16211H39.6396Z"
      fill="#7A869A"
    />
    <path
      d="M34.6403 3.16211H30.1275L24.9932 17.8625H28.9115L29.6411 15.3764C30.5058 15.6196 31.4246 15.7817 32.3974 15.7817C33.3432 15.7817 34.262 15.6466 35.1537 15.3764L35.8833 17.8625H39.8016L34.6403 3.16211ZM32.3704 12.7552C31.7218 12.7552 31.0733 12.6471 30.4788 12.485L32.3704 6.05355L34.262 12.485C33.6675 12.6471 33.0459 12.7552 32.3704 12.7552Z"
      fill="#7A869A"
    />
    <path
      d="M72.7428 3.16211H68.23L63.0957 17.8625H67.014L67.7436 15.3764C68.6084 15.6196 69.5271 15.7817 70.4999 15.7817C71.4457 15.7817 72.3645 15.6466 73.2563 15.3764L73.9859 17.8625H77.9042L72.7428 3.16211ZM70.4729 12.7552C69.8244 12.7552 69.1758 12.6471 68.5813 12.485L70.4729 6.05355L72.3645 12.485C71.77 12.6471 71.1215 12.7552 70.4729 12.7552Z"
      fill="#7A869A"
    />
    <path
      d="M125.491 3.16211H120.978L115.844 17.8625H119.762L120.492 15.3764C121.356 15.6196 122.275 15.7817 123.248 15.7817C124.194 15.7817 125.113 15.6466 126.004 15.3764L126.734 17.8625H130.652L125.491 3.16211ZM123.248 12.7552C122.599 12.7552 121.951 12.6471 121.356 12.485L123.248 6.05355L125.14 12.485C124.518 12.6471 123.897 12.7552 123.248 12.7552Z"
      fill="#7A869A"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3640_9301"
        x1="7.70855"
        y1="9.61209"
        x2="3.0792"
        y2="17.6304"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#344563" />
        <stop offset="0.9228" stopColor="#7A869A" />
      </linearGradient>
    </defs>
  </svg>
);
