import { token } from '@atlaskit/tokens';
import {
  BodyNormal,
  breakpointValues,
  FinePrint,
  H6,
  useResponsiveBreakpoint,
} from '@atlassian/mpac-primitives';
import { useHistory } from '@atlassian/mpac-ui';
import { styled } from '@compiled/react';
import React from 'react';

import { useGlobalNavAnalytics } from '../Analytics/useGlobalNavAnalytics';
import { dataTestIds } from '../constants';

const Wrapper = styled.a({
  display: 'flex',
  padding: `${token('space.100')} ${token('space.200')} ${token('space.050')} ${token(
    'space.100'
  )}`,
  marginBottom: `${token('space.050', '4px')}`,
  backgroundColor: token('elevation.surface.overlay'),
  borderRadius: '3px',
  cursor: 'pointer',
  minHeight: '78px',
  height: '100%',

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },

  '&:active': {
    textDecoration: 'none',
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },

  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
  },
});

const ImageWrapper = styled.div({
  height: '44px',
  width: '44px',
  marginRight: token('space.200'),
});

const FinePrintText = styled(FinePrint)({
  color: token('color.text.subtlest'),
  marginTop: token('space.025'),
  fontSize: '0.75rem',
});

const BodyNormalText = styled(BodyNormal)({
  color: token('color.text.subtlest'),
  marginTop: 0,
});

type DescriptiveItemProps = {
  iconUrl?: string;
  name: string;
  description: string;
  href: string;
  closeMenu: () => void;
  id?: string;
  focusFirstLinkUnderMenu?: React.RefObject<HTMLAnchorElement>;
};

export const DescriptiveItem = (props: DescriptiveItemProps) => {
  const { iconUrl, name, description, href, closeMenu, focusFirstLinkUnderMenu } = props;
  const history = useHistory();
  const { value: breakpoint } = useResponsiveBreakpoint();
  const isMobile = breakpoint < breakpointValues.md;
  const { fireNavBarLinkClickEvent } = useGlobalNavAnalytics();

  const onNavItemClick = (event) => {
    event.preventDefault();
    fireNavBarLinkClickEvent(name, href);
    if (history?.location.pathname !== href) {
      history?.push(href);
    }
    //adding this to follow H1 focus when same descriptive item clicked twice.
    else {
      history?.push({ ...history, action: 'PUSH' });
    }
    closeMenu();
  };

  return (
    <li>
      <Wrapper
        id={props.id}
        onClick={onNavItemClick}
        href={href}
        data-testid={`${dataTestIds.EXPANDABLE_MENU__ITEM}`}
        ref={props.id ? focusFirstLinkUnderMenu : undefined}
      >
        {iconUrl && (
          <ImageWrapper>
            <img src={iconUrl} alt={`${name} logo`} aria-hidden={true} />
          </ImageWrapper>
        )}
        <div>
          <H6 as="h3">{name}</H6>
          {isMobile ? (
            <BodyNormalText>{description}</BodyNormalText>
          ) : (
            <FinePrintText>{description}</FinePrintText>
          )}
        </div>
      </Wrapper>
    </li>
  );
};
