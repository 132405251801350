import { useEffect } from 'react';
import { getSuggestionSource } from '../../../AnalyticsUtils';
import { BODY_STATES } from '../../../constants';
export const useSearchViewAnalytics = _ref => {
  let {
    showSuggestionPopup,
    isQueryFromUrl,
    inputRef,
    onViewed,
    recentSearch,
    hasFiredViewedAnalyticsRef,
    autocompleteState,
    bodyState
  } = _ref;
  useEffect(() => {
    recentSearch.refreshRecentSearches();
    // HANDLE ANALYTICS FOR EMPTY QUERY
    if (showSuggestionPopup && !isQueryFromUrl && !hasFiredViewedAnalyticsRef.current) {
      onViewed({
        query: '',
        suggestionSource: getSuggestionSource(false),
        count: recentSearch.count()
      });
      hasFiredViewedAnalyticsRef.current = true;
    }
  }, [showSuggestionPopup, isQueryFromUrl, recentSearch, onViewed]);
  useEffect(() => {
    // HANDLE ANALYTICS FOR EXISTING QUERY
    if (showSuggestionPopup && isQueryFromUrl && !hasFiredViewedAnalyticsRef.current) {
      if ((bodyState === BODY_STATES.SEARCH_RESULTS || bodyState === BODY_STATES.NO_SUGGESTIONS) && autocompleteState.status === 'idle') {
        var _inputRef$current$val, _inputRef$current, _autocompleteState$co, _autocompleteState$co2, _autocompleteState$co3;
        onViewed({
          query: (_inputRef$current$val = (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.value) !== null && _inputRef$current$val !== void 0 ? _inputRef$current$val : '',
          suggestionSource: getSuggestionSource(true),
          count: (_autocompleteState$co = (_autocompleteState$co2 = autocompleteState.collections[0]) === null || _autocompleteState$co2 === void 0 ? void 0 : (_autocompleteState$co3 = _autocompleteState$co2.items) === null || _autocompleteState$co3 === void 0 ? void 0 : _autocompleteState$co3.length) !== null && _autocompleteState$co !== void 0 ? _autocompleteState$co : 0
        });
        hasFiredViewedAnalyticsRef.current = true;
      }
    }
  }, [showSuggestionPopup, bodyState]);
};