import { AtlassianProductHostingType } from './graphql-gateway-types';
export const HostingDisplayName = Object.freeze({
  [AtlassianProductHostingType.Cloud]: 'Cloud',
  [AtlassianProductHostingType.Server]: 'Server',
  [AtlassianProductHostingType.DataCenter]: 'Data Center'
});
export const HostingTypeToQueryParam = Object.freeze({
  [AtlassianProductHostingType.DataCenter]: 'datacenter',
  [AtlassianProductHostingType.Cloud]: 'cloud',
  [AtlassianProductHostingType.Server]: 'server'
});
export const HostingTypeToFiltersQueryParam = Object.freeze({
  [AtlassianProductHostingType.DataCenter]: 'dataCenter',
  [AtlassianProductHostingType.Cloud]: 'cloud',
  [AtlassianProductHostingType.Server]: 'server'
});
export const HostingQueryParamToHostingType = {
  [HostingTypeToQueryParam[AtlassianProductHostingType.Cloud]]: AtlassianProductHostingType.Cloud,
  [HostingTypeToQueryParam[AtlassianProductHostingType.Server]]: AtlassianProductHostingType.Server,
  [HostingTypeToQueryParam[AtlassianProductHostingType.DataCenter]]: AtlassianProductHostingType.DataCenter
};