import lscache from 'lscache';

const currentUserKey = 'CURRENT_USER_DATA';
const timeToLiveInMins = 60;

function get() {
  return lscache.get(currentUserKey);
}

function set(data) {
  lscache.set(currentUserKey, data, timeToLiveInMins);
}

function remove() {
  lscache.remove(currentUserKey);
}

export default { get, set, remove };
