import { useCallback, useEffect, useRef } from 'react';

import { FIRST_FOCUSABLE_MENU_ELEMENT, LAST_FOCUSABLE_MENU_ELEMENT } from './constants';
import { ExpandableMenuType } from './ExpandableMenu';

import type { ExpandableMenuRef } from './ExpandableMenu';

type UseDesktopKeyboardNavigation = {
  openedMenu: ExpandableMenuType | null;
  isMenuOpen: boolean;
  closeMenu: () => void;
};

export const useDesktopKeyboardNavigation = ({
  openedMenu,
  isMenuOpen,
  closeMenu,
}: UseDesktopKeyboardNavigation) => {
  const expandableMenuRef = useRef<ExpandableMenuRef>({
    triggers: {
      [ExpandableMenuType.APPS_FOR_PRODUCTS]: null,
      [ExpandableMenuType.CATEGORIES]: null,
      [ExpandableMenuType.RESOURCES]: null,
      [ExpandableMenuType.FIRST_LINK_TO_FOCUS]: null,
    },
  });

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        return handleEscapePress();
      }
      if (e.key === 'Tab' && e.shiftKey) {
        return handleShiftTabPress();
      } else if (e.key === 'Tab') {
        return handleTabPress();
      } else if (['Enter', 'NumpadEnter'].includes(e?.code)) {
        return focusOnFirstLinkUnderGlobalNav();
      }
    },
    [isMenuOpen]
  );
  const onkeyUp = useCallback(
    (e) => {
      if (
        ['Space'].includes(e?.code) &&
        Object.values(expandableMenuRef.current?.triggers).includes(e?.target)
      ) {
        return focusOnFirstLinkUnderGlobalNav();
      }
    },
    [isMenuOpen]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    //adding this to handle Space click event
    document.addEventListener('keyup', onkeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onkeyUp);
    };
  }, [onKeyDown, onkeyUp]);

  const focusOpenedMenuButton = () => {
    if (openedMenu === ExpandableMenuType.APPS_FOR_PRODUCTS) {
      expandableMenuRef.current?.triggers[ExpandableMenuType.APPS_FOR_PRODUCTS]?.focus();
    } else if (openedMenu === ExpandableMenuType.CATEGORIES) {
      expandableMenuRef.current?.triggers[ExpandableMenuType.CATEGORIES]?.focus();
    } else if (openedMenu === ExpandableMenuType.RESOURCES) {
      expandableMenuRef.current?.triggers[ExpandableMenuType.RESOURCES]?.focus();
    }
  };

  const handleEscapePress = () => {
    if (isMenuOpen) {
      focusOpenedMenuButton();
      closeMenu();
    }
  };

  const handleTabPress = () => {
    if (isMenuOpen && document.activeElement?.id === LAST_FOCUSABLE_MENU_ELEMENT) {
      closeMenu();
    }
  };

  const handleShiftTabPress = () => {
    if (isMenuOpen && document.activeElement?.id === FIRST_FOCUSABLE_MENU_ELEMENT) {
      closeMenu();
    }
  };

  const focusOnFirstLinkUnderGlobalNav = useCallback(() => {
    setTimeout(() => {
      if (expandableMenuRef.current?.triggers?.[ExpandableMenuType.FIRST_LINK_TO_FOCUS])
        expandableMenuRef.current?.triggers?.[ExpandableMenuType.FIRST_LINK_TO_FOCUS]?.focus();
    });
  }, []);

  return {
    expandableMenuRef,
  };
};
