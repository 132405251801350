export const Analytics = {
  source: 'collectionScreen',
  subjectId: {
    screenRendered: 'collectionScreenRendered',
    appTilesRendered: 'collectionAppTilesRendered',
    appTileClicked: 'collectionAppTileClicked',
    loadMoreClicked: 'collectionLoadMoreClicked',
    sortOptionSelected: 'collectionSortOptionSelected',
  },
  event: {
    globalError: 'global',
    initialError: 'initial',
    loadMoreError: 'load more',
  },
};
