import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class CodeSplitComponentInner extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
  };

  render() {
    // @ts-expect-error [MC-2850] - TS2339 - Property 'component' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
    const { component, ...passThroughProps } = this.props;
    const PassedComponent = component;
    return <PassedComponent {...passThroughProps} />;
  }
}
