import memoize from 'lodash/memoize';
import { GetProductCategoryAndKeywords } from '../../../listing-services';
import { transposeProductTagsToFilters } from '../utils/ProductTagsToFiltersTranspose';
export const CategoriesUsecasesFilterMemoized = memoize(async stargateBaseUrl => {
  try {
    const serviceResponse = await GetProductCategoryAndKeywords({
      stargateBaseUrl
    });
    return transposeProductTagsToFilters({
      data: serviceResponse
    });
  } catch (error) {
    throw new Error('Error in SearchFiltersHandler');
  }
});

/**
 * Fetches & memoizes the categories and use cases filters
 * Errors are not memoized
 */
export const CategoriesUsecasesFilter = async stargateBaseUrl => {
  try {
    return await CategoriesUsecasesFilterMemoized(stargateBaseUrl);
  } catch (error) {
    CategoriesUsecasesFilterMemoized.cache.delete(stargateBaseUrl);
    throw error;
  }
};