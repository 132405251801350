import { connect } from 'react-redux';

import {
  closeSubscribeDialog,
  dismissFlag,
  subscribe,
} from '../action/SubscribeMailingListActions';
import SubscribeMailingListDialog from '../component';

function mapStateToProps(state) {
  return {
    isOpen: state.subscribeMailingList.get('dialogOpen'),
    success: state.subscribeMailingList.get('success'),
    errorReason: state.subscribeMailingList.get('errorReason'),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onModalDismissed: () => dispatch(closeSubscribeDialog()),
    onClickSubscribe: ({ emailId, listIds }) => dispatch(subscribe({ emailId, listIds })),
    onFlagDismissed: () => dispatch(dismissFlag()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeMailingListDialog);
