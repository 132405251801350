import { List, Record } from 'immutable';

import LinkTemplate from '../../common/records/LinkTemplate';

export class SolutionIdentifier extends Record({
  key: '',
  name: '',
}) {}

export class SolutionsLinks extends Record({
  solution: new LinkTemplate(),
}) {}

export class SolutionsMetricsKeys extends Record({
  solution: '',
}) {}

export default class SolutionsConfig extends Record({
  solutions: List(),
  links: new SolutionsLinks(),
  metricsKeys: new SolutionsMetricsKeys(),
}) {}

export const resolveSolutionsConfig = ({ appConfig }) =>
  new SolutionsConfig({
    solutions: appConfig
      .getIn(['solutions', 'solutions'])
      .map((s) => new SolutionIdentifier({ key: s.get('key'), name: s.get('name') })),
    links: new SolutionsLinks({
      solution: new LinkTemplate({
        href: appConfig.getIn(['solutions', 'links', 'solution', 'href']),
      }),
    }),
    metricsKeys: new SolutionsMetricsKeys({
      solution: appConfig.getIn(['solutions', 'metricsKeys', 'solution']),
    }),
  });
