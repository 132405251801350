import { Meta } from '@atlassian/mpac-primitives';
import { ErrorView, StaticContextRoute } from '@atlassian/mpac-ui';
import React from 'react';

import type { RouterHistory } from 'react-router';

export type ErrorPageProps = {
  homeLink: string;
  heading: string;
  primaryMessage: string;
  history?: RouterHistory;
  image: string;
  statusCode: number;
  title: string;
};

const ErrorPage = (props: ErrorPageProps) => {
  const { title, statusCode, ...errorViewProps } = props;
  return (
    <StaticContextRoute
      statusCode={statusCode}
      render={() => (
        <>
          <Meta title={title} />
          <ErrorView statusCode={statusCode} {...errorViewProps} />
        </>
      )}
    />
  );
};

export default ErrorPage;
