import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { getActiveInstallsData } from '@atlassian/mpac-api-client';
import { DateUtils } from '@atlassian/mpac-utils';
import { BaseHandler } from '../../../graphql-orchestrator-link';
import { transposeDistributionsData } from '../utils/DistributionTransposeUtils';
export class ActiveInstallsHandler extends BaseHandler {
  constructor(mpacBaseUrl) {
    super();
    _defineProperty(this, "mpacBaseUrl", void 0);
    this.mpacBaseUrl = mpacBaseUrl;
  }
  responseTransformer(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    var _customResponse$activ, _customResponse$activ2;
    const {
      groupBy
    } = operation.variables;
    if (customResponse !== null && customResponse !== void 0 && (_customResponse$activ = customResponse.activeInstallsWeekData) !== null && _customResponse$activ !== void 0 && (_customResponse$activ2 = _customResponse$activ.records) !== null && _customResponse$activ2 !== void 0 && _customResponse$activ2.length) {
      return {
        marketplaceConsoleAppActiveInstalls: transposeDistributionsData(customResponse.activeInstallsWeekData, groupBy, customResponse === null || customResponse === void 0 ? void 0 : customResponse.activeInstallsDayData)
      };
    }
    return {
      marketplaceConsoleAppActiveInstalls: []
    };
  }
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    const {
      appKey,
      startDate,
      endDate,
      groupBy,
      partnerId
    } = operation.variables;
    const activeInstallsPromises = [getActiveInstallsData(this.mpacBaseUrl, {
      appKey,
      startDate,
      endDate,
      groupBy,
      partnerId,
      granularity: 'WEEK'
    }), getActiveInstallsData(this.mpacBaseUrl, {
      appKey,
      startDate: DateUtils.ISOFormatHyphenatedDate(DateUtils.subtractDateByDays(endDate, 3)),
      endDate,
      groupBy,
      partnerId,
      granularity: 'DAY'
    })];
    const [activeInstallsWeekData, activeInstallsDayData] = await Promise.allSettled(activeInstallsPromises);
    if (activeInstallsWeekData.status === 'fulfilled') {
      return {
        activeInstallsWeekData: activeInstallsWeekData.value,
        activeInstallsDayData: activeInstallsDayData.status === 'fulfilled' ? activeInstallsDayData.value : null
      };
    }
    throw new Error('Failed to fetch active installs data');
  }
}