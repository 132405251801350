export const TrustFilters = () => {
  return [{
    label: 'Runs on Atlassian',
    id: 'runsOnAtlassian',
    value: 'runs-on-atlassian'
  }, {
    label: 'Cloud Fortified',
    id: 'cloudFortified',
    value: 'approved'
  }, {
    label: 'Cloud Security Participant',
    id: 'bugBountyParticipant',
    value: 'bugBountyApproved'
  }];
};