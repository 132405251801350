/**
 * gets browser timing for event `name` from window object.
 *
 * `window.performance.mark(name)` will set the timing value
 * and should have been triggered before getMark
 *
 * @param {string} name
 * @returns number | null
 */
export const getMark = name => {
  var _window$performance;
  const entries = ((_window$performance = window.performance) === null || _window$performance === void 0 ? void 0 : _window$performance.getEntriesByName) && window.performance.getEntriesByName(name);
  const mark = entries && entries[0];
  return (mark === null || mark === void 0 ? void 0 : mark.startTime) || null;
};

/**
 * Used for browser metrics pageLoad function
 *
 * @returns `{doneAsFmp: true}` when SSR happened successfully
 * else @returns undefined
 */
export const getSSRConfig = () => typeof window.__SSR_RENDERED__ === 'boolean' && window.__SSR_RENDERED__ ? {
  doneAsFmp: true
} : undefined;