import { TwitterLogo } from './TwitterLogo';
import { YoutubeLogo } from './YoutubeLogo';

export const LEARN_AND_EXPLORE_LINKS = [
  { text: 'What’s Marketplace', href: 'https://developer.atlassian.com/platform/marketplace' },
  {
    text: 'App installation',
    href: 'https://confluence.atlassian.com/upm/installing-add-ons-273875715.html',
  },
  { text: 'About Atlassian', href: 'https://www.atlassian.com' },
  { text: 'Atlassian resources', href: 'https://www.atlassian.com/resources' },
  {
    text: 'Search and ranking',
    href: 'https://developer.atlassian.com/platform/marketplace/marketplace-search-results-and-rankings',
  },
  {
    text: 'Atlassian events',
    href: 'https://www.atlassian.com/company/events',
  },
  {
    text: 'Atlassian foundation',
    href: 'https://www.atlassianfoundation.org/',
  },
];

export const CONNECT_LINKS = [
  { text: 'Get support', href: 'https://support.atlassian.com/' },
  {
    text: 'Partner connect',
    href: 'https://atlassianpartners.atlassian.net/wiki/spaces/resources/overview?mode=global',
  },
  { text: 'Developer resources', href: 'https://developer.atlassian.com/' },
  {
    text: 'Solution partner directory',
    href: 'https://partnerdirectory.atlassian.com/?page=1&expertise=Custom%20Development',
  },
  {
    text: 'Atlassian communication channels',
    href: 'https://developer.atlassian.com/developer-guide/communication-channels/',
  },
];

export const MEDIA_LINKS = [
  {
    key: 'youtube-logo',
    image: YoutubeLogo,
    href: 'https://www.youtube.com/@Atlassian',
    ariaLabel: 'Atlassian on Youtube',
  },
  {
    key: 'twitter-logo',
    image: TwitterLogo,
    href: 'https://twitter.com/atlassianmarket',
    ariaLabel: 'Atlassian on Twitter',
  },
];

export const PRIVACY_LINK = 'https://www.atlassian.com/legal/privacy-policy';
export const TERMS_OF_USE_LINK = 'https://www.atlassian.com/licensing/marketplace/termsofuse';
export const WAC_LINK = 'https://www.atlassian.com/';
