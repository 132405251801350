export const OtherFilters = () => {
  return [{
    label: 'Beta',
    id: 'stable',
    value: 'false'
  }, {
    label: 'Partner supported',
    id: 'vendorSupported',
    value: 'true'
  }];
};