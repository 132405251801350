import { CategoriesUsecasesFilter } from './CategoriesUsecasesFilter';
import { HostingFilters } from './HostingFilters';
import { OtherFilters } from './OtherFilters';
import { PricingFilters } from './PricingFilters';
import { ProductFilters } from './ProductFilters';
import { TrustFilters } from './TrustFilters';
import { VersionHistoryHostingFilters } from './VersionHistoryProductFilters';
const SearchFilters = async filterParams => {
  switch (filterParams.filterId) {
    case 'product':
      return ProductFilters(filterParams);
    case 'hosting':
      return HostingFilters();
    case 'price':
      return PricingFilters();
    case 'trustSignals':
      return TrustFilters();
    case 'other':
      return OtherFilters();
    case 'useCases':
    case 'categories':
      {
        const response = await CategoriesUsecasesFilter(filterParams.stargateBaseUrl);
        if (filterParams.filterId === 'useCases') {
          return response.useCases.options;
        } else {
          return response.categories.options;
        }
      }
    case 'versionHistoryHosting':
      {
        return VersionHistoryHostingFilters();
      }
    default:
      throw new Error("Unknown filterId");
  }
};
export default SearchFilters;