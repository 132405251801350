import { ApolloLink } from '@apollo/client';
import { GQLOrchestrator } from './gqlOrchestrator';
/**
 * Return a GQLOrchestrator Link or a NOOP Link based on if the isEnabled is true/false.
 * In case for any GQL call if configuration is NOT provided then `default` configuration
 * would be used.
 *
 * EG: Sample configuration:
 * {
 *  isEnabled: true,
 *  config: { // DLA orchestration configuration
 *    GetMarketplaceAppListingById: { // GQL call for which handling needs to be done.
 *      config: {
 *        sequence: SequenceTypes.PRIMARY_ONLY, // type of sequence to use out of the given 5 sequece types.
 *        handler: new BaseHandler(), // handler to handle the given GQL call
 *      }
 *    }
 *  }
 * }
 *
 *
 * `Default` DLA orchestration Configuration:
 * {
 *   default: {
 *       operationName: 'default'
 *       config: {
 *         sequence: SequenceTypes.PRIMARY_ONLY,
 *         handler: new BaseHandler(),
 *       }
 *     }
 * }
 *
 *
 *
 * @param options isEnabled + DLA orchestration configuration
 * @returns ApolloLink
 */
function createGQLOrchestrationLink(options) {
  if (options && options.isEnabled) {
    const gqlOrchestratorInstance = new GQLOrchestrator(options.config, options.middlewares || []);
    return new ApolloLink((operation, forward) => {
      return gqlOrchestratorInstance.orchestrate(operation, forward);
    });
  }
  return new ApolloLink((operation, forward) => forward(operation));
}
export { createGQLOrchestrationLink };