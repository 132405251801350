import Avatar from '@atlaskit/avatar';
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Tooltip from '@atlaskit/tooltip';
import React, { useState } from 'react';

import { trackUserActionClicked } from '../../../../../../common/utils/analytics/analytics';
import { useResolvedCurrentUser } from '../../../../../context/CurrentUserContext';
import { useGlobalNavAnalytics } from '../../Analytics/useGlobalNavAnalytics';
import { dataTestIds, USER_DROPDOWN_ITEMS } from '../../constants';
import { LinkItem, UserDetailsItem } from '../DropdownItems';
import { TriggerAvatarWrapper } from './UserDropdown.styles';

import type { UserProps } from '../../userTypes';
import type { Ref } from 'react';

type UserDropdownProps = {
  userProps: UserProps;
  onOpen?: () => void;
};

export const DropdownTrigger = ({ triggerRef, userProps, ...props }) => {
  const { loggedInUser } = useResolvedCurrentUser({ userProps });
  return (
    <TriggerAvatarWrapper
      aria-label={`${loggedInUser?.name} profile and settings`}
      ref={triggerRef}
      data-testId={dataTestIds.USER_DROPDOWN}
      {...props}
    >
      <Avatar appearance="circle" size="medium" src={loggedInUser?.picture} />
    </TriggerAvatarWrapper>
  );
};

export const DropdownItems = ({ userProps }: { userProps: UserProps }) => {
  const { loggedInUser } = useResolvedCurrentUser({ userProps });

  const openInNewTabProps = {
    showShortcutIcon: true,
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  const onSwitchAccountClick = () => {
    trackUserActionClicked(USER_DROPDOWN_ITEMS.SWITCH_ACCOUNT);
    userProps.onSwitchAccount();
  };

  const onSignOutClick = () => {
    userProps.onLogout();
    trackUserActionClicked(USER_DROPDOWN_ITEMS.SIGN_OUT);
  };

  return (
    <>
      <DropdownItemGroup title="Atlassian account">
        <UserDetailsItem userProps={userProps} />
        <LinkItem
          label={USER_DROPDOWN_ITEMS.SWITCH_ACCOUNT}
          href={loggedInUser?.loggedInUserLinks?.switchAccount}
          showNewLozenge={true}
          onClick={onSwitchAccountClick}
        />
        <LinkItem
          label={USER_DROPDOWN_ITEMS.MANAGE_ACCOUNT}
          href={loggedInUser?.loggedInUserLinks?.manageAccount}
          onClick={() => trackUserActionClicked(USER_DROPDOWN_ITEMS.MANAGE_ACCOUNT)}
          {...openInNewTabProps}
        />
      </DropdownItemGroup>
      <DropdownItemGroup hasSeparator={true} title="Marketplace partner">
        <LinkItem
          label={USER_DROPDOWN_ITEMS.MANAGE_PARTNER_ACCOUNT}
          href={loggedInUser?.loggedInUserLinks?.manageDeveloperSpace}
          onClick={() => trackUserActionClicked(USER_DROPDOWN_ITEMS.MANAGE_PARTNER_ACCOUNT)}
          {...openInNewTabProps}
        />
        {loggedInUser?.loggedInUserLinks?.createAddon && (
          <LinkItem
            label={USER_DROPDOWN_ITEMS.PUBLISH_NEW_APP}
            href={loggedInUser?.loggedInUserLinks?.createAddon}
            onClick={() => trackUserActionClicked(USER_DROPDOWN_ITEMS.PUBLISH_NEW_APP)}
            {...openInNewTabProps}
          />
        )}
        {loggedInUser?.loggedInUserLinks?.admin && (
          <LinkItem
            label={USER_DROPDOWN_ITEMS.SITE_ADMIN}
            href={loggedInUser?.loggedInUserLinks?.admin}
            onClick={() => trackUserActionClicked(USER_DROPDOWN_ITEMS.SITE_ADMIN)}
          />
        )}
      </DropdownItemGroup>
      <DropdownItemGroup hasSeparator={true}>
        <LinkItem
          label={USER_DROPDOWN_ITEMS.SIGN_OUT}
          href={loggedInUser?.loggedInUserLinks?.logout}
          onClick={onSignOutClick}
        />
      </DropdownItemGroup>
    </>
  );
};

export const UserDropdown = ({ userProps, onOpen }: UserDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { fireUserProfileClickEvent } = useGlobalNavAnalytics();

  const { loggedInUser } = useResolvedCurrentUser({ userProps });

  if (!loggedInUser) return null;

  const onOpenChange = ({ isOpen }) => {
    onOpen?.();
    setIsDropdownOpen(isOpen);
    if (isOpen) fireUserProfileClickEvent();
  };

  return (
    <DropdownMenu
      appearance="tall"
      placement="bottom-end"
      isOpen={isDropdownOpen}
      onOpenChange={onOpenChange}
      trigger={({ triggerRef, ...props }) => (
        <Tooltip content="Your profile and settings" hideTooltipOnClick={true}>
          <DropdownTrigger
            triggerRef={triggerRef as Ref<HTMLButtonElement>}
            userProps={userProps}
            {...props}
          />
        </Tooltip>
      )}
    >
      <DropdownItems userProps={userProps} />
    </DropdownMenu>
  );
};
