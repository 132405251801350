import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Events } from './events';
import TaskTracker from './TaskTracker';
import Deferred from './utils/Deferred';
import MessageQueue from './utils/MessageQueue';
import { getNavigationTiming } from './utils/observers';
export default class Telemetry {
  static setPage() {
    let pageName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'PAGE_UNKNOWN';
    // If we'd previously set a page and are changing it now,
    // it should be a client side render
    if (Telemetry.activePageName && Telemetry.activePageName !== pageName) {
      Telemetry.isInitialRender = false;
      Telemetry.pageNavigationStartTime = performance.now();
      Telemetry.isPageReady.resolve();
    }
    Telemetry.activePageName = pageName;
  }
  static fireComponentBoundaryError(componentName, error) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_COMPONENT_ERROR_BOUNDARY,
      componentName,
      error
    });
    Telemetry.abortAllTasks(error);
  }
  static fireSearchTiming(duration) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_ALGOLIA_SEARCH_TIMING,
      duration
    });
  }
  static task(options) {
    const {
      successful,
      task,
      taskId,
      duration,
      cause
    } = options;
    Telemetry.messageQueue.emit({
      type: Events.LABEL_GENERIC_TASK,
      successful,
      task,
      taskId,
      duration,
      cause
    });
  }
  static _createTask(taskName, taskType) {
    const createdTask = new TaskTracker(taskName);
    createdTask.addCompletionListener(args => {
      const {
        success,
        duration,
        cause,
        taskId,
        task
      } = args;
      Telemetry.messageQueue.emit(_objectSpread(_objectSpread({}, taskType), {}, {
        successful: success,
        task,
        taskId,
        duration,
        cause
      }));
    });
    Telemetry.registeredTasks.push(createdTask);
    return createdTask;
  }
  static createPageViewTask(taskName) {
    return Telemetry._createTask(taskName, {
      type: Events.LABEL_PAGE_VIEW_TASK
    });
  }
  static createGenericTask(taskName) {
    return Telemetry._createTask(taskName, {
      type: Events.LABEL_GENERIC_TASK
    });
  }
  static abortAllTasks(error) {
    Telemetry.registeredTasks.forEach(task => task.failed(error));
  }
  static routeChangeStart() {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_ROUTE_CHANGE_START
    });
  }
  static timeToFirstPaint(duration) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_TIME_TO_FIRST_CONTENTFUL_PAINT,
      duration
    });
  }
  static timeToFirstMeaningfulPaint(endTime) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_TIME_TO_FIRST_MEANINGFUL_PAINT,
      endTime
    });
  }
  static timeToLargestContentfulPaint(duration) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_TIME_TO_LARGEST_CONTENTFUL_PAINT,
      duration
    });
  }
  static firstInputDelay(duration) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_FIRST_INPUT_DELAY,
      duration
    });
  }
  static timeToFirstByte(duration) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_TIME_TO_FIRST_BYTE,
      duration
    });
  }
  static timeToInteractive(duration) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_TIME_TO_INTERACTIVE,
      duration
    });
  }
  static cumulativeLayoutShift(shift) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_CUMULATIVE_LAYOUT_SHIFT,
      shift
    });
  }
  static resourceDownloadTime(type, duration) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_RESOURCE_DOWNLOAD_TIME_SUM,
      duration,
      resourceType: type
    });
  }
  static traceUFOPageLoad(routeName) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_TRACE_UFO_PAGE_LOAD,
      routeName
    });
  }
  static setUFOInteractionError(error, interactionName) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_UFO_INTERACTION_ERROR,
      error,
      interactionName
    });
  }
  static traceUFOPress(interactionName) {
    Telemetry.messageQueue.emit({
      type: Events.LABEL_TRACE_UFO_PRESS,
      interactionName
    });
  }
}
_defineProperty(Telemetry, "messageQueue", new MessageQueue());
_defineProperty(Telemetry, "registeredTasks", []);
_defineProperty(Telemetry, "isInitialRender", true);
_defineProperty(Telemetry, "activePageName", '');
_defineProperty(Telemetry, "pageNavigationStartTime", getNavigationTiming().fetchStart);
_defineProperty(Telemetry, "isPageReady", new Deferred());