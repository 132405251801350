import { useParams } from '@atlassian/mpac-ui';
import React from 'react';

import codeSplitComponentFactory from '../../../../common/codeSplitComponentFactory';
import BaseRouteHandler from '../../common/routeHandlers/BaseRouteHandler';

export const initialize = () => {
  const SolutionsCodeSplit = codeSplitComponentFactory(() =>
    import(/* webpackPrefetch: true, webpackChunkName: "chunk-solutions" */ './Solution').then(
      (c) => c.default
    )
  );
  const SolutionsRouteHandler = (props) => {
    const { categoryId } = useParams();
    return (
      <BaseRouteHandler scrollToTopOnPathChange includeFooter includeSearch>
        <SolutionsCodeSplit solutionKey={categoryId} {...props} />
      </BaseRouteHandler>
    );
  };

  return SolutionsRouteHandler;
};

export default initialize();
