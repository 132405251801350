import { MarketplaceAnalyticsContext } from '@atlassian/mpac-analytics';
import { useParams } from '@atlassian/mpac-ui';
import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../common/routeHandlers/BaseRouteHandler';

const HomePageCodeSplit = lazyForPaint(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "chunk-home" */ './AddonHomePageRouteProcessor'
  ).then((module) => module.default)
);

const RouteHandler = (props) => {
  const { productId } = useParams();

  return (
    <BaseRouteHandler scrollToTopOnPathChange includePartnerQuickLinks>
      <MarketplaceAnalyticsContext
        properties={{ source: productId ? 'productHomeScreen' : 'homeScreen' }}
      >
        <LazySuspense fallback={<div />}>
          <HomePageCodeSplit {...props} />
        </LazySuspense>
      </MarketplaceAnalyticsContext>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
