/**
 * Transposes product tags into filters.
 *
 * This function groups product tags by their types and constructs filter options for each type.
 * Each filter option includes the name of the tag as the label, and the id of the tag as the optionId and value.
 *
 * @returns {Object} groupedByTagType - An object where each property is a tag type, and the value is an array of filter options corresponding to that tag type.
 */
export function transposeProductTagsToFilters(_ref) {
  let {
    data
  } = _ref;
  // Reduce function is used to transform the array of tags into an object where keys are tag types and values are arrays of corresponding filter options.
  const groupedByTagType = data.values.reduce((accumulator, _ref2) => {
    let {
      tagType,
      name,
      id
    } = _ref2;
    const option = {
      label: name,
      id: name,
      value: id
    };
    // If the accumulator object doesn't have a property corresponding to the current tag type, create it and assign an empty array as its value.
    if (!accumulator[tagType]) {
      accumulator[tagType] = {
        options: [option]
      };
    } else {
      // Push a new filter option into the array corresponding to the current tag type.
      accumulator[tagType].options.push({
        label: name,
        id: name,
        value: id
      });
    }
    return accumulator;
  }, {});
  return {
    categories: groupedByTagType.CATEGORY,
    useCases: groupedByTagType.KEYWORDS
  };
}