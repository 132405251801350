import { Route } from '@atlassian/mpac-ui';
import * as React from 'react';
import { Switch } from 'react-router';

import RoutePermissionHandler from '../../../../../../../common/components/RoutePermissionHandler';

import type { PARTNER_NAV_ITEMS } from '@atlassian/mpac-utils';

type Props = {
  routesConfigData: RoutesConfigData[];
  componentProps: Record<string, any>;
};

export type RoutesConfigData = {
  selectedTab?: PARTNER_NAV_ITEMS;
  path: string;
  isPermitted: boolean;
  component: React.ComponentType<any>;
  exact: boolean;
};

export const RouteSwitcher = ({ routesConfigData, componentProps }: Props) => {
  return (
    <Switch>
      {routesConfigData.map((config) => (
        <Route
          path={config.path}
          key={config.path}
          render={(_) => (
            <RoutePermissionHandler hasPermission={config.isPermitted}>
              <config.component {...componentProps} selectedTab={config.selectedTab} />
            </RoutePermissionHandler>
          )}
          exact={config.exact}
        />
      ))}
    </Switch>
  );
};
