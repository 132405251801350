/**
 * This is usually used with exhaustive union type handling
 * It provides compile time validation that we have handled all the cases in the union type
 * It also returns an error to throw which will help at runtime.
 * To know more about it : https://medium.com/@ibosz/advance-flow-type-1-exhaustive-checking-with-empty-type-a02e503cd3a0
 * @param impossible
 * @return {Error}
 */
function unexpectedCase(impossible) {
  return new Error("Unexpected case ".concat(impossible));
}
export default {
  unexpectedCase
};