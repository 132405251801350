import _defineProperty from "@babel/runtime/helpers/defineProperty";
import Telemetry from '@atlassian/mpac-telemetry';
import { log } from '@atlassian/mpac-utils';
import { Component } from 'react';
export default class ErrorBoundary extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      hasError: false
    });
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    log.error("".concat(this.props.boundaryName, " Error Boundary"), 'Error in render', {
      error,
      extraInfo: {
        componentStack: errorInfo.componentStack
      }
    });
    Telemetry.fireComponentBoundaryError(this.props.boundaryName, error);
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}