import { useScrollLock } from '@atlassian/mpac-primitives';
import { useState } from 'react';

import { useGlobalNavAnalytics } from '../Analytics/useGlobalNavAnalytics';

import type { ExpandableMenuType } from './types';

export const useExpandableMenuState = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openedMenu, setOpenedMenu] = useState<ExpandableMenuType | null>(null);
  const { fireNavBarItemClickEvent } = useGlobalNavAnalytics();
  useScrollLock(isMenuOpen);

  const openMenu = (menuType: ExpandableMenuType) => () => {
    setIsMenuOpen(true);
    setOpenedMenu(menuType);
    fireNavBarItemClickEvent(menuType);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return {
    closeMenu,
    openMenu,
    isMenuOpen,
    openedMenu,
  };
};
