import { Record } from 'immutable';

export class AlgoliaConfig extends Record({
  applicationId: '',
  apiKey: '',
}) {}

export class ContentfulConfig extends Record({
  authToken: '',
  spaceId: '',
  previewMode: false,
}) {}

export default class CommonConfig extends Record({
  algoliaConfig: new AlgoliaConfig(),
  contentfulConfig: new ContentfulConfig(),
  googleAnalyticsId: '',
  googleAnalyticsGa4Id: '',
  environment: '',
}) {}

export const resolveCommonConfig = ({ appConfig }) =>
  new CommonConfig({
    algoliaConfig: new AlgoliaConfig({
      applicationId: appConfig.getIn(['commonConfig', 'algoliaConfig', 'applicationId']),
      apiKey: appConfig.getIn(['commonConfig', 'algoliaConfig', 'apiKey']),
    }),
    contentfulConfig: new ContentfulConfig({
      authToken: appConfig.getIn(['commonConfig', 'contentfulConfig', 'authToken']),
      spaceId: appConfig.getIn(['commonConfig', 'contentfulConfig', 'spaceId']),
      previewMode: appConfig.getIn(['commonConfig', 'contentfulConfig', 'previewMode']),
    }),
    googleAnalyticsId: appConfig.getIn(['commonConfig', 'googleAnalyticsId']),
    googleAnalyticsGa4Id: appConfig.getIn(['commonConfig', 'googleAnalyticsGa4Id']),
    environment: appConfig.getIn(['commonConfig', 'environment']),
  });
