import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ApolloLink, from as chainLinks } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { log, objectToPathValuePair } from '@atlassian/mpac-utils';
import { createGQLOrchestrationLink } from '../graphql-orchestrator-link';
const ERROR_LINK_LOG_PREFIX = '[Apollo GraphQL]';

/**
 * Create an error link to capture & report errors from a single place.
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-error/
 **/
const createErrorLink = () => {
  // get details from the base fetch response error
  const getNetworkErrorDetails = networkError => {
    if ('response' in networkError) {
      var _ref, _serverError$statusCo, _serverError$response;
      const serverError = networkError;
      return {
        message: networkError.message,
        statusCode: (_ref = (_serverError$statusCo = serverError.statusCode) !== null && _serverError$statusCo !== void 0 ? _serverError$statusCo : (_serverError$response = serverError.response) === null || _serverError$response === void 0 ? void 0 : _serverError$response.status) !== null && _ref !== void 0 ? _ref : 'unknown',
        // only populated in same-origin requests, or if 'Access-Control-Expose-Headers' is specified
        requestId: serverError === null || serverError === void 0 ? void 0 : serverError.response.headers.get('x-request-id'),
        traceId: serverError === null || serverError === void 0 ? void 0 : serverError.response.headers.get('x-trace-id')
      };
    }
    networkError;
    return {
      message: networkError.message
    };
  };

  // GraphQL errors are populated directly from the GraphQL response's "errors" field. This may contain
  // a list of errors from various sources - gateway, service etc.
  const getGraphQLErrorDetails = graphQLErrors => {
    return objectToPathValuePair({
      graphQLErrors: graphQLErrors.map(_ref2 => {
        let {
          message,
          extensions
        } = _ref2;
        return {
          message,
          // Extensions are populated by the service
          // See https://developer.atlassian.com/platform/graphql-gateway/standards/error-categorisation/#graphql-error-categorization
          extensions
        };
      })
    });
  };
  // response is only populated when the body is a parseable GraphQL response
  const getErrorLinkResponseDetails = response => {
    var _extensions$gateway$r, _extensions, _extensions$gateway;
    return {
      // https://developer.atlassian.com/platform/graphql-gateway/devloop/debugging-page/#using-request-ids
      gatewayRequestId: (_extensions$gateway$r = response === null || response === void 0 ? void 0 : (_extensions = response.extensions) === null || _extensions === void 0 ? void 0 : (_extensions$gateway = _extensions.gateway) === null || _extensions$gateway === void 0 ? void 0 : _extensions$gateway.request_id) !== null && _extensions$gateway$r !== void 0 ? _extensions$gateway$r : 'unknown'
    };
  };
  return onError(_ref3 => {
    let {
      graphQLErrors,
      networkError,
      operation,
      response
    } = _ref3;
    if (graphQLErrors) {
      log.error(ERROR_LINK_LOG_PREFIX, "Operation \"".concat(operation.operationName, "\" failed"), _objectSpread(_objectSpread(_objectSpread({}, getErrorLinkResponseDetails(response)), getGraphQLErrorDetails(graphQLErrors)), {}, {
        variables: operation.variables
      }));
    }
    if (networkError) {
      log.error(ERROR_LINK_LOG_PREFIX, "Network error in operation \"".concat(operation.operationName, "\""), _objectSpread(_objectSpread(_objectSpread({}, getErrorLinkResponseDetails(response)), getNetworkErrorDetails(networkError)), {}, {
        variables: operation.variables
      }));
    }
  });
};

/**
 * Creates a context link:
 */
const createContextLink = _ref4 => {
  let {
    getClient
  } = _ref4;
  return new ApolloLink((operation, forward) => {
    operation.setContext({
      getClient
    });
    return forward(operation);
  });
};

/**
 * Compose links for apollo
 * @see https://www.apollographql.com/docs/react/api/link/introduction/#composing-a-link-chain
 */
export const createApolloLinks = _ref5 => {
  let {
    uri,
    name,
    version,
    gqlOrchestratorLinkOptions,
    getClient
  } = _ref5;
  return chainLinks([createContextLink({
    getClient
  }), createErrorLink(), createGQLOrchestrationLink(gqlOrchestratorLinkOptions),
  // httpLink must be the last link, since it is a terminating link
  new HttpLink({
    uri,
    credentials: 'include',
    headers: {
      'atl-client-name': name,
      'atl-client-version': version
    }
  })]);
};