import { MarketplaceAnalyticsContext } from '@atlassian/mpac-analytics';
import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../Routes/components/common/routeHandlers/BaseRouteHandler';
import { Analytics } from './constants/analytics';

const CategoriesPageCodeSplit = lazyForPaint(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "chunk-categoriesPage" */ './AddonCategoryPageRouteProcessor'
  ).then((module) => module.default)
);

const RouteHandler = (props) => {
  return (
    <BaseRouteHandler scrollToTopOnPathChange includePartnerQuickLinks>
      <MarketplaceAnalyticsContext properties={{ source: Analytics.source }}>
        <LazySuspense fallback={<div />}>
          <CategoriesPageCodeSplit {...props} />
        </LazySuspense>
      </MarketplaceAnalyticsContext>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
