import { UrlUtils } from '@atlassian/mpac-utils';

import PartnerProfileRouteHandler from '../../../../pages/partnerProfile/PartnerProfileRouteHandler';
import VendorDetailsRouteHandler from './VendorDetailsRouteHandler';

export default ({
  vendorDetailsConfig,
  enableMosaicPartnerProfileExperienceFlag,
  enableAetherExperienceFlag,
}) => {
  if (enableMosaicPartnerProfileExperienceFlag) {
    return [
      {
        name: 'vendorDetailsScreen',
        exact: true,
        hidePartnerLinks: true,
        path: UrlUtils.transformUriTemplateToRoutePath({
          template: vendorDetailsConfig.links.vendorDetailsPage.href,
        }),
        component: PartnerProfileRouteHandler,
        showAetherNavAndFooter: enableAetherExperienceFlag,
        additionalAttributes: {
          enableMosaicPartnerProfileExperienceFlag: true,
        },
      },
      {
        name: 'vendorDetailsScreen',
        exact: true,
        hidePartnerLinks: true,
        path: UrlUtils.transformUriTemplateToRoutePath({
          template: '/vendors/{vendorId}',
        }),
        component: PartnerProfileRouteHandler,
        showAetherNavAndFooter: enableAetherExperienceFlag,
        additionalAttributes: {
          enableMosaicPartnerProfileExperienceFlag: true,
        },
      },
    ];
  }

  return [
    {
      name: 'vendorDetailsScreen',
      exact: true,
      path: UrlUtils.transformUriTemplateToRoutePath({
        template: vendorDetailsConfig.links.vendorDetailsPage.href,
      }),
      component: VendorDetailsRouteHandler,
      additionalAttributes: {
        enableMosaicPartnerProfileExperienceFlag: false,
      },
    },
    {
      name: 'vendorDetailsScreen',
      exact: true,
      path: UrlUtils.transformUriTemplateToRoutePath({
        template: '/vendors/{vendorId}',
      }),
      component: VendorDetailsRouteHandler,
      additionalAttributes: {
        enableMosaicPartnerProfileExperienceFlag: false,
      },
    },
  ];
};
