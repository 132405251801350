export const locale = {
  or: 'or',
  youCan: 'You can',
  goBack: 'go back',
  goToHome: 'Go to home',
  goToOur: 'go to our',
  contactSupport: 'contact support',
  statusPage: 'status page',
  homePage: 'home page',
  forUpdates: 'for updates',
  supportTeam: 'support team',
  notFound: {
    title: 'We couldn’t find the page you’re looking for',
    heading: 'We couldn’t find the page you’re looking for',
    pageNotExist: 'Refresh the page or return to the',
    contactSupportTeam: 'contact our support team'
  },
  outage: {
    title: 'We’re experiencing an outage',
    heading: 'We’re experiencing an outage',
    teamInvestigatingIssue: 'Our team is already on it. We’re investigating the issue. Please find more information from the ',
    urgentAssistance: 'If you need urgent assistance,'
  },
  partialOutage: {
    title: 'We’re experiencing an issue',
    heading: 'We’re experiencing an issue',
    sectionHeading: 'We’re unable to load this section',
    tileHeading: 'We’re unable to load more apps',
    errorMessage: 'Refresh the page or try again in some time.',
    issuePersists: 'If the issue persists, check the ',
    urgentAssistance: 'If you need urgent assistance,',
    globalErrorMessage: 'Refreshing the page may help.'
  }
};