import { RECEIVE_APP_CONFIG } from '../actions/App';
import CommonConfig from '../records/CommonConfig';

export default function CommonConfigReducer(state = new CommonConfig(), action) {
  switch (action.type) {
    case RECEIVE_APP_CONFIG:
      return action.appConfig.commonConfig;
    default:
      return state;
  }
}
