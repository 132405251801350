import { List, Map } from 'immutable';

import resolveContentfulClient from '../../../../../../common/utils/contentful-client';
import transformContentfulModel from '../util/contentful/transformContentfulModel';

export const RETRIEVE_SOLUTION_CONTENT = 'SOLUTIONS_SOLUTION_CONTENT_RETRIEVE';
export const RECEIVE_SOLUTION_CONTENT = 'SOLUTIONS_SOLUTION_CONTENT_RECEIVE';
export const DATA_ERROR_SOLUTION_CONTENT = 'SOLUTIONS_SOLUTION_CONTENT_DATA_ERROR';

export const LARGE_CURATED_ADDONS_RENDER_COMPLETE =
  'SOLUTIONS_LARGE_CURATED_ADDONS_RENDER_COMPLETE';
export const LARGE_CURATED_ADDONS_RENDER_STARTED = 'SOLUTIONS_LARGE_CURATED_ADDONS_RENDER_STARTED';
export const SMALL_CURATED_ADDONS_RENDER_COMPLETE =
  'SOLUTIONS_SMALL_CURATED_ADDONS_RENDER_COMPLETE';
export const SMALL_CURATED_ADDONS_RENDER_STARTED = 'SOLUTIONS_SMALL_CURATED_ADDONS_RENDER_STARTED';

const retrieveSolutionContent = () => ({
  type: RETRIEVE_SOLUTION_CONTENT,
});

const receiveSolutionContent = ({ solutionContent }) => ({
  type: RECEIVE_SOLUTION_CONTENT,
  solutionContent,
});

const dataErrorSolutionContent = ({ dataErrorMessages }) => ({
  type: DATA_ERROR_SOLUTION_CONTENT,
  dataErrorMessages,
});

export const largeCuratedAddonsRenderComplete = () => ({
  type: LARGE_CURATED_ADDONS_RENDER_COMPLETE,
});

export const largeCuratedAddonsRenderStarted = () => ({
  type: LARGE_CURATED_ADDONS_RENDER_STARTED,
});

export const smallCuratedAddonsRenderComplete = () => ({
  type: SMALL_CURATED_ADDONS_RENDER_COMPLETE,
});

export const smallCuratedAddonsRenderStarted = () => ({
  type: SMALL_CURATED_ADDONS_RENDER_STARTED,
});

export const CATEGORY_NOT_FOUND_ERROR_MESSAGE = 'Category page not found';

export const doRetrieveSolutionContent =
  ({ solutionKey, commonConfig }) =>
  (dispatch) => {
    dispatch(retrieveSolutionContent());
    resolveContentfulClient({ commonConfig })
      .getEntries({
        content_type: 'categoryPage',
        'fields.key': solutionKey,
        include: 2,
      })
      .then(
        (response) => {
          const content = transformContentfulModel({ data: response.items[0].fields });
          dispatch(receiveSolutionContent({ solutionContent: content }));
        },
        (error) => {
          dispatch(
            dataErrorSolutionContent({
              dataErrorMessages: List([Map({ message: error.message })]),
            })
          );
          console.error('Contentful Error while fetching Category pages:', error);
        }
      )
      .catch(() => {
        const message = CATEGORY_NOT_FOUND_ERROR_MESSAGE;
        dispatch(
          dataErrorSolutionContent({
            dataErrorMessages: List([Map({ message })]),
          })
        );
        console.error(message);
      });
  };
