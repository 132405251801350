/*
 This backend logs a sentry event for every task failure
 */

import { getSentryClient } from '@atlassian/mpac-utils';
import { Events } from '../events';
import { getCurrentNetworkStatus } from '../NetworkStatusTracker';
import { isIgnorableGenericTaskSLOEvent } from '../utils/errors';
/**
 * Serializes primitive objects or Error instances
 */function stringifyError(error) {
  if (error && typeof error === 'object') {
    const errorObject = {};
    Object.getOwnPropertyNames(error).forEach(key => {
      errorObject[key] = typeof error[key] === 'object' ? stringifyError(error[key]) : error[key];
    });
    return JSON.stringify(errorObject);
  }
  return JSON.stringify(error);
}
const isAppErrorWithRequestId = cause => {
  var _meta;
  return ((_meta = cause.meta) === null || _meta === void 0 ? void 0 : _meta.requestId) !== undefined;
};
const createListener = () => (event, getContext) => {
  switch (event.type) {
    case Events.LABEL_PAGE_VIEW_TASK:
    case Events.LABEL_GENERIC_TASK:
      {
        if (!event.successful) {
          var _getSentryClient;
          const context = getContext();
          const ignorable = isIgnorableGenericTaskSLOEvent(event);
          const level = ignorable ? 'warning' : 'error';
          const messagePrefix = ignorable ? 'Telemetry task warning' : 'Telemetry task failed';
          const networkStatus = getCurrentNetworkStatus();
          (_getSentryClient = getSentryClient()) === null || _getSentryClient === void 0 ? void 0 : _getSentryClient.withScope(scope => {
            var _getSentryClient2;
            scope.setLevel(level);
            scope.setTag('page', context.page);
            scope.setTag('task', event.task);
            scope.setTag('taskId', event.taskId);
            scope.setExtra('networkStatus', networkStatus);
            const {
              cause
            } = event;
            if (cause) {
              scope.setExtra('cause', stringifyError(cause));
              if (event.task.startsWith('install-app') && isAppErrorWithRequestId(cause)) {
                var _cause$meta;
                scope.setTag('requestId', (_cause$meta = cause.meta) === null || _cause$meta === void 0 ? void 0 : _cause$meta.requestId);
              }
            }
            (_getSentryClient2 = getSentryClient()) === null || _getSentryClient2 === void 0 ? void 0 : _getSentryClient2.captureMessage("".concat(messagePrefix, ": ").concat([event.task, event.taskId].filter(a => !!a).join(',')));
          });
        }
      }
  }
};
export default createListener;