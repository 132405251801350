import { B400 } from '@atlaskit/theme/colors';
import styled from '@emotion/styled';
import React from 'react';

import HeaderContent from '../../../../../../../common/components/Header/components/HeaderContent';
import PartnerQuickLinks from './PartnerQuickLinks/components/PartnerQuickLinks/PartnerQuickLinks';

// @ts-expect-error [MC-2850] - TS2614 - Module '"history/createBrowserHistory"' has no exported member 'BrowserHistory'. Did you mean to use 'import BrowserHistory from "history/createBrowserHistory"' instead?
import type { BrowserHistory } from 'history/createBrowserHistory';
import type { Map } from 'immutable';
import type { ReactNode } from 'react';

const HeaderContainer = styled.header`
  position: relative;
  background-color: ${B400};
  min-height: 70px; // here to ensure that the header always has some height so there is less flashing
`;

type HeaderProps = {
  onLogout: () => void;
  // @ts-expect-error [MC-2850] - TS2314 - Generic type 'Map<K, V>' requires 2 type argument(s).
  userData: Map;
  children?: ReactNode;
  history: BrowserHistory;
  displayPartnerQuickLinks: boolean;
  onSwitchAccount: () => void;
};

const Header = (props: HeaderProps) => {
  const {
    displayPartnerQuickLinks,
    userData,
    onLogout,
    history,
    children = null,
    onSwitchAccount,
  } = props;
  return (
    <>
      {displayPartnerQuickLinks && <PartnerQuickLinks userData={userData} />}
      <HeaderContainer role="banner">
        <HeaderContent
          onLogout={onLogout}
          userData={userData}
          history={history}
          onSwitchAccount={onSwitchAccount}
        >
          {children}
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};

export default Header;
