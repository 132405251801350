import Avatar from '@atlaskit/avatar';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import React from 'react';

import { useResolvedCurrentUser } from '../../../../context/CurrentUserContext';
import { dataTestIds } from '../constants';
import {
  DropdownAvatarWrapper,
  DropdownItemContent,
  DropdownItemLabel,
  LozengeContainer,
  UserDetailsItemContent,
  UserEmail,
  Username,
} from './DropdownItems.styles';

import type { UserProps } from '../userTypes';

export const UserDetailsItem = ({ userProps }: { userProps: UserProps }) => {
  const { loggedInUser } = useResolvedCurrentUser({ userProps });

  if (!loggedInUser) return null;

  return (
    <DropdownItem
      href={loggedInUser.loggedInUserLinks?.profile}
      testId={dataTestIds.USER_DROPDOWN__USER_PROFILE}
    >
      <UserDetailsItemContent aria-label={`${loggedInUser.name} profile and settings`}>
        <DropdownAvatarWrapper>
          <Avatar appearance="circle" size="small" src={loggedInUser.picture} />
        </DropdownAvatarWrapper>
        <div>
          <UserEmail>{loggedInUser.name}</UserEmail>
          <Username>{loggedInUser.email}</Username>
        </div>
      </UserDetailsItemContent>
    </DropdownItem>
  );
};

export const LinkItem = ({
  label,
  showShortcutIcon = false,
  showNewLozenge = false,
  ...moreProps
}) => {
  const linkItemProps = { ...moreProps };

  if (showShortcutIcon) {
    linkItemProps['aria-label'] = `${label} open in new window`;
  }

  return (
    <DropdownItem {...linkItemProps}>
      <DropdownItemContent>
        <DropdownItemLabel>
          {label}
          {showNewLozenge && (
            <LozengeContainer>
              <Lozenge appearance="new">New</Lozenge>
            </LozengeContainer>
          )}
        </DropdownItemLabel>
        {showShortcutIcon && <ShortcutIcon label="" primaryColor={token('color.icon')} />}
      </DropdownItemContent>
    </DropdownItem>
  );
};
