import type { BootstrapConfig as BootstrapConfigType } from '../application-config/types/BootstrapConfig';

class BootstrapConfigSingleton {
  _bootstrapConfig: BootstrapConfigType | Record<any, any>;

  constructor() {
    this._bootstrapConfig = {};
  }

  get(): BootstrapConfigType {
    // @ts-expect-error [MC-2850] - TS2322 - Type 'Readonly<{ adminHubBaseUrl: string; graphQLGatewayUrl: string; mpacBaseUrl: string; mpacCdnBaseUrl: string; macBaseUrl: string; wacBaseUrl: string; atlassianCommunityBaseUrl: string; jiraServiceDeskEmbedBaseUrl: string; ... 8 more ...; analyticsClient: AnalyticsClientInterface; }> | Record<...>' is not assignable to type 'Readonly<{ adminHubBaseUrl: string; graphQLGatewayUrl: string; mpacBaseUrl: string; mpacCdnBaseUrl: string; macBaseUrl: string; wacBaseUrl: string; atlassianCommunityBaseUrl: string; jiraServiceDeskEmbedBaseUrl: string; ... 8 more ...; analyticsClient: AnalyticsClientInterface; }>'.
    return this._bootstrapConfig;
  }

  set(config: BootstrapConfigType) {
    this._bootstrapConfig = config;
  }
}

const bootstrapConfig = new BootstrapConfigSingleton();
export default bootstrapConfig;
