export const ProductFilters = _ref => {
  let {
    statsigClient
  } = _ref;
  const response = [{
    label: 'Jira',
    id: 'jira',
    value: 'jira'
  }, {
    label: 'Jira Service Management',
    id: 'jira-service-management',
    value: 'jira-service-desk'
  }, {
    label: 'Confluence',
    id: 'confluence',
    value: 'confluence'
  }, {
    label: 'Bitbucket',
    id: 'bitbucket',
    value: 'bitbucket'
  }, {
    label: 'Fisheye/Crucible',
    id: 'fisheye',
    value: 'fecru'
  }, {
    label: 'Bamboo',
    id: 'bamboo',
    value: 'bamboo'
  }, {
    label: 'Crowd',
    id: 'crowd',
    value: 'crowd'
  }];
  if (statsigClient.showCompassApps()) {
    response.push({
      label: 'Compass',
      id: 'compass',
      value: 'compass'
    });
  }
  return response;
};