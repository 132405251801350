import { PageNotFoundEvent, useAnalyticsEvents } from '@atlassian/mpac-analytics';
import React, { useEffect } from 'react';

import {
  ANALYTICS_EVENT_CONTAINER_NOT_FOUND_404,
  ANALYTICS_SUBJECT_ID_NOT_FOUND_404,
} from '../../../app/analytics/Events';
import ErrorPage from '../ErrorPage';

import type { RouterHistory } from 'react-router';

import ErrorWindow from './ErrorWindow.svg';

export type NotFoundProps = {
  homeLink: string;
  history?: RouterHistory;
};

const NotFound = (props: NotFoundProps) => {
  const fireAnalyticsEvent = useAnalyticsEvents();

  useEffect(() => {
    fireAnalyticsEvent({
      eventType: PageNotFoundEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NOT_FOUND_404,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NOT_FOUND_404,
    });
  }, []);

  return (
    <ErrorPage
      // @ts-ignore MC-2850
      homeLink={props.homeLink}
      heading="Looks like you took a wrong turn"
      image={ErrorWindow}
      primaryMessage="This page does not exist"
      statusCode={404}
      title="Page not found"
      history={props.history}
    />
  );
};

export default NotFound;
