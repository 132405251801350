import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { deleteFromStorage, getFromStorage, getLocalStorageSupport, isTypeOfLocalStorageChanged, LOCAL_STORAGE_CHANGE_EVENT_NAME, tryParse, writeStorage } from '../LocalStorageUtils/LocalStorageUtils';
function promiseReducer(_state, action) {
  switch (action.type) {
    case 'pending':
      return {
        pending: true,
        result: null,
        error: null,
        refresh: null
      };
    case 'fulfilled':
      return {
        pending: false,
        result: action.payload,
        refresh: action.refresh
      };
    default:
      return {
        pending: false,
        error: action.payload,
        refresh: action.refresh
      };
  }
}
export function usePromise(promiseFn) {
  let memoizeOn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let runPromise = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const initialState = {
    result: null,
    error: null,
    pending: true,
    refresh: null
  };
  const [state, dispatch] = useReducer(promiseReducer, initialState);
  const promiseExecuter = () => {
    dispatch({
      type: 'pending'
    });
    promiseFn().then(result => dispatch({
      type: 'fulfilled',
      payload: result,
      refresh: promiseExecuter
    })).catch(error => dispatch({
      type: 'error',
      payload: error,
      refresh: promiseExecuter
    }));
  };
  useEffect(() => {
    if (runPromise) {
      promiseExecuter();
    }
  }, memoizeOn);
  return state;
}

/**
 * In functional components, it isn't possible to get the previous
 * value of a state / prop directly (something that was possible
 * using lifecycle methods of class components). This hook provides
 * a lagging version of the value passed to it.
 */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function useLocalStorage(key, defaultValue) {
  const isStorageSupported = getLocalStorageSupport();
  const value = getFromStorage(key);
  const [localState, updateLocalState] = useState(value === null || value === undefined ? defaultValue : tryParse(value));
  const onLocalStorageChange = useCallback(event => {
    if (isTypeOfLocalStorageChanged(event) && event.detail.key === key) {
      updateLocalState(event.detail.value);
    }
  }, [updateLocalState, key]);
  useEffect(() => {
    if (!isStorageSupported) {
      return;
    }
    window.addEventListener(LOCAL_STORAGE_CHANGE_EVENT_NAME, onLocalStorageChange);
    return () => {
      window.removeEventListener(LOCAL_STORAGE_CHANGE_EVENT_NAME, onLocalStorageChange);
    };
  }, [onLocalStorageChange]);
  const writeState = useCallback(newValue => writeStorage(key, newValue), [key]);
  const deleteState = useCallback(() => deleteFromStorage(key), [key]);
  const state = localState !== null && localState !== void 0 ? localState : defaultValue;
  return {
    state,
    isLocalStorageSupported: isStorageSupported,
    writeState,
    deleteState
  };
}