import { LocalStorageUtils } from '@atlassian/mpac-utils';

import { clearCurrentUserCache, getUserData } from 'app/actions/UserData';

export default function headerCommonMapDispatchToProps(dispatch) {
  return {
    // @ts-expect-error [MC-2850] - TS2554 - Expected 0 arguments, but got 1.
    getUserData: (params) => dispatch(getUserData(params)),
    onLogout: () => {
      LocalStorageUtils.deleteFromStorage('last-visited-partner-id');
      clearCurrentUserCache();
    },
    onSwitchAccount: () => {
      LocalStorageUtils.deleteFromStorage('last-visited-partner-id');
      clearCurrentUserCache();
    },
  };
}
