import { B75, B400, N0, N20, N800 } from '@atlaskit/theme/colors';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import { charlieDisplayFontFamily } from '@atlassian/mpac-ui/theme';
import styled from '@emotion/styled';

export const MenuSections = styled.div`
  width: 80%;
  position: absolute;
  left: 20%;
  top: 70px;
  z-index: ${layers.navigation};
  background-color: ${B400};
  font-size: 16px;

  .help-menu-section {
    background-color: ${N20};
    padding-top: 10px;
    a {
      color: ${N800};
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: lighter;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 10px;
  color: ${N0};
  font-family: ${charlieDisplayFontFamily()};
`;

export const UserMenuSectionHeader = styled(SectionHeader)`
  display: flex;
  align-items: center;
`;

export const SectionSeparator = styled.hr`
  margin: 0;
  border: none;
  height: 1px;
  background-color: ${B75};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${token('space.100', '8px')};
`;

export const UserEmail = styled.div`
  font-size: 11px;
`;
