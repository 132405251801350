import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeVar, Observable } from '@apollo/client';
import { BaseMiddleware } from '../graphql-orchestrator-link';
const isBlockedPartnerAccessDetected = makeVar(false);
class BlockedPartnerAccessDetectionMiddleware extends BaseMiddleware {
  getKey() {
    return BlockedPartnerAccessDetectionMiddleware.key;
  }
  checkIfBlockedPartnerAccessDetected(extensions) {
    var _extensions$marketpla, _extensions$marketpla2;
    if (!extensions) {
      return;
    }
    if (extensions !== null && extensions !== void 0 && (_extensions$marketpla = extensions.marketplaceConsole) !== null && _extensions$marketpla !== void 0 && (_extensions$marketpla2 = _extensions$marketpla.knownErrors) !== null && _extensions$marketpla2 !== void 0 && _extensions$marketpla2.partner_access_denied) {
      isBlockedPartnerAccessDetected(true);
    }
  }
  onFetchPrimary(args, callbackFn, _context) {
    const observable = callbackFn(...args);
    return new Observable(observer => {
      observable.subscribe({
        error: err => {
          observer.error(err);
        },
        next: primaryData => {
          this.checkIfBlockedPartnerAccessDetected(primaryData.extensions);
          observer.next(primaryData);
        },
        complete() {
          observer.complete();
        }
      });
    });
  }
}
_defineProperty(BlockedPartnerAccessDetectionMiddleware, "key", 'blockedPartnerAccessDetection');
export { BlockedPartnerAccessDetectionMiddleware, isBlockedPartnerAccessDetected };