import { ApolloClient } from '@apollo/client';
import { createApolloLinks } from './apolloLinks';
const appendAggParametersForDev = uri => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.BFF_AGG_BRANCH) {
      // https://developer.atlassian.com/platform/graphql-gateway/devloop/making-a-pr/#branch-deploys
      uri += "/".concat(process.env.BFF_AGG_BRANCH, "/graphql");
    }
    if (process.env.BFF_AGG_TUNNEL_URL && process.env.BFF_AGG_TUNNEL_FOR_SERVICE) {
      // https://developer.atlassian.com/platform/graphql-gateway/devloop/url-overrides/
      uri += "?serviceOverride=".concat(process.env.BFF_AGG_TUNNEL_FOR_SERVICE, "=").concat(process.env.BFF_AGG_TUNNEL_URL).concat(process.env.BFF_AGG_TUNNEL_SERVICE_URL);
    }
  }
  return uri;
};
export const createApolloClient = options => {
  const {
    uri,
    name,
    version,
    ssrMode = false,
    cache,
    gqlOrchestratorLinkOptions,
    typeDefs
  } = options;
  const getClient = () => client;
  const client = new ApolloClient({
    cache,
    ssrMode,
    typeDefs,
    link: createApolloLinks({
      uri: appendAggParametersForDev(uri),
      name,
      version,
      getClient,
      gqlOrchestratorLinkOptions
    })
  });
  return client;
};