import MenuIcon from '@atlaskit/icon/glyph/menu';
import React, { useState } from 'react';

import SideMenu from './SideMenu';
import { HamburgerMenu, SmallHeader } from './styles';

import type { Map } from 'immutable';

type SmallHeaderContentProps = {
  onClickLogin: () => void;
  onClickLogout: () => void;
  // @ts-expect-error [MC-2850] - TS2314 - Generic type 'Map<K, V>' requires 2 type argument(s).
  userData: Map;
  onClickSwitchAccount: () => void;
};
const SmallHeaderContent = ({
  onClickLogin,
  onClickLogout,
  userData,
  onClickSwitchAccount,
}: SmallHeaderContentProps) => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);

  const handleCloseMenu = () => {
    setSideMenuIsOpen(false);
  };

  const toggleMenu = () => {
    setSideMenuIsOpen((isOpen) => !isOpen);
  };

  return (
    <SmallHeader>
      <HamburgerMenu
        onClick={toggleMenu}
        // this is required by react-onclickoutside via
        // the MenuSectionsContainer element, do not remove
        className="sideMenuTrigger"
      >
        <MenuIcon label="Menu" size="large" />
      </HamburgerMenu>
      <SideMenu
        closeMenu={handleCloseMenu}
        onClickLogin={onClickLogin}
        onClickLogout={onClickLogout}
        showSmallHeaderMenu={sideMenuIsOpen}
        userData={userData}
        onClickSwitchAccount={onClickSwitchAccount}
      />
    </SmallHeader>
  );
};

export default SmallHeaderContent;
