import Tooltip from '@atlaskit/tooltip';
import { SearchIcon, useScrollLock } from '@atlassian/mpac-primitives';
import { SearchOverlay } from '@atlassian/mpac-search';
import React, { useState } from 'react';

import { dataTestIds } from '../constants';
import { IconButton } from './Button.styles';

type SearchProps = {
  onClick?: () => void;
};

export const Search = ({ onClick }: SearchProps) => {
  const [isSearchSelected, setIsSearchSelected] = useState<boolean>(false);
  useScrollLock(isSearchSelected);

  const handleClick = () => {
    onClick?.();
    setIsSearchSelected(true);
  };

  return (
    <>
      <Tooltip content="Search Marketplace" hideTooltipOnClick={true}>
        <IconButton
          aria-label="Search"
          isSelected={isSearchSelected}
          onClick={handleClick}
          data-testId={dataTestIds.SEARCH}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
      {isSearchSelected && (
        <SearchOverlay
          onClose={() => setIsSearchSelected(false)}
          analytics={{
            source: 'navigation-bar',
          }}
        />
      )}
    </>
  );
};
