import { type Map, fromJS } from 'immutable';
import React from 'react';

import {
  transformToUserProps,
  useResolvedCurrentUser,
} from '../../../../../../app/context/CurrentUserContext';
import ShowHelp from './HelpDropdown/ShowHelp';
import { HeaderContentMenu, LogInLink } from './styles';
import UserDropdown from './UserDropdown';

import type { ReactNode } from 'react';

type LargeHeaderContentProps = {
  children?: ReactNode;
  onClickLogin: () => void;
  onClickLogout: () => void;
  userAvatarUrl?: string;
  // @ts-expect-error [MC-2850] - TS2314 - Generic type 'Map<K, V>' requires 2 type argument(s).
  userData: Map;
  onClickSwitchAccount: () => void;
};

const LargeHeaderContent = ({
  children,
  onClickLogin,
  onClickLogout,
  userAvatarUrl,
  userData,
  onClickSwitchAccount,
}: LargeHeaderContentProps) => {
  const { loggedInUser, anonymousUser } = useResolvedCurrentUser({
    userProps: transformToUserProps(fromJS({ data: userData })),
  });

  return (
    <HeaderContentMenu aria-label="Site Navigation">
      {children}
      <ShowHelp userLoggedIn={!!loggedInUser} />
      {loggedInUser ? (
        <UserDropdown
          onClickLogout={onClickLogout}
          userData={userData}
          // @ts-expect-error [MC-2850] - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
          userAvatarUrl={userAvatarUrl}
          onClickSwitchAccount={onClickSwitchAccount}
        />
      ) : (
        <LogInLink
          className="log-in-link"
          href={anonymousUser?.anonymousUserLinks.login}
          onClick={onClickLogin}
        >
          Log in
        </LogInLink>
      )}
    </HeaderContentMenu>
  );
};

export default LargeHeaderContent;
