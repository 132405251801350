import { useCallback, useState } from 'react';
import { useScrollLock } from '../useScrollLock';
export const useFeedbackCollector = _ref => {
  let {
    onSubmitClick
  } = _ref;
  const [feedbackDialogVisible, setFeedbackDialogVisible] = useState(false);
  useScrollLock(feedbackDialogVisible);
  const onSubmit = useCallback(feedbackValues => {
    // hide modal
    setFeedbackDialogVisible(false);
    onSubmitClick === null || onSubmitClick === void 0 ? void 0 : onSubmitClick(feedbackValues);
  }, [onSubmitClick]);
  const onClose = useCallback(() => {
    // hide modal
    setFeedbackDialogVisible(false);
  }, []);
  const openFeedbackDialog = useCallback(() => {
    setFeedbackDialogVisible(true);
  }, []);
  return {
    feedbackDialogVisible,
    setFeedbackDialogVisible,
    openFeedbackDialog,
    onSubmit,
    onClose
  };
};