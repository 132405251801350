import { StaticContextRoute } from '@atlassian/mpac-ui';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import type { ComponentProps } from 'react';

type RedirectProps = JSX.LibraryManagedAttributes<typeof Redirect, ComponentProps<typeof Redirect>>;

type SEOFriendlyRedirectProps = RedirectProps & {
  statusCode: 301 | 302;
};

/**
 * A simple wrapper over react-router's <Redirect /> that makes sure SEO4Ajax and/or SSR is notified
 * of the redirect. SEO4Ajax or the SSR runtime can then start serving the appropriate status codes
 * to search engines.
 */
const SEOFriendlyRedirect = ({ statusCode = 301, ...redirectProps }: SEOFriendlyRedirectProps) => {
  useEffect(() => {
    // SEO4Ajax sets onCaptureReady on window - when SEO4Ajax is rendering this error page, we can execute it
    // with a status code so that we can override the standard 200 with a more appropriate status code for it to cache
    // (or not cache, if a 5xx) and later return to bots
    // @ts-expect-error [MC-2850] - TS2339 - Property 'onCaptureReady' does not exist on type 'Window & typeof globalThis'.
    if (typeof window.onCaptureReady === 'function') {
      // @ts-expect-error [MC-2850] - TS2339 - Property 'onCaptureReady' does not exist on type 'Window & typeof globalThis'.
      window.onCaptureReady(statusCode);
    }
  }, []);

  return (
    <StaticContextRoute statusCode={statusCode} render={() => <Redirect {...redirectProps} />} />
  );
};

export default SEOFriendlyRedirect;
