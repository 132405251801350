import { Record } from 'immutable';

import Link from '../../common/records/Link';
import LinkTemplate from '../../common/records/LinkTemplate';
import * as perspectives from '../components/Routes/components/AddonDiscoveryRoutes/constants/perspectives';

export class AddonDiscoveryLinks extends Record({
  application: new LinkTemplate(),
  applicationSilter: new LinkTemplate(),
  home: new Link(),
  silter: new LinkTemplate(),
  search: new Link(),
}) {}

export class AddonDiscoveryMetricsKeys extends Record({
  [perspectives.DISCOVER]: '',
  [perspectives.SEARCH]: '',
}) {}

export default class AddonDiscoveryConfig extends Record({
  links: new AddonDiscoveryLinks(),
  metricsKeys: new AddonDiscoveryMetricsKeys(),
}) {}

export const resolveAddonDiscoveryConfig = ({ appConfig }) =>
  new AddonDiscoveryConfig({
    links: new AddonDiscoveryLinks({
      application: new LinkTemplate({
        href: appConfig.getIn(['addonDiscovery', 'links', 'application', 'href']),
      }),
      applicationSilter: new LinkTemplate({
        href: appConfig.getIn(['addonDiscovery', 'links', 'applicationSilter', 'href']),
      }),
      home: new Link({
        href: appConfig.getIn(['addonDiscovery', 'links', 'home', 'href']),
      }),
      silter: new LinkTemplate({
        href: appConfig.getIn(['addonDiscovery', 'links', 'silter', 'href']),
      }),
      search: new Link({
        href: appConfig.getIn(['addonDiscovery', 'links', 'search', 'href']),
      }),
    }),
    metricsKeys: new AddonDiscoveryMetricsKeys({
      [perspectives.DISCOVER]: appConfig.getIn(['addonDiscovery', 'metricsKeys', 'discover']),
      [perspectives.SEARCH]: appConfig.getIn(['addonDiscovery', 'metricsKeys', 'search']),
    }),
  });
