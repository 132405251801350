import Flag, { FlagGroup } from '@atlaskit/flag';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Y400 } from '@atlaskit/theme/colors';
import { DateUtils } from '@atlassian/mpac-utils';
import { isAfter } from 'date-fns';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';

const DEPRECATION_DATE = new Date(Date.UTC(2021, 2, 1)); // 11st March 2021
const isAlreadyDeprecated = isAfter(new Date(), DEPRECATION_DATE);

function DeprecationMessage() {
  return (
    <div>
      We recommend you switch to one of our other supported browsers
      {isAlreadyDeprecated ? '' : ` by ${DateUtils.longDate(DEPRECATION_DATE)}`}, such as{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/en-us/edge">
        Microsoft Edge
      </a>
      ,{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/chrome/">
        Google Chrome
      </a>
      , or{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://www.mozilla.org/en-US/firefox/">
        Mozilla Firefox
      </a>
      .
    </div>
  );
}

/**
 * Returns `true` only once every 24 hours
 */
function trueOnceADay(key: string): boolean {
  if (!('localStorage' in window)) {
    return true;
  }

  const setNewExpiry = (oldExpiry: number = Date.now()) =>
    localStorage.setItem(key, (oldExpiry + 1000 * 60 * 60 * 24).toString());

  try {
    const expiry = localStorage.getItem(key);

    if (expiry && !Number.isNaN(expiry)) {
      const expiryMs = Number(expiry);
      const hasExpired = isAfter(new Date(), new Date(expiryMs));
      if (hasExpired) {
        setNewExpiry(expiryMs);
        return true;
      } else {
        return false;
      }
    }
    setNewExpiry();
    return true;
  } catch {
    return true;
  }
}

export default function IEDeprecationFlag() {
  const [flagIsVisible, setFlagIsVisible] = useState(true);

  if (!flagIsVisible) return null;
  const handleDismissClick = () => {
    setFlagIsVisible(false);
  };

  return (
    <FlagGroup onDismissed={handleDismissClick}>
      <Flag
        id="ie-11-warning"
        icon={<WarningIcon label="Warning" primaryColor={Y400} />}
        key="ie-11-warning"
        title={
          isAlreadyDeprecated
            ? 'Marketplace no longer supports IE11'
            : 'Marketplace is saying goodbye to IE11'
        }
        description={<DeprecationMessage />}
        onDismissed={handleDismissClick}
        actions={[
          {
            content: 'Dismiss',
            onClick: handleDismissClick,
          },
          {
            content: 'Learn More',
            href: 'https://community.atlassian.com/t5/Marketplace-Apps-Integrations/Retiring-IE11-support-for-the-Atlassian-Marketplace-website/ba-p/1592713',
          },
        ]}
      />
    </FlagGroup>
  );
}

export function showIEDeprecationFlagIfNeeded() {
  const { userAgent } = navigator;
  const isIE = userAgent.includes('MSIE') || userAgent.includes('Trident');
  const hasNotSeen = trueOnceADay('marketplace-ie-11-flag');

  if (isIE && hasNotSeen) {
    const flagContainer = document.createElement('div');
    document.body?.appendChild(flagContainer);
    const root = createRoot(flagContainer);
    root.render(<IEDeprecationFlag />);
  }
}
