import { uploadFile } from '@atlassian/mpac-api-client';
import { BaseHandler } from '../../../graphql-orchestrator-link';
export class AppAdminUploadLogoHandler extends BaseHandler {
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    try {
      const {
        logo
      } = operation.variables;
      const logoUploadUrl = '/rest/2/assets/image/title-logo';
      const response = await uploadFile(logoUploadUrl, logo);
      return response;
    } catch (error) {
      const errorResponse = await error.error.json();
      throw new Error(JSON.stringify(errorResponse.errors));
    }
  }
  responseTransformer(_operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    return {
      marketplaceConsoleAdminTitleLogo: {
        __typename: 'MarketplaceAdminTitleLogoUploadResponse',
        success: customResponse.success,
        titleLogo: customResponse._links.image.href,
        selfImageHref: customResponse._links.self.href
      }
    };
  }
}