// source: https://www.algolia.com/doc/ui-libraries/autocomplete/guides/debouncing-sources/#writing-an-asynchronous-debouncing-function
function debouncePromise(fn, time) {
  let timerId;
  return function debounced() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (timerId) {
      clearTimeout(timerId);
    }
    return new Promise(resolve => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}
export const debounceAutoComplete = debouncePromise(items => Promise.resolve(items), 300);