class BaseHandler {
  requestTransformer(operation, _sequenceConfigValue) {
    return operation;
  }
  responseTransformer(_operation, _transformedOperation, _sequenceConfigValue, primaryResponse, _customResponse) {
    return primaryResponse;
  }
  fetchPrimary(operation, forward) {
    return forward(operation);
  }
  fetchCustom(_operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    return Promise.resolve(void 0);
  }
}
export { BaseHandler };