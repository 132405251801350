const CONNECT_DEPLOYMENT = 'MarketplaceConnectAppDeployment';
const ECOSYSTEM_CLOUD_DEPLOYMENT = 'MarketplaceCloudAppDeployment';
const INSTRUCTIONAL_DEPLOYMENT = 'MarketplaceInstructionalAppDeployment';
const PLUGINS1_DEPLOYMENT = 'MarketplacePlugins1AppDeployment';
const PLUGINS2_DEPLOYMENT = 'MarketplacePlugins2AppDeployment';
const WORKFLOW_DEPLOYMENT = 'MarketplaceWorkflowAppDeployment';
export const AppDeployment = Object.freeze({
  CONNECT: CONNECT_DEPLOYMENT,
  ECOSYSTEM_CLOUD: ECOSYSTEM_CLOUD_DEPLOYMENT,
  INSTRUCTIONAL: INSTRUCTIONAL_DEPLOYMENT,
  PLUGINS1: PLUGINS1_DEPLOYMENT,
  PLUGINS2: PLUGINS2_DEPLOYMENT,
  WORKFLOW: WORKFLOW_DEPLOYMENT
});
export const CloudFortifiedStatusValues = Object.freeze({
  Approved: 'approved',
  Rejected: 'rejected'
});
export const BugBountyStatusValues = Object.freeze({
  Approved: 'approved',
  Rejected: 'rejected'
});
export const CompatibleProductTypeValues = Object.freeze({
  CLOUD_PRODUCT: 'CompatibleAtlassianCloudProduct',
  SERVER_PRODUCT: 'CompatibleAtlassianServerProduct',
  DATACENTER_PRODUCT: 'CompatibleAtlassianDataCenterProduct'
});

/** Cloud app deployment properties */

/**
 * Known marketing label names
 */
export const AppMarketingLabel = Object.freeze({
  CompatibleWithJSM: 'jira-servicedesk',
  CompatibleWithJSW: 'jira-software'
});