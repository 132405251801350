import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["retries", "accept", "retryDelay"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 *
 * Code taken from : https://github.com/jonbern/fetch-retry
 *
 * This is a wrapper for isomorphic-fetch with retry and accept
 *
 * Updated the code to have a accept param :
 * this is to retry when the response returned is not acceptable and we want to retry,
 * even if the response was 2XX, or 4XX or 5XX
 *
 * @param url
 * @param options
 *      retry: Int
 *      retryDelay: Int : milliseconds
 *      accept: (response) => Promise<void> : Will retry if the Promise is rejected
 * @return {Promise}
 */
function iFetch(url) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const defaultAccept = () => Promise.resolve();
  const {
      retries = 0,
      accept = defaultAccept,
      retryDelay = 0
    } = options,
    fetchOptions = _objectWithoutProperties(options, _excluded);
  return new Promise((resolve, reject) => {
    const wrappedFetch = function wrappedFetch(n) {
      fetch(url, fetchOptions).then(r => accept(r.clone()).then(_ => resolve(r))).catch(error => {
        if (n > 0) {
          setTimeout(() => {
            wrappedFetch(--n);
          }, retryDelay);
        } else {
          reject({
            url,
            error,
            status: error.status || 'unknown'
          });
        }
      });
    };
    wrappedFetch(retries);
  });
}

/**
 * Function for accept param in fetch(), that only resolves when the response is ok and rejects in any other response
 */
const responseOkOnly = resp => resp.ok === true ? Promise.resolve() : Promise.reject(resp);
function iFetchOk(url) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return iFetch(url, _objectSpread(_objectSpread({}, options), {}, {
    accept: resp => responseOkOnly(resp).then(() => {
      const accept = options.accept;
      return (accept ? accept : () => Promise.resolve())(resp);
    })
  })).catch(error => Promise.reject(error));
}
export { iFetch as fetch, iFetchOk as fetchOk, responseOkOnly };