import { useEffect } from 'react';
export const useScrollLock = isLocked => {
  useEffect(() => {
    if (isLocked) {
      const scrollBarWidth = window.innerWidth - document.body.clientWidth;
      // Disable scrolling, but keep space for scrollbar
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = "".concat(scrollBarWidth, "px");
      document.body.style.position = 'fixed';
    } else {
      // Re-enable scrolling and remove the reserved space for the scrollbar
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0px';
      document.body.style.position = 'static';
    }
  }, [isLocked]);
};