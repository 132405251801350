import { type StatsigClientType, withStatsig } from '@atlassian/mpac-feature-flags';
import { FOOTER_HEIGHT, TOP_NAV_HEIGHT } from '@atlassian/mpac-partners';
import { Route, SentryGASv3Listener } from '@atlassian/mpac-ui';
import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Switch } from 'react-router-dom';

import CommonPropTypes from '../../../constants/PropTypes';
import TelemetryNavigationListener from '../../../telemetry/TelemetryNavigationListener';
import getAllRoutes from '../allRoutes';
import { getMatchedRoute, resolveCurrentRoute } from '../utils/RouteUtils';
import RouteContainer from './RouteContainer';

import type AddonDiscoveryConfig from '../../../records/AddonDiscoveryConfig';
import type CollectionsConfig from '../../../records/CollectionsConfig';
import type CommonConfig from '../../../records/CommonConfig';
import type SolutionsConfig from '../../../records/SolutionsConfig';
import type VendorDetailsConfig from '../../../records/VendorDetailsConfig';

export const MainContent = styled.main`
  min-height: calc(100vh - ${TOP_NAV_HEIGHT + FOOTER_HEIGHT}px);
  position: relative;

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

// Keep in sync with React `PropTypes`
type Props = {
  commonConfig: CommonConfig;
  addonDiscoveryConfig: AddonDiscoveryConfig;
  collectionsConfig: CollectionsConfig;
  solutionsConfig: SolutionsConfig;
  vendorDetailsConfig: VendorDetailsConfig;
  statsigClient: StatsigClientType;
};

class Routes extends Component<Props> {
  static propTypes = {
    commonConfig: CommonPropTypes.commonConfig.isRequired,
    addonDiscoveryConfig: CommonPropTypes.addonDiscoveryConfig.isRequired,
    collectionsConfig: CommonPropTypes.collectionsConfig.isRequired,
    solutionsConfig: CommonPropTypes.solutionsConfig.isRequired,
    vendorDetailsConfig: CommonPropTypes.vendorDetailsConfig.isRequired,
  };

  render() {
    const { statsigClient } = this.props;

    const allRoutes = () =>
      getAllRoutes({
        addonDiscoveryConfig: this.props.addonDiscoveryConfig,
        collectionsConfig: this.props.collectionsConfig,
        solutionsConfig: this.props.solutionsConfig,
        vendorDetailsConfig: this.props.vendorDetailsConfig,
        flags: {
          enableAetherExperienceFlag: statsigClient.enableAetherExperience(),
          enableMosaicPartnerProfileExperienceFlag:
            statsigClient.enableMosaicPartnerProfileExperience(),
        },
      });

    // @ts-expect-error [MC-2850] - TS2663 - Cannot find name 'props'. Did you mean the instance member 'this.props'? | TS1005 - ',' expected. | TS1005 - ';' expected.
    const RenderRoutes = (props: ContextRouter) => {
      const routeList = allRoutes();
      const getRouteName = (pathname: string) => resolveCurrentRoute(routeList, pathname)?.name;
      const getRouteParams = (pathname: string) => getMatchedRoute(routeList, pathname)?.params;

      return (
        <RouteContainer
          {...props}
          allRoutes={routeList}
          enableCurrentUserApiViaStoreBff={statsigClient.enableCurrentUserApiViaStoreBff()}
        >
          <TelemetryNavigationListener getRouteName={getRouteName} />
          {/* @ts-expect-error [MC-2850] - TS2322 - Type '(pathname: string) => any' is not assignable to type 'GetRouteName'. | TS2322 - Type '(pathname: string) => {} | undefined' is not assignable to type 'GetRouteParams'. */}
          <SentryGASv3Listener getRouteName={getRouteName} getRouteParams={getRouteParams}>
            <MainContent id="main-page-container" tabIndex={-1}>
              <Switch>
                {allRoutes()
                  .filter((route) => !route.isNestedRoute)
                  .map(({ path, render, component, exact }) => (
                    // We avoid using keys on each of the routes so that there is not a full re-render
                    // of the AddonDiscovery components. If we fully re-render the AddonDiscovery components,
                    // we needlessly re-retrieve data and there is more flashing on screen
                    // eslint-disable-next-line react/jsx-key
                    <Route path={path} exact={exact} render={render} component={component} />
                  ))}
              </Switch>
            </MainContent>
          </SentryGASv3Listener>
        </RouteContainer>
      );
    };

    return <Route path="/" render={RenderRoutes} />;
  }
}

export default withStatsig(Routes);
