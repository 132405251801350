import { getAuthorisedSubTab, TabNames } from '@atlassian/mpac-partners';
import { getPartnerNavLinks, PARTNER_NAV_ITEMS } from '@atlassian/mpac-utils';

import {
  ANALYTICS_SUBJECT_ID_PARTNER_ANNOUNCEMENTS_LINK,
  ANALYTICS_SUBJECT_ID_PARTNER_APPS_LINK,
  ANALYTICS_SUBJECT_ID_PARTNER_PROMOTIONS_LINK,
  ANALYTICS_SUBJECT_ID_PARTNER_REPORTS_LINK,
} from '../../../../../../../../analytics/Events';

import type { PartnerPermissions } from '@atlassian/mpac-api-client';

export const getQuickLinkItems = (
  permissions: PartnerPermissions,
  partnerId: number
): {
  path: string;
  name: string;
  linkActionSubjectId: string;
}[] => {
  const links = getPartnerNavLinks();
  return [
    ...(permissions.canManageAppDetails
      ? [
          {
            path: links[PARTNER_NAV_ITEMS.APPS].expand({ id: partnerId }),
            name: TabNames.APPS,
            linkActionSubjectId: ANALYTICS_SUBJECT_ID_PARTNER_APPS_LINK,
          },
        ]
      : []),
    ...(permissions.canManagePromotions
      ? [
          {
            path: links[PARTNER_NAV_ITEMS.PROMOTIONS].expand({ id: partnerId }),
            name: TabNames.PROMOTIONS,
            linkActionSubjectId: ANALYTICS_SUBJECT_ID_PARTNER_PROMOTIONS_LINK,
          },
        ]
      : []),
    ...(permissions.canViewAnyReports
      ? [
          {
            path: links[
              getAuthorisedSubTab(PARTNER_NAV_ITEMS.REPORTS, permissions.canViewSalesReport)
            ].expand({ id: partnerId }),
            name: TabNames.REPORTS,
            linkActionSubjectId: ANALYTICS_SUBJECT_ID_PARTNER_REPORTS_LINK,
          },
        ]
      : []),
    {
      path: 'https://community.developer.atlassian.com/c/marketplace/announcements',
      name: 'Announcements',
      linkActionSubjectId: ANALYTICS_SUBJECT_ID_PARTNER_ANNOUNCEMENTS_LINK,
    },
  ];
};
