import PropTypes from 'prop-types';
import React, { Component } from 'react';

import codeSplitComponentFactory from '../../../../common/codeSplitComponentFactory';
import BaseRouteHandler from '../../common/routeHandlers/BaseRouteHandler';

const VendorDetailsCodeSplit = codeSplitComponentFactory(() =>
  import(/* webpackChunkName: "chunk-vendor-details" */ './VendorDetails').then((c) => c.default)
);

export default class VendorDetailsRouteHandler extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        vendorId: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  render() {
    return (
      <BaseRouteHandler includeFooter includeSearch>
        {/* @ts-expect-error [MC-2850] - TS2339 - Property 'match' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'. */}
        <VendorDetailsCodeSplit vendorId={Number.parseInt(this.props.match.params.vendorId, 10)} />
      </BaseRouteHandler>
    );
  }
}
