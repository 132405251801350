import { HostingTypeToQueryParam } from '@atlassian/mpac-types';

import type { HostingQueryParamType } from '@atlassian/mpac-types';

export const AllHostingTypeQueryParams: HostingQueryParamType[] = Object.keys(
  HostingTypeToQueryParam
).map((key) => HostingTypeToQueryParam[key]);

export const showOnlyPublicQueryParam = 'showOnlyPublic';

export const STARGATE_BASE_PATH = '/gateway/api';

export const PAGE_CONTAINER_MAX_WIDTH = '990px';

const getReleaseVersion = (): string => {
  const prefix = __REACT_SSR__ ? 'AMKT_FRONTEND_SSR' : 'AMKT_FRONTEND';
  // process.env.RELEASE_VERSION is populated via webpack, might be undefined in local env
  const version = process.env.RELEASE_VERSION || 'UNKNOWN';
  return `${prefix}_${version}`;
};

export const AMKT_FRONTEND_RELEASE_VERSION: string = getReleaseVersion();

export const APOLLO_CLIENT_NAME = __REACT_SSR__
  ? 'marketplace-amkt-frontend-ssr'
  : 'marketplace-amkt-frontend';

export const APOLLO_CLIENT_VERSION = AMKT_FRONTEND_RELEASE_VERSION;

/**
 * DOM Selectors which are used either in pollinators or other scripts
 * outside this repo and should be updated/cleaned up in those consumers
 * before changing here.
 * Ex – `INPUT_NAME_<..>`, `TEST_ID_<..>`, `ID_<...>`, `CLASSNAME_<...>`
 */
export const SELECTORS = Object.freeze({
  /** Name of the text search input box in the site header, used in pollinators */
  INPUT_NAME_HEADER_SEARCH_BOX: 'search-query',
});
