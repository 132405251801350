/**
 * =======================
 *    BaseMiddleware
 * =======================
 *
 * Provides smart defaults for middlewares, all middlewares should
 * implement `getKey` method and should return a unique key.
 */
class BaseMiddleware {
  onRequestTransformer(args, callbackFn, _context, _middlewareConfig) {
    return callbackFn(...args);
  }
  onFetchPrimary(args, callbackFn, _context, _middlewareConfig) {
    return callbackFn(...args);
  }
  onFetchCustom(args, callbackFn, _context, _middlewareConfig) {
    return callbackFn(...args);
  }
  onResponseTransformer(args, callbackFn, _context, _middlewareConfig) {
    return callbackFn(...args);
  }
}
export { BaseMiddleware };