import { token } from '@atlaskit/tokens';
import React from 'react';

export const TwitterLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    data-testid="twitter-logo-svg"
    aria-hidden={true}
  >
    <rect width="32" height="32" fill={token('color.background.neutral.bold')} rx="16" />
    <path
      fill={token('color.text.inverse')}
      d="M16.902 15.446 20.593 11h-.874l-3.206 3.86-2.56-3.86H11l3.872 5.838L11 21.5h.875l3.385-4.077 2.704 4.077h2.953l-4.015-6.054Zm-1.199 1.443-.392-.581-3.12-4.626h1.343l2.519 3.733.392.582 3.274 4.852h-1.344l-2.671-3.96Z"
    />
  </svg>
);
