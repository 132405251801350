import { FILTER_KEYS } from '@atlassian/mpac-utils';
import { isPlainObject } from '@atlassian/mpac-utils/object';
import { filtersMap as algoliaFacetMap } from './FiltersMap';
const defaultFacetsValues = {
  [algoliaFacetMap[FILTER_KEYS.HIDDEN]]: 'notHidden',
  [algoliaFacetMap[FILTER_KEYS.CANONICAL_VERSION]]: 'true'
};

/**
 * Transform the filters to match the Algolia index.
 * @param {SelectedOptions} filters - The filters to transform.
 * @return {string[]} - The transformed filters.
 */
export const transformFilters = function transformFilters() {
  let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let sortFilters = arguments.length > 1 ? arguments[1] : undefined;
  let additionalFilters = arguments.length > 2 ? arguments[2] : undefined;
  // Transform each filter option
  const transformedFilters = Object.entries(filters).map(_ref => {
    let [filterId, filterOptions] = _ref;
    const filterOptionsValue = Array.isArray(filterOptions) ? filterOptions : [filterOptions];
    return filterOptionsValue.map(filterOption => isPlainObject(algoliaFacetMap[filterId]) ? // If the specified filterId maps to an object in algoliaFacetMap, use the id of the filterOption as algolia facet key
    "".concat(algoliaFacetMap[filterId][filterOption.id], ":").concat(filterOption.value === 'bugBountyApproved' ? 'approved' : filterOption.value) : "".concat(algoliaFacetMap[filterId], ":").concat(filterOption.value));
  });

  // Add default facet filtering
  transformedFilters.push(...getDefaultFacetFilters());
  if (sortFilters && Object.keys(sortFilters).length > 0) {
    transformedFilters.push(transformSortToFacetFilters(sortFilters));
  }
  if (additionalFilters !== null && additionalFilters !== void 0 && additionalFilters.vendorId) {
    transformedFilters.push(["vendor.id:".concat(additionalFilters.vendorId)]);
  }
  if (additionalFilters !== null && additionalFilters !== void 0 && additionalFilters.product) {
    transformedFilters.push(["version.compatibilities.applicationKey:".concat(additionalFilters.product)]);
  }
  if (additionalFilters !== null && additionalFilters !== void 0 && additionalFilters.hosting) {
    transformedFilters.push(["version.compatibilities.hosting:".concat(additionalFilters.hosting)]);
  }
  return transformedFilters;
};
export const transformSortToFacetFilters = sortFilters => Object.entries(sortFilters).map(_ref2 => {
  let [filterId, value] = _ref2;
  return "".concat(filterId, ":").concat(value);
});
export const getDefaultFacetFilters = () => Object.entries(defaultFacetsValues).map(_ref3 => {
  let [filterId, value] = _ref3;
  return ["".concat(filterId, ":").concat(value)];
});