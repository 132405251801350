import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CodeSplitComponent from './CodeSplitComponent';

export default (resolveComponent) => {
  return class CodeSplitComponentWrapper extends Component {
    static propTypes = {
      resolveAsyncProps: PropTypes.func,
    };

    static defaultProps = {
      resolveAsyncProps: () => Promise.resolve({}),
    };

    render() {
      // @ts-expect-error [MC-2850] - TS2339 - Property 'resolveAsyncProps' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
      const { resolveAsyncProps, ...syncProps } = this.props;
      return (
        <CodeSplitComponent syncProps={syncProps} resolveAsyncProps={this.resolveAsyncProps} />
      );
    }

    resolveAsyncProps = () =>
      // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'resolveComponent'. | TS2339 - Property 'resolveAsyncProps' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
      Promise.all([resolveComponent(), this.props.resolveAsyncProps()]).then(
        ([component, otherProps]: [any, any]) => ({ component, ...otherProps })
      );
  };
};
