import { FILTER_KEYS } from '@atlassian/mpac-utils';
export const filtersMap = {
  [FILTER_KEYS.PRODUCT]: 'version.compatibilities.applicationKey',
  [FILTER_KEYS.PRICE]: {
    [FILTER_KEYS.PAID]: 'version.paid',
    [FILTER_KEYS.FREE_STARTER_TIER]: 'freeStarterTierStatus'
  },
  [FILTER_KEYS.HOSTING]: 'version.compatibilities.hosting',
  [FILTER_KEYS.TRUST_SIGNALS]: {
    [FILTER_KEYS.CLOUD_FORTIFIED]: 'programs.cloudFortified.status',
    [FILTER_KEYS.BUG_BOUNTY_PARTICIPANT]: 'programs.bugBountyParticipant.cloud.status',
    [FILTER_KEYS.RUNS_ON_ATLASSIAN]: 'tags.marketingLabels'
  },
  // // has to be added as direct numericFilters instead of facetFilter (numericFilters: ['ratings.avgStars >= 3'])
  // // https://www.algolia.com/doc/api-reference/api-parameters/numericFilters/
  // ratings: {
  //   '3StarAbove': 'ratings.avgStars >= 3',
  //   '2StarAbove': 'ratings.avgStars >= 2',
  //   '1StarAbove': 'ratings.avgStars >= 1',
  //   noRating: 'ratings.avgStars = 0',
  // },
  [FILTER_KEYS.OTHER]: {
    [FILTER_KEYS.STABLE]: 'version.stable',
    [FILTER_KEYS.VENDOR_SUPPORTED]: 'version.supported'
  },
  [FILTER_KEYS.CATEGORIES]: 'tags.searchTerm',
  [FILTER_KEYS.USE_CASES]: 'tags.searchTerm',
  [FILTER_KEYS.HIDDEN]: 'hidden',
  [FILTER_KEYS.CANONICAL_VERSION]: 'version.canonicalVersion',
  [FILTER_KEYS.DEPLOYMENTS]: 'deployments',
  [FILTER_KEYS.TOP_VENDOR]: 'vendor.isTopVendor',
  [FILTER_KEYS.LATEST_OF_HOSTING]: 'version.latestOfHosting',
  [FILTER_KEYS.IS_ATLASSIAN]: 'vendor.isAtlassian',
  [FILTER_KEYS.MARKETPLACE_TYPE]: 'version.marketplaceType',
  [FILTER_KEYS.MARKETING_LABELS]: 'marketingLabels',
  [FILTER_KEYS.PLUGIN_SYSTEM_VERSION]: 'version.pluginSystemVersion'
};