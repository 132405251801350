import { UrlUtils } from '@atlassian/mpac-utils';
import React from 'react';

import { DISCOVER, SEARCH } from '../constants/perspectives';
import AddonDiscoveryRouteHandler from './AddonDiscoveryRouteHandler';

// eslint-disable-next-line react/display-name
const renderGen = (perspective) => (props) =>
  <AddonDiscoveryRouteHandler {...props} perspective={perspective} />;
const renderDiscover = renderGen(DISCOVER);
const renderSearch = renderGen(SEARCH);

export default ({ addonDiscoveryConfig }) => {
  return [
    {
      name: 'homeScreen',
      path: addonDiscoveryConfig.links.home.href,
      exact: true,
      render: renderDiscover,
    },
    {
      name: 'searchScreen',
      path: addonDiscoveryConfig.links.search.href,
      exact: true,
      render: renderSearch,
    },
    {
      name: 'searchScreen',
      path: UrlUtils.transformUriTemplateToRoutePath({
        template: addonDiscoveryConfig.links.silter.href,
      }),
      exact: true,
      render: renderSearch,
    },
    {
      name: 'searchScreen',
      path: UrlUtils.transformUriTemplateToRoutePath({
        template: addonDiscoveryConfig.links.applicationSilter.href,
      }),
      exact: true,
      render: renderSearch,
    },
    {
      name: 'productHomeScreen',
      path: UrlUtils.transformUriTemplateToRoutePath({
        template: addonDiscoveryConfig.links.application.href,
      }),
      exact: true,
      render: renderDiscover,
    },
  ];
};
