import { Record } from 'immutable';

import {
  DISPLAY_COLLECTIONS_FOOTER,
  DISPLAY_GENERAL_SEARCH,
  DISPLAY_PARTNER_QUICK_LINKS,
  LOADING_COMPLETE,
} from '../actions/App';

// exposed for testing
export const AppState = Record({
  initialLoadComplete: false,
  displayGeneralTextSearch: true,
  displayPartnerQuickLinks: false,
  displayCollectionsFooter: false,
});

export default function AppReducer(state = new AppState(), action) {
  switch (action.type) {
    case LOADING_COMPLETE:
      return state.set('initialLoadComplete', true);
    case DISPLAY_GENERAL_SEARCH:
      return state.set('displayGeneralTextSearch', action.show);
    case DISPLAY_PARTNER_QUICK_LINKS:
      return state.set('displayPartnerQuickLinks', action.show);
    case DISPLAY_COLLECTIONS_FOOTER:
      return state.set('displayCollectionsFooter', action.show);
    default:
      return state;
  }
}
