import type { ApplicationConfig, InitialConfig } from '../application-config/types/BootstrapConfig';

type GetAppConfigArgs = {
  applicationConfig: ApplicationConfig;
  initialConfig: InitialConfig;
};

const getFilesLink = (baseUrl: string) =>
  `${baseUrl}/files/{fileName}?fileType=image&mode=full-fit`;

const getGeneralLinks = ({ applicationConfig }: GetAppConfigArgs) => {
  const { mpacCdnBaseUrl, preferencesBaseUrl, mpacBaseUrl } = applicationConfig;

  return {
    addonListingWithHosting: {
      href: '/apps/{pluginId}/{appName}{?hosting,tab}',
      templated: true,
    },
    files: {
      href: getFilesLink(mpacCdnBaseUrl),
      templated: true,
    },
    getMailingListLocale: {
      href: `${preferencesBaseUrl}/rest/forms/locale`,
      templated: true,
    },
    subscribeToMailingList: {
      href: `${mpacBaseUrl}/rest/internal/newsletters/subscriptions`,
      templated: true,
    },
    subscribeToMailingListFailed: {
      href: `${mpacBaseUrl}/rest/internal/users/subscription-failed{?emailId,mailingListId}`,
      templated: true,
    },
    currentUser: {
      href: `${mpacBaseUrl}/rest/internal/users/current`,
    },
  };
};

const getAddonDiscoveryConfig = () => {
  return {
    metricsKeys: {
      discover: 'http.home.get',
      search: 'http.search.get',
      application: 'http.addons_app_application.get',
      applicationSilter: 'http.addons_app_application_silter.get',
      silter: 'http.addons_silter.get',
    },
    links: {
      application: {
        href: '/addons/app/{application}',
        templated: true,
      },
      applicationSilter: {
        href: '/addons/app/{application}/{silter}',
        templated: true,
      },
      home: {
        href: '/',
      },
      silter: {
        href: '/addons/{silter}',
        templated: true,
      },
      search: {
        href: '/search',
      },
    },
  };
};

const getCommonConfig = ({ applicationConfig, initialConfig }: GetAppConfigArgs) => {
  const { algolia, analytics } = applicationConfig;
  const { frontendBootstrap } = initialConfig;
  return {
    algoliaConfig: {
      applicationId: algolia.applicationId,
      apiKey: algolia.apiKey,
      environment: algolia.environment,
    },
    contentfulConfig: {
      authToken: frontendBootstrap.isAdmin
        ? '95c3b871aa1b5e5a103917933a44ff649850e1c94de1cfbb2cf0e9f8ba32704c'
        : '34f9a2bf6ab2d9c35b7f312bd5b559c69e4386c11d441c5660add1df7821bb31',
      spaceId: 'xi2je6di2mcr',
      previewMode: !!frontendBootstrap.isAdmin,
    },
    googleAnalyticsId: analytics.googleAnalyticsId,
    googleAnalyticsGa4Id: analytics.googleAnalyticsGa4Id,
    environment: algolia.environment,
  };
};

const getSolutionsConfig = () => {
  return {
    solutions: [
      {
        key: 'project-management',
        name: 'Project management',
      },
      {
        key: 'themes-styles',
        name: 'Themes and styles',
      },
      {
        key: 'test-management',
        name: 'Test management',
      },
      {
        key: 'monitoring',
        name: 'Monitoring',
      },
      {
        key: 'it-service-management',
        name: 'IT service management',
      },
      {
        key: 'document-management',
        name: 'Document management',
      },
      {
        key: 'continuous-integration',
        name: 'Continuous integration',
      },
      {
        key: 'charts-diagrams',
        name: 'Charts and diagrams',
      },
      {
        key: 'design-tools',
        name: 'Design tools',
      },
      {
        key: 'time-tracking',
        name: 'Time tracking',
      },
    ],
    metricsKeys: {
      solution: 'http.categories_category.get',
    },
    links: {
      solution: {
        href: '/categories/{category}',
        templated: true,
      },
    },
  };
};

const getCollectionsConfig = () => ({
  metricsKeys: {
    collection: 'http.categories_collection.get',
  },
  links: {
    collection: {
      href: '/categories/{collection}',
      templated: true,
    },
    collections: [
      {
        key: 'devops',
        name: 'DevOps',
        link: {
          href: '/categories/devops',
        },
        contentfulEntryId: '5x6DyCJbejcXJ0l6QHKANY',
      },
      {
        key: 'devops-discover',
        name: 'Devops - Discover',
        link: {
          href: '/categories/devops/discover',
        },
        contentfulEntryId: '6343Ae31QhUDJ0JiPmy0rK',
      },
      {
        key: 'devops-plan',
        name: 'Devops - Plan',
        link: {
          href: '/categories/devops/plan',
        },
        contentfulEntryId: '5WodC2EGvjSuo8vvShSfzs',
      },
      {
        key: 'devops-build',
        name: 'Devops - Build',
        link: {
          href: '/categories/devops/build',
        },
        contentfulEntryId: '25WTaDRO1Un1yZOBveNKXZ',
      },
      {
        key: 'devops-test',
        name: 'Devops - Test',
        link: {
          href: '/categories/devops/test',
        },
        contentfulEntryId: '4BFg4Y92hqGKmQsiZWIB0p',
      },
      {
        key: 'devops-deploy',
        name: 'Devops - Deploy',
        link: {
          href: '/categories/devops/deploy',
        },
        contentfulEntryId: '4PxiQoxe0G5hyYyzI3WzfX',
      },
      {
        key: 'devops-operate',
        name: 'Devops - Operate',
        link: {
          href: '/categories/devops/operate',
        },
        contentfulEntryId: 'HHGMEZ0Z9yoNXmqIc2OMD',
      },
      {
        key: 'devops-observe',
        name: 'Devops - Observe',
        link: {
          href: '/categories/devops/observe',
        },
        contentfulEntryId: 'dBY8jQikN2pVsNGR0JPtf',
      },
      {
        key: 'devops-continuous-feedback',
        name: 'Devops - Continuous Feedback',
        link: {
          href: '/categories/devops/continuous-feedback',
        },
        contentfulEntryId: '3J9gQ1LhdG3jxJ0Lkg9dA0',
      },
    ],
  },
});

const getVendorDetailsConfig = ({ applicationConfig }: GetAppConfigArgs) => {
  const { mpacBaseUrl } = applicationConfig;
  return {
    metricsKeys: {
      vendorDetails: 'http.vendors_vendorId_vendorName.get',
    },
    links: {
      vendorDetailsPage: {
        href: '/vendors/{vendorId}/{vendorName}',
        templated: true,
      },
      vendorAddonsRest: {
        href: `${mpacBaseUrl}/rest/2/addons/vendor/{vendorId}{?application,applicationBuild,category*,cost,filter,forThisUser,hosting*,includeHidden,includePrivate,marketingLabel*,text,withVersion,storesPersonalData,offset,limit}`,
        templated: true,
      },
      vendorArchivedAddonsRest: {
        href: `${mpacBaseUrl}/rest/2/addons/archived/vendor/{vendorId}{?application,applicationBuild,category*,cost,filter,forThisUser,hosting*,includeHidden,includePrivate,marketingLabel*,text,withVersion,storesPersonalData,offset,limit}`,
        templated: true,
      },
      vendorDetailsRest: {
        href: `${mpacBaseUrl}/rest/2/vendors/{vendorId}`,
        templated: true,
      },
    },
  };
};

export const getBootstrapConfig = (
  applicationConfig: ApplicationConfig,
  initialConfig: InitialConfig
) => {
  const { analyticsClient, featureFlags } = initialConfig;

  return {
    /* Picked from application config directly */
    adminHubBaseUrl: applicationConfig.adminHubBaseUrl,
    graphQLGatewayUrl: applicationConfig.graphQLGatewayUrl,
    mpacBaseUrl: applicationConfig.mpacBaseUrl,
    mpacCdnBaseUrl: applicationConfig.mpacCdnBaseUrl,
    macBaseUrl: applicationConfig.macBaseUrl,
    wacBaseUrl: applicationConfig.wacBaseUrl,
    stargateBaseUrl: applicationConfig.stargateBaseUrl,
    atlassianCommunityBaseUrl: applicationConfig.atlassianCommunityBaseUrl,
    jiraServiceDeskEmbedBaseUrl: applicationConfig.jiraServiceDeskEmbedBaseUrl,
    identityBaseUrl: applicationConfig.identityBaseUrl,
    environment: applicationConfig.environment,
    sentryDsn: applicationConfig.sentry.dsn,
    analytics: applicationConfig.analytics,
    metal: applicationConfig.metal,
    enableIdentityHeartbeats: applicationConfig.enableIdentityHeartbeats,

    /* Additional app config derived using application config, feature flags */
    appConfig: {
      generalLinks: getGeneralLinks({ applicationConfig, initialConfig }),
      addonDiscovery: getAddonDiscoveryConfig(),
      solutions: getSolutionsConfig(),
      collections: getCollectionsConfig(),
      commonConfig: getCommonConfig({ applicationConfig, initialConfig }),
      vendorDetails: getVendorDetailsConfig({ applicationConfig, initialConfig }),
    },

    analyticsClient,
    featureFlags,

    feedbackCollectorApiKeys: applicationConfig.feedbackCollectorApiKeys,

    feedbackGatewayBaseUrl: applicationConfig.feedbackGatewayBaseUrl,
    launchDarklyUserKey: initialConfig.frontendBootstrap.launchDarklyUserKey,
  };
};
