/*
 The backend handles pushing performance metrics
 Currently to: bm3
 */

import initBrowserMetrics, { appListingPageLoad, categoryPageLoad, collectionPageLoad, discoverPageLoad, homePageLoad, partnerProfilePageLoad, productPageLoad, screenPageLoad, searchPageLoad, startPageLoad } from '../../clients/browser-metrics';
import { Events } from '../../events';
export const pageNameToEventFnMap = {
  appListingScreen: appListingPageLoad,
  homeScreen: discoverPageLoad,
  searchScreen: screenPageLoad,
  vendorDetailsScreen: partnerProfilePageLoad
};
export const aetherPageNameToEventFnMap = {
  appListingScreen: appListingPageLoad,
  homeScreen: homePageLoad,
  searchScreen: searchPageLoad,
  collectionScreen: collectionPageLoad,
  categoriesScreen: categoryPageLoad,
  productHomeScreen: productPageLoad,
  vendorDetailsScreen: partnerProfilePageLoad
};
const createListener = statsigClient => (event, getContext) => {
  const {
    enableAetherExperience
  } = statsigClient;
  const page = getContext().page;
  switch (event.type) {
    case Events.LABEL_TIME_TO_FIRST_MEANINGFUL_PAINT:
      {
        const eventFn = enableAetherExperience && enableAetherExperience() ? aetherPageNameToEventFnMap[page] : pageNameToEventFnMap[page];
        if (!eventFn) {
          return;
        }
        eventFn.stop();
        break;
      }
    case Events.LABEL_ROUTE_CHANGE_START:
      {
        startPageLoad();
        break;
      }
  }
};
const performanceBackend = config => {
  initBrowserMetrics(config);
  const {
    statsigClient
  } = config;
  return createListener(statsigClient);
};
export default performanceBackend;