import { B100, B300, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import styled from '@emotion/styled';

export const HeaderContentMenu = styled.nav`
  display: flex;
  justify-items: center;
  flex: 0 0 auto;
  height: 100%;
  margin-right: ${token('space.250', '20px')};
  justify-content: center;
`;

export const LogInLink = styled.a`
  color: ${N0};
  padding: 14px 10px ${token('space.100', '8px')} 10px;
  display: block;
  line-height: 1;

  &:hover {
    background-color: ${B300};
    text-decoration: none;
    color: ${N0};
  }

  &:focus,
  &:active {
    background-color: ${B100};
    text-decoration: none;
    color: ${N0};
  }
`;
