import { MarketplaceAnalyticsContext } from '@atlassian/mpac-analytics';
import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../Routes/components/common/routeHandlers/BaseRouteHandler';

const PartnerProfilePageCodeSplit = lazyForPaint(() =>
  import(/* webpackChunkName: "chunk-partner-profile-page" */ './PartnerProfilePage').then(
    (module) => module.default
  )
);

const PartnerProfileRouteHandler = () => {
  return (
    <BaseRouteHandler scrollToTopOnPathChange>
      <LazySuspense fallback={<div />}>
        <MarketplaceAnalyticsContext properties={{ source: 'vendorDetailsScreen' }}>
          <PartnerProfilePageCodeSplit />
        </MarketplaceAnalyticsContext>
      </LazySuspense>
    </BaseRouteHandler>
  );
};
export default PartnerProfileRouteHandler;
