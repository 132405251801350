import { h5Css, Link } from '@atlassian/amkt-common';
import { LinkUtils } from '@atlassian/mpac-utils';
import styled from '@emotion/styled';
import React from 'react';
import { ScreenClassRender } from 'react-grid-system';

import type CollectionsConfig from 'app/records/CollectionsConfig';
import type CommonConfig from 'app/records/CommonConfig';
import type SolutionsConfig from 'app/records/SolutionsConfig';
import { helpLinks, mediaLinks } from '../constants/linkGroups';
import { useFooterDetails } from '../hooks';
import NamedLinksFooterSection from './NamedLinksFooterSection';

import type { History } from 'history';

import styles from './Footer.less';

export const MediaLinksHeading = styled.div`
  ${h5Css}
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
`;

type FooterPropTypes = {
  history: History;
  config: {
    commonConfig: CommonConfig;
    solutionsConfig: SolutionsConfig;
    collectionsConfig: CollectionsConfig;
  };
};
const Footer = (props: FooterPropTypes) => {
  const { footerDetails } = useFooterDetails(props.config);

  const renderFooter = (sc) => {
    const largeScreen = sc === 'lg' || sc === 'xl';
    return largeScreen ? (
      <div className={styles.footerContent}>
        <div className={styles.footerContentInner}>
          <div className={styles.footerSectionContainer}>
            {footerDetails ? (
              <NamedLinksFooterSection
                heading="Collections"
                namedLinks={footerDetails}
                onLinkClick={onCollectionLinkClick}
              />
            ) : null}
          </div>
          <div className={styles.footerSectionContainer}>
            <NamedLinksFooterSection
              heading="Help"
              namedLinks={helpLinks}
              openLinksInNewWindow={true}
            />
          </div>
          <div className={styles.footerSectionContainer}>
            <div className={styles.mediaLinksContainer}>
              <MediaLinksHeading>Follow</MediaLinksHeading>
              {mediaLinks.map((link) => {
                return (
                  <Link
                    className={styles.mediaLink}
                    href={link.link.href}
                    key={link.key}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={link.image} alt={link.key} />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  };

  const onCollectionLinkClick = (e, namedLink) => {
    if (props.history) {
      LinkUtils.maybeOpenInNewTab(e, () => {
        props.history.push({ pathname: namedLink.link.href });
      });
    }
  };

  return (
    <ScreenClassRender render={renderFooter}>
      <span />
    </ScreenClassRender>
  );
};

export default Footer;
