import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import AkIconQuestionCircle from '@atlaskit/icon/glyph/question-circle';
import { OrderedSet } from 'immutable';
import React from 'react';

import * as analytics from '../../../../../../utils/analytics/analytics';
import {
  customerDropdownItems,
  developerDropdownItems,
  helpItems,
} from '../../../../constants/dropdownGroups';
import { HelpDropdownTrigger, QuestionIcon } from './styles';

const HelpDropdown = () => {
  const onItemActivate = (menuItem) => {
    analytics.trackHelpActionClicked(menuItem.content);
    if (menuItem.onActivate) {
      menuItem.onActivate();
    }
  };

  const renderDropdownItems = (items) => {
    return items.map((item) => (
      <DropdownItem
        key={item.content}
        href={item.href}
        target={item.target ?? '_blank'}
        onClick={() => onItemActivate(item)}
      >
        {item.content}
      </DropdownItem>
    ));
  };

  const renderDropdownItemGroups = (itemGroups) => {
    return itemGroups.map((itemGroup, index) => (
      <DropdownItemGroup key={index} title={itemGroup.heading}>
        {renderDropdownItems(itemGroup.items)}
      </DropdownItemGroup>
    ));
  };

  const items = OrderedSet([
    // @ts-expect-error [MC-2850] - TS2345 - Argument of type 'string' is not assignable to parameter of type 'OrderedSet<Map<string, string>>'.
    developerDropdownItems.set('heading', 'Developers'),
    // @ts-expect-error [MC-2850] - TS2345 - Argument of type 'string' is not assignable to parameter of type 'OrderedSet<Map<string, string>>'.
    customerDropdownItems.set('heading', 'Customers'),
    helpItems,
  ]);

  return (
    <DropdownMenu
      appearance="tall"
      placement="bottom-end"
      trigger={({ triggerRef, ...props }) => (
        // @ts-ignore MC-2850
        <HelpDropdownTrigger ref={triggerRef} role="button" tabIndex={0} {...props}>
          <QuestionIcon>
            <AkIconQuestionCircle label="Help" secondaryColor="inherit" size="medium" />
          </QuestionIcon>
          <span>Help</span>
        </HelpDropdownTrigger>
      )}
    >
      {renderDropdownItemGroups(items.toJS())}
    </DropdownMenu>
  );
};

export default HelpDropdown;
