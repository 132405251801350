import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { fetchOk } from '@atlassian/mpac-fetch-wrapper';
export const FallbackFrontendBootstrap = {
  launchDarklyUserKey: 'ANY_USER'
};
export const getFrontendBootstrap = async (headers, isSsr) => {
  /**
   * Tesseract in S2S auth changes the base URL to hit demand service directly
   * with appropriate asap auth headers, hence CSR would require appropriate prefix which gateway
   * understands while SSR would only need to have the URL which demand service understands
   */
  const path = isSsr ? "/frontend/bootstrap" : "/gateway/api/marketplace/internal/demand/frontend/bootstrap";
  const options = _objectSpread({}, headers && {
    headers
  });
  try {
    const response = await fetchOk(path, options);
    const frontendBootstrapResponse = await response.json();
    return _objectSpread(_objectSpread({}, frontendBootstrapResponse), {}, {
      setCookieHeader: response.headers.get('set-cookie')
    });
  } catch (error) {
    console.error('getFrontendBootstrap', 'Failed to fetch bootstrap, using fallback', error);
    return _objectSpread({}, FallbackFrontendBootstrap);
  }
};