export default function registerClicktaleEvents(clicktaleId) {
  // Load after spinner animations, so we get the final rep of the page
  window.addEventListener('load', () => {
    // @ts-expect-error [MC-2850] - TS2339 - Property 'WRInitTime' does not exist on type 'Window & typeof globalThis'.
    window.WRInitTime = Date.now();
    const ctJS = document.createElementNS('http://www.w3.org/1999/xhtml', 'script');
    // @ts-expect-error [MC-2850] - TS2339 - Property 'type' does not exist on type 'HTMLElement'.
    ctJS.type = 'text/javascript';
    // @ts-expect-error [MC-2850] - TS2339 - Property 'src' does not exist on type 'HTMLElement'.
    ctJS.src = `https://cdnssl.clicktale.net/www33/ptc/${clicktaleId}.js`;
    document.body.append(ctJS);
  });
}
