import styled from '@emotion/styled';

type OverlayProps = {
  top: string;
};

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  right: 0;
  min-width: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  top: ${({ top }: OverlayProps) => top || 0};
`;

export const TransitionGroupContainer = styled.div`
  .fadeIn {
    opacity: 0.01;

    &.fadeInActive {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }
  }

  .fadeOut {
    opacity: 1;

    &.fadeOutActive {
      opacity: 0;
      transition: opacity 500ms ease-in;
    }
  }
`;

export const MenuContent = styled.div`
  .slideLeft {
    left: 100%;

    &.slideLeftActive {
      left: 20%;
      transition: left 0.5s;
    }
  }

  .slideRight {
    left: 20%;
  }

  .slideRightActive {
    left: 100%;
    transition: left 0.5s;
  }
`;
