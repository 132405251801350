import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeVar, Observable } from '@apollo/client';
import { BaseMiddleware } from '../graphql-orchestrator-link';
const isTwoStepAuthTimedOutGraphqlVar = makeVar(false);
const TWO_STEP_AUTH_TIMEOUT_STATUS_CODE = 412;
class TwoStepAuthTimedOutMiddleware extends BaseMiddleware {
  getKey() {
    return TwoStepAuthTimedOutMiddleware.key;
  }
  checkIfTwoSVAuthTimedOut(errors) {
    var _errors$, _errors$$extensions;
    if (!(errors !== null && errors !== void 0 && errors.length)) {
      return;
    }
    if (((_errors$ = errors[0]) === null || _errors$ === void 0 ? void 0 : (_errors$$extensions = _errors$.extensions) === null || _errors$$extensions === void 0 ? void 0 : _errors$$extensions.statusCode) === TWO_STEP_AUTH_TIMEOUT_STATUS_CODE) {
      isTwoStepAuthTimedOutGraphqlVar(true);
    }
  }
  onFetchPrimary(args, callbackFn, _context) {
    const observable = callbackFn(...args);
    return new Observable(observer => {
      observable.subscribe({
        error: err => {
          observer.error(err);
        },
        next: primaryData => {
          this.checkIfTwoSVAuthTimedOut(primaryData.errors);
          observer.next(primaryData);
        },
        complete() {
          observer.complete();
        }
      });
    });
  }
}
_defineProperty(TwoStepAuthTimedOutMiddleware, "key", 'twoStepVerification');
export { isTwoStepAuthTimedOutGraphqlVar, TwoStepAuthTimedOutMiddleware };