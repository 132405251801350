import { getPartnerApisHeaders } from '@atlassian/mpac-api-client';
import { UrlUtils } from '@atlassian/mpac-utils';
import { fromJS, List, Map } from 'immutable';

import Link from '../../../../../../common/records/Link';
import VendorArchivedAddons, {
  VendorArchivedAddonAddonEmbeddedImage,
  VendorArchivedAddonAddonEmbeddedImageLinks,
  VendorArchivedAddonsAddon,
  VendorArchivedAddonsAddonEmbedded,
  VendorArchivedAddonsAddonLinks,
  VendorArchivedAddonsEmbedded,
} from '../records/VendorArchivedAddons';
import VendorDetails, {
  TopVendorStatus,
  VendorDetailsAddress,
  VendorDetailsEmbedded,
  VendorDetailsLinks,
  VendorDetailsLogo,
  VendorDetailsLogoLinks,
  VendorDetailsPrograms,
  VendorDetailsSolutionPartner,
  VendorDetailsSupportDetails,
  VendorDetailsSupportDetailsSupportOrg,
  VendorDetailsVendorProvidedLinks,
} from '../records/VendorDetails';
import VendorHasCloudAddons from '../records/VendorHasCloudAddons';

export const RETRIEVE_VENDOR_DETAILS = 'VENDOR_DETAILS_RETRIEVE';
export const RECEIVE_VENDOR_DETAILS = 'VENDOR_DETAILS_RECEIVE';
export const DATA_ERROR_VENDOR_DETAILS = 'VENDOR_DETAILS_DATA_ERROR';

export const RETRIEVE_VENDOR_HAS_CLOUD_ADDONS = 'VENDOR_HAS_CLOUD_ADDONS_RETRIEVE';
export const RECEIVE_VENDOR_HAS_CLOUD_ADDONS = 'VENDOR_HAS_CLOUD_ADDONS_RECEIVE';
export const DATA_ERROR_VENDOR_HAS_CLOUD_ADDONS = 'VENDOR_HAS_CLOUD_ADDONS_DATA_ERROR';

export const RETRIEVE_VENDOR_ARCHIVED_ADDONS = 'VENDOR_ARCHIVED_ADDONS_RETRIEVE';
export const RECEIVE_VENDOR_ARCHIVED_ADDONS = 'VENDOR_ARCHIVED_ADDONS_RECEIVE';
export const DATA_ERROR_VENDOR_ARCHIVED_ADDONS = 'VENDOR_ARCHIVED_ADDONS_DATA_ERROR';

export const VENDOR_ADDONS_LIST_RENDER_COMPLETE = 'VENDOR_ADDONS_LIST_RENDER_COMPLETE';

function retrieveVendorDetails() {
  return {
    type: RETRIEVE_VENDOR_DETAILS,
  };
}

function receiveVendorDetails({ vendorDetails }) {
  return {
    type: RECEIVE_VENDOR_DETAILS,
    vendorDetails,
  };
}

function dataErrorVendorDetails({ dataErrorMessages }) {
  return {
    type: DATA_ERROR_VENDOR_DETAILS,
    dataErrorMessages,
  };
}

function retrieveVendorHasCloudAddons() {
  return {
    type: RETRIEVE_VENDOR_HAS_CLOUD_ADDONS,
  };
}

function receiveVendorHasCloudAddons({ vendorHasCloudAddons }) {
  return {
    type: RECEIVE_VENDOR_HAS_CLOUD_ADDONS,
    vendorHasCloudAddons,
  };
}

function dataErrorVendorHasCloudAddons({ dataErrorMessages }) {
  return {
    type: DATA_ERROR_VENDOR_HAS_CLOUD_ADDONS,
    dataErrorMessages,
  };
}

export function retrieveVendorArchivedAddons() {
  return {
    type: RETRIEVE_VENDOR_ARCHIVED_ADDONS,
  };
}

export function receiveVendorArchivedAddons({ vendorArchivedAddons }) {
  return {
    type: RECEIVE_VENDOR_ARCHIVED_ADDONS,
    vendorArchivedAddons,
  };
}

export function dataErrorVendorArchivedAddons({ dataErrorMessages }) {
  return {
    type: DATA_ERROR_VENDOR_ARCHIVED_ADDONS,
    dataErrorMessages,
  };
}

export function vendorAddonsListRenderComplete() {
  return {
    type: VENDOR_ADDONS_LIST_RENDER_COMPLETE,
  };
}

const generateRetriever =
  ({ onRetrieveAction, onReceiveAction, onDataErrorAction, resolveUrl }) =>
  ({ vendorId }) =>
  (dispatch, getState) => {
    dispatch(onRetrieveAction());
    fetch(resolveUrl({ vendorId, state: getState() }), {
      credentials: 'same-origin',
      headers: getPartnerApisHeaders(),
    })
      .then((response) => {
        return response.ok ? response.json() : Promise.reject(response.statusText);
      })
      .then(
        (json) => {
          dispatch(onReceiveAction({ data: fromJS(json) }));
        },
        (error) => {
          dispatch(
            onDataErrorAction({
              dataErrorMessages: List([Map({ message: error })]),
            })
          );
        }
      );
  };

export const doRetrieveVendorDetails = generateRetriever({
  onRetrieveAction: retrieveVendorDetails,
  onReceiveAction: ({ data }) =>
    receiveVendorDetails({
      vendorDetails: new VendorDetails({
        _links: new VendorDetailsLinks({
          // @ts-expect-error [MC-2850] - TS2322 - Type '(Record<{ href: string; }> & Readonly<{ href: string; }>) | undefined' is not assignable to type 'undefined'.
          edit: data.getIn(['_links', 'edit'])
            ? new Link({ href: data.getIn(['_links', 'edit', 'href']) })
            : undefined,
        }),
        _embedded: new VendorDetailsEmbedded({
          logo: new VendorDetailsLogo({
            _links: new VendorDetailsLogoLinks({
              image: new Link({
                href: data.getIn(['_embedded', 'logo', '_links', 'image', 'href']),
              }),
            }),
          }),
        }),
        vendorLinks: new VendorDetailsVendorProvidedLinks({
          homePage: data.getIn(['vendorLinks', 'homePage']),
        }),
        name: data.get('name'),
        description: data.get('description'),
        email: data.get('email'),
        phone: data.get('phone'),
        // @ts-expect-error [MC-2850] - TS2322 - Type 'VendorDetailsAddress | undefined' is not assignable to type 'undefined'.
        address: data.get('address')
          ? new VendorDetailsAddress({
              line1: data.getIn(['address', 'line1']),
              line2: data.getIn(['address', 'line2']),
              city: data.getIn(['address', 'city']),
              state: data.getIn(['address', 'state']),
              postCode: data.getIn(['address', 'postCode']),
              country: data.getIn(['address', 'country']),
            })
          : undefined,
        otherContactDetails: data.get('otherContactDetails'),
        supportDetails: new VendorDetailsSupportDetails({
          // @ts-expect-error [MC-2850] - TS2322 - Type 'VendorDetailsSupportDetailsSupportOrg | undefined' is not assignable to type 'undefined'.
          supportOrg: data.getIn(['supportDetails', 'supportOrg'])
            ? new VendorDetailsSupportDetailsSupportOrg({
                name: data.getIn(['supportDetails', 'supportOrg', 'name']),
                supportEmail: data.getIn(['supportDetails', 'supportOrg', 'supportEmail']),
                supportUrl: data.getIn(['supportDetails', 'supportOrg', 'supportUrl']),
                supportPhone: data.getIn(['supportDetails', 'supportOrg', 'supportPhone']),
              })
            : undefined,
        }),
        // @ts-expect-error [MC-2850] - TS2322 - Type 'VendorDetailsSolutionPartner | undefined' is not assignable to type 'undefined'.
        solutionPartner: data.get('solutionPartner')
          ? new VendorDetailsSolutionPartner({
              partnerLevel: data.getIn(['solutionPartner', 'partnerLevel']),
            })
          : undefined,
        isAtlassian: data.get('isAtlassian'),
        // @ts-expect-error [MC-2850] - TS2322 - Type '{ type: any; } | undefined' is not assignable to type 'undefined'.
        partnerTier: data.get('partnerTier')
          ? {
              type: data.getIn(['partnerTier', 'type']),
            }
          : undefined,
        programs: new VendorDetailsPrograms({
          topVendor: new TopVendorStatus({
            status: data.getIn(['programs', 'topVendor', 'status']),
          }),
        }),
      }),
    }),
  onDataErrorAction: dataErrorVendorDetails,
  resolveUrl: ({ vendorId, state }) =>
    UrlUtils.resolveUrlFromTemplateAndParameters({
      template: state.vendorDetails.config.links.vendorDetailsRest.href,
      parameters: { vendorId },
    }),
});

export const doRetrieveVendorHasCloudAddons = generateRetriever({
  onRetrieveAction: retrieveVendorHasCloudAddons,
  onReceiveAction: ({ data }) =>
    receiveVendorHasCloudAddons({
      vendorHasCloudAddons: new VendorHasCloudAddons({ value: data.get('count') > 0 }),
    }),
  onDataErrorAction: dataErrorVendorHasCloudAddons,
  resolveUrl: ({ vendorId, state }) =>
    UrlUtils.resolveUrlFromTemplateAndParameters({
      template: state.vendorDetails.config.links.vendorAddonsRest.href,
      parameters: { hosting: 'cloud', vendorId },
    }),
});

export const doRetrieveVendorArchivedAddons = generateRetriever({
  onRetrieveAction: retrieveVendorArchivedAddons,
  onReceiveAction: ({ data }) =>
    receiveVendorArchivedAddons({
      vendorArchivedAddons: new VendorArchivedAddons({
        _embedded: new VendorArchivedAddonsEmbedded({
          addons: data.getIn(['_embedded', 'addons']).map(
            (a) =>
              new VendorArchivedAddonsAddon({
                _links: new VendorArchivedAddonsAddonLinks({
                  alternate: new Link({
                    href: a.getIn(['_links', 'alternate', 'href']),
                  }),
                }),
                _embedded: new VendorArchivedAddonsAddonEmbedded({
                  // @ts-expect-error [MC-2850] - TS2322 - Type 'VendorArchivedAddonAddonEmbeddedImage | undefined' is not assignable to type 'undefined'.
                  image: a.getIn(['_embedded', 'image'])
                    ? new VendorArchivedAddonAddonEmbeddedImage({
                        _links: new VendorArchivedAddonAddonEmbeddedImageLinks({
                          image: new Link({
                            href: a.getIn(['_embedded', 'image', '_links', 'image', 'href']),
                          }),
                        }),
                      })
                    : undefined,
                }),
                name: a.get('name'),
                key: a.get('key'),
                tagLine: a.get('tagLine'),
              })
          ),
        }),
        count: data.get('count'),
      }),
    }),
  onDataErrorAction: dataErrorVendorArchivedAddons,
  resolveUrl: ({ vendorId, state }) =>
    `${UrlUtils.resolveUrlFromTemplateAndParameters({
      template: state.vendorDetails.config.links.vendorArchivedAddonsRest.href,
      parameters: { vendorId, hosting: 'any' },
    })}&hidden=none`, // cannot specify this via link template parameters as it is not documented in our REST API
});
