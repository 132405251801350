import { PageNotFoundEvent, useAnalyticsEvents } from '@atlassian/mpac-analytics';
import { NotFound } from '@atlassian/mpac-primitives';
import React, { useEffect } from 'react';

import {
  ANALYTICS_EVENT_CONTAINER_NOT_FOUND_404,
  ANALYTICS_SUBJECT_ID_NOT_FOUND_404,
} from '../../../analytics/Events';

const NotFoundPage = () => {
  const fireAnalyticsEvent = useAnalyticsEvents();

  useEffect(() => {
    fireAnalyticsEvent({
      eventType: PageNotFoundEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NOT_FOUND_404,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NOT_FOUND_404,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NotFound />;
};

export default NotFoundPage;
