import { uuid4 } from '@sentry/utils';
import Cookies from 'js-cookie';

import type { ServerCookie } from '@atlassian/mpac-types';

export const LD_ANONYMOUS_ID_COOKIE = 'ldAnonymousId';
export const LD_COOKIE_MAX_AGE_DAYS = 365;
export const LD_COOKIE_MAX_AGE_MILLS = LD_COOKIE_MAX_AGE_DAYS * 24 * 60 * 60 * 1000;

class LdAnonymousIdSingleton {
  id: string | null;

  constructor() {
    this.id = null;
  }

  get() {
    if (this.id) {
      return this.id;
    }

    const ldAnonymousIdCookie = Cookies.get(LD_ANONYMOUS_ID_COOKIE);
    return ldAnonymousIdCookie ? ldAnonymousIdCookie : this.create();
  }

  create() {
    const newLdAnonymousId = uuid4();
    Cookies.set(LD_ANONYMOUS_ID_COOKIE, newLdAnonymousId, {
      expires: LD_COOKIE_MAX_AGE_DAYS,
      sameSite: 'strict',
    });

    return newLdAnonymousId;
  }

  set(id: string) {
    this.id = id;
  }

  getServerCookie(): ServerCookie {
    return {
      name: LD_ANONYMOUS_ID_COOKIE,
      value: this.get(),
      options: {
        maxAge: LD_COOKIE_MAX_AGE_MILLS,
        sameSite: 'strict',
      },
    };
  }
}

const ldAnonymousId = new LdAnonymousIdSingleton();
export default ldAnonymousId;
