import { Map } from 'immutable';

import {
  DISMISS_FLAG,
  SUBSCRIBE_DIALOG_CLOSED,
  SUBSCRIBE_DIALOG_OPEN,
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_SUCCESS,
} from '../action/SubscribeMailingListActions';

const initialState = Map({
  dialogOpen: false,
  errorReason: undefined,
  success: undefined,
});

export default function SubscribeMailingListReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIBE_DIALOG_OPEN:
      return state.set('dialogOpen', true);
    case SUBSCRIBE_DIALOG_CLOSED:
      return state.set('dialogOpen', false);
    case SUBSCRIBE_FAILURE:
      return state.set('errorReason', action.reason).set('success', false);
    case SUBSCRIBE_SUCCESS:
      return state.set('success', true).set('errorReason', undefined);
    case DISMISS_FLAG:
      return state.set('success', false).set('errorReason', undefined);
    default:
      return state;
  }
}
