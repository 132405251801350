import _isPlainObject from "lodash/isPlainObject";
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import _forEach from "lodash/forEach";
import _reduce from "lodash/reduce";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import _some from "lodash/some";
import _size from "lodash/size";
import camelCase from 'lodash/camelCase';

/**
 * Recursively transform all keys of the object from any case to camelCase using `lodash/camelCase`.
 * - set `depth=null` to transform all keys recursively
 * - set `depth>=1` to transform up to a certain depth of keys
 * - `depth<=0` behaves like `depth=1`
 */
export function keysToCamelCase(originalObject) {
  let depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return Object.entries(originalObject).reduce((transformedObject, _ref) => {
    let [key, value] = _ref;
    const newKey = camelCase(key);
    const newValue = typeof value === 'object' && (depth === null || depth > 1) ? keysToCamelCase(value, depth === null ? depth : depth - 1) : value;
    transformedObject[newKey] = newValue;
    return transformedObject;
  }, {});
}
/**
 * A **partial implementation** of `serialize-javscript`, in which we simply uses `JSON.stringify` and
 * then strip out any unsafe characters like `<,>,/` etc.
 *
 * We can't use `serialize-javascript` library within Tesseract since it depends on the `randombytes`
 * lib which in turn requires either a real browser or NodeJS environment. Until Tesseract runtime
 * provides an equivalent `crypto` host object, we use this partial implementation.
 *
 * Note: This method is similar to what Confluence uses; and we should move to using `serialize-javascript`
 * once Tesseract runtime provides an appropriate `crypto` implementation.
 *
 * @see https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-frontend/browse/next/packages/ssr-app/src/helpers/preloadConnectWRM.ts?at=1c367fe0d05#13-21
 * @see https://github.com/yahoo/serialize-javascript/blob/7f3ac25/index.js#L210
 */
export const sanitizeJavascript = (() => {
  // eslint-disable-next-line unicorn/better-regex, no-useless-escape
  const UNSAFE_CHARS_REGEXP = /[<>\/\u2028\u2029]/g;
  const ESCAPED_CHARS = {
    '<': '\\u003C',
    '>': '\\u003E',
    '/': '\\u002F',
    '\u2028': '\\u2028',
    '\u2029': '\\u2029'
  };
  const escapeUnsafeChars = unsafeChar => ESCAPED_CHARS[unsafeChar];
  return obj => {
    const serialized = typeof obj === 'string' ? obj : JSON.stringify(obj);

    // Protects against `JSON.stringify()` returning `undefined`, by serializing
    // to the literal string: "undefined".
    if (typeof serialized !== 'string') {
      return String(serialized);
    }
    return serialized.replace(UNSAFE_CHARS_REGEXP, escapeUnsafeChars);
  };
})();
export const flattenObject = function flattenObject(obj) {
  let prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (obj[k] && typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
};
export const unflattenObject = data => {
  const result = {};
  for (const i in data) {
    const keys = i.split('.');
    keys.reduce((r, e, j) => {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? keys.length - 1 == j ? data[i] : {} : []);
    }, result);
  }
  return result;
};
export function throwErrorOnCycles(obj) {
  try {
    if (_isObject(obj) || _isArray(obj)) {
      return JSON.parse(JSON.stringify(obj));
    } else {
      throw new Error('Expected an object however received something else');
    }
  } catch (e) {
    console.error('ERROR: objectToPathValuePair -> e', e);
    throw e;
  }
}
export function objectToPathValuePair(obj) {
  let prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  obj = throwErrorOnCycles(obj);
  return _reduce(obj, (result, value, key) => {
    const newKey = prefix ? "".concat(prefix, ".").concat(key) : key;
    if (_isObject(value) && !_isArray(value)) {
      Object.assign(result, objectToPathValuePair(value, newKey));
    } else if (_isArray(value)) {
      _forEach(value, (item, index) => {
        if (_isObject(item) && !_isArray(item)) {
          Object.assign(result, objectToPathValuePair(item, "".concat(newKey, "[").concat(index, "]")));
        } else {
          result["".concat(newKey, "[").concat(index, "]")] = item;
        }
      });
    } else {
      result[newKey] = value;
    }
    return result;
  }, {});
}
export function pathValuePairToObject(obj) {
  obj = throwErrorOnCycles(obj);
  const result = {};
  _forEach(obj, (value, key) => {
    _set(result, key, value);
  });
  return result;
}
export function removeAttributes(obj, keys) {
  const newObj = _cloneDeep(obj);
  const helper = paramObj => {
    _forEach(paramObj, (value, key) => {
      if (value && typeof value === 'object') {
        helper(value);
      }
      if (keys.includes(key)) {
        delete paramObj[key];
      }
    });
  };
  helper(newObj);
  return newObj;
}
export function isPlainObject(obj) {
  return _isPlainObject(obj);
}

/**
 * This function checks if an option is selected from the provided selected options.
 *
 * @param {SelectedOptions<T>} selectedOptions - The object containing the selected options.
 *
 * @returns {boolean} Returns true if at least one option is selected, otherwise false.
 *
 * @example
 *  const options = {option1: [], option2: [{id: 1, name: 'Option A'}]};
 *  console.log(isOptionSelected(options)); // Outputs: true
 */

export function isOptionSelected(selectedOptions) {
  return _some(selectedOptions, option => _size(option) > 0);
}