import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { FluidContainer } from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';

import { IconButton } from './Buttons/Button.styles';

export const Container = styled.header({
  position: 'sticky',
  top: 0,
  zIndex: 200,
});

export const NavbarBackground = styled.div({
  background: token('elevation.surface.overlay'),
  borderBottom: `1px solid ${token('color.border')}`,
  position: 'relative',
});

export const NavbarContainer = styled(FluidContainer)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 'auto',
  height: '64px',
  [media.above.sm]: {
    height: '72px',
  },
  alignItems: 'stretch',
});

export const LogoAndMenuContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const ActionButtonsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const MenuButtonsContainer = styled.div({
  marginLeft: token('space.200', '16px'),
  display: 'flex',
  gap: token('space.100'),
  height: '100%',
  alignItems: 'center',
  '&:focus-visible': {
    outline: 'none',
  },
});

export const VerticalRule = styled.span({
  height: '24px',
  width: '1px',
  marginLeft: `${token('space.100')}`,
  backgroundColor: token('color.border'),
});

export const ActionButton = styled(IconButton)({
  marginLeft: token('space.100'),
});
