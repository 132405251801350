import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import MenuSectionsContainer from './MenuSectionsContainer';
import { MenuContent, Overlay, TransitionGroupContainer } from './styles';

import type { Map } from 'immutable';

type SideMenutProps = {
  closeMenu: () => void;
  onClickLogin: () => void;
  onClickLogout: () => void;
  showSmallHeaderMenu: boolean;
  // @ts-expect-error [MC-2850] - TS2314 - Generic type 'Map<K, V>' requires 2 type argument(s).
  userData: Map;
  onClickSwitchAccount: () => void;
};
const SideMenu = ({
  closeMenu,
  onClickLogin,
  onClickLogout,
  showSmallHeaderMenu,
  userData,
  onClickSwitchAccount,
}: SideMenutProps) => {
  const [overlayTop, setOverlayTop] = useState('70px');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    const target = event.srcElement || event.target;
    // this makes sure we cover all browsers
    const scrollTop =
      window.pageYOffset || target.body.scrollTop || target.documentElement.scrollTop;
    const itemTranslate = Math.max(0, 70 - scrollTop);
    setOverlayTop(itemTranslate + 'px');
  };

  return (
    <TransitionGroupContainer>
      <TransitionGroup>
        {showSmallHeaderMenu ? (
          <CSSTransition
            classNames={{
              appear: 'fadeIn',
              appearActive: 'fadeInActive',
              enter: 'fadeIn',
              enterActive: 'fadeInActive',
              exit: 'fadeOut',
              exitActive: 'fadeOutActive',
            }}
            appear={true}
            exit={true}
            timeout={500}
          >
            <Overlay top={overlayTop} />
          </CSSTransition>
        ) : null}
        {showSmallHeaderMenu ? (
          <CSSTransition
            classNames={{
              appear: 'slideLeft',
              appearActive: 'slideLeftActive',
              enter: 'slideLeft',
              enterActive: 'slideLeftActive',
              exit: 'slideRight',
              exitActive: 'slideRightActive',
            }}
            appear={true}
            exit={true}
            timeout={500}
          >
            <MenuContent>
              <MenuSectionsContainer
                closeMenu={closeMenu}
                onClickLogin={onClickLogin}
                onClickLogout={onClickLogout}
                outsideClickIgnoreClass="sideMenuTrigger"
                userData={userData}
                onClickSwitchAccount={onClickSwitchAccount}
              />
            </MenuContent>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    </TransitionGroupContainer>
  );
};

export default SideMenu;
