import Banner from '@atlaskit/banner';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import React from 'react';

import { isBrowserSupported } from '../BrowserSupportUtil';

type Props = {
  browserBlacklist: {
    [key: string]: (version: string) => boolean;
  };
};

const BrowserSupportBanner = (props: Props) => {
  const isOpen = !isBrowserSupported(window.navigator.userAgent, props.browserBlacklist);

  return (
    <div>
      {isOpen ? (
        <Banner
          appearance="warning"
          icon={<WarningIcon label="Warning icon" secondaryColor="inherit" />}
        >
          <strong>
            For a better experience, update to Internet Explorer 11 or later, or use a different
            browser.&nbsp;
          </strong>
          <span>Your browser is incompatible, so things might not work properly.</span>
        </Banner>
      ) : undefined}
    </div>
  );
};

export default BrowserSupportBanner;
