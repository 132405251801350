import { Record } from 'immutable';

import AddonDiscoveryConfig from './AddonDiscoveryConfig';
import CollectionsConfig from './CollectionsConfig';
import CommonConfig from './CommonConfig';
import GeneralLinks from './GeneralLinks';
import SolutionsConfig from './SolutionsConfig';
import VendorDetailsConfig from './VendorDetailsConfig';

export default class AppConfig extends Record({
  commonConfig: new CommonConfig(),
  generalLinks: new GeneralLinks(),
  addonDiscovery: new AddonDiscoveryConfig(),
  collections: new CollectionsConfig(),
  solutions: new SolutionsConfig(),
  vendorDetails: new VendorDetailsConfig(),
}) {}
