import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { BaseHandler, MiddlewareHandler } from './handler';
import { customFirstSequence, customOnlySequence, parallelSequence, primaryFirstSequence, primaryOnlySequence } from './sequence';
import { SequenceTypes } from './types';
/**
 * GQLOrchestrator:
 * Intercepts the GQL call and resolves the call based on the given sequence and Handler.
 */
class GQLOrchestrator {
  constructor(config) {
    let middlewares = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    _defineProperty(this, "config", void 0);
    _defineProperty(this, "context", {});
    _defineProperty(this, "middlewares", void 0);
    _defineProperty(this, "DEFAULT_OPERATION", 'default');
    this.config = _objectSpread({
      [this.DEFAULT_OPERATION]: {
        config: {
          sequence: SequenceTypes.PRIMARY_ONLY,
          handler: new BaseHandler()
        }
      }
    }, config);
    this.middlewares = middlewares;
    this.initializeMiddlewares();
  }
  initializeMiddlewares() {
    const uniqueConfigKeys = new Set();
    this.middlewares.forEach(middleware => uniqueConfigKeys.add(middleware.getKey()));

    // validate diff config keys
    if (uniqueConfigKeys.size !== this.middlewares.length) {
      throw new Error('GQL Orchestrator: duplicate config keys');
    } else {
      this.middlewares.forEach(middleware => {
        var _middleware$init;
        return (_middleware$init = middleware.init) === null || _middleware$init === void 0 ? void 0 : _middleware$init.call(middleware, this.context);
      });
    }
  }
  getConfig(key) {
    return this.config[key] || this.config[this.DEFAULT_OPERATION];
  }
  orchestrate(operation, forward) {
    const operationName = operation.operationName;
    const selectedConfig = this.getConfig(operationName);
    selectedConfig.operationName = operation.operationName;
    const orchestrationConfig = typeof selectedConfig.config === 'function' ? selectedConfig.config(operation) : selectedConfig.config;
    let selectedSequence;
    switch (orchestrationConfig.sequence) {
      case SequenceTypes.PRIMARY_FIRST:
        selectedSequence = primaryFirstSequence;
        break;
      case SequenceTypes.CUSTOM_FIRST:
        selectedSequence = customFirstSequence;
        break;
      case SequenceTypes.PRIMARY_ONLY:
        selectedSequence = primaryOnlySequence;
        break;
      case SequenceTypes.CUSTOM_ONLY:
        selectedSequence = customOnlySequence;
        break;
      case SequenceTypes.PARALLEL:
        selectedSequence = parallelSequence;
        break;
      default:
        selectedSequence = primaryOnlySequence;
    }
    let handler = orchestrationConfig.handler;
    if (this.middlewares.length) {
      var _selectedConfig$middl;
      // wraps the given handler with provided middlewares
      handler = new MiddlewareHandler(orchestrationConfig.handler, this.middlewares, this.context, (_selectedConfig$middl = selectedConfig.middlewaresConfig) !== null && _selectedConfig$middl !== void 0 ? _selectedConfig$middl : {});
    }
    return selectedSequence(operation, forward, selectedConfig, handler);
  }
}
export { GQLOrchestrator };