import { MarketplaceConsoleAppDistributionsGroupByKey } from '@atlassian/mpac-types/client-graphql-schema-types';
import { DateUtils } from '@atlassian/mpac-utils';
const getGroupByKeyField = groupedBy => {
  switch (groupedBy) {
    case MarketplaceConsoleAppDistributionsGroupByKey.AppVersion:
      return 'appVersion';
    case MarketplaceConsoleAppDistributionsGroupByKey.ParentMajorVersion:
      return 'parentSoftwareMajorVersions';
    case MarketplaceConsoleAppDistributionsGroupByKey.ParentMinorVersion:
      return 'parentSoftwareMinorVersions';
    case MarketplaceConsoleAppDistributionsGroupByKey.ParentAllVersions:
      return 'parentSoftwareVersion';
    default:
      return null;
  }
};
const getGroupByKeyValueFromResponse = (record, groupedBy) => {
  const groupByKeyField = getGroupByKeyField(groupedBy);
  if (groupByKeyField !== null && groupByKeyField !== void 0 && groupByKeyField.startsWith('parentSoftware') && 'parentSoftware' in record) {
    return "".concat(record.parentSoftware || '', " ").concat(record[groupByKeyField]);
  }
  return groupByKeyField ? record[groupByKeyField] : MarketplaceConsoleAppDistributionsGroupByKey.None;
};
const getDistributionCount = record => {
  /*If the record is an ActiveInstallsRecord by checking for the property 'installCounts'
  if not it must be a DownloadsRecord, then use 'downloadCounts */

  return 'installCounts' in record ? record.installCounts : record.downloadCounts;
};
const makeAppDistributionsCountResponse = (record, groupedBy) => {
  const count = getDistributionCount(record);
  return {
    __typename: 'MarketplaceConsoleAppDistributionCountResponse',
    groupByKey: getGroupByKeyValueFromResponse(record, groupedBy),
    count
  };
};
const makeAppDistributionsResponse = (record, groupedBy) => ({
  __typename: 'MarketplaceConsoleAppDistributionsResponse',
  date: record.date,
  groupedBy,
  distributions: [makeAppDistributionsCountResponse(record, groupedBy)]
});
const groupByDate = (distributionData, groupedBy) => {
  const groupedByDate = {};
  distributionData.records.forEach(record => {
    const groupByKeyValueFromResponse = getGroupByKeyValueFromResponse(record, groupedBy);
    // Consider the record only if required group by key's value is present
    if (groupByKeyValueFromResponse) {
      if (!groupedByDate[record.date]) {
        groupedByDate[record.date] = makeAppDistributionsResponse(record, groupedBy);
      } else {
        // Checks if the group by key already exists in the current date
        const distributionsCountWithGroupByKey = groupedByDate[record.date].distributions.find(entry => entry.groupByKey === groupByKeyValueFromResponse);
        if (distributionsCountWithGroupByKey) {
          distributionsCountWithGroupByKey.count += getDistributionCount(record);
        } else {
          groupedByDate[record.date].distributions.push(makeAppDistributionsCountResponse(record, groupedBy));
        }
      }
    }
  });
  return groupedByDate;
};
const getDataForDate = (distributionsDayData, dayDate) => {
  return distributionsDayData.records.filter(record => record.date === dayDate);
};
const distributionDataShouldBeAdded = differenceInDaysToNow => {
  if (differenceInDaysToNow.match(/day/)) return parseInt(differenceInDaysToNow) >= 1 && parseInt(differenceInDaysToNow) <= 7;
  return true;
};
const addOngoingWeekDistributionData = (weekDistributionDataGroupedByDate, lastMonday, distributionsDayData, daysAgo, groupedBy) => {
  const daysAgoDate = DateUtils.ISOFormatHyphenatedDate(DateUtils.subtractDateByDays(new Date(), daysAgo));
  const differenceInDaysToDaysAgo = DateUtils.differenceInDaysOrMonths(DateUtils.parseDateString(daysAgoDate), DateUtils.parseDateString(lastMonday));

  // Try to get the data for yesterday
  const daysAgoData = getDataForDate(distributionsDayData, daysAgoDate);

  //add it to group by date weekly data
  if (daysAgoData !== null && daysAgoData !== void 0 && daysAgoData.length && distributionDataShouldBeAdded(differenceInDaysToDaysAgo)) {
    const lastDayData = groupByDate({
      records: [...daysAgoData]
    }, groupedBy)[daysAgoDate];
    if (lastDayData) {
      weekDistributionDataGroupedByDate[daysAgoDate] = lastDayData;
      return {
        success: true
      };
    }
  }
  return {
    success: false,
    value: null
  };
};
export const transposeDistributionsData = (distributionsWeeklyData, groupedBy, distributionsDayData) => {
  const weekDistributionDataGroupedByDate = groupByDate(distributionsWeeklyData, groupedBy);
  /* In order to maintain parity between installs count on app listing page &
  active install / downloads report  we are adding an additional datapoint to reflect the ongoing week status
  • We check for n-1 and n-2 dates in order to decide the last datapoint
  • We can remove this logic after UI adopts new design to show daily installs as well */
  const lastMonday = Object.keys(weekDistributionDataGroupedByDate)[Object.keys(weekDistributionDataGroupedByDate).length - 1];
  if (distributionsDayData) {
    // check if n-1 days data is available if not check for n-2 days
    const result = addOngoingWeekDistributionData(weekDistributionDataGroupedByDate, lastMonday, distributionsDayData, 1, groupedBy);
    if (!result.success) {
      addOngoingWeekDistributionData(weekDistributionDataGroupedByDate, lastMonday, distributionsDayData, 2, groupedBy);
    }
  }
  return Object.values(weekDistributionDataGroupedByDate);
};