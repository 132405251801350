export const LOCAL_STORAGE_CHANGE_EVENT_NAME = 'onLocalStorageChange';
export const isGivenStorageSupported = givenStorage => function () {
  let supported = false;
  let calculated = false;
  return function () {
    try {
      if (calculated) return supported;
      if (!isBrowser()) return false;
      const key = 'storageTest';
      givenStorage.setItem(key, key);
      const result = givenStorage.getItem(key) === key;
      givenStorage.removeItem(key);
      supported = result && !!givenStorage;
      calculated = true;
      return supported;
    } catch (_unused) {
      supported = false;
      calculated = true;
      return supported;
    }
  };
}();
export const getLocalStorageSupport = () => isGivenStorageSupported(window.localStorage)();
const isBrowser = () => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
};
export function isTypeOfLocalStorageChanged(evt) {
  return !!evt && evt.type === LOCAL_STORAGE_CHANGE_EVENT_NAME;
}
export function tryParse(value) {
  try {
    return JSON.parse(value);
  } catch (_unused2) {
    return value;
  }
}
export function writeStorage(key, value) {
  if (!getLocalStorageSupport()) {
    return;
  }
  window.localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : "".concat(String(value)));
  window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_CHANGE_EVENT_NAME, {
    detail: {
      key,
      value
    }
  }));
}
export function deleteFromStorage(key) {
  if (!getLocalStorageSupport()) {
    return;
  }
  window.localStorage.removeItem(key);
  window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_CHANGE_EVENT_NAME, {
    detail: {
      key,
      value: null
    }
  }));
}
export function getFromStorage(key) {
  if (!getLocalStorageSupport()) {
    return;
  }
  return window.localStorage.getItem(key);
}