import BitbucketBranchesIcon from '@atlaskit/icon/glyph/bitbucket/branches';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { styled } from '@compiled/react';
import React from 'react';

import { exitBranchDeploy, getBranchDeployTooltip } from '../../../../../common/utils/branchDeploy';

const BranchDeploymentIndicatorButton = styled.button({
  height: '32px',
  width: '32px',
  marginRight: token('space.050'),
  background: token('color.background.danger'),
  color: token('color.text.danger'),
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'transparent',
  cursor: 'pointer',
  '&:focus, &:active': {
    outline: 'none',
  },
});

export const BranchDeploymentIndicator = () => {
  return (
    <BranchDeploymentIndicatorButton onClick={exitBranchDeploy}>
      <Tooltip content={getBranchDeployTooltip()}>
        <BitbucketBranchesIcon label="Branch Deploy" primaryColor={token('color.icon.danger')} />
      </Tooltip>
    </BranchDeploymentIndicatorButton>
  );
};
