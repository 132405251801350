import { combineReducers } from 'redux';

import subscribeMailingListReducer from '../components/common/SubscribeMailingListDialog/reducer';
import addonDiscoveryReducer from '../components/Routes/components/AddonDiscoveryRoutes/reducer';
import solutionsReducer from '../components/Routes/components/CategoriesRoutes/reducer';
import collectionsReducer from '../components/Routes/components/CollectionsRoutes/reducer';
import vendorDetailsReducer from '../components/Routes/components/VendorDetailsRoutes/reducer';
import appReducer from './App';
import commonConfigReducer from './CommonConfig';
import generalLinksReducer from './GeneralLinks';
import userReducer from './UserData';

export default combineReducers({
  app: appReducer,
  generalLinks: generalLinksReducer,
  addonDiscovery: addonDiscoveryReducer,
  solutions: solutionsReducer,
  collections: collectionsReducer,
  commonConfig: commonConfigReducer,
  user: userReducer,
  vendorDetails: vendorDetailsReducer,
  subscribeMailingList: subscribeMailingListReducer,
});
