import { AtlassianProductHostingType } from '@atlassian/mpac-types/client-graphql-schema-types';
import { HostingTypes } from './HostingFilters';
export const VersionHistoryHostingFilters = () => {
  return [{
    label: 'Data Center',
    id: HostingTypes.DATA_CENTER,
    value: AtlassianProductHostingType.DataCenter
  }, {
    label: 'Server',
    id: HostingTypes.SERVER,
    value: AtlassianProductHostingType.Server
  }];
};