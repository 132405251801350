import { N300, N400 } from '@atlaskit/theme/colors';
import { h5Css, Link } from '@atlassian/amkt-common';
import styled from '@emotion/styled';
import React from 'react';

import type { NamedLinkType } from '../../../../records/NamedLink';

export const NamedLinksSectionHeading = styled.div`
  ${h5Css}
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const NamedLinkListItem = styled.li`
  margin: 0;
`;

const UnorderedListStyled = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 14px 0;
`;

const LinkStyled = styled(Link)`
  color: ${N400};
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.1;
  &:hover {
    color: ${N300};
  }
`;

type NamedLinksFooterSectionPropTypes = {
  heading: string;
  namedLinks: NamedLinkType[];
  onLinkClick?: (e, namedLink) => void;
  openLinksInNewWindow?: boolean;
};

const NamedLinksFooterSection = ({
  heading,
  namedLinks,
  onLinkClick,
  openLinksInNewWindow = false,
}: NamedLinksFooterSectionPropTypes) => {
  const onLinkClickSafe = (namedLink, e) => onLinkClick && onLinkClick(e, namedLink);

  return (
    <div>
      <NamedLinksSectionHeading>{heading}</NamedLinksSectionHeading>
      <UnorderedListStyled>
        {namedLinks?.map((namedLink, i) => {
          return (
            <NamedLinkListItem key={`named-link-${i}`}>
              <LinkStyled
                href={namedLink.link.href}
                onClick={onLinkClick && onLinkClickSafe.bind(this, namedLink)}
                rel={openLinksInNewWindow ? 'noopener noreferrer' : undefined}
                target={openLinksInNewWindow ? '_blank' : undefined}
              >
                {namedLink.name}
              </LinkStyled>
            </NamedLinkListItem>
          );
        })}
      </UnorderedListStyled>
    </div>
  );
};

export default NamedLinksFooterSection;
