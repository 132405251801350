// @ts-expect-error [MC-2850] - TS2614 - Module '"@atlassiansox/metal-client"' has no exported member 'metalTypes'. Did you mean to use 'import metalTypes from "@atlassiansox/metal-client"' instead?
import MetalClient, { metalTypes } from '@atlassiansox/metal-client';

export function mapAMKTEnvToMetalEnv(amktEnv) {
  if (amktEnv === 'prod') {
    return metalTypes.Env.PROD;
  } else if (amktEnv === 'stg') {
    return metalTypes.Env.STAGING;
  } else if (amktEnv === 'dev') {
    return metalTypes.Env.DEV;
  } else {
    return metalTypes.Env.LOCAL;
  }
}

export function initMetalMonitoring(config) {
  const productInfo = {
    metalId: config.id,
    version: config.version,
    env: config.env,
  };

  new MetalClient({
    productInfo,
  });
}
