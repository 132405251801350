import { UrlUtils } from '@atlassian/mpac-utils';
const {
  updateQueryParam
} = UrlUtils;
export const redirectToNewQuery = _ref => {
  let {
    query,
    locationHref,
    history,
    additionalQueryParams,
    source,
    mountPath
  } = _ref;
  const isSearchPage = locationHref.includes('/search');
  if (isSearchPage) {
    const updatedPathname = updateQueryParam(locationHref, 'query', query);
    history === null || history === void 0 ? void 0 : history.push(updatedPathname);
  } else {
    const queryParams = Object.keys(additionalQueryParams).reduce((acc, key) => {
      return "".concat(acc, "&").concat(key, "=").concat(additionalQueryParams[key]);
    }, '');
    if (source === 'PRODUCT') {
      history === null || history === void 0 ? void 0 : history.push("".concat(mountPath, "/discover/search?query=").concat(query) + queryParams);
    } else {
      history === null || history === void 0 ? void 0 : history.push("/search?query=".concat(query) + queryParams);
    }
  }
};