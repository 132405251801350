export const PricingFilters = () => {
  return [{
    label: 'Free for all teams',
    id: 'paid',
    value: 'false'
  }, {
    label: 'Free up to 10 users',
    id: 'freeStarterTier',
    value: 'enabled'
  }];
};