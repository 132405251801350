import FeatureFlagWebClient from '@atlassiansox/feature-flag-web-client';
import { MarketplaceFeatureFlagsClient } from '../marketplace-feature-flags-client';
import { getFeatureFlagUser } from './featureFlagUser';
function initializeLdClient(productKey, apiKey, environment, user, analyticsClient) {
  const clientOptions = {
    productKey,
    environment
  };
  return new FeatureFlagWebClient(apiKey, analyticsClient, user, clientOptions);
}
export async function getFeatureFlagsClient(_ref) {
  let {
    apiKey,
    environment,
    userId,
    additionalIds,
    analyticsClient,
    productKey,
    customAttributes
  } = _ref;
  const user = getFeatureFlagUser(userId, additionalIds, customAttributes);
  const featureFlagsClient = initializeLdClient(productKey, apiKey, environment, user, analyticsClient);
  await featureFlagsClient.ready();
  return new MarketplaceFeatureFlagsClient(featureFlagsClient, user);
}