import { useStatsig } from '@atlassian/mpac-feature-flags';

import { useCurrentUser } from './CurrentUserContext';
import { transformToCurrentUserContext } from './utils';

import type { UserProps } from '../../components/common/GlobalNav/userTypes';
import type { CurrentUserContextType } from './types';

type UseResolvedCurrentUserProps = {
  userProps: Omit<UserProps, 'onLogout' | 'onSwitchAccount'>;
};

export const useResolvedCurrentUser = ({
  userProps,
}: UseResolvedCurrentUserProps): CurrentUserContextType => {
  const { enableCurrentUserApiViaStoreBff } = useStatsig();
  const currentUserViaBff = useCurrentUser();

  const currentUser = enableCurrentUserApiViaStoreBff()
    ? currentUserViaBff
    : transformToCurrentUserContext(userProps);

  return currentUser;
};
