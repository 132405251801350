import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import CreditcardIcon from '@atlaskit/icon/glyph/creditcard';
import FileIcon from '@atlaskit/icon/glyph/file';
import FlagFilledIcon from '@atlaskit/icon/glyph/flag-filled';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import LabelIcon from '@atlaskit/icon/glyph/label';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { PARTNER_NAV_ITEMS } from '@atlassian/mpac-utils';

/*
 * The names of each navigational item including nested navigational items
 */
export const SideNavNames = {
  [PARTNER_NAV_ITEMS.APPS]: 'Apps',
  [PARTNER_NAV_ITEMS.DETAILS]: 'Partner profile',
  [PARTNER_NAV_ITEMS.TEAM]: 'Team members',
  [PARTNER_NAV_ITEMS.PAYMENT_INFO]: 'Billing details',
  [PARTNER_NAV_ITEMS.PROMOTIONS]: 'Promotions',
  [PARTNER_NAV_ITEMS.SECURITY]: 'Report incident',
  [PARTNER_NAV_ITEMS.REPORTS]: 'Reports',
  [PARTNER_NAV_ITEMS.ADMIN]: 'Admin',
  [PARTNER_NAV_ITEMS.AUDIT_LOGS]: 'Audit logs',
  // Reports
  [PARTNER_NAV_ITEMS.SALE_REPORTS]: 'Sales',
  [PARTNER_NAV_ITEMS.EVALUATIONS_REPORTS]: 'Evaluations',
  [PARTNER_NAV_ITEMS.FEEDBACK_REPORTS]: 'Feedback',
  [PARTNER_NAV_ITEMS.CLOUD_RENEWALS_REPORTS]: 'Cloud renewals',
  [PARTNER_NAV_ITEMS.CLOUD_CONVERSIONS_REPORTS]: 'Cloud conversions',
  [PARTNER_NAV_ITEMS.CLOUD_CHURN_REPORTS]: 'Cloud churn',
  [PARTNER_NAV_ITEMS.TRANSACTIONS_REPORTS]: 'Transactions',
  [PARTNER_NAV_ITEMS.LICENSES_REPORTS]: 'Licenses',
  [PARTNER_NAV_ITEMS.FEEDBACK_DETAILS_REPORTS]: 'Feedback details'
};

/*
 * The icon mapping of each navigational item
 */

export const SideNavIconsMapping = {
  [PARTNER_NAV_ITEMS.APPS]: AppSwitcherIcon,
  [PARTNER_NAV_ITEMS.DETAILS]: OfficeBuildingIcon,
  [PARTNER_NAV_ITEMS.TEAM]: PeopleGroupIcon,
  [PARTNER_NAV_ITEMS.PAYMENT_INFO]: CreditcardIcon,
  [PARTNER_NAV_ITEMS.PROMOTIONS]: LabelIcon,
  [PARTNER_NAV_ITEMS.SECURITY]: FlagFilledIcon,
  [PARTNER_NAV_ITEMS.REPORTS]: GraphLineIcon,
  [PARTNER_NAV_ITEMS.ADMIN]: WatchIcon,
  [PARTNER_NAV_ITEMS.AUDIT_LOGS]: FileIcon
};
/*
 * The order of Manage Partner side navigation is maintained here in this array.
 */
export const managePartnerSideNav = [{
  title: '',
  navItems: [PARTNER_NAV_ITEMS.APPS, PARTNER_NAV_ITEMS.PROMOTIONS, PARTNER_NAV_ITEMS.REPORTS]
}, {
  title: 'space management',
  navItems: [PARTNER_NAV_ITEMS.TEAM, PARTNER_NAV_ITEMS.DETAILS, PARTNER_NAV_ITEMS.PAYMENT_INFO]
}, {
  title: 'security',
  navItems: [PARTNER_NAV_ITEMS.SECURITY, PARTNER_NAV_ITEMS.AUDIT_LOGS, PARTNER_NAV_ITEMS.ADMIN]
}];

/*
 * This object contains the list of all the nav items which has nested navigation (for ex: Reports).
 * The order of each nested navigation is also maintained here.
 */
export const nestedNav = {
  [PARTNER_NAV_ITEMS.REPORTS]: [{
    title: 'Trends',
    navItems: [PARTNER_NAV_ITEMS.SALE_REPORTS, PARTNER_NAV_ITEMS.EVALUATIONS_REPORTS, PARTNER_NAV_ITEMS.FEEDBACK_REPORTS, PARTNER_NAV_ITEMS.CLOUD_CONVERSIONS_REPORTS, PARTNER_NAV_ITEMS.CLOUD_RENEWALS_REPORTS, PARTNER_NAV_ITEMS.CLOUD_CHURN_REPORTS]
  }, {
    title: 'Explore',
    navItems: [PARTNER_NAV_ITEMS.TRANSACTIONS_REPORTS, PARTNER_NAV_ITEMS.LICENSES_REPORTS, PARTNER_NAV_ITEMS.FEEDBACK_DETAILS_REPORTS]
  }]
};