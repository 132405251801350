import createCollectionRouteHandler from './CollectionsRouteHandler';

export default ({ collectionsConfig }) =>
  collectionsConfig.links.collections.map((collection) => ({
    exact: true,
    name: 'collectionsScreen',
    path: collection.link.link.href,
    // @ts-expect-error [MC-2850] - TS2554 - Expected 0 arguments, but got 1.
    component: createCollectionRouteHandler({ collectionKey: collection.key }),
  }));
