import { AlgoliaEnvironment } from '@atlassian/mpac-types';
const searchIndicesV1 = {
  [AlgoliaEnvironment.DEV]: {
    indexName: 'marketplace_plugins_dev'
  },
  [AlgoliaEnvironment.STG]: {
    indexName: 'marketplace_plugins_stg'
  },
  [AlgoliaEnvironment.PROD]: {
    indexName: 'marketplace_plugins_prod'
  }
};
const searchIndicesV2 = {
  [AlgoliaEnvironment.DEV]: {
    indexName: 'marketplace_plugins_dev_v2'
  },
  [AlgoliaEnvironment.STG]: {
    indexName: 'marketplace_plugins_stg_v2'
  },
  [AlgoliaEnvironment.PROD]: {
    indexName: 'marketplace_plugins_prod_v2'
  }
};
export { searchIndicesV1, searchIndicesV2 };