import { getOperationName } from '@apollo/client/utilities';
function copyToNewQueryOperation(oldOperation, newGQLDocument, mapVariables) {
  var _newGQLDocument$loc;
  const newGQLOperationName = getOperationName(newGQLDocument);
  if (!newGQLOperationName) throw new Error("OperationName not defined for given new query: ".concat((_newGQLDocument$loc = newGQLDocument.loc) === null || _newGQLDocument$loc === void 0 ? void 0 : _newGQLDocument$loc.source));
  return Object.assign(Object.create(oldOperation), {
    query: oldOperation.getContext().getClient().documentTransform.transformDocument(newGQLDocument),
    operationName: newGQLOperationName,
    variables: mapVariables ? mapVariables(oldOperation.variables) : oldOperation.variables
  });
}
export { copyToNewQueryOperation };