import {
  LinkClickedEvent,
  NavBarItemClickEvent,
  useAnalyticsEvents,
} from '@atlassian/mpac-analytics';

import {
  ANALYTICS_EVENT_CONTAINER_NAV_BAR,
  ANALYTICS_EVENT_CONTAINER_PARTNER_QUICK_LINK,
  ANALYTICS_SUBJECT_ID_NAVBAR,
  ANALYTICS_SUBJECT_ID_NAVIGATION_ITEM_EXPAND,
} from '../../../../analytics/Events';

import type { ExpandableMenuType } from '../ExpandableMenu';

export const useGlobalNavAnalytics = () => {
  const fireEvent = useAnalyticsEvents();

  const fireNavBarItemClickEvent = (menuType: ExpandableMenuType) => {
    fireEvent({
      eventType: NavBarItemClickEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NAV_BAR,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NAVBAR,
      attributes: {
        label: menuType,
      },
    });
  };

  const fireLogoClickEvent = () => {
    fireEvent({
      eventType: NavBarItemClickEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NAV_BAR,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NAVBAR,
      attributes: {
        label: 'Atlassian marketplace home',
      },
    });
  };

  const fireHelpDropdownClickEvent = () => {
    fireEvent({
      eventType: NavBarItemClickEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NAV_BAR,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NAVBAR,
      attributes: {
        label: 'Help',
      },
    });
  };

  const fireFeedbackCollectorClickEvent = () => {
    fireEvent({
      eventType: NavBarItemClickEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NAV_BAR,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NAVBAR,
      attributes: {
        label: 'Give Feedback',
      },
    });
  };

  const fireUserProfileClickEvent = () => {
    fireEvent({
      eventType: NavBarItemClickEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NAV_BAR,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NAVBAR,
      attributes: {
        label: 'Profile',
      },
    });
  };

  const fireNavBarLinkClickEvent = (label, slug) => {
    fireEvent({
      eventType: LinkClickedEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_NAV_BAR,
      actionSubjectId: ANALYTICS_SUBJECT_ID_NAVIGATION_ITEM_EXPAND,
      attributes: {
        label,
        slug,
      },
    });
  };

  const firePartnerQuickLinksClickEvent = (partnerId, partnerName, linkActionSubjectId) => {
    fireEvent({
      eventType: LinkClickedEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_PARTNER_QUICK_LINK,
      actionSubjectId: linkActionSubjectId,
      attributes: {
        partnerId,
        partnerName,
      },
    });
  };

  return {
    fireNavBarItemClickEvent,
    fireLogoClickEvent,
    fireHelpDropdownClickEvent,
    fireFeedbackCollectorClickEvent,
    fireUserProfileClickEvent,
    fireNavBarLinkClickEvent,
    firePartnerQuickLinksClickEvent,
  };
};
