import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/*
 If enabled logs all events to the browser console.
 Helpful when debugging events
 */

import { Events } from '../events';
const createListener = _ref => {
  let {
    enabled
  } = _ref;
  return (event, getContext) => {
    if (!enabled) return;
    const logEvent = title => {
      console.groupCollapsed("%c Fired telemetry event: ".concat(title), 'color: teal');
      Object.entries(_objectSpread(_objectSpread({}, event), getContext())).sort((entryA, entryB) => entryA[0].localeCompare(entryB[0])).forEach(entry => console.log(entry[0], ':', entry[1]));
      console.groupEnd();
    };
    switch (event.type) {
      case Events.LABEL_PAGE_VIEW_TASK:
      case Events.LABEL_GENERIC_TASK:
        logEvent([event.task, event.taskId].filter(t => !!t).join(','));
        break;
      default:
        logEvent(event.type);
    }
  };
};
export default createListener;