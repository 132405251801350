/* We use parseAlgoliaHitHighlight() from @algolia/autocomplete-preset-algolia
  There's a limitation of this function, that it only parses the highlight for following
  highlightPreTag and highlightPostTag. Hence we are passing same as arguments when fetching search results.
*/
export const HIGHLIGHT_PRE_TAG = '__aa-highlight__';
export const HIGHLIGHT_POST_TAG = '__/aa-highlight__';
export const SEARCH_INDEX_NAME = 'marketplace_plugins_prod_v2_query_suggestions';

// source: https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/state
export const AutoCompleteStatusValues = {
  IDLE: 'idle',
  LOADING: 'loading',
  STALLED: 'stalled',
  ERROR: 'error'
};
export const desktopPlaceholder = 'Type your search query here';
export const mobilePlaceholder = 'Search for apps';
export const embeddedPlaceholder = 'Search for apps within Marketplace';
export const inProductPlaceholder = 'Search for apps';
export const BODY_STATES = {
  LOADING: 'loading',
  SEARCH_RESULTS: 'searchResults',
  RECENT_SEARCHES: 'recentSearches',
  EMPTY_STATE: 'emptyState',
  API_FAILURE: 'apiFailure',
  NO_SUGGESTIONS: 'noSuggestions',
  NO_BODY: 'noBody'
};
export const ANALYTICS_ACTION_SUBJECT_ID = {
  FILTER_OPTION_SELECTED: 'filterOptionSelected',
  CLEAR_FILTER_CLICKED: 'clearFilterClicked',
  CLEAR_ALL_FILTERS_CLICKED: 'clearAllFiltersClicked',
  SEARCH_TILES_RENDERED: 'searchTilesRendered',
  SEARCH_TILE_CLICKED: 'searchTileClicked',
  LOAD_MORE_CLICKED: 'loadMoreClicked',
  APPLY_FILTERS_CLICKED: 'applyFiltersClicked',
  YOUR_SEARCH_QUERY_CLICKED: 'yourSearchQueryClicked',
  FILTER_OPTIONS_ERROR_RENDERED: 'filterOptionsErrorRendered',
  SEARCH_EMBEDDED_VIEW: 'searchEmbeddedViewed',
  SEARCH_EMBEDDED_ITEM_SELECTED: 'searchEmbeddedItemSelected',
  SEARCH_EMBEDDED_ENTER_KEY_PRESSED: 'searchEmbeddedEnterKeyPressed',
  SEARCH_OVERLAY_VIEW: 'searchOverlayViewed',
  SEARCH_OVERLAY_ITEM_SELECTED: 'searchOverlayItemSelected',
  SEARCH_OVERLAY_ENTER_KEY_PRESSED: 'searchOverlayEnterKeyPressed'
};
export const ANALYTICS = {
  GLOBAL: 'global',
  INITIAL: 'initial',
  LOAD_MORE: 'load more'
};