import { getPartnerPermissions } from '@atlassian/mpac-api-client';
import { useTwoStepVerifiedAsync } from '@atlassian/mpac-ui';
import { log } from '@atlassian/mpac-utils';
import { useCallback } from 'react';

import type { PartnerPermissions } from '@atlassian/mpac-api-client';
import type { FetchError } from '@atlassian/mpac-fetch-wrapper';

export const usePartnerPermissions = (
  mpacBaseUrl: string,
  partnerId: number | string
): {
  permissionsPending: boolean;
  permissions: PartnerPermissions | undefined;
  permissionsError: FetchError | undefined;
} => {
  const promiseFn = useCallback((_) => getPartnerPermissions(mpacBaseUrl, partnerId), []);

  const { data, error, isPending } = useTwoStepVerifiedAsync({ promiseFn });

  if (error) {
    log.error('ManagePartners', `Failed to retrieve partner permissions`, {
      error,
    });
  }

  return {
    permissionsPending: isPending,
    permissions: data,
    permissionsError: error,
  };
};
