/* code based on detect-browser npm module */

// browserRules is of form [["browserName", "userAgentRegex"]]
const browserRules = [
  ['edge', /Edge\/([\d._]+)/],
  ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([\d.]+)(:?\s|$)/],
  ['firefox', /Firefox\/([\d.]+)(?:\s|$)/],
  ['opera', /Opera\/([\d.]+)(?:\s|$)/],
  ['opera', /OPR\/([\d.]+)(:?\s|$)$/],
  ['ie', /Trident\/7\.0.*rv:([\d.]+)\).*Gecko$/],
  ['ie', /MSIE\s([\d.]+);.*Trident\/[4-7].0/],
  ['ie', /MSIE\s(7\.0)/],
  ['android', /Android\s([\d.]+)/],
  ['ios', /Version\/([\d._]+).*Mobile.*Safari.*/],
  ['safari', /Version\/([\d._]+).*Safari/],
];

export function isBrowserSupported(userAgent, browserBlacklist) {
  const browser = getBrowserNameAndVersion(userAgent);

  // @ts-expect-error [MC-2850] - TS2538 - Type 'RegExp' cannot be used as an index type. | TS2538 - Type 'undefined' cannot be used as an index type.
  const versionBlacklistPredicate = browserBlacklist[browser.name];

  if (versionBlacklistPredicate) {
    return !versionBlacklistPredicate(browser.version);
  }
  return true;
}

export function getBrowserNameAndVersion(userAgent) {
  for (const rule of browserRules) {
    // @ts-expect-error [MC-2850] - TS2339 - Property 'test' does not exist on type 'string | RegExp'.
    if (rule[1].test(userAgent)) {
      // @ts-expect-error [MC-2850] - TS2339 - Property 'exec' does not exist on type 'string | RegExp'.
      const match = rule[1].exec(userAgent);

      if (!match) {
        return {};
      }

      // get major version number, if any.
      const version = match[1].split(/[._]/).slice(0, 1).join('.');

      const versionNumber = Number.parseInt(version, 10);

      return {
        name: rule[0],
        version: versionNumber,
      };
    }
  }

  return {};
}
