import _styled from "@emotion/styled-base";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
export const ContentWrapper = _styled("div", {
  target: "ex486hq0",
  label: "ContentWrapper"
})("width:100%;transition:opacity ", _ref => {
  let {
    visible
  } = _ref;
  return visible ? 'linear 0.2s' : '0s';
}, ";opacity:", _ref2 => {
  let {
    visible
  } = _ref2;
  return visible ? 1 : 0;
}, ";visibility:", _ref3 => {
  let {
    visible
  } = _ref3;
  return visible ? 'visible' : 'hidden';
}, ";" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9TcGlubmVyTWFzay9zdHlsZXMudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRThEIiwiZmlsZSI6Ii4uLy4uL3NyYy9TcGlubmVyTWFzay9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5cbmV4cG9ydCBjb25zdCBDb250ZW50V3JhcHBlciA9IHN0eWxlZC5kaXY8eyB2aXNpYmxlOiBib29sZWFuIH0+YFxuICB3aWR0aDogMTAwJTtcbiAgdHJhbnNpdGlvbjogb3BhY2l0eSAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAnbGluZWFyIDAuMnMnIDogJzBzJyl9O1xuICBvcGFjaXR5OiAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAxIDogMCl9O1xuICB2aXNpYmlsaXR5OiAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAndmlzaWJsZScgOiAnaGlkZGVuJyl9O1xuYDtcblxuZXhwb3J0IGNvbnN0IE1hc2sgPSBzdHlsZWQuZGl2PHsgbWluSGVpZ2h0OiBzdHJpbmcgfCBudW1iZXIgfT5gXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgd2lkdGg6IDEwMCU7XG4gIG1pbi1oZWlnaHQ6ICR7KHByb3BzKSA9PiBwcm9wcy5taW5IZWlnaHR9cHg7XG5gO1xuXG5leHBvcnQgY29uc3QgU3Bpbm5lckNvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIHdpZHRoOiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgaGVpZ2h0OiAxMDAlO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHRvcDogMDtcbmA7XG4iXX0= */"));
export const Mask = _styled("div", {
  target: "ex486hq1",
  label: "Mask"
})("position:relative;width:100%;min-height:", props => props.minHeight, "px;" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9TcGlubmVyTWFzay9zdHlsZXMudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBUzhEIiwiZmlsZSI6Ii4uLy4uL3NyYy9TcGlubmVyTWFzay9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5cbmV4cG9ydCBjb25zdCBDb250ZW50V3JhcHBlciA9IHN0eWxlZC5kaXY8eyB2aXNpYmxlOiBib29sZWFuIH0+YFxuICB3aWR0aDogMTAwJTtcbiAgdHJhbnNpdGlvbjogb3BhY2l0eSAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAnbGluZWFyIDAuMnMnIDogJzBzJyl9O1xuICBvcGFjaXR5OiAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAxIDogMCl9O1xuICB2aXNpYmlsaXR5OiAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAndmlzaWJsZScgOiAnaGlkZGVuJyl9O1xuYDtcblxuZXhwb3J0IGNvbnN0IE1hc2sgPSBzdHlsZWQuZGl2PHsgbWluSGVpZ2h0OiBzdHJpbmcgfCBudW1iZXIgfT5gXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgd2lkdGg6IDEwMCU7XG4gIG1pbi1oZWlnaHQ6ICR7KHByb3BzKSA9PiBwcm9wcy5taW5IZWlnaHR9cHg7XG5gO1xuXG5leHBvcnQgY29uc3QgU3Bpbm5lckNvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIHdpZHRoOiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgaGVpZ2h0OiAxMDAlO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHRvcDogMDtcbmA7XG4iXX0= */"));
export const SpinnerContainer = _styled("div", {
  target: "ex486hq2",
  label: "SpinnerContainer"
})(process.env.NODE_ENV === "production" ? {
  name: "16jw3vr",
  styles: "width:100%;display:flex;justify-content:center;height:100%;align-items:center;position:absolute;top:0;"
} : {
  name: "16jw3vr",
  styles: "width:100%;display:flex;justify-content:center;height:100%;align-items:center;position:absolute;top:0;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9TcGlubmVyTWFzay9zdHlsZXMudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBZTBDIiwiZmlsZSI6Ii4uLy4uL3NyYy9TcGlubmVyTWFzay9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5cbmV4cG9ydCBjb25zdCBDb250ZW50V3JhcHBlciA9IHN0eWxlZC5kaXY8eyB2aXNpYmxlOiBib29sZWFuIH0+YFxuICB3aWR0aDogMTAwJTtcbiAgdHJhbnNpdGlvbjogb3BhY2l0eSAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAnbGluZWFyIDAuMnMnIDogJzBzJyl9O1xuICBvcGFjaXR5OiAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAxIDogMCl9O1xuICB2aXNpYmlsaXR5OiAkeyh7IHZpc2libGUgfSkgPT4gKHZpc2libGUgPyAndmlzaWJsZScgOiAnaGlkZGVuJyl9O1xuYDtcblxuZXhwb3J0IGNvbnN0IE1hc2sgPSBzdHlsZWQuZGl2PHsgbWluSGVpZ2h0OiBzdHJpbmcgfCBudW1iZXIgfT5gXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgd2lkdGg6IDEwMCU7XG4gIG1pbi1oZWlnaHQ6ICR7KHByb3BzKSA9PiBwcm9wcy5taW5IZWlnaHR9cHg7XG5gO1xuXG5leHBvcnQgY29uc3QgU3Bpbm5lckNvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIHdpZHRoOiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgaGVpZ2h0OiAxMDAlO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHRvcDogMDtcbmA7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});