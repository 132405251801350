import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Observable } from '@apollo/client';
/**
 * Details:
 * First `custom` data is fetched and then based on custom data, we can modify the `primary` query using `transformRequest` fn.
 * The result of both primary and custom calls are passed back to the `transformResponse` function for data massaging.
 *
 * Usecase:
 * When our call to endpoint(BFF in our case(`primary`)) is dependant on getting some data from some other service(`custom`).
 *
 * Call sequence:
 * fetchCustom -> transformRequest -> fetchPrimary -> transformResponse
 */
function customFirstSequence(operation, forward, sequenceConfig, handler) {
  return new Observable(observer => {
    handler.fetchCustom(operation, null, sequenceConfig, null).then(customFetchResponse => {
      const newOperation = handler.requestTransformer(operation, sequenceConfig, customFetchResponse);
      handler.fetchPrimary(newOperation, forward).subscribe({
        next(data) {
          const primaryResponse = data.data;
          const queryResponse = handler.responseTransformer(operation, newOperation, sequenceConfig, primaryResponse, customFetchResponse);
          observer.next(_objectSpread(_objectSpread({}, data), {}, {
            data: queryResponse
          }));
          observer.complete();
        },
        error(err) {
          observer.error(err);
        }
      });
    }).catch(err => {
      observer.error(err);
    });
  });
}
export { customFirstSequence };