import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { Breakpoint, breakpointValues } from '../../Breakpoints';

// TODO: We need to think of a better way to implement this hook. If we see the getBreakpoint function and pick any condition,
// width < breakpointValues.sm returns value of breakpointValues.xs, which is not correct.
export const useResponsiveBreakpoint = () => {
  const [rawWidth, setRawWidth] = useState(window.innerWidth);
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint());
  function getBreakpoint() {
    const width = window.innerWidth;
    if (width < breakpointValues.xs) return {
      value: breakpointValues.xxs,
      id: Breakpoint.xxs
    };
    if (width < breakpointValues.sm) return {
      value: breakpointValues.xs,
      id: Breakpoint.xs
    };
    if (width < breakpointValues.md) return {
      value: breakpointValues.sm,
      id: Breakpoint.sm
    };
    if (width < breakpointValues.lg) return {
      value: breakpointValues.md,
      id: Breakpoint.md
    };
    if (width < breakpointValues.xl) return {
      value: breakpointValues.lg,
      id: Breakpoint.lg
    };
    return {
      value: breakpointValues.xl,
      id: Breakpoint.xl
    };
  }
  useEffect(() => {
    const calcInnerWidth = debounce(() => {
      setBreakpoint(getBreakpoint());
      setRawWidth(window.innerWidth);
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);
  return {
    value: breakpoint.value,
    id: breakpoint.id,
    rawWidth
  };
};