import { List, Map } from 'immutable';

export const defaultData = Map({
  data: undefined,
  loading: false,
  dataError: false,
  dataErrorMessages: List(),
});

export const retrieveDataGenerator = (stateKey) => (state) =>
  state.update(stateKey, (vd) => vd.set('loading', true).set('data', undefined));

export const receiveDataGenerator = (stateKey, getData) => (state, action) =>
  state.update(stateKey, (vd) =>
    vd
      .set('dataError', false)
      .set('dataErrorMessages', List())
      .set('data', getData(action))
      .set('loading', false)
  );

export const dataErrorGenerator = (stateKey) => (state, action) =>
  state.update(stateKey, (vd) =>
    vd
      .set('data', undefined)
      .set('dataError', true)
      .set('dataErrorMessages', action.dataErrorMessages)
      .set('loading', false)
  );
