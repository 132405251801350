import { userType } from '@atlassiansox/analytics-web-client';
import { fromJS } from 'immutable';

import * as analytics from '../../common/utils/analytics/analytics';
import getUserDataAjax from '../../common/utils/user-data';
import bootstrapConfig from '../bootstrapConfig';
import currentUserCache from '../utils/currentUserCache';

export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const DATA_ERROR = 'DATA_ERROR';

export const receiveUserData = (data) => ({
  type: RECEIVE_USER_DATA,
  data,
});

export const dataError = (options) => ({
  type: DATA_ERROR,
  dataErrorMessages: options.dataErrorMessages,
});

export const getUserData = () => (dispatch) => {
  const cachedData = currentUserCache.get();
  if (cachedData) {
    dispatch(receiveUserData(fromJS(cachedData)));
  }

  const {
    appConfig: { generalLinks },
    analyticsClient,
  } = bootstrapConfig.get();

  return getUserDataAjax(generalLinks.currentUser.href).then(
    (data) => {
      const loggedIn = !!data._embedded.user;
      if (loggedIn) {
        analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, data._embedded.user?.id || '');
        analytics.markHasLoggedIn();
        currentUserCache.set(data);
      }

      dispatch(receiveUserData(fromJS(data)));
    },
    (error) => {
      let errorData = [];
      if (error.status === 400) {
        const response = JSON.parse(error.responseText);
        errorData = response.errors;
      }
      dispatch(
        dataError({
          dataErrorMessages: fromJS(errorData),
        })
      );
    }
  );
};

export const clearCurrentUserCache = () => {
  currentUserCache.remove();
};
