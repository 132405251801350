import { List, Record } from 'immutable';

import Link from '../../../../../../common/records/Link';

export class VendorArchivedAddonsAddonLinks extends Record({
  alternate: new Link(),
}) {}

export class VendorArchivedAddonsAddonEmbedded extends Record({
  image: undefined,
}) {}

export class VendorArchivedAddonAddonEmbeddedImageLinks extends Record({
  image: new Link(),
}) {}

export class VendorArchivedAddonAddonEmbeddedImage extends Record({
  _links: new VendorArchivedAddonAddonEmbeddedImageLinks(),
}) {}

export class VendorArchivedAddonsAddon extends Record({
  _links: new VendorArchivedAddonsAddonLinks(),
  _embedded: new VendorArchivedAddonsAddonEmbedded(),
  name: '',
  key: '',
  tagLine: undefined,
}) {}

export class VendorArchivedAddonsEmbedded extends Record({
  addons: List(),
}) {}

export default class VendorArchivedAddons extends Record({
  _embedded: new VendorArchivedAddonsEmbedded(),
  count: 0,
}) {}
