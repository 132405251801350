import React from 'react';
import { createRoot } from 'react-dom/client';

import BrowserBlacklist from './BrowserBlacklist';
import BrowserSupportBanner from './components/BrowserSupportBanner';

export default function render(rootId) {
  const container = rootId ? document.getElementById(rootId) : null;
  if (!container) {
    console.error('root id must be provided to render into.');
    return;
  }
  const root = createRoot(container);
  root.render(<BrowserSupportBanner browserBlacklist={BrowserBlacklist} />);
}
