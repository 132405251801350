// A vanilla alternative to $.Deferred (jquery)
export default function Deferred() {
  // @ts-expect-error [MC-2850] - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
  this.promise = new Promise((resolve, reject) => {
    // @ts-expect-error [MC-2850] - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
    this.resolve = resolve;
    // @ts-expect-error [MC-2850] - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
    this.reject = reject;
  });

  // @ts-expect-error [MC-2850] - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation. | TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation. | TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
  this.then = this.promise.then.bind(this.promise);
  // @ts-expect-error [MC-2850] - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation. | TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation. | TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
  this.catch = this.promise.catch.bind(this.promise);
}
