import { useAsync } from 'react-async';
import { useTwoStepAuthTimedOut } from '../TwoStepAuthTimedOut';
export const showModalIfTwoSVSessionExpires = (fetchError, callback) => {
  var _fetchError$error;
  if ((fetchError === null || fetchError === void 0 ? void 0 : fetchError.status) === 401 && !(fetchError !== null && fetchError !== void 0 && (_fetchError$error = fetchError.error) !== null && _fetchError$error !== void 0 && _fetchError$error.bodyUsed)) {
    var _fetchError$error2;
    fetchError === null || fetchError === void 0 ? void 0 : (_fetchError$error2 = fetchError.error) === null || _fetchError$error2 === void 0 ? void 0 : _fetchError$error2.text().then(errorCode => {
      if (errorCode === '2SV_SESSION_EXPIRED') {
        callback(true);
      }
    });
  }
};
function useTwoStepVerifiedAsync(_ref) {
  let {
    promiseFn
  } = _ref;
  const {
    data,
    error,
    isPending,
    isRejected,
    reload,
    cancel
  } = useAsync({
    promiseFn
  });
  const {
    setTwoStepAuthTimedOut
  } = useTwoStepAuthTimedOut();
  const fetchError = error;
  showModalIfTwoSVSessionExpires(fetchError, setTwoStepAuthTimedOut);
  return {
    data,
    error: fetchError,
    isPending,
    isRejected,
    reload,
    cancel
  };
}
export default useTwoStepVerifiedAsync;