import Button from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import { SearchOverlay } from '@atlassian/mpac-search';
import { styled } from '@compiled/react';
import React, { useState } from 'react';

import TextSearch from '../../../../../common/TextSearch';

type GeneralTextSearchProps = {
  onSubmit: (args: { query: string }) => void;
};

// TODO: will be removed after dogfooding of new search experience
// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
const SearchButton = styled(Button)({
  background: '#0052cc !important',
});

const GeneralTextSearch = (props: GeneralTextSearchProps) => {
  const [query, setQuery] = useState('');

  const { enableAetherSearchOverlay } = useStatsig();
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);

  const onChange = (q: string) => {
    if (enableAetherSearchOverlay()) {
      return;
    }

    setQuery(q);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (enableAetherSearchOverlay()) {
      return;
    }

    e.preventDefault();
    props.onSubmit({ query });
  };

  const handleOnClick = () => {
    if (enableAetherSearchOverlay()) {
      setShowSearchOverlay(true);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {enableAetherSearchOverlay() ? (
        <SearchButton
          onClick={handleOnClick}
          iconBefore={<SearchIcon label="search icon" />}
          appearance="primary"
          aria-label="search-button"
        >
          Search
        </SearchButton>
      ) : (
        <TextSearch
          name="general-text-search"
          focusOnMount={false}
          mode="header"
          onChange={onChange}
          query={query}
        />
      )}

      {showSearchOverlay && (
        <SearchOverlay
          onClose={() => setShowSearchOverlay(false)}
          analytics={{
            source: 'navigation-bar',
          }}
        />
      )}
    </form>
  );
};

export default GeneralTextSearch;
