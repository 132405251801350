import { connect } from 'react-redux';

import commonMapStateToProps from 'app/container/commonMapStateToProps';
import headerCommonMapDispatchToProps from 'common/components/Header/container/headerCommonMapDispatchToProps';
import * as analytics from 'common/utils/analytics/analytics';
import * as webAnalytics from 'common/utils/analytics/webAnalytics';
import { openSubscribeDialog as openMailingListSubscribeDialog } from '../../../../common/SubscribeMailingListDialog/action/SubscribeMailingListActions';
import RouteContainer from '../components/RouteContainer';

function mapStateToProps(state) {
  return {
    ...commonMapStateToProps(state),
    addonDiscoveryConfig: state.addonDiscovery.config,
    collectionsConfig: state.collections.config,
    displayGeneralTextSearch: state.app.displayGeneralTextSearch,
    displayPartnerQuickLinks: state.app.displayPartnerQuickLinks,
    displayCollectionsFooter: state.app.displayCollectionsFooter,
    user: state.user,
    solutionsConfig: state.solutions.config,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...headerCommonMapDispatchToProps(dispatch),
    openMailingListSubscribeDialog: () => dispatch(openMailingListSubscribeDialog()),
    onRouteChange: ({ pathname, search, name, referrer, suppressPageView, options }) => {
      if (!suppressPageView) {
        analytics.trackPageView(`${pathname}${search}`);

        // Parse the query parameters from the search string
        const params = new URLSearchParams(search || '');
        const utmSource = params.get('utm_source');

        let payload = {
          poweredBy: 'AMKT_FRONTEND',
          filters: options?.filters,
          utm_source: '',
        };

        if (utmSource) {
          payload.utm_source = utmSource;
        }

        if (options?.additionalAttributes) {
          payload = {
            ...payload,
            ...options.additionalAttributes,
          };
        }

        webAnalytics.sendScreenEvent(referrer, name, payload);
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteContainer);
