import { Record } from 'immutable';

import { RECEIVE_APP_CONFIG } from '../../../../../actions/App';
import CollectionsConfig from '../../../../../records/CollectionsConfig';

const CollectionsState = Record({
  config: new CollectionsConfig(),
});

export default function CollectionsReducer(state = new CollectionsState(), action) {
  switch (action.type) {
    case RECEIVE_APP_CONFIG:
      return state.set('config', action.appConfig.collections);
    default:
      return state;
  }
}
