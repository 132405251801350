import { matchPath } from 'react-router';

import type { AMKTRoute } from '../components/Routes.types';

export const resolveCurrentRoute = (
  allRoutes: AMKTRoute[],
  pathname: string
): AMKTRoute | null | undefined =>
  allRoutes.find((route) => matchPath(pathname, { path: route.path, exact: true, strict: false }));

export const getMatchedRoute = (allRoutes: AMKTRoute[], pathname: string) =>
  matchPath(pathname, {
    path: resolveCurrentRoute(allRoutes, pathname)?.path,
    exact: true,
    strict: false,
  });

export const getRouteByName = (allRoutes: AMKTRoute[], routeName: string) =>
  allRoutes.find(({ name }) => name === routeName);
