import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import React, { useState } from 'react';

import { trackHelpActionClicked } from '../../../../../../common/utils/analytics/analytics';
import IssueCollector from '../../../../../../common/utils/issue-collectors';
import { useGlobalNavAnalytics } from '../../Analytics/useGlobalNavAnalytics';
import { Help } from '../../Buttons';
import { helpMenuLinks } from '../../constants';
import { LinkItem } from '../DropdownItems';

import type { Ref } from 'react';

const GiveFeedbackLabel = 'Give feedback';

type HelpDropdownProps = {
  openFeedbackDialog: false | (() => void);
  onOpen?: () => void;
};

type FeedbackDialogProps = {
  openFeedbackDialog: false | (() => void);
};

export const DropdownItems = ({ openFeedbackDialog }: FeedbackDialogProps) => {
  const openInNewTabProps = {
    showShortcutIcon: true,
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  const onFeedbackCollectorClick = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    if (IssueCollector) {
      IssueCollector.showCollector(e);
    }
  };

  const onOpenFeedbackDialog = (e) => {
    if (openFeedbackDialog) {
      trackHelpActionClicked(GiveFeedbackLabel);
      openFeedbackDialog();
    } else {
      onFeedbackCollectorClick(e);
    }
  };

  return (
    <>
      <DropdownItemGroup title="Help">
        {helpMenuLinks.map(({ text, href }) => (
          <LinkItem
            key={text}
            label={text}
            href={href}
            onClick={() => trackHelpActionClicked(text)}
            {...openInNewTabProps}
          />
        ))}
      </DropdownItemGroup>
      <DropdownItemGroup hasSeparator={true} title="Help us improve">
        <LinkItem label={GiveFeedbackLabel} onClick={onOpenFeedbackDialog} />
      </DropdownItemGroup>
    </>
  );
};

export const HelpDropdown = ({ openFeedbackDialog, onOpen }: HelpDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { fireHelpDropdownClickEvent } = useGlobalNavAnalytics();

  const onOpenChange = ({ isOpen }) => {
    onOpen?.();
    setIsDropdownOpen(isOpen);
    if (isOpen) fireHelpDropdownClickEvent();
  };

  return (
    <DropdownMenu
      appearance="tall"
      placement="bottom-end"
      isOpen={isDropdownOpen}
      onOpenChange={onOpenChange}
      trigger={({ triggerRef, ...props }) => (
        <Help triggerRef={triggerRef as Ref<HTMLButtonElement>} {...props} />
      )}
    >
      <DropdownItems openFeedbackDialog={openFeedbackDialog} />
    </DropdownMenu>
  );
};
