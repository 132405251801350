import { BaseHandler } from './baseHandler';
/**
 * ===================================
 *        Middleware Handler
 * ===================================
 * Wraps the given handler with middleware functionality.
 */
class MiddlewareHandler extends BaseHandler {
  constructor(handlerInstance, middlewares, context, middlewareConfig) {
    super();
    this.handlerInstance = handlerInstance;
    this.middlewares = middlewares;
    this.context = context;
    this.middlewareConfig = middlewareConfig;
    this.context = context;
    this.handlerInstance = handlerInstance;
    this.middlewares = middlewares;
    this.middlewareConfig = middlewareConfig;
  }
  applyMiddlewares(
  // Deliberately keeping it wide, with narrow types, TS gets confused and is unable
  // to map a relationship b/w a handler fn and a middleware fn.
  // eslint-disable-next-line @typescript-eslint/ban-types
  getMiddlewareFn, originalMethod) {
    var _this = this;
    return this.middlewares.reduceRight((result, nextMiddleware) => function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return getMiddlewareFn(nextMiddleware).apply(nextMiddleware, [args, result, _this.context, _this.middlewareConfig[nextMiddleware.getKey()] || {}]);
    }, originalMethod.bind(this.handlerInstance));
  }
  requestTransformer(operation, sequenceConfigValue, customResponse) {
    const withMiddleware = this.applyMiddlewares(middleware => middleware.onRequestTransformer, this.handlerInstance.requestTransformer);
    return withMiddleware(operation, sequenceConfigValue, customResponse);
  }
  fetchPrimary(operation, forward) {
    const withMiddleware = this.applyMiddlewares(middleware => middleware.onFetchPrimary, this.handlerInstance.fetchPrimary);
    return withMiddleware(operation, forward);
  }
  fetchCustom(operation, transformedOperation, sequenceConfigValue, primaryResponse) {
    const withMiddleware = this.applyMiddlewares(middleware => middleware.onFetchCustom, this.handlerInstance.fetchCustom);
    return withMiddleware(operation, transformedOperation, sequenceConfigValue, primaryResponse);
  }
  responseTransformer(operation, transformedOperation, sequenceConfigValue, primaryResponse, customResponse) {
    const withMiddleware = this.applyMiddlewares(middleware => middleware.onResponseTransformer, this.handlerInstance.responseTransformer);
    return withMiddleware(operation, transformedOperation, sequenceConfigValue, primaryResponse, customResponse);
  }
}
export { MiddlewareHandler };