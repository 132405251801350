export const transposeCategory = category => {
  return {
    __typename: 'MarketplaceStoreCategoryResponse',
    id: category === null || category === void 0 ? void 0 : category.id,
    slug: category === null || category === void 0 ? void 0 : category.slug,
    name: category === null || category === void 0 ? void 0 : category.name,
    heroSection: {
      __typename: 'MarketplaceStoreCategoryHeroSection',
      description: category === null || category === void 0 ? void 0 : category.heroSection.description,
      title: category === null || category === void 0 ? void 0 : category.heroSection.title,
      image: {
        __typename: 'MarketplaceStoreCategoryHeroSectionImage',
        altText: category === null || category === void 0 ? void 0 : category.heroSection.image.altText,
        url: category === null || category === void 0 ? void 0 : category.heroSection.image.url
      }
    }
  };
};