import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { styled } from '@compiled/react';
import React from 'react';

import { BaseButton, Text } from './Button.styles';

const Button = styled(BaseButton)({
  padding: `${token('space.0')} ${token('space.300')}`,
  gap: token('space.100'),
  height: '56px',
  width: '100%',
  justifyContent: 'start',

  '& path': {
    fill: token('color.icon.subtle'),
  },
  '&:hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },
  [media.above.xs]: {
    padding: `${token('space.0')} ${token('space.400')}`,
  },
  [media.above.sm]: {
    padding: `${token('space.0')} ${token('space.500')}`,
  },
});

const Separator = styled.div({
  borderBottom: `1px solid ${token('color.border')}`,
});

export const MenuButtonMobile = ({ onClick, label }: { onClick: () => void; label: string }) => {
  return (
    <div>
      <Button onClick={onClick}>
        <Text>{label}</Text>
        <ChevronRightIcon label="" size="large" />
      </Button>
      <Separator />
    </div>
  );
};
