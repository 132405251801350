import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import { BodySmall, H6, LinkWithIcon } from '@atlassian/mpac-primitives';
import { useHistory } from '@atlassian/mpac-ui';
import { styled } from '@compiled/react';
import React from 'react';

import { EyebrowText } from '../ExpandedMenus.styles';
import { HelpfulLinks } from '../HelpfulLinks';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: token('space.300', '24px'),
  height: '100%',
  [media.above.md]: {
    marginTop: 0,
    marginBottom: 0,
  },
  // Extra margin to make bottom content easily reachable on mobile/tablet while scrolling
  marginBottom: '240px',
});

const WhatsNewSection = styled.div({
  marginBottom: token('space.400', '32px'),

  '& h3': {
    marginTop: token('space.200', '16px'),
  },
});

const Description = styled(BodySmall)({
  margin: `${token('space.050', '4px')} 0 ${token('space.100', '8px')} 0`,
});

export const WhatsNew = ({ closeMenu }: { closeMenu: () => void }) => {
  const { showCompassApps } = useStatsig();
  const history = useHistory();
  const navigationLinkClickHandler = (event) => {
    event.preventDefault();
    history?.push('/product/compass');
    closeMenu();
  };
  return (
    <Container>
      <WhatsNewSection>
        <EyebrowText role="heading" aria-level={2}>
          What's new
        </EyebrowText>
        {showCompassApps() && (
          <>
            <H6 as="h3">Compass apps</H6>
            <Description>
              Upgrade to the ultimate developer experience with apps for Compass that enable unified
              implementation in days.
            </Description>
            <LinkWithIcon
              size="small"
              type="internal"
              href="/product/compass"
              onClick={(event) => navigationLinkClickHandler(event)}
              aria-label="Explore new compass apps"
            >
              Explore now
            </LinkWithIcon>
          </>
        )}
        <H6 as="h3">Atlassian Team ’24</H6>
        <Description>
          Be the first to see our latest product advancements in action, network with industry
          greats, and learn how to level up your work.
        </Description>
        <LinkWithIcon
          size="small"
          type="external"
          href="https://events.atlassian.com/team"
          onClick={closeMenu}
          aria-label="Watch highlights Atlassian team '24"
        >
          Watch highlights
        </LinkWithIcon>
      </WhatsNewSection>
      <HelpfulLinks closeMenu={closeMenu} />
    </Container>
  );
};
