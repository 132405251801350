import { MarketplacePartnerTierType, MarketplaceStorePartnerEnrollmentProgramValue } from '@atlassian/mpac-types/graphql-gateway-types';
export const mapPartnerTierToEnrollmentValue = tier => {
  switch (tier) {
    case MarketplacePartnerTierType.Gold:
      return MarketplaceStorePartnerEnrollmentProgramValue.Gold;
    case MarketplacePartnerTierType.Platinum:
      return MarketplaceStorePartnerEnrollmentProgramValue.Platinum;
    case MarketplacePartnerTierType.Silver:
      return MarketplaceStorePartnerEnrollmentProgramValue.Silver;
    default:
      throw new Error("Unsupported partner tier: ".concat(tier));
  }
};