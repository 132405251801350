import { token } from '@atlaskit/tokens';
import React from 'react';

export const YoutubeLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    data-testid="youtube-logo-svg"
    aria-hidden={true}
  >
    <rect width="32" height="32" fill={token('color.background.neutral.bold')} rx="16" />
    <path
      fill={token('color.text.inverse')}
      d="M22.707 12.64c-.16-.646-.635-1.154-1.238-1.327C20.38 11 16 11 16 11s-4.378 0-5.47.313c-.602.173-1.077.681-1.237 1.326C9 13.81 9 16.25 9 16.25s0 2.44.293 3.61c.16.646.635 1.154 1.238 1.327C11.623 21.5 16 21.5 16 21.5s4.378 0 5.47-.313c.602-.173 1.077-.681 1.237-1.326C23 18.69 23 16.25 23 16.25s0-2.44-.293-3.61Zm-8.139 5.826v-4.432l3.66 2.216-3.66 2.216Z"
    />
  </svg>
);
