import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isPlainObject } from '@atlassian/mpac-utils/object';
import { filtersMap } from './FiltersMap';
/**
 * Transforms filter request params for search service API call
 */
export const transformFiltersForNlpSearch = function transformFiltersForNlpSearch(filters) {
  var _filters$product, _filters$hosting;
  let sortKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const searchFilters = {};
  const tagsSearchTerm = [];
  const sortOverrides = getFilterOverridesForSort(sortKey);

  // Transform each filter option
  Object.entries(filters).forEach(_ref => {
    let [filterId, filterOptions] = _ref;
    const filterOptionsValue = Array.isArray(filterOptions) ? filterOptions : [filterOptions];
    filterOptionsValue.forEach(filterOption => {
      if (filtersMap[filterId] === 'tags.searchTerm') {
        tagsSearchTerm.push(filterOption.value);
      } else if (isPlainObject(filtersMap[filterId])) {
        searchFilters[filtersMap[filterId][filterOption.id]] = filterOption.value;
      } else {
        searchFilters[filtersMap[filterId]] = filterOption.value;
      }
    });
  });
  return _objectSpread({
    parentApplicationKey: filters === null || filters === void 0 ? void 0 : (_filters$product = filters.product) === null || _filters$product === void 0 ? void 0 : _filters$product[0].value,
    cloudFortifiedStatus: searchFilters['programs.cloudFortified.status'],
    bugBountyParticipantStatus: searchFilters['programs.bugBountyParticipant.cloud.status'] === 'bugBountyApproved' ? 'approved' : undefined,
    partnerSupported: searchFilters['version.supported'],
    paid: searchFilters['version.paid'],
    stable: searchFilters['version.stable'],
    freeStarterTierStatus: searchFilters.freeStarterTierStatus,
    hosting: filters === null || filters === void 0 ? void 0 : (_filters$hosting = filters.hosting) === null || _filters$hosting === void 0 ? void 0 : _filters$hosting[0].value,
    tagsSearchTerm: tagsSearchTerm.length > 0 ? tagsSearchTerm : undefined
  }, sortOverrides);
};
function getFilterOverridesForSort(sortKey) {
  if (sortKey === 'topSelling') {
    return {
      isAtlassian: 'false',
      marketplaceType: 'Paid via Atlassian'
    };
  }
  if (sortKey === 'trending') {
    return {
      isAtlassian: 'false'
    };
  }
  return {};
}

/**
 * Transforms sort request params for search service API call
 */
export const transformSortForNlpSearch = function transformSortForNlpSearch() {
  var _sortMappings$sortKey;
  let sortKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const defaultSort = {
    field: 'relevance'
  };
  const sortMappings = {
    newest: {
      field: 'releaseDate'
    },
    topSelling: {
      field: 'recentGrossTotal'
    },
    trending: {
      field: 'deltaInstalls'
    },
    topRated: {
      field: 'ratingsScore'
    }
  };
  return ((_sortMappings$sortKey = sortMappings[sortKey]) === null || _sortMappings$sortKey === void 0 ? void 0 : _sortMappings$sortKey.field) || defaultSort.field;
};