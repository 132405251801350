import { UrlUtils } from '@atlassian/mpac-utils';
import Cookies from 'js-cookie';

export const BRANCH_DEPLOY_COOKIE_PARAM_NAME = 'useFrontendBranch';

export const isBranchDeployment = () =>
  window.STAGING_BRANCH_DEPLOY_INFO?.branchIndexHtmlServed === true;

export const getCurrentDeployedBranch = (): string | undefined =>
  Cookies.get(BRANCH_DEPLOY_COOKIE_PARAM_NAME);

export const getBranchDeployTooltip = (): string =>
  `Click to exit branch deploy: ${getCurrentDeployedBranch() || 'unknown'}`;

export const exitBranchDeploy = () => {
  Cookies.remove(BRANCH_DEPLOY_COOKIE_PARAM_NAME);
  window.location.href = UrlUtils.unsetQueryParam(
    window.location.href,
    BRANCH_DEPLOY_COOKIE_PARAM_NAME
  );
};
