import { managePartnerBaseLink } from '@atlassian/mpac-utils';

import { getLinksFromUserResponse } from '../../components/common/GlobalNav/getLinksFromUserResponse';

import type { UserProps, UserResponse } from '../../components/common/GlobalNav/userTypes';
import type { AnonymousUser, CurrentUserContextType, LoggedInUser } from './types';

// TODO: remove this file and usage of the util functions during FF cleanup

const transformToLoggedInUser = (
  userProps: Omit<UserProps, 'onLogout' | 'onSwitchAccount'>
): LoggedInUser | undefined => {
  if (!userProps.user) {
    return;
  }
  const { id, name, email, _links } = userProps.user;
  const { addons, createAddon, admin, switchAccount, atlassianAccount, logout } =
    userProps.userLinks;

  const { lastVisitedPartnerId, lastVisitedPartnerName } = userProps.lastVisited || {};

  return {
    __typename: 'MarketplaceStoreLoggedInUser',
    id,
    name,
    email,
    picture: _links.picture.href,
    loggedInUserLinks: {
      addons: addons as string,
      createAddon: createAddon as string,
      admin,
      switchAccount: switchAccount as string,
      manageAccount: atlassianAccount as string,
      logout: logout as string,
      profile: _links.alternate.href,
      manageDeveloperSpace: getManageDeveloperSpaceLink(lastVisitedPartnerId?.toString()),
    },
    lastVisitedDeveloperSpace:
      lastVisitedPartnerId && lastVisitedPartnerName
        ? {
            id: lastVisitedPartnerId.toString(),
            name: lastVisitedPartnerName,
            vendorId: lastVisitedPartnerId.toString(),
          }
        : null,
  };
};

const transformToAnonymousUser = (
  userProps: Omit<UserProps, 'onLogout' | 'onSwitchAccount'>
): AnonymousUser | undefined => {
  if (userProps.user) {
    return;
  }
  return {
    __typename: 'MarketplaceStoreAnonymousUser',
    anonymousUserLinks: {
      login: userProps.userLinks.login as string,
    },
  };
};

export const transformToCurrentUserContext = (
  userProps: Omit<UserProps, 'onLogout' | 'onSwitchAccount'>
): CurrentUserContextType => ({
  loggedInUser: transformToLoggedInUser(userProps),
  anonymousUser: transformToAnonymousUser(userProps),
  loading: userProps.isUserLoading,
});

export const transformToUserProps = (userData): Omit<UserProps, 'onLogout' | 'onSwitchAccount'> => {
  const userResponse = userData.toJS() as UserResponse;
  return {
    isUserLoading: Boolean(userResponse.loading),
    userLinks: getLinksFromUserResponse(userResponse.data),
    user: userResponse.data?._embedded?.user,
    lastVisited: userResponse.data?._embedded?.lastVisited,
  };
};

export const getManageDeveloperSpaceLink = (partnerId?: string) =>
  partnerId
    ? managePartnerBaseLink().expand({
        id: parseInt(partnerId),
      })
    : '/manage/vendor/create';
