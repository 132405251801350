import { List, Map, Set } from 'immutable';

import Link from '../../../../../../../common/records/Link';
import NamedLink from '../../../../../../../common/records/NamedLink';
import RotatableEntity from '../../../../../../../common/records/RotatableEntity';
import CategoryContent, {
  AtlassianProductDiversion,
  HeroImage,
  MoreDetails,
  PageMetaDetails,
  UseCases,
} from '../../records/CategoryContent';

export default ({ data }) => {
  const transformViewMoreFilters = (viewMoreFilters) =>
    Map(viewMoreFilters).update(
      'moreFilters',
      // @ts-expect-error [MC-2850] - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Iterable<unknown>'.
      (filterValue) => filterValue && Set(filterValue)
    );

  return new CategoryContent({
    name: data.name,
    heroImage: new HeroImage({
      svg: data.heroImage.fields.svg.fields.file.url,
      png: data.heroImage.fields.png.fields.file.url,
    }),
    heading: data.heading,
    tagline: data.tagline,
    primaryDescription: data.primaryDescription,
    appsSectionTitle: data.appsSectionTitle,
    // @ts-expect-error [MC-2850] - TS2322 - Type '(Record<{ introduction: string; productLinks: List<any>; }> & Readonly<{ introduction: string; productLinks: List<any>; }>) | undefined' is not assignable to type 'undefined'.
    atlassianProductDiversion: data.atlassianProductDiversion
      ? new AtlassianProductDiversion({
          introduction: data.atlassianProductDiversion.fields.introduction,
          productLinks: List(data.atlassianProductDiversion.fields.productLinks).map(
            (pl) =>
              new NamedLink({
                // @ts-expect-error [MC-2850] - TS2571 - Object is of type 'unknown'.
                name: pl.fields.linkText,
                link: new Link({
                  // @ts-expect-error [MC-2850] - TS2571 - Object is of type 'unknown'.
                  href: pl.fields.url,
                }),
              })
          ),
        })
      : undefined,
    moreDetails: new MoreDetails({
      heading: data.moreDetails.fields.heading,
      content: data.moreDetails.fields.content.split('\n'),
      icon: data.moreDetails.fields.icon.fields.file.url,
    }),
    useCases: new UseCases({
      heading: data.useCases.fields.name,
      description: data.useCases.fields.description,
      items: List(data.useCases.fields.useCases),
    }),
    callToActionTitle: data.callToActionTitle,
    curatedApps: new RotatableEntity({
      pinned: List(data.curatedApps.fields.pinned),
      unpinned: List(data.curatedApps.fields.unpinned),
    }),
    viewMoreFilters: transformViewMoreFilters(data.viewMoreFilters),
    preferredHosting: data.preferredHosting,
    viewMoreActionText: data.viewMoreActionText,
    pageMetaDetails: new PageMetaDetails({
      title: data.pageMetaDetails.fields.title,
      description: data.pageMetaDetails.fields.description,
    }),
  });
};
