import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import styled from '@emotion/styled';

export const MenuSectionContainer = styled.div`
  padding: 10px;
`;

export const MenuSectionHeader = styled.div`
  display: flex;
  padding-left: 10px;
  height: 100%;
`;

export const MenuList = styled.ul`
  list-style: none;
  padding-left: 10px;
  margin: 0;
`;

export const MenuListItem = styled.li`
  padding-bottom: 10px;

  a {
    color: ${N0};
    cursor: pointer;
    line-height: 40px;

    &:active {
      color: ${N0};
    }
  }
`;

export const LozengeWrapper = styled.span`
  margin-left: ${token('space.100', '8px')};
`;
