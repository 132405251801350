import { Observable } from '@apollo/client';
/**
 * Details:
 * Only `Custom` data is fetched. `Primary` call is ignored.
 * The result of primary call is passed back to the `transformResponse` function for data massaging.
 *
 * Usecase:
 * Mainly for cases when we want to do `custom` handling for the given GQL call without making a `primary` call.
 *
 * Call sequence:
 * fetchCustom -> transformResponse
 */
function customOnlySequence(operation, _forward, sequenceConfig, handler) {
  return new Observable(observer => {
    handler.fetchCustom(operation, null, sequenceConfig, null).then(customFetchResponse => {
      const queryResponse = handler.responseTransformer(operation, null, sequenceConfig, null, customFetchResponse);
      observer.next({
        data: queryResponse
      });
      observer.complete();
    }).catch(err => {
      observer.error(err);
    });
  });
}
export { customOnlySequence };