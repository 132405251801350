import { Record } from 'immutable';

import Link from './Link';

export type ImageLinkType = {
  key?: string;
  image?: string;
  link: {
    href: string;
  };
};
export default Record<ImageLinkType>({
  key: undefined,
  image: undefined,
  link: new Link(),
});
