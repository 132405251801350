import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import MetalClient, { catalog } from '@atlassiansox/metal-client';
import { Events } from '../events';
import { isIgnorableGenericTaskSLOEvent } from '../utils/errors';
const REQUEST_RESOURCE_TYPES = new Set(['fetch', 'xmlhttprequest', 'beacon', 'other']);

/* disabling the (complexity, max-lines-per-function) rules since splitting this function is unlikely to improve the readability */
const createListener =
// eslint-disable-next-line complexity, max-lines-per-function
metalClient => (event, getContext) => {
  const context = getContext();
  const commonTags = {
    page: context.page,
    isInitial: context.isInitialRender,
    isActiveTab: !context.hasDocumentBeenHidden
  };
  switch (event.type) {
    case Events.LABEL_FIRST_INPUT_DELAY:
      metalClient.metric.submit(_objectSpread({
        name: catalog.performance.FIRST_INPUT_DELAY,
        value: event.duration
      }, commonTags));
      break;
    case Events.LABEL_TIME_TO_FIRST_BYTE:
      metalClient.metric.submit(_objectSpread({
        name: catalog.performance.TIME_TO_FIRST_BYTE,
        value: event.duration
      }, commonTags));
      break;
    case Events.LABEL_TIME_TO_FIRST_CONTENTFUL_PAINT:
      metalClient.metric.submit(_objectSpread({
        name: catalog.performance.FIRST_CONTENTFUL_PAINT,
        value: event.duration
      }, commonTags));
      break;
    case Events.LABEL_TIME_TO_FIRST_MEANINGFUL_PAINT:
      metalClient.metric.submit(_objectSpread({
        name: catalog.performance.FIRST_MEANINGFUL_PAINT,
        value: event.endTime - context.pageNavigationStart
      }, commonTags));
      break;
    case Events.LABEL_TIME_TO_INTERACTIVE:
      metalClient.metric.submit(_objectSpread({
        name: catalog.performance.TIME_TO_INTERACTIVE,
        value: event.duration
      }, commonTags));
      break;

    // Note: Metal does not directly support sending
    // of LCP, so we use report it as a task duration instead
    case Events.LABEL_TIME_TO_LARGEST_CONTENTFUL_PAINT:
      metalClient.metric.submit(_objectSpread({
        name: catalog.userInteraction.TASK_DURATION,
        task: Events.LABEL_TIME_TO_LARGEST_CONTENTFUL_PAINT,
        value: event.duration
      }, commonTags));
      break;

    // Note: Metal does not directly support sending
    // of CLS, so we use report it as a task duration instead
    case Events.LABEL_CUMULATIVE_LAYOUT_SHIFT:
      metalClient.metric.submit(_objectSpread({
        name: catalog.userInteraction.TASK_DURATION,
        task: Events.LABEL_CUMULATIVE_LAYOUT_SHIFT,
        value: event.shift
      }, commonTags));
      break;
    case Events.LABEL_RESOURCE_DOWNLOAD_TIME_SUM:
      metalClient.metric.submit(_objectSpread({
        name: REQUEST_RESOURCE_TYPES.has(event.resourceType) ? catalog.performance.REQUEST_TIMING : catalog.performance.RESOURCE_TIMING,
        type: event.resourceType,
        value: event.duration
      }, commonTags));
      break;
    case Events.LABEL_PAGE_VIEW_TASK:
    case Events.LABEL_GENERIC_TASK:
      {
        if (isIgnorableGenericTaskSLOEvent(event)) {
          break;
        }
        metalClient.metric.submit(_objectSpread({
          name: event.successful ? catalog.userInteraction.TASK_SUCCESS : catalog.userInteraction.TASK_FAILURE,
          task: event.task,
          taskId: event.taskId,
          value: 1
        }, commonTags));
        metalClient.metric.submit(_objectSpread({
          name: catalog.userInteraction.TASK_DURATION,
          task: event.task,
          taskId: event.taskId,
          value: event.duration
        }, commonTags));
        break;
      }

    // TODO: Remove this once we start to measure these
    // using more generic task events
    case Events.LABEL_ALGOLIA_SEARCH_TIMING:
      metalClient.metric.submit(_objectSpread({
        name: catalog.userInteraction.TASK_DURATION,
        task: Events.LABEL_ALGOLIA_SEARCH_TIMING,
        value: event.duration
      }, commonTags));
      break;
    case Events.LABEL_COMPONENT_ERROR_BOUNDARY:
      return metalClient.error.submit(_objectSpread(_objectSpread({}, commonTags), {}, {
        name: catalog.error.COMPONENT_BOUNDARY,
        component: event.componentName
      }));
  }
};
const metalBackend = metalConfig => {
  var _metalConfig$subProdu;
  const productInfo = {
    metalId: metalConfig.id,
    version: metalConfig.version,
    env: metalConfig.env
  };
  const settings = {
    meta: {
      subproduct: (_metalConfig$subProdu = metalConfig.subProduct) === null || _metalConfig$subProdu === void 0 ? void 0 : _metalConfig$subProdu.toLowerCase(),
      synthetic: metalConfig.synthetic
    },
    metrics: {
      sampleRate: 1,
      maxBufferSize: 20,
      flushInterval: metalConfig.flushInteval || 3000
    }
  };
  const metalClient = new MetalClient({
    productInfo,
    settings
  });
  return createListener(metalClient);
};
export default metalBackend;