import { useStatsig } from '@atlassian/mpac-feature-flags';
import React from 'react';

import { dataTestIds, FIRST_FOCUSABLE_MENU_ELEMENT, products } from '../../constants';
import { DescriptiveItem } from '../../DescriptiveItem';
import { DescriptiveItemsContainer, EyebrowText } from '../../ExpandedMenus.styles';
import { ExpandedNavItemLayout } from '../../ExpandedNavItemLayout';
import { WhatsNew } from '../../WhatsNew';

export const AppsForProducts = ({
  closeMenu,
  focusFirstLinkUnderMenu,
}: {
  closeMenu: () => void;
  focusFirstLinkUnderMenu?: React.RefObject<HTMLAnchorElement>;
}) => {
  const { showCompassApps } = useStatsig();
  const primaryContent = (
    <>
      <EyebrowText role="heading" aria-level={2}>
        Apps for products
      </EyebrowText>
      <DescriptiveItemsContainer>
        {products
          .filter((product) => (showCompassApps() ? true : product.id !== 'compass'))
          .map((product, index) => (
            <DescriptiveItem
              id={index === 0 ? FIRST_FOCUSABLE_MENU_ELEMENT : undefined}
              key={product.id}
              iconUrl={product.icon}
              name={product.name}
              description={product.description}
              href={`/product/${product.id}`}
              closeMenu={closeMenu}
              focusFirstLinkUnderMenu={focusFirstLinkUnderMenu}
            />
          ))}
      </DescriptiveItemsContainer>
    </>
  );

  return (
    <ExpandedNavItemLayout
      dataTestId={dataTestIds.EXPANDABLE_MENU__APPS_FOR_PRODUCTS}
      primaryContent={primaryContent}
      secondaryContent={<WhatsNew closeMenu={closeMenu} />}
    />
  );
};
