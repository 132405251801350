export const CompatibleProducts = Object.freeze({
  JIRA: 'JIRA',
  CONFLUENCE: 'CONFLUENCE',
  CRUCIBLE: 'CRUCIBLE',
  BAMBOO: 'BAMBOO',
  CROWD: 'CROWD',
  BITBUCKET: 'BITBUCKET',
  OPSGENIE: 'OPSGENIE',
  STATUS_PAGE: 'STATUS_PAGE',
  TOWNSQUARE: 'TOWNSQUARE',
  COMPASS: 'COMPASS',
  FISHEYE: 'FISHEYE',
  JIRA_SERVICE_MANAGEMENT: 'JIRA_SERVICE_MANAGEMENT'
});
export const CompatibleProductId = Object.freeze({
  Jira: 'jira',
  Confluence: 'confluence',
  Crucible: 'crucible',
  Bamboo: 'bamboo',
  Crowd: 'crowd',
  Bitbucket: 'bitbucket',
  Opsgenie: 'opsgenie',
  StatusPage: 'status_page',
  Townsquare: 'townsquare',
  Hipchat: 'hipchat',
  COMPASS: 'compass',
  Fisheye: 'fisheye',
  JiraServiceManagement: 'jira-service-management'
});
export const CompatibleProductName = {
  [CompatibleProductId.Jira]: 'Jira',
  [CompatibleProductId.Confluence]: 'Confluence',
  [CompatibleProductId.JiraServiceManagement]: 'Jira Service Management',
  [CompatibleProductId.Fisheye]: 'Fisheye',
  [CompatibleProductId.Crucible]: 'Crucible',
  [CompatibleProductId.Bitbucket]: 'Bitbucket',
  [CompatibleProductId.Crowd]: 'Crowd',
  [CompatibleProductId.Bamboo]: 'Bamboo',
  [CompatibleProductId.COMPASS]: 'Compass'
};