import { Record } from 'immutable';

import { RECEIVE_APP_CONFIG } from '../../../../../actions/App';
import AddonDiscoveryConfig from '../../../../../records/AddonDiscoveryConfig';

// exposed for testing
export const AddonDiscoveryState = Record({
  config: new AddonDiscoveryConfig(),
});

export default function AddonDiscoveryReducer(state = new AddonDiscoveryState(), action) {
  switch (action.type) {
    case RECEIVE_APP_CONFIG:
      return state.set('config', action.appConfig.addonDiscovery);
    default:
      return state;
  }
}
