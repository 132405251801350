import { List, Record } from 'immutable';

import Link from '../../common/records/Link';
import NamedLink from '../../common/records/NamedLink';

export const CollectionLink = Record({
  key: '',
  link: new NamedLink(),
  contentfulEntryId: '',
});

export const CollectionsLinks = Record({
  collections: List(),
});

export const CollectionsMetricsKeys = Record({
  collection: '',
});

export default class CollectionsConfig extends Record({
  links: new CollectionsLinks(),
  metricsKeys: new CollectionsMetricsKeys(),
}) {}

export const resolveCollectionsConfig = ({ appConfig }) =>
  new CollectionsConfig({
    links: new CollectionsLinks({
      collections: appConfig.getIn(['collections', 'links', 'collections']).map(
        (c) =>
          new CollectionLink({
            key: c.get('key'),
            link: new NamedLink({
              name: c.get('name'),
              link: new Link({
                href: c.getIn(['link', 'href']),
              }),
            }),
            contentfulEntryId: c.get('contentfulEntryId'),
          })
      ),
      // @ts-expect-error [MC-2850] - TS2345 - Argument of type '{ collections: any; metricsKeys: Record<{ collection: string; }> & Readonly<{ collection: string; }>; }' is not assignable to parameter of type 'Iterable<[string, any]> | Partial<{ collections: List<any>; }> | undefined'.
      metricsKeys: new CollectionsMetricsKeys({
        collection: appConfig.getIn(['collections', 'metricsKeys', 'collection']),
      }),
    }),
  });
