import { List } from 'immutable';

import ImageLink from '../../../records/ImageLink';
import Link from '../../../records/Link';
import NamedLink from '../../../records/NamedLink';

import TwitterLogo from './logos/twitter-logo.svg';
import YoutubeLogo from './logos/youtube-logo.svg';

export const helpLinks = [
  new NamedLink({
    key: 'ask-atlassian-community',
    name: 'Ask Atlassian Community',
    link: new Link({
      href: 'https://community.atlassian.com/t5/Product-Add-ons/ct-p/atlassian-marketplace',
    }),
  }),
  new NamedLink({
    key: 'build-your-own-solution',
    name: 'Build your own app',
    link: new Link({
      href: 'https://developer.atlassian.com/developer-guide/start-building-with-atlassian/',
    }),
  }),
  new NamedLink({
    key: 'customize-with-solution-partners',
    name: 'Customize with Solutions Partners',
    link: new Link({
      href: 'https://www.atlassian.com/partners/search?page=1&expertise=Custom%20Development',
    }),
  }),
  new NamedLink({
    key: 'get-support',
    name: 'Get support',
    link: new Link({
      href: 'https://www.atlassian.com/licensing/marketplace#generalmarketplacequestions-9',
    }),
  }),
  new NamedLink({
    key: 'installing-apps',
    name: 'Installing apps',
    link: new Link({
      href: 'https://confluence.atlassian.com/upm/installing-add-ons-273875715.html',
    }),
  }),
  new NamedLink({
    key: 'system-status',
    name: 'System status',
    link: new Link({ href: 'https://status.developer.atlassian.com' }),
  }),
];

export const mediaLinks = List([
  new ImageLink({
    key: 'twitter-logo',
    image: TwitterLogo,
    link: new Link({ href: 'https://twitter.com/atlassianmarket' }),
  }),
  new ImageLink({
    key: 'youtube-logo',
    image: YoutubeLogo,
    link: new Link({
      href: 'https://www.youtube.com/@AtlassianDeveloper',
    }),
  }),
]);
