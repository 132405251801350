import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { AlgoliaSearch } from '../../algolia';
import { BaseHandler } from '../../graphql-orchestrator-link';
import { getNlpSearchResults } from '../../search-service';
import { transformFilters } from './utils/AlgoliaFiltersTransformUtil';
import { resolveAlgoliaSortIndexAndFilters } from './utils/AlgoliaSortUtil';
import { transposeAppsConnection } from './utils/AppTileTransposeUtil';
import { transformFiltersForNlpSearch, transformSortForNlpSearch } from './utils/NlpSearchFiltersTransformUtil';
export class SearchResultHandler extends BaseHandler {
  constructor(algoliaConfig, stargateBaseUrl, options) {
    super();
    _defineProperty(this, "algoliaConfig", void 0);
    _defineProperty(this, "stargateBaseUrl", void 0);
    _defineProperty(this, "options", void 0);
    this.algoliaConfig = algoliaConfig;
    this.stargateBaseUrl = stargateBaseUrl;
    this.options = options;
  }
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    var _this$options;
    const {
      query,
      filters,
      sort,
      after,
      first,
      additionalFilters,
      enableNLPSearch
    } = operation.variables;
    if (enableNLPSearch && (_this$options = this.options) !== null && _this$options !== void 0 && _this$options.useNlpSearch) {
      const response = await getNlpSearchResults({
        stargateBaseUrl: this.stargateBaseUrl,
        query,
        filter: transformFiltersForNlpSearch(filters, sort),
        sort: transformSortForNlpSearch(sort),
        after,
        first
      });
      return response;
    } else {
      const {
        transformedAlgoliaIndex,
        sortFilters
      } = resolveAlgoliaSortIndexAndFilters(this.algoliaConfig.algoliaSearchIndex, sort, filters);
      const facetFilters = transformFilters(filters, sortFilters, additionalFilters);
      const response = await AlgoliaSearch({
        applicationId: this.algoliaConfig.algoliaAppId,
        apiKey: this.algoliaConfig.algoliaApiKey,
        indexName: transformedAlgoliaIndex,
        query,
        facetFilters,
        after,
        first
      });
      return response;
    }
  }
  responseTransformer(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    const newResponse = {
      marketplaceSearch: customResponse ? transposeAppsConnection({
        customResponse,
        query: operation.variables.query
      }) : null
    };
    return newResponse;
  }
}