import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { styled } from '@compiled/react';

export const UserDetailsItemContent = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const UserEmail = styled.div({
  color: token('color.text'),
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '0.75rem',
  marginBottom: token('space.050'),
});

export const Username = styled.div({
  color: token('color.text.subtlest'),
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.125rem',
});

export const DropdownAvatarWrapper = styled.div({
  marginRight: token('space.150'),
  display: 'flex',
  alignItems: 'center',
});

export const DropdownItemContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '260px',
  [media.above.xs]: {
    minWidth: '360px',
  },
});

export const DropdownItemLabel = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const LozengeContainer = styled.div({
  marginLeft: token('space.150'),
});
