import Banner from '@atlaskit/banner';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { useFeatureFlags } from '@atlassian/mpac-feature-flags';
import { isMarketplacePartnerPortalRoute } from '@atlassian/mpac-partners';
import {
  MAINTENANCE_BANNER_TARGET_PAGES,
  MAINTENANCE_STATE,
  MAINTENANCE_TYPE,
} from '@atlassian/mpac-types';
import { UserGeneratedHtml } from '@atlassian/mpac-ui';
import React from 'react';

import type { MaintenanceBannerConfiguration } from '@atlassian/mpac-types';

export const MAINTENANCE_BANNER_TEST_ID = 'marketplace-maintenance-banner';
export const MAINTENANCE_BANNER_WARNING_ICON_TEST_ID = 'maintenance-banner-warning-icon';

/*
  @atlaskit/banner does not export the type `Appearance`.
  Variable `appearance` in component code needs a type declaration without which
  TS complains with error ts(2322) -- "Type 'string' is not assignable to type 'Appearance | undefined'."
*/
type AKBannerAppearance = 'announcement' | 'error' | 'warning';

const MaintenanceBanner = ({ pagePath }: { pagePath: string }) => {
  const {
    maintenanceBannerConfiguration,
  }: { maintenanceBannerConfiguration?: MaintenanceBannerConfiguration } = useFeatureFlags();

  if (!maintenanceBannerConfiguration) {
    return null;
  }

  if (maintenanceBannerConfiguration?.type === MAINTENANCE_TYPE.NONE) {
    return null;
  }

  if (
    maintenanceBannerConfiguration?.targetPages ===
      MAINTENANCE_BANNER_TARGET_PAGES.MARKETPLACE_PARTNER_PORTAL &&
    !isMarketplacePartnerPortalRoute(pagePath)
  ) {
    return null;
  }

  let appearance: AKBannerAppearance = 'announcement';
  let bannerWithWarningIcon = false;
  if (
    maintenanceBannerConfiguration?.type === MAINTENANCE_TYPE.WITH_CAPABILITIES_DISRUPTION &&
    maintenanceBannerConfiguration?.state === MAINTENANCE_STATE.IN_PROGRESS
  ) {
    appearance = 'warning';
    bannerWithWarningIcon = true;
  }

  return (
    <Banner
      appearance={appearance}
      {...(bannerWithWarningIcon && {
        icon: (
          <WarningIcon
            label=""
            secondaryColor="inherit"
            testId={MAINTENANCE_BANNER_WARNING_ICON_TEST_ID}
          />
        ),
      })}
      testId={MAINTENANCE_BANNER_TEST_ID}
    >
      {/*
        although showMarketplaceMaintenanceBanner.content is not user-generated content, preferring to use UserGeneratedHtml to prevent use of dangerousHtml with an eslint suppression.
      */}
      <UserGeneratedHtml
        dangerousHtml={maintenanceBannerConfiguration?.content}
        extraStyle={{ fontSize: '14px', lineHeight: '20px' }}
      />
    </Banner>
  );
};

export default MaintenanceBanner;
