import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { LocalStorageUtils } from '@atlassian/mpac-utils';
export class RecentSearch {
  constructor(limit, storageKey) {
    _defineProperty(this, "limit", void 0);
    _defineProperty(this, "recentSearches", []);
    _defineProperty(this, "storageKey", void 0);
    _defineProperty(this, "loadFromLocalStorage", () => {
      const storedSearches = LocalStorageUtils.getFromStorage(this.storageKey);
      if (storedSearches) {
        this.recentSearches = JSON.parse(storedSearches);
      }
    });
    _defineProperty(this, "saveToLocalStorage", () => {
      LocalStorageUtils.writeStorage(this.storageKey, JSON.stringify(this.recentSearches));
    });
    _defineProperty(this, "clearAll", () => {
      this.recentSearches = [];
      this.saveToLocalStorage();
    });
    _defineProperty(this, "push", search => {
      if (!search || !search.query) {
        return;
      }
      const index = this.recentSearches.findIndex(s => s.objectID === search.objectID);
      if (index > -1) {
        this.recentSearches.splice(index, 1);
      }
      if (this.recentSearches.length >= this.limit) {
        this.recentSearches.pop();
      }
      this.recentSearches.unshift(search);
      this.saveToLocalStorage();
    });
    _defineProperty(this, "remove", search => {
      const index = this.recentSearches.findIndex(s => s.objectID === search.objectID);
      if (index > -1) {
        this.recentSearches.splice(index, 1);
        this.saveToLocalStorage();
      } else {
        console.warn('Search not found in recent searches');
      }
    });
    _defineProperty(this, "getRecentSearches", () => {
      return [...this.recentSearches];
    });
    _defineProperty(this, "count", () => {
      return this.recentSearches.length;
    });
    _defineProperty(this, "refreshRecentSearches", () => {
      this.loadFromLocalStorage();
    });
    if (limit < 1) {
      throw new Error('Limit should be greater than 0');
    }
    this.limit = limit;
    this.storageKey = storageKey;
    this.loadFromLocalStorage();
  }
}