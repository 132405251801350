import { fetchOk } from '@atlassian/mpac-fetch-wrapper';

export const SUBSCRIBE_DIALOG_OPEN = 'MAILING_LIST_SUBSCRIBE_DIALOG_OPEN';
export const SUBSCRIBE_DIALOG_CLOSED = 'MAILING_LIST_SUBSCRIBE_DIALOG_CLOSED';
export const SUBSCRIBE_SUCCESS = 'MAILING_LIST_SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'MAILING_LIST_SUBSCRIBE_FAILURE';
export const DISMISS_FLAG = 'MAILING_LIST_DISMISS_FLAG';

const success = () => ({
  type: SUBSCRIBE_SUCCESS,
});

const failure = (reason) => ({
  type: SUBSCRIBE_FAILURE,
  reason,
});

export const dismissFlag = () => ({
  type: DISMISS_FLAG,
});

export const openSubscribeDialog = () => ({
  type: SUBSCRIBE_DIALOG_OPEN,
});

export const closeSubscribeDialog = () => ({
  type: SUBSCRIBE_DIALOG_CLOSED,
});

export function subscribe({ emailId, listIds }) {
  return (dispatch, getState) => {
    fetchOk(getState().generalLinks.getMailingListLocale.href, {
      method: 'GET',
    })
      .then((resp) => resp.json())
      .then(({ locale }) => {
        const body = {
          email: emailId,
          formUrl: window.location.href,
          locale,
          subscriptions: listIds,
        };

        return fetchOk(getState().generalLinks.subscribeToMailingList.href, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        });
      })
      .then(() => {
        dispatch(success());
        dispatch(closeSubscribeDialog());
        setTimeout(() => dispatch(dismissFlag()), 5000);
      })
      .catch(() => {
        dispatch(failure('Unable to reach the server.'));
      });
  };
}
