import { ImmutableUtils } from '@atlassian/mpac-utils';
import { List, Record } from 'immutable';

export default class RotatableEntity extends Record({
  pinned: List(),
  unpinned: List(),
}) {
  resolveItems(n) {
    // @ts-expect-error [MC-2850] - TS2554 - Expected 2 arguments, but got 1.
    const pinned = this.get('pinned');
    // @ts-expect-error [MC-2850] - TS2554 - Expected 2 arguments, but got 1.
    const unpinned = this.get('unpinned');

    return ImmutableUtils.fill(pinned)(n)(unpinned);
  }
}
