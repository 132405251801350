import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { getDownloadsData } from '@atlassian/mpac-api-client';
import { MarketplaceConsoleAppDistributionsGroupByKey } from '@atlassian/mpac-types/client-graphql-schema-types';
import { DateUtils } from '@atlassian/mpac-utils';
import { BaseHandler } from '../../../graphql-orchestrator-link';
import { transposeDistributionsData } from '../utils/DistributionTransposeUtils';
export class DownloadsHandler extends BaseHandler {
  constructor(mpacBaseUrl) {
    super();
    _defineProperty(this, "mpacBaseUrl", void 0);
    this.mpacBaseUrl = mpacBaseUrl;
  }
  responseTransformer(_operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    var _customResponse$recor;
    if (customResponse !== null && customResponse !== void 0 && (_customResponse$recor = customResponse.records) !== null && _customResponse$recor !== void 0 && _customResponse$recor.length) {
      return {
        marketplaceConsoleAppDownloads: transposeDistributionsData(customResponse, MarketplaceConsoleAppDistributionsGroupByKey.AppVersion, null)
      };
    }
    return {
      marketplaceConsoleAppDownloads: []
    };
  }
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    const {
      appKey,
      startDate,
      endDate,
      partnerId
    } = operation.variables;
    try {
      const downloadsWeekData = await getDownloadsData(this.mpacBaseUrl, {
        appKey,
        startDate,
        /**
         * For downloads, since it is an aggregation we will be able to show the data only for last week and not the current week.
         * So we are subtracting 1 week from the end date to get the data for last week. This is not an issue in active installs
         * as it is snapshot count (not an aggregation).
         */
        endDate: DateUtils.ISOFormatHyphenatedDate(DateUtils.subtractDateByDays(endDate, 6)),
        partnerId,
        granularity: 'WEEK'
      });
      return downloadsWeekData;
    } catch (error) {
      throw new Error('Failed to fetch downloads data');
    }
  }
}