import { fetchOk } from '@atlassian/mpac-fetch-wrapper';
export async function getNlpSearchResults(_ref) {
  let {
    stargateBaseUrl,
    query,
    filter,
    sort,
    after,
    first
  } = _ref;
  const requestParams = {
    query,
    filter,
    sortBy: sort,
    page: Math.floor(after / first),
    hitsPerPage: first
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(requestParams),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const response = await fetchOk("".concat(stargateBaseUrl, "/marketplace/search/api/v1/marketplace-search"), options);
  if (!response.ok) {
    throw new Error('Response not ok from search service');
  }
  const json = await response.json();
  return {
    results: [json]
  };
}