import Deferred from '../Deferred';

const ready = new Deferred();
const dataReadyCallbacks: any = [];

// Invoking this function signifies that all AJAX data has been received, or that none is expected.  All
// data requirements for the page to be ready for the user have been met.
export function dataReady() {
  dataReadyCallbacks.forEach((callback) => callback());
  document.addEventListener('DOMContentLoaded', () => {
    // Ready for user when the data is ready and the document is ready.
    ready.resolve();
  });
}

export function whenDataReady(callback) {
  dataReadyCallbacks.push(callback);
}

export function whenReady(callback) {
  // eslint-disable-next-line promise/catch-or-return
  ready.then(callback);
}
