import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { EyebrowMedium } from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';

export const DescriptiveItemsContainer = styled.ul({
  display: 'grid',
  gridAutoRows: '1fr',
  rowGap: 0,
  columnGap: token('space.400', '32px'),
  gridTemplateColumns: 'repeat(1, 1fr)',
  [media.above.md]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

export const EyebrowText = styled(EyebrowMedium)({
  color: token('color.text.subtle'),
  marginBottom: token('space.200', '16px'),
});
