import React, { Component } from 'react';

import codeSplitComponentFactory from '../../../../common/codeSplitComponentFactory';
import BaseRouteHandler from '../../common/routeHandlers/BaseRouteHandler';

// exposed for testing
export const initialize = () => {
  const AddonDiscoveryRouteProcessor = codeSplitComponentFactory(() =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "chunk-discovery" */ './AddonDiscoveryRouteProcessor'
    ).then((c) => c.default)
  );
  return class AddonDiscoveryRouteHandler extends Component {
    render() {
      return (
        <BaseRouteHandler
          scrollToTopOnPathChange
          scrollToTopOnQueryParamChange
          includeFooter
          includeSearch={false}
          includePartnerQuickLinks
        >
          <AddonDiscoveryRouteProcessor {...this.props} />
        </BaseRouteHandler>
      );
    }
  };
};

export default initialize();
