import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createAlgoliaFacetFiltersFromQueryMetaData } from '@atlassian/mpac-utils';
import algoliaSearch from 'algoliasearch';
import { getDefaultFacetFilters, transformFilters, transformSortToFacetFilters } from '../dla-handlers/SearchResult/utils/AlgoliaFiltersTransformUtil';
import { resolveAlgoliaSortIndexAndFilters } from '../dla-handlers/SearchResult/utils/AlgoliaSortUtil';
export function AlgoliaSearch(_ref) {
  let {
    applicationId,
    apiKey,
    indexName,
    query,
    facetFilters,
    after,
    first
  } = _ref;
  const client = algoliaSearch(applicationId, apiKey);
  return client.search([{
    indexName,
    query: query ? query : '',
    params: {
      hitsPerPage: first,
      page: Math.floor(after / first),
      facetFilters
    }
  }]);
}
export class AlgoliaSearchV2 {
  constructor(algoliaSearchConfig) {
    _defineProperty(this, "applicationId", void 0);
    _defineProperty(this, "apiKey", void 0);
    _defineProperty(this, "facetFilters", getDefaultFacetFilters());
    _defineProperty(this, "indexName", void 0);
    this.applicationId = algoliaSearchConfig.algoliaAppId;
    this.apiKey = algoliaSearchConfig.algoliaApiKey;
    this.indexName = algoliaSearchConfig.algoliaSearchIndex;
  }
  setSortIndexAndFilters(sortKey, filters) {
    const {
      sortFilters,
      transformedAlgoliaIndex
    } = resolveAlgoliaSortIndexAndFilters(this.indexName, sortKey, filters);
    this.indexName = transformedAlgoliaIndex;
    this.facetFilters.push(transformSortToFacetFilters(sortFilters));
    return this;
  }
  setAlgoliaMetaDataIndexAndFilters(algoliaQueryMetaData) {
    if (algoliaQueryMetaData.searchIndex) {
      this.indexName = algoliaQueryMetaData.searchIndex;
    }
    this.facetFilters.push(createAlgoliaFacetFiltersFromQueryMetaData(algoliaQueryMetaData));
    return this;
  }
  appendFacetFiltersFromUIFilters(filters) {
    this.facetFilters.push(...transformFilters(filters));
    return this;
  }
  async search(_ref2) {
    let {
      after,
      first,
      query
    } = _ref2;
    return AlgoliaSearch({
      applicationId: this.applicationId,
      apiKey: this.apiKey,
      indexName: this.indexName,
      query,
      facetFilters: this.facetFilters,
      after,
      first
    });
  }
}
export function AlgoliaSearchV3(_ref3) {
  let {
    applicationId,
    apiKey,
    indexName,
    attributesToHighlight,
    attributesToRetrieve,
    facetFilters,
    hitsPerPage
  } = _ref3;
  const client = algoliaSearch(applicationId, apiKey);
  return client.search([{
    indexName,
    params: {
      attributesToHighlight,
      attributesToRetrieve,
      facetFilters,
      hitsPerPage
    }
  }]);
}