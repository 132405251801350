import { hooks, log, UrlUtils } from '@atlassian/mpac-utils';

import type CollectionsConfig from 'app/records/CollectionsConfig';
import type CommonConfig from 'app/records/CommonConfig';
import type SolutionsConfig from 'app/records/SolutionsConfig';
import resolveContentfulClient from '../../utils/contentful-client';

import type { NamedLinkType } from 'common/records/NamedLink';

type FooterDetailsType = {
  pending?: boolean;
  error?;
  footerDetails?: NamedLinkType[] | null;
};

type ConfigType = {
  commonConfig: CommonConfig;
  solutionsConfig: SolutionsConfig;
  collectionsConfig: CollectionsConfig;
};

export const useFooterDetails = (config: ConfigType): FooterDetailsType => {
  let footerDetails;
  const { commonConfig, solutionsConfig, collectionsConfig } = config;
  const { result, error, pending } = hooks.usePromise(() =>
    resolveContentfulClient({ commonConfig }).getEntries({
      content_type: 'categoryPage',
      select: ['fields.key', 'fields.name', 'fields.includeInFooter'],
    })
  );

  if (error) {
    log.error('Footer/hooks', `Failed to retrieve footer data from Contentful`, {
      error,
    });
  }

  if (result) {
    footerDetails = result.items
      .filter((resp: any) => resp.fields.includeInFooter)
      .map((s: any) => ({
        name: s.fields.name,
        link: {
          href: UrlUtils.resolveUrlFromTemplateAndParameters({
            template: solutionsConfig.links.solution.href,
            parameters: { category: s.fields.key },
          }),
        },
      }));

    footerDetails = [
      ...footerDetails,
      ...collectionsConfig.links.collections.map((c) => c.link).toJS(),
    ];

    footerDetails = footerDetails.sort((a, b) => a.name.localeCompare(b.name));
  }

  return {
    pending,
    error,
    footerDetails,
  };
};
