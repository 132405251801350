import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import {
  BodySmall,
  ExternalSubtleLink,
  EyebrowMedium,
  FluidContainer,
  SubtleLink,
} from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';

export const FooterContainer = styled.div({
  backgroundColor: token('color.background.accent.gray.subtlest'),
});

export const FooterPrimary = styled(FluidContainer)({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.300'),
  alignItems: 'flex-start',
  padding: `${token('space.400')} 0 ${token('space.200')}`,

  [media.above.sm]: {
    padding: `${token('space.400')} 0 ${token('space.300')}`,
  },
  [media.above.md]: {
    gap: token('space.0'),
    flexDirection: 'row',
  },
});

export const LogoContainer = styled.div({
  width: 'auto',
  [media.above.md]: {
    width: '25%',
  },
});

export const FooterSecondary = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.100'),
  padding: `${token('space.200')} 0 ${token('space.500')}`,

  [media.above.sm]: {
    gap: token('space.400'),
    flexDirection: 'row',
    padding: `${token('space.200')} 0`,
  },

  li: {
    listStyle: 'none',
    marginTop: 0,
  },
});

export const BodySmallStyled = styled(BodySmall)({
  margin: token('space.0'),
});

export const Separator = styled.div({
  borderBottom: `1px solid ${token('color.border')}`,
});

export const FooterSectionHeading = styled(EyebrowMedium)({
  marginBottom: token('space.200'),
  color: token('color.text.subtle'),
});

export const FooterSectionLinks = styled.ul({
  listStyle: 'none',
  margin: '0',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.100'),
});

export const MediaLinksContainer = styled.ul({
  display: 'flex',
  gap: token('space.200'),
  padding: 0,

  li: {
    listStyle: 'none',
    marginTop: 0,
  },
});

export const FooterSectionsContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: token('space.300'),
  [media.above.sm]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [media.above.md]: {
    width: '75%',
  },
});

export const MediaLink = styled(ExternalSubtleLink)({
  height: '32px',
  width: '32px',

  '&:hover rect': {
    fill: token('color.background.neutral.bold.hovered'),
  },
  '&:active rect': { fill: token('color.background.neutral.bold.pressed') },

  '&:focus-visible': {
    borderRadius: '50%',
    outline: `2px solid ${token('color.border.focused')}`,
    outlineOffset: token('space.050'),
  },
});

export const Anchor = styled.a({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  padding: `${token('space.050')}`,
  borderRadius: '3px',
  '&:hover': {
    backgroundColor: token('color.background.neutral.hovered'),
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.pressed'),
  },
  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
  },
});

export const SubtleLinkStyled = styled(SubtleLink)({
  width: 'fit-content',
});

export const FooterLinks = styled.li({
  lineHeight: 'normal',
  marginTop: '0',
});
