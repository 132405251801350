import { Record } from 'immutable';

import {
  dataErrorGenerator,
  defaultData,
  receiveDataGenerator,
  retrieveDataGenerator,
} from '../../../../../../common/utils/reducerUtils';
import { RECEIVE_APP_CONFIG } from '../../../../../actions/App';
import SolutionsConfig from '../../../../../records/SolutionsConfig';
import {
  DATA_ERROR_SOLUTION_CONTENT,
  LARGE_CURATED_ADDONS_RENDER_COMPLETE,
  LARGE_CURATED_ADDONS_RENDER_STARTED,
  RECEIVE_SOLUTION_CONTENT,
  RETRIEVE_SOLUTION_CONTENT,
  SMALL_CURATED_ADDONS_RENDER_COMPLETE,
  SMALL_CURATED_ADDONS_RENDER_STARTED,
} from '../actions/Solutions';

const onRetrieveSolutionContent = retrieveDataGenerator('solutionContent');
const onReceiveSolutionContent = receiveDataGenerator(
  'solutionContent',
  (action) => action.solutionContent
);
const onDataErrorSolutionContent = dataErrorGenerator('solutionContent');

// exposed for testing
export const SolutionsState = Record({
  config: new SolutionsConfig(),
  isLargeCuratedAddonsRenderComplete: false,
  isSmallCuratedAddonsRenderComplete: false,
  solutionContent: defaultData,
});

export default function SolutionsReducer(state = new SolutionsState(), action) {
  switch (action.type) {
    case RECEIVE_APP_CONFIG:
      return state.set('config', action.appConfig.solutions);
    case RETRIEVE_SOLUTION_CONTENT:
      return onRetrieveSolutionContent(state);
    case RECEIVE_SOLUTION_CONTENT:
      return onReceiveSolutionContent(state, action);
    case DATA_ERROR_SOLUTION_CONTENT:
      return onDataErrorSolutionContent(state, action);
    case LARGE_CURATED_ADDONS_RENDER_COMPLETE:
      return state.set('isLargeCuratedAddonsRenderComplete', true);
    case LARGE_CURATED_ADDONS_RENDER_STARTED:
      return state.set('isLargeCuratedAddonsRenderComplete', false);
    case SMALL_CURATED_ADDONS_RENDER_COMPLETE:
      return state.set('isSmallCuratedAddonsRenderComplete', true);
    case SMALL_CURATED_ADDONS_RENDER_STARTED:
      return state.set('isSmallCuratedAddonsRenderComplete', false);
    default:
      return state;
  }
}
