import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import { token } from '@atlaskit/tokens';
import { styled } from '@compiled/react';
import React from 'react';

import { BaseButton, Text } from './Button.styles';

const Button = styled(BaseButton)({
  padding: `${token('space.0')} ${token('space.150')} ${token('space.0')} ${token('space.0')}`,
  gap: token('space.200'),
  height: '40px',
  '& path': {
    fill: token('color.icon.subtle'),
  },
  '&:hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },
});

export const MenuBackButton = ({ onClick, label }: { onClick: () => void; label: string }) => {
  return (
    <Button onClick={onClick}>
      <ChevronLeftLargeIcon label="Menu back button" size="large" />
      <Text>{label}</Text>
    </Button>
  );
};
