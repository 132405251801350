import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

type TextSearchWrapperProps = {
  mode: 'header' | 'hero';
};

export const SearchBox = styled.input`
  font-family: 'Charlie Text';
  background: transparent;
  border: none;
  border-bottom: 2px ${N0} solid;
  color: ${N0};
  outline: none;
  padding: 10px 5px;
  text-overflow: ellipsis;
  transition: width 0.2s ease;

  &::placeholder {
    color: ${N0};
    font-weight: 100;
  }
  &::-webkit-input-placeholder {
    color: ${N0};
    font-weight: 100;
  }
  &::-moz-placeholder {
    color: ${N0};
    font-weight: 100;
  }
  &:-ms-input-placeholder {
    color: ${N0};
    font-weight: 100;
  }
  &:-moz-placeholder {
    color: ${N0};
    font-weight: 100;
  }
`;

export const SearchIconContainer = styled.span`
  color: ${N0};
  position: absolute;
  pointer-events: none;
`;

export const TextSearchWrapper = styled.div<TextSearchWrapperProps>`
  position: relative;
  ${({ mode }: TextSearchWrapperProps) =>
    mode === 'header'
      ? css`
          ${SearchBox} {
            width: 300px;
            color: ${N0};
            padding-left: ${token('space.500', '40px')};

            &:focus {
              width: 500px;
            }
          }

          ${SearchIconContainer} {
            left: ${token('space.050', '4px')};
            top: 7px;
          }
        `
      : css`
          ${SearchBox} {
            font-size: 18px;
            height: 2.64285714em;
            padding-left: 55px;
            width: 500px;
          }

          ${SearchIconContainer} {
            top: 5px;
            left: 7px;
          }
        `}
`;
