import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../Routes/components/common/routeHandlers/BaseRouteHandler';

const PlansPageCodeSplit = lazyForPaint(() =>
  import(/* webpackChunkName: "chunk-app-plans-page" */ './PlansPage').then(
    (module) => module.PlansPage
  )
);

const RouteHandler = () => {
  return (
    <BaseRouteHandler scrollToTopOnPathChange>
      <LazySuspense fallback={<div />}>
        <PlansPageCodeSplit />
      </LazySuspense>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
