import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Observable } from '@apollo/client';
/**
 * Details:
 * First `primary` data is fetched(after optionally transforming the given request) and then for primary data
 * some `custom data`(or dependent data) is fetched.
 * The result of both primary and custom calls are passed back to the `transformResponse` function for data massaging.
 *
 * Use case:
 * When the endpoint service(BFF in our case(primary)) is not able to return the full data and we are dependant of
 * other services(like Algolia(custom)) for fetching the dependant data.
 *
 * Call sequence:
 * transformRequest -> fetchPrimary -> fetchCustom -> transformResponse
 */
function primaryFirstSequence(operation, forward, sequenceConfig, handler) {
  const newOperation = handler.requestTransformer(operation, sequenceConfig);
  return new Observable(observer => {
    const handler$ = handler.fetchPrimary(newOperation, forward).subscribe({
      async next(data) {
        const primaryResponse = data.data;
        try {
          const customResponse = await handler.fetchCustom(operation, newOperation, sequenceConfig, primaryResponse);
          const queryResponse = handler.responseTransformer(operation, newOperation, sequenceConfig, primaryResponse, customResponse);
          observer.next(_objectSpread(_objectSpread({}, data), {}, {
            data: queryResponse
          }));
          observer.complete();
        } catch (err) {
          observer.error(err);
        }
      },
      error(err) {
        observer.error(err);
      }
    });
    return () => {
      handler$.unsubscribe();
    };
  });
}
export { primaryFirstSequence };