import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Collection, fromJS, Set } from 'immutable';
import queryString from 'query-string';
import URL from 'url-parse';
import urlTemplate from 'url-template';
export function foldImmutableQueryValue(iqv) {
  return function (string, set) {
    if (typeof iqv === 'string') {
      return string(iqv);
    } else if (Set.isSet(iqv)) {
      return set(iqv);
    } else {
      throw new TypeError("foldResolvedImmutableQueryValue is not comprehensive in ResolvedImmutableQueryValue: ".concat(JSON.stringify(iqv)));
    }
  };
}
export function resolveImmutableQuery(search) {
  const immutableMap = fromJS(queryString.parse(search), (_key, value) => Collection.isIndexed(value) ? value.toSet() : value.toMap());
  return immutableMap;
}
export function resolveSearchFromImmutableQuery(query) {
  const stringifiedQuery = stringifyImmutableQuery(query);
  return stringifiedQuery ? "?".concat(stringifiedQuery) : '';
}
const filterEmptyStringsFromQuery = queryObject => {
  return Object.entries(queryObject).filter(_ref => {
    let [_, v] = _ref;
    return !(typeof v === 'string' && v.trim() === '');
  }).reduce((res, _ref2) => {
    let [k, _] = _ref2;
    res[k] = queryObject[k];
    return res;
  }, {});
};
function stringifyImmutableQuery(query) {
  const queryObject = query && query.toJS();
  return queryObject ? queryString.stringify(filterEmptyStringsFromQuery(queryObject)) : '';
}
export function resolveUrlFromTemplateAndParameters(options) {
  return urlTemplate.parse(options.template).expand(options.parameters);
}
export function transformUriTemplateToRoutePath(_ref3) {
  let {
    template
  } = _ref3;
  return template.replace(/{([^{}]+)}/g, (_, expression) => ":".concat(expression));
}
export function transformRoutePathToUriTemplate(_ref4) {
  let {
    routePath
  } = _ref4;
  return routePath
  // Remove route regex from the path ( <>/:param((\d+)) -> <>/:param )
  .replace(/ *\([^)]*\) */g, '')
  // convert Route Parameters to URI template expression ( <>/:param -> <>/{param} )
  .replace(/:([^/]+)/g, (_, expression) => "{".concat(expression, "}"));
}
export function validateSafeAbsoluteUrl(url) {
  if (!url) return null;
  const urlObj = new URL(url, {});
  const scheme = urlObj.protocol.toLowerCase();
  switch (scheme) {
    case 'http:':
    case 'https:':
      return url;
    default:
      return null;
  }
}

/**
 * Replaces the value of a query parameter in a url if present,
 * and adds it if not, and returns the path only.
 *  @example
 * updateQueryParam(https://google.com/something?a=1, a, 3); // returns /something?a=3
 */
export const updateQueryParam = (url, paramName, paramValue) => {
  const newUrl = new URL(url, {}, true);
  const {
    query,
    pathname,
    hash
  } = newUrl;
  const urlPath = new URL('', {});
  urlPath.set('query', _objectSpread(_objectSpread({}, query), {}, {
    [paramName]: paramValue
  })).set('pathname', pathname).set('hash', hash);
  return urlPath.toString();
};

/**
 * Removes a query parameter and its value from a given url
 * and and returns the path only.
 *  @example
 * unsetQueryParam(https://google.com/something?a=1, a); // returns /something
 */
export const unsetQueryParam = (url, paramName) => {
  const newUrl = new URL(url, {}, true);
  const {
    query,
    pathname,
    hash
  } = newUrl;
  const {
      [paramName]: _paramToRemove
    } = query,
    otherQueryParams = _objectWithoutProperties(query, [paramName].map(_toPropertyKey));
  const urlPath = new URL('', {});
  urlPath.set('query', _objectSpread({}, otherQueryParams)).set('pathname', pathname).set('hash', hash);
  return urlPath.toString();
};
export const getLocationPath = () => window.location.hash.split('#')[1].split('?')[0];
export const getSupportTicketNumber = url => url.split('/').pop();
export const joinPathNames = pathNames => {
  const fullPath = pathNames.join('/');
  return fullPath.replace(/\/+/g, '/');
};
export const removeDomainFromUrl = url => {
  const urlObject = new URL(url);
  return urlObject.pathname + urlObject.query;
};
export const removeQueryParamsFromUrl = url => {
  const urlObject = new URL(url);
  return urlObject.origin + urlObject.pathname;
};