import { fetchOk } from '@atlassian/mpac-fetch-wrapper';
export const archiveApp = (mpacBaseUrl, appKey) => fetchOk("".concat(mpacBaseUrl, "/rest/1.0/plugins/").concat(appKey, "/archive"), {
  method: 'POST',
  headers: {
    'Content-type': 'application/json'
  },
  body: JSON.stringify({
    hostings: ['cloud', 'server', 'datacenter']
  })
});
export const updateAppApproval = (mpacBaseUrl, appKey, body) => fetchOk("".concat(mpacBaseUrl, "/rest/2/addons/").concat(appKey, "/approval"), {
  method: 'PUT',
  headers: {
    'Content-type': 'application/json'
  },
  body: JSON.stringify(body)
});
export const releaseApp = (mpacBaseUrl, appKey, body) => fetchOk("".concat(mpacBaseUrl, "/rest/2/addons/").concat(appKey, "/release"), {
  method: 'PUT',
  headers: {
    'Content-type': 'application/json'
  },
  body: JSON.stringify(body)
});
export const deleteApp = (mpacBaseUrl, appKey) => fetchOk("".concat(mpacBaseUrl, "/rest/2/addons/").concat(appKey), {
  method: 'DELETE'
});
export const getApp = async appKey => {
  return fetchOk("/rest/2/addons/".concat(appKey)).then(response => response.json());
};
export const saveAdminData = async (mpacBaseUrl, appKey, payload) => {
  return fetchOk("".concat(mpacBaseUrl, "/rest/2/addons/").concat(appKey), {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json-patch+json'
    },
    body: JSON.stringify(payload)
  });
};
export const validateAppName = (mpacBaseUrl, body) => fetchOk("".concat(mpacBaseUrl, "/gateway/api/marketplace/rest/1/product-listing/validate/name"), {
  method: 'PUT',
  headers: {
    'Content-type': 'application/json'
  },
  body: JSON.stringify(body)
});