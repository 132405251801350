import { AnalyticsListener as AKAnalyticsListener } from '@atlaskit/analytics-next';
import {
  getAllMarketplaceContexts,
  getMergedMarketplaceContext,
  SCREEN_EVENT_TYPE,
  TRACK_EVENT_TYPE,
} from '@atlassian/mpac-analytics';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import { Map } from 'immutable';
import * as React from 'react';

import { trackEvent as gaTrackEvent } from '../../../../common/utils/analytics/analytics';
import {
  sendScreenEvent,
  sendTrackEvent,
  sendUIEvent,
  trackAddonDiscovery as webTrackAddonDiscovery,
} from '../../../../common/utils/analytics/webAnalytics';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import type { ReactNode } from 'react';

const AllowedChannels = Object.freeze({
  AddonDiscovery: 'addonDiscovery',
  Marketplace: 'marketplace',
});

type AnalyticsListenerProps = {
  children: ReactNode;
};

// eslint-disable-next-line max-lines-per-function
const AnalyticsListener = (props: AnalyticsListenerProps) => {
  const { getEvaluatedFlags, getEvaluatedExperiments } = useStatsig();
  const flags = React.useMemo(() => getEvaluatedFlags(), [getEvaluatedFlags]);
  const experiments = React.useMemo(() => getEvaluatedExperiments(), [getEvaluatedExperiments]);
  const onEvent = (event: AnalyticsEventPayload, channel?: string) => {
    const { payload = {}, context } = event;

    if (channel === AllowedChannels.AddonDiscovery) {
      const marketplaceContexts = getAllMarketplaceContexts(context);
      const {
        objectType,
        objectId,
        eventType: { actionSubject, action, actionSubjectId, type },
      } = payload;

      const label = payload.label ?? [objectType, objectId].filter(Boolean).join(':');

      const gaValue = marketplaceContexts.reduce(
        (value, contextItem) =>
          contextItem.attributes.gaValueKey
            ? Map(payload).getIn(contextItem.attributes.gaValueKey)
            : value,
        null
      );

      const gaCategory = marketplaceContexts.reduce(
        (category, contextItem) => contextItem.attributes.gaCategory ?? category,
        'AddonDiscovery'
      );

      const gaAction = actionSubjectId
        ? `${actionSubject} ${action} ${actionSubjectId}`
        : `${actionSubject} ${action}`;
      gaTrackEvent(gaCategory, gaAction, label, gaValue);
      if (type === TRACK_EVENT_TYPE) {
        sendTrackEvent({
          eventContainer: payload.source || payload.eventContainer,
          eventComponent: actionSubject,
          eventName: `${actionSubject} ${action}`,
          actionSubjectId,
          event: action,
          label,
          statsig: {
            flags,
            experiments,
          },
          category: 'addonDiscovery',
          ...payload,
          ...payload.attributes,
        });
      } else {
        webTrackAddonDiscovery({
          eventName: `${actionSubject} ${action}`,
          actionSubject,
          actionSubjectId,
          action,
          label,
          payload,
        });
      }
    } else if (channel === AllowedChannels.Marketplace) {
      const { eventType = {}, actionSubjectId, eventContainer, source } = payload;
      const { actionSubject, action, type } = eventType;

      const { properties, attributes: marketplaceContextAttributes } =
        getMergedMarketplaceContext(context);

      if (type === TRACK_EVENT_TYPE) {
        sendTrackEvent({
          eventContainer: payload.source || payload.eventContainer,
          eventComponent: actionSubject,
          eventName: `${actionSubject} ${action}`,
          actionSubjectId,
          event: action,
          source,
          statsig: {
            flags,
            experiments,
          },
          category: 'addonDiscovery',
          ...payload,
          ...payload.attributes,
          ...marketplaceContextAttributes,
          ...properties,
        });
      } else if (type === SCREEN_EVENT_TYPE) {
        sendScreenEvent(payload.referer, payload.actionSubjectId, {
          ...marketplaceContextAttributes,
          ...payload.attributes,
          ...properties,
        });
      } else {
        sendUIEvent({
          eventName: `${actionSubject} ${action}`,
          eventComponent: actionSubject,
          event: action,
          actionSubjectId,
          eventContainer,
          source,
          statsig: {
            flags,
            experiments,
          },
          poweredBy: 'AMKT_FRONTEND',
          ...marketplaceContextAttributes,
          ...payload.attributes,
          ...properties,
        });
      }
    }
  };

  return (
    <AKAnalyticsListener channel="*" onEvent={onEvent}>
      {props.children}
    </AKAnalyticsListener>
  );
};

export default AnalyticsListener;
