import { LinkClickedEvent, useAnalyticsEvents } from '@atlassian/mpac-analytics';
import { ExternalSubtleLink, FluidContainer } from '@atlassian/mpac-primitives';
import React from 'react';

import {
  ANALYTICS_EVENT_CONTAINER_FOOTER,
  ANALYTICS_SUBJECT_ID_FOOTER_LINK,
} from '../../../analytics/Events';
import { AtlassianLogo } from './AtlassianLogo';
import {
  CONNECT_LINKS,
  LEARN_AND_EXPLORE_LINKS,
  MEDIA_LINKS,
  PRIVACY_LINK,
  TERMS_OF_USE_LINK,
  WAC_LINK,
} from './Footer.constants';
import {
  Anchor,
  BodySmallStyled,
  FooterContainer,
  FooterLinks,
  FooterPrimary,
  FooterSecondary,
  FooterSectionHeading,
  FooterSectionLinks,
  FooterSectionsContainer,
  LogoContainer,
  MediaLink,
  MediaLinksContainer,
  Separator,
} from './Footer.styles';

const FooterSection = ({ heading, links }) => {
  const fireEvent = useAnalyticsEvents();
  const onFooterLinkClick = ({ text, href }: { text: string; href: string }) => {
    fireEvent({
      eventType: LinkClickedEvent,
      eventContainer: ANALYTICS_EVENT_CONTAINER_FOOTER,
      actionSubjectId: ANALYTICS_SUBJECT_ID_FOOTER_LINK,
      attributes: {
        label: text,
        link: href,
      },
    });
  };
  return (
    <div>
      <FooterSectionHeading role="heading" aria-level={3}>
        {heading}
      </FooterSectionHeading>
      <FooterSectionLinks>
        {links.map(({ text, href }, index) => {
          return (
            <FooterLinks key={text}>
              <ExternalSubtleLink
                size="small"
                key={index}
                href={href}
                onClick={() => onFooterLinkClick({ text, href })}
              >
                {text}
              </ExternalSubtleLink>
            </FooterLinks>
          );
        })}
      </FooterSectionLinks>
    </div>
  );
};

export const Footer = () => {
  return (
    <FooterContainer role="region" aria-label="Footer">
      <FooterPrimary>
        <LogoContainer>
          <Anchor href={WAC_LINK} aria-label="Atlassian home page" target="_blank">
            <AtlassianLogo />
          </Anchor>
        </LogoContainer>
        <FooterSectionsContainer>
          <FooterSection heading="Learn and explore" links={LEARN_AND_EXPLORE_LINKS} />
          <FooterSection heading="Connect" links={CONNECT_LINKS} />
          <div>
            <FooterSectionHeading role="heading" aria-level={3}>
              Follow
            </FooterSectionHeading>
            <MediaLinksContainer>
              {MEDIA_LINKS.map(({ key, image: MediaLogo, href, ariaLabel }) => {
                return (
                  <li key={key}>
                    <MediaLink
                      href={href}
                      data-testid={`${key}-link`}
                      aria-label={ariaLabel}
                      size="small"
                    >
                      <MediaLogo />
                    </MediaLink>
                  </li>
                );
              })}
            </MediaLinksContainer>
          </div>
        </FooterSectionsContainer>
      </FooterPrimary>
      <Separator />
      <FluidContainer>
        <FooterSecondary>
          <li>
            <ExternalSubtleLink size="small" href={PRIVACY_LINK}>
              Privacy Policy
            </ExternalSubtleLink>
          </li>
          <li>
            <ExternalSubtleLink size="small" href={TERMS_OF_USE_LINK}>
              Terms of Use
            </ExternalSubtleLink>
          </li>
          <li>
            <BodySmallStyled>Copyright © {new Date().getFullYear()} Atlassian</BodySmallStyled>
          </li>
        </FooterSecondary>
      </FluidContainer>
    </FooterContainer>
  );
};
