import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import getSentryClient from './client';
/* Module-scoped config. */
const loggerConfig = {
  mode: 'sentry'
};
export const getEmceePath = () => {
  if (window.location.href.includes('emcee')) {
    return window.location.hash.split('#')[1];
  }
};
const logLevelToSentrySeverity = logLevel => {
  const logLevelToSentryLevelMappings = {
    info: 'info',
    warn: 'warning',
    error: 'error'
  };
  return logLevelToSentryLevelMappings[logLevel];
};
export const configureLogger = options => {
  const {
    mode = 'sentry',
    context = {}
  } = options || {};
  loggerConfig.mode = mode;
  loggerConfig.context = context;
};
export const setLoggerContext = context => {
  loggerConfig.context = context;
};
export const resetLoggerContext = () => {
  loggerConfig.context = {};
};
const sendData = (level, location, message, data) => {
  const eventData = data || {};
  const {
    mode,
    context = {}
  } = loggerConfig;
  if (mode === 'console') {
    console[level](_objectSpread(_objectSpread({}, eventData), {}, {
      location,
      message,
      context
    }));
  } else {
    var _getSentryClient;
    (_getSentryClient = getSentryClient()) === null || _getSentryClient === void 0 ? void 0 : _getSentryClient.withScope(scope => {
      var _getSentryClient2;
      const {
        tags = {}
      } = eventData;
      scope.clear();
      Object.entries(tags).forEach(_ref => {
        let [tagKey, tagValue] = _ref;
        scope.setTag(tagKey, tagValue);
      });

      // To override emcee iframe url
      const pathname = getEmceePath();
      if (pathname) {
        scope.setTag('url', pathname);
      }
      scope.setTag('location', location);
      scope.setTag('structuredLog', 'true');
      scope.setExtra('eventData', eventData);
      (_getSentryClient2 = getSentryClient()) === null || _getSentryClient2 === void 0 ? void 0 : _getSentryClient2.captureMessage("".concat(location, ": ").concat(message), logLevelToSentrySeverity(level));
      console[level]("".concat(location, ": ").concat(message));
    });
  }
};
const info = (location, message, eventData) => {
  sendData('info', location, message, eventData);
};
const warn = (location, message, eventData) => {
  sendData('warn', location, message, eventData);
};
const error = (location, message, eventData) => {
  sendData('error', location, message, eventData);
};
export default {
  info,
  warn,
  error
};