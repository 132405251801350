import { log } from '@atlassian/mpac-utils';

const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

const parseValue = (value) => {
  if (!Number.isNaN(Number(value)) && typeof value === 'string' && value.trim() !== '') {
    if (Number(value) > Number.MAX_SAFE_INTEGER) {
      value = BigInt(value);
    } else {
      value = Number(value);
    }
  } else if (
    value !== null &&
    (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')
  ) {
    value = value.toLowerCase() === 'true';
  }

  return value;
};

const queryString = (input) => {
  const ret = {};

  if (typeof input !== 'string') {
    return ret;
  }

  input = input.trim().replace(/^[?#&]/, '');

  if (!input) {
    return ret;
  }

  const params = input.split('&');
  for (let i = 0; i < params.length; i++) {
    // turn '+' into space
    params[i] = params[i].replace(/\+/g, ' ');
    params[i] = params[i].replace(/%2B/g, ' ');
    const index = params[i].indexOf('=');
    let key = params[i];
    let value;
    if (index >= 0) {
      key = params[i].slice(0, index);
      value = params[i].slice(index + 1);
    }

    // Missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    try {
      value = value === undefined ? null : decodeURIComponent(value);
      key = decodeURIComponent(key);
    } catch (e) {
      console.error('Error in decoding URI component');
    }
    if (ret[key] === undefined) {
      ret[key] = value;
    } else {
      ret[key] = [].concat(ret[key], value);
    }
  }

  Object.keys(ret).forEach((key) => {
    const value = ret[key];
    if (typeof value === 'object' && value !== null) {
      Object.keys(value).forEach((k) => {
        value[k] = parseValue(value[k]);
      });
    } else {
      ret[key] = parseValue(value);
    }
  });

  return ret;
};

const urlContainsEmail = (url) => {
  return decodeURIComponent(url).match(EMAIL_REGEX);
};

const dropEmailQueryParams = (obj) => {
  const sanitizedObj = {};
  Object.keys(obj).forEach((key) => {
    if (!urlContainsEmail(obj[key])) {
      sanitizedObj[key] = obj[key];
    }
  });
  return sanitizedObj;
};

const convertQueryObjToString = (obj) => {
  let qs = new URLSearchParams(obj).toString();
  if (qs !== '') {
    qs = '?' + qs;
  }
  return qs;
};

const dropEmailFromPath = (path) => {
  let newPath = '';
  if (urlContainsEmail(path)) {
    const pathArray = path.split('/');
    pathArray.forEach((value) => {
      if (value !== '' && !urlContainsEmail(value)) {
        newPath = newPath + '/' + value;
      }
    });
  }
  return newPath === '' ? path : newPath;
};

export const maskURL = (input: string | null) => {
  if (input === '' || input === undefined || input === null) {
    return input;
  }
  const url = new URL(input);
  let obj = queryString(url.search);
  obj = dropEmailQueryParams(obj);

  return url.origin + dropEmailFromPath(url.pathname) + convertQueryObjToString(obj);
};

const canonical = () => {
  const tags = document.getElementsByTagName('link');
  for (let i = 0, tag; (tag = tags[i]); i++) {
    if (tag.getAttribute('rel') === 'canonical') {
      return tag.getAttribute('href');
    }
  }
};

// taken from https://github.com/segmentio/analytics.js-core/blob/master/lib/pageDefaults.js
export const getCanonicalUrl = (url) => {
  const canon = canonical();
  if (canon) return canon;

  const i = url?.indexOf('#') || -1;
  return i === -1 ? url : url?.slice(0, i);
};

const isInitialized = () => {
  return !!window.optimizely && window.optimizely.initialized;
};

export const getOptimizelyCampaigns = () => {
  if (!isInitialized()) {
    return {};
  }

  const campaignStatesObject = {};
  const campaignStates = window.optimizely.get('state').getCampaignStates();

  // for each campaign state in object, transform data to desired format and add to object
  Object.keys(campaignStates).forEach((key) => {
    const campaign = campaignStates[key];

    const campaignObject: any = {
      isActive: campaign.isActive,
    };

    if (campaign.isActive) {
      // id of single active experiment
      campaignObject.experiment = {
        id: campaign.experiment ? campaign.experiment.id : null,
      };

      // array of all experiments, active or otherwise
      campaignObject.allExperiments = [];
      campaign.allExperiments.forEach((experiment) => {
        campaignObject.allExperiments.push({ id: experiment.id }); // add only the id for each experiment
      });

      // id of active variation
      campaignObject.variation = {
        id: campaign.variation ? campaign.variation.id : null,
      };

      campaignObject.isInCampaignHoldback = campaign.isInCampaignHoldback;
      campaignObject.audiences = campaign.audiences;
    }

    campaignStatesObject[key] = campaignObject;
  });

  return campaignStatesObject;
};

/**
 * Recursively masks email addresses found in any object properties.
 * @param {Object|Array} data The object or array to mask emails in.
 * @return {Object|Array} The new object or array with email addresses masked.
 */
export function maskEmails(data) {
  try {
    if (typeof data === 'string') {
      // Directly return the masked string if the data is a string
      return data.replace(EMAIL_REGEX, '****');
    } else if (Array.isArray(data)) {
      // Iterate through the array and mask each item
      return data.map((item) => maskEmails(item));
    } else if (typeof data === 'object' && data !== null) {
      // Iterate through the object and mask each value
      const result = {};
      Object.entries(data).forEach(([key, value]) => {
        result[key] = maskEmails(value); // Recursively mask emails
      });
      return result;
    }
    // Return the data as is if it's neither a string, array, nor object
    return data;
  } catch (error) {
    log.error('ERROR: caught exception while masking emails from analytics attributes', error);
    return {};
  }
}
