export const getCurrentNetworkStatus = () => {
  if (typeof window.navigator.onLine === 'boolean') {
    return {
      type: 'supported',
      online: window.navigator.onLine
    };
  }
  return {
    type: 'unsupported'
  };
};

/**
 * Get the breadcrumb to add to sentry when network status has changed
 * https://docs.sentry.io/product/issues/issue-details/breadcrumbs/#filtering-the-display */
export const getNetworkStatusBreadcrumb = online => {
  if (online) {
    return {
      type: 'info',
      category: 'network',
      level: 'info',
      message: 'Network status online'
    };
  }
  return {
    type: 'info',
    category: 'network',
    level: 'warning',
    message: 'Network status offline'
  };
};