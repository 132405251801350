import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AsyncPropsLoader from '../../AsyncPropsLoader';
import CodeSplitComponentInner from './CodeSplitComponentInner';

export const errorMessage = 'Looks like something is up with your network connection';
export default class CodeSplitComponent extends Component {
  static propTypes = {
    resolveAsyncProps: PropTypes.func.isRequired,
    syncProps: PropTypes.object,
  };

  render() {
    return (
      <AsyncPropsLoader
        // @ts-expect-error [MC-2850] - TS2769 - No overload matches this call.
        component={CodeSplitComponentInner}
        errorMessage={errorMessage}
        // @ts-expect-error [MC-2850] - TS2339 - Property 'syncProps' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
        syncProps={this.props.syncProps}
        // @ts-expect-error [MC-2850] - TS2339 - Property 'resolveAsyncProps' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
        resolveAsyncProps={this.props.resolveAsyncProps}
      />
    );
  }
}
