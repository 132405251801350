import { Record } from 'immutable';

import Link from './Link';

export type NamedLinkType = {
  key?: string;
  name?: string;
  link: {
    href: string;
  };
};

export default Record<NamedLinkType>({
  name: undefined,
  link: new Link(),
});
