import { useLocation } from '@atlassian/mpac-ui';
import { DOMUtils, hooks } from '@atlassian/mpac-utils';
import React, { useEffect } from 'react';

import type { DispatchProps, OwnProps } from '../container/BaseRouteHandlerContainer';

const { usePrevious } = hooks;

const BaseRouteHandler = (props: DispatchProps & OwnProps) => {
  const { pathname, search } = useLocation();
  const prevPathname = usePrevious(pathname);
  const prevSearch = usePrevious(search);

  const shouldScrollToTop = (
    scrollToTopOnPathChange: undefined | boolean,
    scrollToTopOnQueryParamChange: undefined | boolean
  ) =>
    (scrollToTopOnPathChange && prevPathname !== pathname) ||
    (scrollToTopOnQueryParamChange && prevSearch !== search);

  useEffect(() => {
    props.displayGeneralSearch(props.includeSearch);
    props.displayCollectionsFooter(props.includeFooter);
    props.displayPartnerQuickLinks(props.includePartnerQuickLinks);
  }, []);

  useEffect(() => {
    DOMUtils.scrollToDocumentTop();
  }, []);

  useEffect(() => {
    if (shouldScrollToTop(props.scrollToTopOnPathChange, props.scrollToTopOnQueryParamChange))
      DOMUtils.scrollToDocumentTop();
  }, [pathname, search]);

  return <>{props.children}</>;
};

export default BaseRouteHandler;
