import { browserMetrics, metrics } from '@atlassian/browser-metrics';
import { DOMUtils } from '@atlassian/mpac-utils';
import { version as reactVersion } from 'react';
import { getMark, getSSRConfig } from './browserMetrics.utils';
const initBrowserMetrics = _ref => {
  let {
    featureFlags,
    analyticsClient,
    version
  } = _ref;
  browserMetrics.init({
    info: {
      product: 'marketplace',
      app: {
        version: {
          web: version
        },
        framework: {
          name: 'react',
          version: reactVersion
        }
      },
      region: 'unknown'
    },
    endpoints: {
      eventPipelineClient: Promise.resolve({
        sendOperationalEvent: analyticsClient.sendOperationalEvent
      })
    },
    plugins: {
      featureFlags: {
        client: Promise.resolve({
          getValue: featureFlagName => featureFlags[featureFlagName]
        })
      },
      resourceTimings: {
        sanitiseEndpoints: url => url,
        mapResources: url => url
      }
    },
    // Custom properties sent to all events
    events: {
      all: {
        custom: {
          synthetic: DOMUtils.isSynthetic()
        }
      },
      WEB_VITALS: {
        enabled: true
      }
    },
    ssr: {
      getDoneMark: () => getMark('mpac.ssr-done')
    }
  });

  // https://developer.atlassian.com/platform/tool/browser-metrics/instrumentation/page-load/#page-load
  browserMetrics.startPageLoad({
    isInitial: true
  });
};
export const startPageLoad = browserMetrics.startPageLoad;
export const appListingPageLoad = metrics.pageLoad({
  key: 'app-listing',
  ssr: getSSRConfig()
});
export const discoverPageLoad = metrics.pageLoad({
  key: 'discover'
});
export const screenPageLoad = metrics.pageLoad({
  key: 'search'
});

// New pages
export const collectionPageLoad = metrics.pageLoad({
  key: 'collection-page'
});
export const categoryPageLoad = metrics.pageLoad({
  key: 'category-page'
});
export const homePageLoad = metrics.pageLoad({
  key: 'home-page'
});
export const searchPageLoad = metrics.pageLoad({
  key: 'search-page'
});
export const productPageLoad = metrics.pageLoad({
  key: 'product-page'
});
export const partnerProfilePageLoad = metrics.pageLoad({
  key: 'partner-profile-page'
});
export default initBrowserMetrics;