function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;

    script.addEventListener('load', () => resolve(script));
    script.addEventListener('error', () => reject(new Error(`Script load error for ${src}`)));

    document.head.append(script);
  });
}

// @ts-expect-error [MC-2850] - TS2339 - Property 'ATL_JQ_PAGE_PROPS' does not exist on type 'Window & typeof globalThis'.
window.ATL_JQ_PAGE_PROPS = {
  // @ts-expect-error [MC-2850] - TS2339 - Property 'ATL_JQ_PAGE_PROPS' does not exist on type 'Window & typeof globalThis'.
  ...window.ATL_JQ_PAGE_PROPS,
  '93bdbb52': {
    triggerFunction(showCollectorDialog) {
      // setTimeout will defer the loading of issue collectors to after all other function calls have completed.
      setTimeout(() => {
        showCollectorDialog();
      }, 1);
    },
  },
};

// Marketplace Feedback Issue Collector
const collectorUrl =
  'https://ecosystem.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/kdyoou/100014/c' +
  '/1000.0.10/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector' +
  '/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=93bdbb52';

const IssueCollector = {
  showCollector: (e) => {
    if (e) {
      e.preventDefault();
    }
    loadScript(collectorUrl);
  },
} as const;

export default IssueCollector;
