// IMPORTANT!!
//
// To add a new analytics subjectId or category, you MUST:
// 1) Add a const below representing the subject id (with prefix ANALYTICS_SUBJECT_ID_)
//    or category (prefix with ANALYTICS_CATEGORY_)
// 2) Export the const created in step 1

import { makeAnalyticsCategory } from '@atlassian/mpac-analytics';

import type { AnalyticsCategory } from '@atlassian/mpac-analytics';

// Analytics Categories
export const ANALYTICS_CATEGORY_APP_LISTING: AnalyticsCategory =
  makeAnalyticsCategory('appListing');

// Sources
export const ANALYTICS_EVENT_SOURCE_HOSTING_MODEL = 'selectHostingModel';
export const ANALYTICS_EVENT_SOURCE_APP_LISTING_PAGE = 'appListingPage';
export const ANALYTICS_EVENT_SOURCE_PRICING_TAB = 'pricingTab';
export const ANALYTICS_EVENT_CONTAINER_IN_PRODUCT_HELP = 'inProductHelp' as const;

// Analytics Containers
export const ANALYTICS_EVENT_CONTAINER_APP_HEADER = 'appHeader' as const;
export const ANALYTICS_EVENT_CONTAINER_SITE_SELECTOR = 'siteSelector' as const;
export const ANALYTICS_EVENT_CONTAINER_NAV_BAR = 'navBar' as const;
export const ANALYTICS_EVENT_CONTAINER_OVERVIEW_TAB = 'overviewTab' as const;
export const ANALYTICS_EVENT_CONTAINER_PRICING_TAB = 'pricingTab' as const;
export const ANALYTICS_EVENT_CONTAINER_PARTNER_QUICK_LINK = 'partnerQuickLink';
export const ANALYTICS_EVENT_CONTAINER_NOT_FOUND_404 = 'notFound404' as const;
export const ANALYTICS_EVENT_CONTAINER_FOOTER = 'footer' as const;

// Analytics Action Subject IDs
export const ANALYTICS_SUBJECT_ID_APP_LISTING_TAB = 'appListingTab' as const;
export const ANALYTICS_SUBJECT_ID_CTA = 'installButton' as const;
export const ANALYTICS_SUBJECT_ID_GET_MODAL_TRIGGER = 'getModalTrigger' as const;
export const ANALYTICS_SUBJECT_ID_TRY_MODAL_TRIGGER = 'tryModalTrigger' as const;
export const ANALYTICS_SUBJECT_ID_BUY_MODAL_TRIGGER = 'buyModalTrigger' as const;
export const ANALYTICS_SUBJECT_ID_HOSTING_SELECT = 'hostingSelect' as const;
export const ANALYTICS_SUBJECT_ID_REQUEST_APP = 'requestAppButton' as const;
export const ANALYTICS_SUBJECT_ID_REQUEST_APP_ADMIN = 'requestAppAdmin' as const;
export const ANALYTICS_SUBJECT_ID_CONTACT_VENDOR = 'contactVendor' as const;
export const ANALYTICS_SUBJECT_ID_CLOUD_MIGRATION_INFORMATION = 'migrationInformation' as const;
export const ANALYTICS_SUBJECT_ID_MIGRATION_ASSISTANCE = 'migrationAssistance' as const;
export const ANALYTICS_SUBJECT_ID_PARTNER_APPS_LINK = 'partnerAppsLink';
export const ANALYTICS_SUBJECT_ID_PARTNER_PROMOTIONS_LINK = 'partnerPromotionsLink';
export const ANALYTICS_SUBJECT_ID_PARTNER_REPORTS_LINK = 'partnerReportsLink';
export const ANALYTICS_SUBJECT_ID_PARTNER_ANNOUNCEMENTS_LINK = 'partnerAnnouncementsLink';
export const ANALYTICS_SUBJECT_ID_NOT_FOUND_404 = 'notFound404' as const;
export const ANALYTICS_SUBJECT_ID_RUSSIAN_USER_BLOCKED = 'blockedRussianUser' as const;
export const ANALYTICS_SUBJECT_ID_IN_PRODUCT_HELP = 'inProductHelp' as const;
export const ANALYTICS_SUBJECT_ID_RELATED_HELP = 'relatedHelp' as const;
export const ANALYTICS_SUBJECT_ID_HELP_RESOURCES = 'helpResources' as const;
export const ANALYTICS_SUBJECT_ID_FOOTER_LINK = 'footerLink' as const;
export const ANALYTICS_SUBJECT_ID_NAVBAR = 'navbar' as const;
export const ANALYTICS_SUBJECT_ID_NAVIGATION_ITEM_EXPAND = 'navigationItemExpanded' as const;
