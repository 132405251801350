import { RECEIVE_APP_CONFIG } from '../actions/App';
import GeneralLinks from '../records/GeneralLinks';

export default function GeneralLinksReducer(state = new GeneralLinks(), action) {
  switch (action.type) {
    case RECEIVE_APP_CONFIG:
      return action.appConfig.generalLinks;
    default:
      return state;
  }
}
