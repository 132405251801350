import { token } from '@atlaskit/tokens';
import { AtlassianCompactIcon, ExternalSubtleLink, HelpIcon } from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';
import React from 'react';

import { helpFullLinks, LAST_FOCUSABLE_MENU_ELEMENT } from '../constants';
import { EyebrowText } from '../ExpandedMenus.styles';

export const Links = styled.ul({
  listStyle: 'none',
  padding: 0,
  margin: 0,

  li: {
    margin: `0 0 ${token('space.150', '12px')}`,

    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export const ExternalSubtleLinkStyled = styled(ExternalSubtleLink)({
  display: 'flex',
  alignItems: 'center',
  gap: token('space.050', '4px'),

  '& path': {
    fill: token('color.icon'),
  },

  '&:hover path': {
    fill: 'currentColor',
  },
});

export const HelpfulLinks = ({ closeMenu }: { closeMenu: () => void }) => {
  return (
    <div>
      <EyebrowText role="heading" aria-level={2}>
        You might find helpful
      </EyebrowText>
      <Links>
        <li>
          <ExternalSubtleLinkStyled
            href={helpFullLinks.exploreAtlassianProducts.href}
            size="small"
            aria-label={helpFullLinks.exploreAtlassianProducts.text}
            onClick={closeMenu}
          >
            <AtlassianCompactIcon />
            <span>{helpFullLinks.exploreAtlassianProducts.text}</span>
          </ExternalSubtleLinkStyled>
        </li>
        <li>
          <ExternalSubtleLinkStyled
            href={helpFullLinks.learnAboutMarketplace.href}
            size="small"
            aria-label={helpFullLinks.learnAboutMarketplace.text}
            onClick={closeMenu}
          >
            <AtlassianCompactIcon />
            <span>{helpFullLinks.learnAboutMarketplace.text}</span>
          </ExternalSubtleLinkStyled>
        </li>
        <li>
          <ExternalSubtleLinkStyled
            href={helpFullLinks.getSupport.href}
            size="small"
            onClick={closeMenu}
            aria-label={helpFullLinks.getSupport.text}
            id={LAST_FOCUSABLE_MENU_ELEMENT}
          >
            <HelpIcon />
            <span>{helpFullLinks.getSupport.text}</span>
          </ExternalSubtleLinkStyled>
        </li>
      </Links>
    </div>
  );
};
