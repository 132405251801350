import { MarketplaceAnalyticsContext } from '@atlassian/mpac-analytics';
import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../Routes/components/common/routeHandlers/BaseRouteHandler';
import { Analytics } from './constants/analytics';

const CollectionPageCodeSplit = lazyForPaint(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "chunk-collectionPage" */ './CollectionPage'
  ).then((module) => module.default)
);

const RouteHandler = () => {
  // TODO: MC-4631 - Handle suspense fallback
  return (
    <BaseRouteHandler scrollToTopOnPathChange includePartnerQuickLinks>
      <MarketplaceAnalyticsContext properties={{ source: Analytics.source }}>
        <LazySuspense fallback={<div />}>
          <CollectionPageCodeSplit />
        </LazySuspense>
      </MarketplaceAnalyticsContext>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
