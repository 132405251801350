import { Record } from 'immutable';

import LinkTemplate from '../../common/records/LinkTemplate';

export class VendorDetailsLinks extends Record({
  vendorAddonsRest: new LinkTemplate(),
  vendorArchivedAddonsRest: new LinkTemplate(),
  vendorDetailsPage: new LinkTemplate(),
  vendorDetailsRest: new LinkTemplate(),
}) {}

export class VendorDetailsMetricsKeys extends Record({
  vendorDetails: '',
}) {}

export default class VendorDetailsConfig extends Record({
  links: new VendorDetailsLinks(),
  metricsKeys: new VendorDetailsMetricsKeys(),
}) {}

export const resolveVendorDetailsConfig = ({ appConfig }) =>
  new VendorDetailsConfig({
    links: new VendorDetailsLinks({
      vendorAddonsRest: new LinkTemplate({
        href: appConfig.getIn(['vendorDetails', 'links', 'vendorAddonsRest', 'href']),
      }),
      vendorArchivedAddonsRest: new LinkTemplate({
        href: appConfig.getIn(['vendorDetails', 'links', 'vendorArchivedAddonsRest', 'href']),
      }),
      vendorDetailsPage: new LinkTemplate({
        href: appConfig.getIn(['vendorDetails', 'links', 'vendorDetailsPage', 'href']),
      }),
      vendorDetailsRest: new LinkTemplate({
        href: appConfig.getIn(['vendorDetails', 'links', 'vendorDetailsRest', 'href']),
      }),
    }),
    metricsKeys: new VendorDetailsMetricsKeys({
      vendorDetails: appConfig.getIn(['vendorDetails', 'metricsKeys', 'vendorDetails']),
    }),
  });
