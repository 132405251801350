import { ContentContainer } from '@atlassian/amkt-common';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const initialState = {
  asyncProps: undefined,
  err: false,
} as const;

export default class AsyncPropsLoader extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    syncProps: PropTypes.object,
    resolveAsyncProps: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: "Looks like we weren't able to resolve some data",
    syncProps: {},
  };

  state = initialState;

  componentDidMount() {
    this.resolveAsyncProps(this.props);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resolveAsyncProps !== prevState.resolveAsyncProps) {
      return { ...initialState, resolveAsyncProps: nextProps.resolveAsyncProps };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    // @ts-expect-error [MC-2850] - TS2339 - Property 'resolveAsyncProps' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
    if (this.props.resolveAsyncProps !== prevProps.resolveAsyncProps) {
      this.resolveAsyncProps(this.props);
    }
  }

  resolveAsyncProps(props) {
    props.resolveAsyncProps().then(
      (ap) => {
        this.setState({ asyncProps: ap });
      },
      (error) => {
        console.error(error);
        this.setState({ err: true });
      }
    );
  }

  render() {
    // @ts-expect-error [MC-2850] - TS2339 - Property 'component' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
    const ComponentPassed = this.props.component;
    return (
      <ContentContainer
        dataError={this.state.err}
        // @ts-expect-error [MC-2850] - TS2769 - No overload matches this call. | TS2339 - Property 'errorMessage' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
        dataErrorMessages={List([Map({ message: this.props.errorMessage })])}
        loading={!this.state.err && !this.state.asyncProps}
        optimisticLoadTime={200}
      >
        {this.state.asyncProps ? (
          // @ts-expect-error [MC-2850] - TS2339 - Property 'syncProps' does not exist on type 'Readonly<{}> & Readonly<{ children?: ReactNode; }>'. | TS2698 - Spread types may only be created from object types.
          <ComponentPassed {...this.props.syncProps} {...this.state.asyncProps} />
        ) : undefined}
      </ContentContainer>
    );
  }
}
