import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { UrlUtils } from '@atlassian/mpac-utils';
import * as queryString from 'query-string';
import { useContext } from 'react';
import RouterContext from '../RouterContext';
/**
 * Get location details
 */

export function useLocation() {
  const {
    location
  } = useContext(RouterContext);
  return location;
}

/**
 * Similar to using `window.location.href` but uses react-router instead.
 * Compatible with SSR since we avoid reading for window.
 */
export const useLocationHref = () => {
  const location = useLocation();
  if (typeof location === 'string') return location;
  const {
    pathname,
    search
  } = location;
  return "".concat(pathname).concat(search);
};

/**
 * Get history object
 */
export function useHistory() {
  const {
    history
  } = useContext(RouterContext);
  return history;
}

/**
 * Get query params
 */
export function useParams() {
  const {
    match
  } = useContext(RouterContext);
  return match ? match.params : {};
}

/**
 * Get parsed URL query params
 */
export function useQueryParams() {
  const {
    search
  } = useLocation();
  return queryString.parse(search || '');
}

/**
 * This hook will look for a query param in the url.
 * If present, it will swallow (remove) the query param
 * and its value from the browser URL.
 * It returns the current value of the query param, if present
 */
export function useSwallowQueryParam(queryParam) {
  const queryParams = useQueryParams();
  const history = useHistory();
  const currentPath = window.location.href;
  const updatedPath = UrlUtils.unsetQueryParam(window.location.href, queryParam);
  if (!currentPath.endsWith(updatedPath)) {
    history === null || history === void 0 ? void 0 : history.replace(updatedPath);
  }
  return queryParams[queryParam];
}
/**
 * Validates values of query parameters for a route, and
 * sets defaults if validations fail
 */
export const useValidateQueryParams = paramValidation => {
  const {
    search: oldSearch,
    pathname
  } = useLocation();
  const queryParams = queryString.parse(oldSearch || '');
  const updatedQueryParams = _objectSpread({}, queryParams);
  let hasInvalidOrMissingParams = false;
  Object.keys(paramValidation).forEach(key => {
    const {
      validate,
      defaultValue
    } = paramValidation[key];
    const originalValue = queryParams[key];
    const isMissingValue = typeof originalValue === 'undefined' && typeof defaultValue !== 'undefined';
    const isValidValue = validate ? validate(originalValue) : true;
    if (isMissingValue || !isValidValue) {
      hasInvalidOrMissingParams = true;
      updatedQueryParams[key] = defaultValue;
    }
  });
  const updatedQueryString = queryString.stringify(updatedQueryParams);
  const updatedSearch = updatedQueryString ? "?".concat(updatedQueryString) : '';
  const redirectRoute = hasInvalidOrMissingParams ? pathname + updatedSearch : null;
  return {
    redirectRoute,
    queryParams: updatedQueryParams
  };
};