import React from 'react';

import { categories, dataTestIds, FIRST_FOCUSABLE_MENU_ELEMENT } from '../../constants';
import { DescriptiveItem } from '../../DescriptiveItem';
import { DescriptiveItemsContainer, EyebrowText } from '../../ExpandedMenus.styles';
import { ExpandedNavItemLayout } from '../../ExpandedNavItemLayout';
import { WhatsNew } from '../../WhatsNew';

export const Categories = ({
  closeMenu,
  focusFirstLinkUnderMenu,
}: {
  closeMenu: () => void;
  focusFirstLinkUnderMenu?: React.RefObject<HTMLAnchorElement>;
}) => {
  const primaryContent = (
    <>
      <EyebrowText role="heading" aria-level={2}>
        Categories
      </EyebrowText>
      <DescriptiveItemsContainer>
        {categories.map((category, index) => (
          <DescriptiveItem
            id={index === 0 ? FIRST_FOCUSABLE_MENU_ELEMENT : undefined}
            key={category.id}
            iconUrl={category.icon}
            name={category.name}
            description={category.description}
            href={`/categories/${category.id}`}
            closeMenu={closeMenu}
            focusFirstLinkUnderMenu={focusFirstLinkUnderMenu}
          />
        ))}
      </DescriptiveItemsContainer>
    </>
  );

  return (
    <ExpandedNavItemLayout
      dataTestId={dataTestIds.EXPANDABLE_MENU__CATEGORIES}
      primaryContent={primaryContent}
      secondaryContent={<WhatsNew closeMenu={closeMenu} />}
    />
  );
};
