import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { mediaBreakpoints } from '@atlassian/mpac-ui/theme';
import styled from '@emotion/styled';

const HeaderCommon = `
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  display: none;
`;

export const DesktopWrapper = styled.div`
  ${HeaderCommon}
  align-items: center;
  flex-direction: row;
  ${mediaBreakpoints.mediumUp`
    display: flex;
  `}
`;

export const TabletWrapper = styled.div`
  ${HeaderCommon}
  ${mediaBreakpoints.mediumOnly`
    display: flex;
  `}
`;

export const MobileWrapper = styled.div`
  ${HeaderCommon}
  ${mediaBreakpoints.smallDown`
    display: flex;
  `}
`;

export const WrappedLogoMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AtlassianHeaderLogo = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: ${token('space.250', '20px')} ${token('space.250', '20px')};

  a {
    display: flex;
    height: 30px;
    min-width: 240px;
    max-width: 300px;
  }
`;

export const BranchDeployIndicator = styled.span`
  ${mediaBreakpoints.smallDown`
    display: none;
  `}
  padding: 0px 10px;

  button {
    background-color: ${N0};
    border-radius: 50%;
    padding: 3px;

    &:focus,
    &:hover,
    &:active {
      background-color: ${N0};
    }
  }
`;

export const AtlassianHeaderLogoImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const TextSearchContainer = styled.div`
  margin-left: ${token('space.250', '20px')};
  div {
    ${mediaBreakpoints.mediumDown`
      margin-bottom: 10px;
    `}
  }
`;
