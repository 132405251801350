import { ANALYTICS_SCREEN_CREATE_PROMOTION, ANALYTICS_SCREEN_MANAGE_PARTNER_ADMIN, ANALYTICS_SCREEN_MANAGE_PARTNER_APPS, ANALYTICS_SCREEN_MANAGE_PARTNER_AUDIT_LOGS, ANALYTICS_SCREEN_MANAGE_PARTNER_CONTACTS, ANALYTICS_SCREEN_MANAGE_PARTNER_DETAILS, ANALYTICS_SCREEN_MANAGE_PARTNER_PAYMENT_INFO, ANALYTICS_SCREEN_MANAGE_PARTNER_REPORT_DETAILS, ANALYTICS_SCREEN_MANAGE_PARTNER_REPORTS, ANALYTICS_SCREEN_MANAGE_PARTNER_SECURITY, ANALYTICS_SCREEN_MANAGE_PROMOTIONS, ANALYTICS_SCREEN_PROMOTION_DETAILS, ANALYTICS_SCREEN_PROMOTION_DETAILS_CODES, ANALYTICS_SCREEN_PROMOTION_DETAILS_PRICING } from '../analytics/Sources';
export const managePartnersBaseRoute = '/manage/vendors/:partnerId(\\d+)';
export const promotionDetailsBaseRoute = "".concat(managePartnersBaseRoute, "/promos/:promotionId");
export const partnerDetailsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_DETAILS,
  path: "".concat(managePartnersBaseRoute, "/details"),
  isNestedRoute: true
};
export const partnerContactsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_CONTACTS,
  path: "".concat(managePartnersBaseRoute, "/contacts"),
  isNestedRoute: true
};
export const promotionsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PROMOTIONS,
  path: "".concat(managePartnersBaseRoute, "/promos"),
  isNestedRoute: true
};
export const createPromotionRoute = {
  name: ANALYTICS_SCREEN_CREATE_PROMOTION,
  path: "".concat(managePartnersBaseRoute, "/promos/create"),
  isNestedRoute: true
};
export const promotionDetailsRoute = {
  name: ANALYTICS_SCREEN_PROMOTION_DETAILS,
  path: "".concat(managePartnersBaseRoute, "/promos/:promotionId/details"),
  isNestedRoute: true
};
export const promotionDetailsCodesRoute = {
  name: ANALYTICS_SCREEN_PROMOTION_DETAILS_CODES,
  path: "".concat(managePartnersBaseRoute, "/promos/:promotionId/codes"),
  isNestedRoute: true
};
export const promotionDetailsPricingRoute = {
  name: ANALYTICS_SCREEN_PROMOTION_DETAILS_PRICING,
  path: "".concat(managePartnersBaseRoute, "/promos/:promotionId/pricing"),
  isNestedRoute: true
};
export const partnerAdminRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_ADMIN,
  path: "".concat(managePartnersBaseRoute, "/admin"),
  isNestedRoute: true
};
export const partnerPaymentInfoRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_PAYMENT_INFO,
  path: "".concat(managePartnersBaseRoute, "/payment"),
  isNestedRoute: true
};
export const partnerSecurityRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_SECURITY,
  path: "".concat(managePartnersBaseRoute, "/security"),
  isNestedRoute: true
};
export const partnerAppsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_APPS,
  path: "".concat(managePartnersBaseRoute, "/addons"),
  isNestedRoute: true
};
export const partnerReportsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_REPORTS,
  path: "".concat(managePartnersBaseRoute, "/reporting/:reportName"),
  isNestedRoute: true
};
export const partnerAuditLogsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_AUDIT_LOGS,
  path: "".concat(managePartnersBaseRoute, "/auditlogs"),
  isNestedRoute: true
};
export const partnerReportDetailsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_PARTNER_REPORT_DETAILS,
  path: "".concat(managePartnersBaseRoute, "/report/:reportName/details/:text"),
  isNestedRoute: true
};
export const allManagePartnersRoutes = [promotionsRoute, createPromotionRoute, promotionDetailsRoute, promotionDetailsCodesRoute, promotionDetailsPricingRoute, partnerAdminRoute, partnerDetailsRoute, partnerContactsRoute, partnerPaymentInfoRoute, partnerSecurityRoute, partnerAppsRoute, partnerReportsRoute, partnerAuditLogsRoute, partnerReportDetailsRoute];