import cssReset from '@atlaskit/css-reset/styles';
import { N0, N20, N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { css } from '@emotion/core';
import { Global } from '@emotion/react';
import React from 'react';

import type { ReactNode } from 'react';

import AtlassianLogoFullWhite from '../assets/images/AtlassianLogoFullWhite.svg';

/**
 * Includes
 * - Atlaskit CSS Reset to provide extremely basic styling
 * - Some styles from html, body
 * - Misc global styles
 */
const globalStyles = css`
  ${cssReset}

  html,
  body.app {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  body.app {
    background: ${token('elevation.surface', N0)};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;

    header[role='banner'] {
      margin-bottom: 0;
      min-width: 0;
    }

    section[role='main'] {
      flex: 1 0 auto;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /** Bootstrap data from the server. These elements are removed from the DOM on page load. */
  .data[data-id] {
    display: none;
  }
`;

/**
 * Styles for the site footer which contains Atlassian logo & few static links.
 * These are part of the inital static HTML and hence is styled with global styles.
 */
const siteFooterStyles = css`
  footer[role='contentinfo'] {
    background-color: ${N20};
    flex: 0 0 auto;
    padding-top: ${token('space.250', '20px')};
    padding-bottom: ${token('space.250', '20px')};
    width: 100%;

    &,
    a {
      color: ${N300};
    }

    .logo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .bottom-logo {
      background: url('${AtlassianLogoFullWhite}') no-repeat;
      background-repeat: no-repeat;
      margin-left: 30px;
      width: 160px;
      height: 20px;
    }

    section {
      height: 40px;

      nav {
        float: right;

        ul {
          li {
            list-style: none;
            display: inline-block;
            white-space: nowrap;
            margin: 0 30px 0 0;
          }
        }
      }
    }
  }
`;

export const GlobalStyles = ({ children }: { children?: ReactNode }) => (
  <>
    <Global styles={globalStyles} />
    <Global styles={siteFooterStyles} />
    {children}
  </>
);
