import Lozenge from '@atlaskit/lozenge';
import { Link } from '@atlassian/amkt-common';
import ImmutablePropTypes from '@atlassian/react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  LozengeWrapper,
  MenuList,
  MenuListItem,
  MenuSectionContainer,
  MenuSectionHeader,
} from './styles';

export default class MenuSection extends Component {
  static propTypes = {
    header: PropTypes.node,
    items: ImmutablePropTypes.contains({
      content: PropTypes.string,
      href: PropTypes.string,
      target: PropTypes.string,
      onActivate: PropTypes.func,
      showNewLozenge: PropTypes.bool,
    }).isRequired,
    className: PropTypes.string,
    onItemActivated: PropTypes.func,
  };

  handleItemActivatedFactory = (item) => (e) => {
    // @ts-expect-error [MC-2850] - TS2532 - Object is possibly 'undefined'. | TS2304 - Cannot find name 'item'.
    this.props.onItemActivated(item);
    const itemOnActivate = item.get('onActivate');
    if (itemOnActivate) {
      itemOnActivate(e);
    }
  };

  render() {
    return (
      // @ts-expect-error [MC-2850]
      <MenuSectionContainer className={this.props.className} role="navigation">
        {/* @ts-expect-error [MC-2850] */}
        {this.props.header ? <MenuSectionHeader>{this.props.header}</MenuSectionHeader> : null}
        <MenuList>
          {/* @ts-expect-error [MC-2850] */}
          {this.props.items.map((i) => (
            <MenuListItem key={i.get('content')}>
              <Link
                href={i.get('href')}
                onClick={this.handleItemActivatedFactory(i)}
                target={i.get('target')}
              >
                {i.get('content')}
              </Link>
              {i.get('showNewLozenge') && (
                <LozengeWrapper>
                  <Lozenge appearance="new">New</Lozenge>
                </LozengeWrapper>
              )}
            </MenuListItem>
          ))}
        </MenuList>
      </MenuSectionContainer>
    );
  }
}
