export function maybeOpenInNewTab(e, execWhenNotNewTabAction) {
  if (!e) {
    execWhenNotNewTabAction();
  } else if (!e.ctrlKey && !e.metaKey && !e.shiftKey && e.button !== 1) {
    e.preventDefault();
    execWhenNotNewTabAction();
  }
}
// External links need to open outside of the app window
export const setTargetForAllLinks = (ele, target) => {
  if (ele) {
    const links = [...ele.getElementsByTagName('a')];
    links.forEach(a => {
      a.setAttribute('target', target);
      if (target === '_blank') {
        a.setAttribute('rel', 'noopener noreferrer');
      }
    });
  }
};