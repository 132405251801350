import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Observable } from '@apollo/client';
/**
 * Details:
 * Both `primary`(after optionally transforming the given request) and `custom` calls are made together, we wait for both the calls to resolve before calling the
 * `transformResponse` fn with both the data.
 * The result of both primary and custom calls are passed back to the `transformResponse` function for data massaging.
 *
 * Usecase:
 * When `primary` and `custom` calls are independent of each other and can be made in parallel but data needs to be
 * returned in a joined(primary + custom) fashion.
 *
 * Call sequence:
 *                      fetchCustom->
 *                                    | -> transformResponse
 * transformRequest -> fetchPrimary ->
 */
function parallelSequence(operation, forward, sequenceConfig, handler) {
  return new Observable(observer => {
    const newOperation = handler.requestTransformer(operation, sequenceConfig);
    const fetchCustomPromise = handler.fetchCustom(operation, newOperation, sequenceConfig, null);

    // In case the fetchCustomPromise rejects earlier than fetchPrimary.
    fetchCustomPromise.catch(err => {
      observer.error(err);
      // stop listening for response from fetchPrimary
      handler$.unsubscribe();
    });
    const handler$ = handler.fetchPrimary(newOperation, forward).subscribe({
      async next(data) {
        const primaryResponse = data.data;
        try {
          const customResponse = await fetchCustomPromise;
          const queryResponse = handler.responseTransformer(operation, newOperation, sequenceConfig, primaryResponse, customResponse);
          observer.next(_objectSpread(_objectSpread({}, data), {}, {
            data: queryResponse
          }));
          observer.complete();
        } catch (err) {
          observer.error(err);
        }
      },
      error(err) {
        observer.error(err);
      }
    });
    return () => {
      handler$.unsubscribe();
    };
  });
}
export { parallelSequence };