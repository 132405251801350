import { onLoadingComplete, onLoadingStart } from '../actions/App';

export default function commonMapDispatchToProps(dispatch) {
  return {
    onLoadingStart: ({ metricsKey }) => {
      dispatch(onLoadingStart({ metricsKey }));
    },
    onLoadingComplete: ({ metricsKey }) => {
      dispatch(onLoadingComplete({ metricsKey }));
    },
  };
}
