import { fromJS } from 'immutable';

import { DATA_ERROR, RECEIVE_USER_DATA } from 'app/actions/UserData';

const initialState = {
  data: {},
  dataError: false,
  dataErrorMessages: [],
  loading: true,
} as const;

export default function UserDataReducer(state = fromJS(initialState), action) {
  switch (action.type) {
    case RECEIVE_USER_DATA:
      return state.set('data', action.data).set('loading', false);
    case DATA_ERROR:
      return state
        .set('dataError', true)
        .set('dataErrorMessages', action.dataErrorMessages)
        .set('loading', false);
    default:
      return state;
  }
}
