import Heartbeat from '@atlassian/heartbeat';
import React from 'react';

import useLoggedInUser from '../../app/hooks/useLoggedInUser';

const IdentityHeartBeat = () => {
  const { isLoggedIn } = useLoggedInUser();

  if (!isLoggedIn) {
    return null;
  }

  return <Heartbeat endpoint="/gateway/api/session/heartbeat" />;
};

export default IdentityHeartBeat;
