import { Record } from 'immutable';

import {
  dataErrorGenerator,
  defaultData,
  receiveDataGenerator,
  retrieveDataGenerator,
} from '../../../../../../common/utils/reducerUtils';
import { RECEIVE_APP_CONFIG } from '../../../../../actions/App';
import VendorDetailsConfig from '../../../../../records/VendorDetailsConfig';
import {
  DATA_ERROR_VENDOR_ARCHIVED_ADDONS,
  DATA_ERROR_VENDOR_DETAILS,
  DATA_ERROR_VENDOR_HAS_CLOUD_ADDONS,
  RECEIVE_VENDOR_ARCHIVED_ADDONS,
  RECEIVE_VENDOR_DETAILS,
  RECEIVE_VENDOR_HAS_CLOUD_ADDONS,
  RETRIEVE_VENDOR_ARCHIVED_ADDONS,
  RETRIEVE_VENDOR_DETAILS,
  RETRIEVE_VENDOR_HAS_CLOUD_ADDONS,
  VENDOR_ADDONS_LIST_RENDER_COMPLETE,
} from '../actions/VendorDetails';

const VendorDetailsState = Record({
  config: new VendorDetailsConfig(),
  vendorAddonsListRenderComplete: false,
  vendorArchivedAddons: defaultData,
  vendorDetails: defaultData,
  vendorHasCloudAddons: defaultData,
});

const onRetrieveVendorDetails = retrieveDataGenerator('vendorDetails');
const onReceiveVendorDetails = receiveDataGenerator(
  'vendorDetails',
  (action) => action.vendorDetails
);
const onDataErrorVendorDetails = dataErrorGenerator('vendorDetails');

const onRetrieveVendorHasCloudAddons = retrieveDataGenerator('vendorHasCloudAddons');
const onReceiveVendorHasCloudAddons = receiveDataGenerator(
  'vendorHasCloudAddons',
  (action) => action.vendorHasCloudAddons
);
const onDataErrorVendorHasCloudAddons = dataErrorGenerator('vendorHasCloudAddons');

const onRetrieveVendorArchivedAddons = retrieveDataGenerator('vendorArchivedAddons');
const onReceiveVendorArchivedAddons = receiveDataGenerator(
  'vendorArchivedAddons',
  (action) => action.vendorArchivedAddons
);
const onDataErrorVendorArchivedAddons = dataErrorGenerator('vendorArchivedAddons');

export default function VendorDetailsReducer(state = new VendorDetailsState(), action) {
  switch (action.type) {
    case RECEIVE_APP_CONFIG:
      return state.set('config', action.appConfig.vendorDetails);
    case RETRIEVE_VENDOR_DETAILS:
      return onRetrieveVendorDetails(state);
    case RECEIVE_VENDOR_DETAILS:
      return onReceiveVendorDetails(state, action);
    case DATA_ERROR_VENDOR_DETAILS:
      return onDataErrorVendorDetails(state, action);
    case RETRIEVE_VENDOR_HAS_CLOUD_ADDONS:
      return onRetrieveVendorHasCloudAddons(state);
    case RECEIVE_VENDOR_HAS_CLOUD_ADDONS:
      return onReceiveVendorHasCloudAddons(state, action);
    case DATA_ERROR_VENDOR_HAS_CLOUD_ADDONS:
      return onDataErrorVendorHasCloudAddons(state, action);
    case RETRIEVE_VENDOR_ARCHIVED_ADDONS:
      return onRetrieveVendorArchivedAddons(state);
    case RECEIVE_VENDOR_ARCHIVED_ADDONS:
      return onReceiveVendorArchivedAddons(state, action);
    case DATA_ERROR_VENDOR_ARCHIVED_ADDONS:
      return onDataErrorVendorArchivedAddons(state, action);
    case VENDOR_ADDONS_LIST_RENDER_COMPLETE:
      return state.set('vendorAddonsListRenderComplete', true);
    default:
      return state;
  }
}
