import { managePartnerBaseLink } from '@atlassian/mpac-utils';

import type {
  AnonymousUserLinks,
  AuthenticatedUserLinks,
  MPACCurrentUserResponse,
} from '../../../../common/utils/user-data';
import type { UserLinks } from './userTypes';

const baseLink = managePartnerBaseLink();

export function getLinksFromUserResponse(userResponse: MPACCurrentUserResponse): UserLinks {
  const authenticatedLinks = userResponse._links as AuthenticatedUserLinks;
  const anonymousUserLinks = userResponse._links as AnonymousUserLinks;

  const lastVisitedPartnerId = userResponse._embedded?.lastVisited?.lastVisitedPartnerId;
  return {
    login: anonymousUserLinks?.login?.href,
    logout: authenticatedLinks?.logout?.href,
    switchAccount: authenticatedLinks?.switchAccount?.href,
    addons: authenticatedLinks?.addons?.href,
    admin: authenticatedLinks?.admin?.href,
    createAddon: authenticatedLinks?.createAddon?.href,
    atlassianAccount: authenticatedLinks?.atlassianAccount?.href,
    createVendor: '/manage/vendor/create',
    managePartner: lastVisitedPartnerId
      ? baseLink.expand({
          id: lastVisitedPartnerId,
        })
      : undefined,
  };
}
