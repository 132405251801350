import React from 'react';

import { APP_LISTING_HOME_LINK } from '../../../app/components/Routes/components/AppListingRoutes/constants';
import NotFound from '../NotFound';

import type { ReactNode } from 'react';

type RoutePermissionHandlerProps = {
  hasPermission: boolean;
  children: ReactNode;
};

const RoutePermissionHandler = ({ children, hasPermission }: RoutePermissionHandlerProps) =>
  hasPermission ? <>{children}</> : <NotFound homeLink={APP_LISTING_HOME_LINK} />;

export default RoutePermissionHandler;
