import Avatar from '@atlaskit/avatar';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Lozenge from '@atlaskit/lozenge';
import { Section } from '@atlaskit/menu';
import { type Map, fromJS } from 'immutable';
import React, { useCallback, useMemo } from 'react';

import {
  transformToUserProps,
  useResolvedCurrentUser,
} from '../../../../../../../app/context/CurrentUserContext';
import * as analytics from '../../../../../../utils/analytics/analytics';
import { userDropdownItemsWithSwitchProfile } from '../../../../constants/dropdownGroups';
import {
  AvatarWrapper,
  DetailsWrapper,
  DisplayName,
  LozengeWrapper,
  ProfileWrapper,
  UserMenu,
  UserMenuDropdownTrigger,
} from './UserDropdown.styles';

type UserDropdownProps = {
  onClickLogout: () => void;
  userAvatarUrl: string;
  // @ts-expect-error [MC-2850] - TS2314 - Generic type 'Map<K, V>' requires 2 type argument(s).
  userData: Map;
  onClickSwitchAccount: () => void;
};

const UserDropdown = ({
  onClickLogout,
  userAvatarUrl,
  userData,
  onClickSwitchAccount,
}: UserDropdownProps) => {
  const { loggedInUser } = useResolvedCurrentUser({
    userProps: transformToUserProps(fromJS({ data: userData })),
  });

  const onItemActivate = useCallback((menuItem) => {
    analytics.trackUserActionClicked(menuItem.content);
    if (menuItem.onActivate) {
      menuItem.onActivate();
    }
  }, []);

  const renderAccountDetails = () => {
    return (
      <ProfileWrapper>
        <AvatarWrapper>
          <Avatar appearance="circle" size="medium" src={loggedInUser?.picture} />
        </AvatarWrapper>
        <DetailsWrapper>
          <DisplayName>{loggedInUser?.name}</DisplayName>
          <small>{loggedInUser?.email}</small>
        </DetailsWrapper>
      </ProfileWrapper>
    );
  };

  const renderDropdownItems = useCallback((dropdownItems) => {
    return dropdownItems.map((item) => (
      <DropdownItem key={item.href} href={item.href} onClick={() => onItemActivate(item)}>
        {item.showProfileDetails ? renderAccountDetails() : item.content}
        {item.showNewBadge && (
          <LozengeWrapper>
            {' '}
            <Lozenge appearance="new">New</Lozenge>
          </LozengeWrapper>
        )}
      </DropdownItem>
    ));
  }, []);

  const renderDropdownItemGroups = useCallback((itemGroups) => {
    return itemGroups.map((itemGroup) => (
      <Section hasSeparator key={itemGroup.heading}>
        <DropdownItemGroup title={itemGroup.heading}>
          {renderDropdownItems(itemGroup.items)}
        </DropdownItemGroup>
      </Section>
    ));
  }, []);

  const items = useMemo(
    () =>
      userDropdownItemsWithSwitchProfile.buildItems(
        onClickLogout,
        onClickSwitchAccount,
        loggedInUser
      ),
    [userAvatarUrl, onClickLogout, onClickSwitchAccount]
  );

  return (
    <UserMenu>
      <DropdownMenu
        appearance="tall"
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <UserMenuDropdownTrigger ref={triggerRef} tabIndex={0} {...props}>
            <Avatar src={loggedInUser?.picture} size="small" />
          </UserMenuDropdownTrigger>
        )}
      >
        {renderDropdownItemGroups(items)}
      </DropdownMenu>
    </UserMenu>
  );
};

export default UserDropdown;
