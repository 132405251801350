import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { saveAdminData } from '@atlassian/mpac-api-client';
import { BaseHandler } from '../../../graphql-orchestrator-link';
export class AppAdminSubmitFormHandler extends BaseHandler {
  constructor(mpacBaseUrl) {
    super();
    _defineProperty(this, "mpacBaseUrl", void 0);
    this.mpacBaseUrl = mpacBaseUrl;
  }
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    try {
      const {
        appKey,
        payload
      } = operation.variables;
      const response = await saveAdminData(this.mpacBaseUrl, appKey, payload);
      if (response.ok) return {
        errors: null
      };else return {
        errors: ['Unknown Error']
      };
    } catch (error) {
      return error.error.json();
    }
  }
  responseTransformer(_operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    if (customResponse && customResponse !== null && customResponse !== void 0 && customResponse.errors) {
      throw new Error(JSON.stringify(customResponse === null || customResponse === void 0 ? void 0 : customResponse.errors));
    }
    return {
      marketplaceConsoleAdminSaveForm: {
        success: true
      }
    };
  }
}