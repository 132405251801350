import { connect } from 'react-redux';

import {
  displayCollectionsFooter,
  displayGeneralSearch,
  displayPartnerQuickLinks,
} from '../../../../../../../actions/App';
import BaseRouteHandler from '../components/BaseRouteHandler';

import type { ReactNode } from 'react';
// @ts-expect-error [MC-2850] - TS2305 - Module '"react-redux"' has no exported member 'ConnectedComponentClass'.
import type { ConnectedComponentClass } from 'react-redux';

export type DispatchProps = {
  displayGeneralSearch: (allow: boolean) => void;
  displayPartnerQuickLinks: (allow: boolean) => void;
  displayCollectionsFooter: (allow: boolean) => void;
};

export type OwnProps = {
  includeFooter: boolean;
  includeSearch: boolean;
  includePartnerQuickLinks: boolean;
  children: ReactNode;
  scrollToTopOnPathChange?: boolean;
  scrollToTopOnQueryParamChange?: boolean;
};

const mapDispatchToProps = (dispatch) => ({
  displayGeneralSearch: (show: boolean) => {
    dispatch(displayGeneralSearch(show));
  },
  displayPartnerQuickLinks: (show: boolean) => {
    dispatch(displayPartnerQuickLinks(show));
  },
  displayCollectionsFooter: (show: boolean) => {
    dispatch(displayCollectionsFooter(show));
  },
});

export default connect(null, mapDispatchToProps)(BaseRouteHandler) as ConnectedComponentClass<
  OwnProps,
  DispatchProps
>;
