import Button from '@atlaskit/button';
import BitbucketBranchesIcon from '@atlaskit/icon/glyph/bitbucket/branches';
import { B300 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip';
import { Link } from '@atlassian/amkt-common';
import { LinkUtils } from '@atlassian/mpac-utils';
import React from 'react';

import {
  transformToUserProps,
  useResolvedCurrentUser,
} from '../../../../../app/context/CurrentUserContext';
import {
  exitBranchDeploy,
  getBranchDeployTooltip,
  isBranchDeployment,
} from '../../../../utils/branchDeploy';
import LargeHeaderContent from './LargeHeaderContent';
import SmallHeaderContent from './SmallHeaderContent';
import {
  AtlassianHeaderLogo,
  AtlassianHeaderLogoImage,
  BranchDeployIndicator,
  DesktopWrapper,
  MobileWrapper,
  TabletWrapper,
  TextSearchContainer,
  WrappedLogoMenu,
} from './styles';

// @ts-expect-error [MC-2850] - TS2614 - Module '"history/createBrowserHistory"' has no exported member 'BrowserHistory'. Did you mean to use 'import BrowserHistory from "history/createBrowserHistory"' instead?
import type { BrowserHistory } from 'history/createBrowserHistory';
import type { Map } from 'immutable';
import type { ReactNode } from 'react';

import MarketplaceLogo from './images/MarketplaceLogo.svg';

const setWindowLocation = (location: string) => {
  // @ts-expect-error [MC-2850] - TS2322 - Type 'string' is not assignable to type '(string | Location) & Location'.
  window.location = location;
};

type HeaderContentType = {
  onLogout: () => void;
  // @ts-expect-error [MC-2850] - TS2314 - Generic type 'Map<K, V>' requires 2 type argument(s).
  userData: Map;
  children?: ReactNode;
  history: BrowserHistory;
  onSwitchAccount: () => void;
};

const HeaderLogo = ({ history }: { history: BrowserHistory }) => {
  const onClickLogo = (e) => {
    if (history) {
      LinkUtils.maybeOpenInNewTab(e, () => {
        history.push({ pathname: '/' });
      });
    }
  };
  return (
    <AtlassianHeaderLogo>
      <Link onClick={onClickLogo} title="Atlassian Marketplace" href="/">
        <AtlassianHeaderLogoImage
          src={MarketplaceLogo}
          alt="Atlassian Marketplace Logo"
          id="site-header-logo"
        />
      </Link>
      {isBranchDeployment() && (
        <BranchDeployIndicator>
          <Tooltip content={getBranchDeployTooltip()}>
            <Button onClick={exitBranchDeploy} testId="branch-deploy-button">
              <BitbucketBranchesIcon label="Branch Deploy" primaryColor={B300} />
            </Button>
          </Tooltip>
        </BranchDeployIndicator>
      )}
    </AtlassianHeaderLogo>
  );
};

const HeaderContent = ({
  onLogout,
  userData,
  children,
  history,
  onSwitchAccount,
}: HeaderContentType) => {
  const user = userData.getIn(['data', '_embedded', 'user']);
  const userAvatarUrl = user?.getIn(['_links', 'picture', 'href']);
  const userDataValue = userData.get('data');

  const { loading, loggedInUser, anonymousUser } = useResolvedCurrentUser({
    userProps: transformToUserProps(userData),
  });

  const onLogin = (e) => {
    LinkUtils.maybeOpenInNewTab(e, () => {
      if (!loading && anonymousUser?.anonymousUserLinks.login) {
        setWindowLocation(
          anonymousUser?.anonymousUserLinks.login +
            `?redirect=${window.location.pathname}${encodeURIComponent(window.location.search)}`
        );
      }
    });
  };

  const onClickLogout = () => {
    onLogout();
    return true;
  };

  const onClickSwitchAccount = (e) => {
    onSwitchAccount();
    LinkUtils.maybeOpenInNewTab(e, () => {
      if (loggedInUser?.loggedInUserLinks?.switchAccount) {
        setWindowLocation(loggedInUser?.loggedInUserLinks?.switchAccount);
      }
    });
  };

  const headerTextSearch = <TextSearchContainer>{children}</TextSearchContainer>;

  const headerLogo = <HeaderLogo history={history} />;

  const headerProps = {
    onClickLogin: onLogin,
    onClickLogout,
    userAvatarUrl,
    userData: userDataValue,
    onClickSwitchAccount,
  };

  // @ts-expect-error [MC-2850] - TS6133 - 'largerHeader' is declared but its value is never read.
  const largerHeader = <LargeHeaderContent {...headerProps} />;
  // @ts-expect-error [MC-2850] - TS6133 - 'smallHeader' is declared but its value is never read.
  const smallHeader = <SmallHeaderContent {...headerProps} />;
  return (
    <>
      <MobileWrapper>
        <WrappedLogoMenu>
          {headerLogo}
          {smallHeader}
        </WrappedLogoMenu>
        {headerTextSearch}
      </MobileWrapper>
      <TabletWrapper>
        <WrappedLogoMenu>
          {headerLogo}
          {largerHeader}
        </WrappedLogoMenu>
        {headerTextSearch}
      </TabletWrapper>
      <DesktopWrapper>
        {headerLogo}
        {headerTextSearch}
        {largerHeader}
      </DesktopWrapper>
    </>
  );
};

export default HeaderContent;
