import { Record } from 'immutable';

import LinkTemplate from '../../common/records/LinkTemplate';

export default class GeneralLinks extends Record({
  addonListingWithHosting: new LinkTemplate(),
  files: new LinkTemplate(),
  getMailingListLocale: new LinkTemplate(),
  subscribeToMailingList: new LinkTemplate(),
  subscribeToMailingListFailed: new LinkTemplate(),
}) {}

export const resolveGeneralLinks = ({ appConfig }) =>
  new GeneralLinks({
    addonListingWithHosting: new LinkTemplate({
      href: appConfig.getIn(['generalLinks', 'addonListingWithHosting', 'href']),
    }),
    files: new LinkTemplate({
      href: appConfig.getIn(['generalLinks', 'files', 'href']),
    }),
    getMailingListLocale: new LinkTemplate({
      href: appConfig.getIn(['generalLinks', 'getMailingListLocale', 'href']),
    }),
    subscribeToMailingList: new LinkTemplate({
      href: appConfig.getIn(['generalLinks', 'subscribeToMailingList', 'href']),
    }),
    subscribeToMailingListFailed: new LinkTemplate({
      href: appConfig.getIn(['generalLinks', 'subscribeToMailingListFailed', 'href']),
    }),
  });
