/**
 * Map defining browsers that are not supported.
 *
 * Key is browser name
 * Value is blacklisted version predicate function.
 * Predicate function returns true (version is blacklisted) if browser version matches, false otherwise (version not blacklisted).
 */
export default {
  ie: (version) => {
    return version < 11;
  },
};
