import Telemetry from './Telemetry';
import { PageVisibilityObserver } from './utils/observers';
const wait = duration => new Promise(resolve => setTimeout(resolve, duration));
export default function initTelemetry(backends) {
  const getContext = () => ({
    page: Telemetry.activePageName || 'PAGE_UNKNOWN',
    pageNavigationStart: Telemetry.pageNavigationStartTime,
    isInitialRender: Telemetry.isInitialRender,
    hasDocumentBeenHidden: PageVisibilityObserver.hasDocumentBeenHidden
  });

  /**
   * We encourage early initialization of telemetry, so listeners can listen to and
   * forward events even if a fatal crash occurs in the application early on.
   * However, it might be possible that such events are fired even before a page navigation
   * occurs or a page name is available.
   *
   * Here we wait for a small amount of time before setting subscribers to give a chance to the
   * application to set a page name using `Telemetry.setPage`.
   *
   * This should be immune to page crashes as we've already put it on the micro-task queue.
   */
  Promise.race([wait(4000), Telemetry.isPageReady]).then(() => {
    Telemetry.messageQueue.setSubscriber(event => {
      backends.forEach(backend => backend(event, getContext));
    });
  }).catch(() => {
    /* ignore this failure since we can't act on it anyway */
  });
}