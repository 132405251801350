import Bacon from 'baconjs';

import * as readyForUser from './ready-for-user';

// DEBUGGING PRO TIP
// See commit f82150a for debugging code which displays the status of the Bacon
// properties defined here in the page header, where they are visible in realtime in
// the browser.

/////////////////////////////////////////////////////////////////
// Fundamental Bacon properties, whose values are set directly //
/////////////////////////////////////////////////////////////////

// A boolean-valued Bacon property:
// true while an AJAX request to load data is (or might be) outstanding.
// false after data loading is complete.
const dataLoading = new Bacon.Bus();

// A boolean-valued Bacon property:
// true while HTML is or might be loading, generally via PJAX.
// false after HTML loading is complete.
const htmlLoading = new Bacon.Bus();

// A string-valued Bacon property, which holds the browser-metrics key for the current page or tab.
// It needs to be updated with the new tab key before the endCallbacks are executed for a given page transition.
const metricsKey = new Bacon.Bus();

////////////////////////////////////////////////////////////////////////////////////////////////////
// Derived Bacon properties and event streams, computed from the values of other Bacon properties //
////////////////////////////////////////////////////////////////////////////////////////////////////

// A boolean-valued Bacon property:
// true while HTML OR data might be loading--i.e., during a page transition.
// false after loading is complete.
const loading = dataLoading.toProperty().or(htmlLoading);

// An event stream which ticks when loading transitions from true to false.
// Each tick represents a page ready event.
const readyTick = loading
  .filter((l) => {
    return !l;
  })
  .toEventStream();

///////////////////////////////////////
// End of Bacon property definitions //
///////////////////////////////////////

const startCallbacks: any = [];
const endCallbacks: any = [];

// Every page signals when it has finished loading data by triggering the ready-for-user event.  This is what
// ties the ready-for-user event in to the Bacon properties used by browser-metrics-transition.
readyForUser.whenDataReady(() => {
  dataLoading.push(false);
});

// Desired semantics: event stream from readyTick, but include a sample (latest value) from metricsKey in the
// combining function.  Bacon.when uses event streams for pattern matching, while properties are just sampled
// at the time that the matching events fire.
Bacon.when([readyTick, metricsKey.toProperty()], (_, key) => {
  return key;
}).onValue((key) => {
  endCallbacks.forEach((callback) => callback(key));
});

export function onStart(callback) {
  startCallbacks.push(callback);
}

export function onEnd(callback) {
  endCallbacks.push(callback);
}

export function transitionStart(key) {
  startCallbacks.forEach((callback) => callback(key));
  dataLoading.push(true);
  htmlLoading.push(true);
}

// Invoking this function signifies that all the HTML needed for the page has been received, or that
// no additional HTML is needed.  All HTML requirements for the page to be ready for the user have been met.
// This is our PJAX equivalent of $(document).ready.
export function htmlReady(key) {
  // order is important here, to ensure that the correct metrics key is present when the readyTick ticks.
  metricsKey.push(key);
  htmlLoading.push(false);
}
