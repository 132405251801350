export const ANALYTICS_SCREEN_MANAGE_PARTNER_DETAILS = 'managePartnerDetailsScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_CONTACTS = 'managePartnerContactsScreen';
export const ANALYTICS_SCREEN_MANAGE_PROMOTIONS = 'managePromotionsScreen';
export const ANALYTICS_SCREEN_CREATE_PROMOTION = 'createPromotionScreen';
export const ANALYTICS_SCREEN_PROMOTION_DETAILS = 'promotionDetailsScreen';
export const ANALYTICS_SCREEN_PROMOTION_DETAILS_CODES = 'promotionDetailsCodesScreen';
export const ANALYTICS_SCREEN_PROMOTION_DETAILS_PRICING = 'promotionDetailsPricingScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_ADMIN = 'managePartnerAdminScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_PAYMENT_INFO = 'managePartnerPaymentInfoScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_SECURITY = 'managePartnerSecurityScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_APPS = 'managePartnerAppsScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_REPORTS = 'managePartnerReportsScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_AUDIT_LOGS = 'managePartnerAuditLogsScreen';
export const ANALYTICS_SCREEN_MANAGE_PARTNER_REPORT_DETAILS = 'managePartnerReportDetailsScreen';