import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { HelpIcon } from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';
import React from 'react';

import { dataTestIds } from '../constants';
import { IconButton } from './Button.styles';

import type { CustomTriggerProps } from '@atlaskit/dropdown-menu/types';
import type { Ref } from 'react';

const HelpContainer = styled(IconButton)({
  [media.above.md]: {
    marginLeft: token('space.100'),
  },
});
type HelpProps = CustomTriggerProps<HTMLButtonElement> & {
  triggerRef: Ref<HTMLButtonElement>;
};
export const Help = ({ triggerRef, ...props }: HelpProps) => {
  return (
    <Tooltip content="Help" hideTooltipOnClick={true}>
      <HelpContainer
        aria-label="Support"
        ref={triggerRef}
        data-testid={dataTestIds.HELP_DROPDOWN}
        {...props}
      >
        <HelpIcon height="32" width="32" />
      </HelpContainer>
    </Tooltip>
  );
};
