import { AppListingTab, AppListingTabs } from '@atlassian/mpac-app-listing';
import { stringify } from 'query-string';

import { AllHostingTypeQueryParams } from '../../../../../../../constants';

import type { GetMarketplacePluginByAppKeyQuery } from '../queries.gql.types';
import type { useParams } from '@atlassian/mpac-ui';
import type { Location } from 'react-router';

type App = NonNullable<GetMarketplacePluginByAppKeyQuery['marketplaceAppByKey']>;

type GetRouteToRedirectToParams = {
  app: App;
  params: ReturnType<typeof useParams>;
};

export const getRouteToRedirectTo = ({ app, params }: GetRouteToRedirectToParams): Location => {
  const pathname = `/apps/${app.appId}/${app.slug}`;
  const search = stringify({
    // @ts-expect-error [MC-2850] - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
    ...(AllHostingTypeQueryParams.includes(params.hosting) && { hosting: params.hosting }),
    // @ts-expect-error [MC-2850] - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'AppListingTabsType'.
    tab: AppListingTabs.includes(params.tab) ? params.tab : AppListingTab.Overview,
  });

  return { pathname, search };
};
