import React from 'react';

import codeSplitComponentFactory from '../../../../../common/codeSplitComponentFactory';
import BaseRouteHandler from '../../../common/routeHandlers/BaseRouteHandler';

type CreateRouteHandlerArgs = {
  includeSearch: boolean;
  includeFooter: boolean;
  importModule: () => Promise<{ default: React.ComponentType }>;
};

export const createRouteHandler = ({
  includeFooter,
  includeSearch,
  importModule,
}: CreateRouteHandlerArgs) => {
  const CodeSplitComponent = codeSplitComponentFactory(() => importModule().then((c) => c.default));

  const RouteHandler = () => (
    <BaseRouteHandler includeSearch={includeSearch} includeFooter={includeFooter}>
      <CodeSplitComponent />
    </BaseRouteHandler>
  );

  return RouteHandler;
};
