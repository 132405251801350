import React, { Component } from 'react';

import codeSplitComponentFactory from '../../../../common/codeSplitComponentFactory';
import BaseRouteHandler from '../../common/routeHandlers/BaseRouteHandler';

export const initialize = () => {
  const CollectionsCodeSplit = codeSplitComponentFactory(() =>
    import(/* webpackChunkName: "chunk-collections" */ '../component').then((c) => c.default)
  );

  return () =>
    class CollectionRouteHandler extends Component {
      render() {
        return (
          <BaseRouteHandler includeFooter includeSearch>
            <CollectionsCodeSplit {...this.props} />
          </BaseRouteHandler>
        );
      }
    };
};

export default initialize();
