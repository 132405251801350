import { B100, B300, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import styled from '@emotion/styled';

export const SmallHeader = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const HamburgerMenu = styled.div`
  appearance: none;
  color: ${N0};
  margin: 7px 10px ${token('space.150', '12px')} 10px;
  display: block;
  line-height: 1;

  &:hover {
    color: ${N0};
    background-color: ${B300};
    text-decoration: none;
  }

  &:focus,
  &:active {
    color: ${N0};
    background-color: ${B100};
    text-decoration: none;
  }
`;
