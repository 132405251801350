import { B100, B300, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import styled from '@emotion/styled';

export const UserMenu = styled.div`
  min-width: 58px;
  padding-left: 10px;
`;

export const UserMenuDropdownTrigger = styled.a`
  color: ${N0};
  padding: ${token('space.100', '8px')} 10px ${token('space.075', '6px')};
  display: block;
  line-height: 1;
  &:hover {
    background-color: ${B300};
    text-decoration: none;
  }

  &:focus,
  &:active {
    background-color: ${B100};
    text-decoration: none;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${token('space.100', '8px')};
`;

export const DisplayName = styled.div`
  line-height: 20px;
`;

export const DetailsWrapper = styled.div`
  flex-direction: row;
`;

export const LozengeWrapper = styled.span`
  padding-left: ${token('space.100', '8px')};
`;
