import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export const getSuggestionSource = query => query ? 'search results' : 'recent searches';
export const getAnalyticsFilters = selectedOptions => Object.keys(selectedOptions).reduce((acc, key) => {
  const selectedOption = selectedOptions[key];
  if (selectedOption.length) {
    return _objectSpread(_objectSpread({}, acc), {}, {
      [key]: Array.isArray(selectedOption) ? selectedOption.map(option => ({
        id: option.id,
        value: option.value
      })) : selectedOption
    });
  }
  return acc;
}, {});
export const getAnalyticsFilterOption = filterOption => Array.isArray(filterOption) ? filterOption.map(option => ({
  id: option.id,
  value: option.value
})) : filterOption;