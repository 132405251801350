import Avatar from '@atlaskit/avatar';
import { type Map, fromJS } from 'immutable';
import React from 'react';
import onClickOutside from 'react-onclickoutside';

import {
  transformToUserProps,
  useResolvedCurrentUser,
} from '../../../../../../../../app/context/CurrentUserContext';
import * as analytics from '../../../../../../../utils/analytics/analytics';
import {
  customerDropdownItems,
  developerDropdownItems,
  helpItems,
  loginDropdownItems,
  mobileUserDropdownItems,
} from '../../../../../constants/dropdownGroups';
import MenuSection from '../MenuSection';
import {
  DetailsWrapper,
  MenuSections,
  SectionHeader,
  SectionSeparator,
  UserEmail,
  UserMenuSectionHeader,
} from './styles';

type MenuSectionsContainerProps = {
  closeMenu: () => void;
  onClickLogin: () => void;
  onClickLogout: () => void;
  // @ts-expect-error [MC-2850] - TS2314 - Generic type 'Map<K, V>' requires 2 type argument(s).
  userData: Map;
  onClickSwitchAccount: () => void;
};

function MenuSectionsContainer({
  closeMenu,
  onClickLogin,
  onClickLogout,
  userData,
  onClickSwitchAccount,
}: MenuSectionsContainerProps) {
  const { loggedInUser, anonymousUser } = useResolvedCurrentUser({
    userProps: transformToUserProps(fromJS({ data: userData })),
  });

  const onHelpItemActivated = (item) => {
    analytics.trackUserActionClicked(item.get('content'));
  };

  const onUserItemActivated = (item) => {
    analytics.trackHelpActionClicked(item.get('content'));
  };

  /*
   * This function is used by react-onclickoutside
   * to handle clicks outside the component
   * Please DON'T REMOVE AS UNUSED.
   */
  // @ts-expect-error [MC-2850] - TS2339 - Property 'handleClickOutside' does not exist on type '({ closeMenu, onClickLogin, onClickLogout, userAvatarUrl, userData, username, userEmail, userLoggedIn, onClickSwitchAccount, }: MenuSectionsContainerProps) => Element'.
  MenuSectionsContainer.handleClickOutside = () => {
    closeMenu();
  };

  const renderUserMenu = () => {
    if (loggedInUser) {
      return (
        <MenuSection
          header={
            <UserMenuSectionHeader>
              <Avatar src={loggedInUser?.picture} size="small" />
              <DetailsWrapper>
                <div>{loggedInUser?.name}</div>
                <UserEmail>{loggedInUser?.email}</UserEmail>
              </DetailsWrapper>
            </UserMenuSectionHeader>
          }
          // @ts-expect-error [MC-2850] - TS2722 - Cannot invoke an object which is possibly 'undefined'.
          items={mobileUserDropdownItems.get('buildItems')(
            onClickLogout,
            onClickSwitchAccount,
            loggedInUser
          )}
          onItemActivated={onUserItemActivated}
        />
      );
    }
    return (
      <MenuSection
        // @ts-expect-error [MC-2850] - TS2722 - Cannot invoke an object which is possibly 'undefined'.
        items={loginDropdownItems.get('buildItems')(onClickLogin, anonymousUser)}
        onItemActivated={onUserItemActivated}
      />
    );
  };

  return (
    <MenuSections>
      <SectionSeparator />
      {renderUserMenu()}
      <SectionSeparator />
      <MenuSection
        header={<SectionHeader>Developer help</SectionHeader>}
        items={developerDropdownItems.get('items')}
        onItemActivated={onHelpItemActivated}
      />
      <SectionSeparator />
      <MenuSection
        header={<SectionHeader>Customer help</SectionHeader>}
        items={customerDropdownItems.get('items')}
        onItemActivated={onHelpItemActivated}
      />
      <SectionSeparator />
      <MenuSection
        className="help-menu-section"
        items={helpItems.get('items')}
        onItemActivated={onHelpItemActivated}
      />
    </MenuSections>
  );
}

const clickOutsideConfig = {
  // @ts-expect-error [MC-2850] - TS2339 - Property 'handleClickOutside' does not exist on type '({ closeMenu, onClickLogin, onClickLogout, userAvatarUrl, userData, username, userEmail, userLoggedIn, onClickSwitchAccount, }: MenuSectionsContainerProps) => Element'.
  handleClickOutside: () => MenuSectionsContainer.handleClickOutside,
} as const;

export default onClickOutside(MenuSectionsContainer, clickOutsideConfig);
