import { useEffect, useMemo, useState } from 'react';
import { AutoCompleteStatusValues, BODY_STATES } from '../../../constants';
export const useAutocompleteState = _ref => {
  let {
    autocompleteState
  } = _ref;
  const [bodyState, setBodyState] = useState(BODY_STATES.EMPTY_STATE);
  const hasCollections = useMemo(() => {
    return autocompleteState.collections.some(collection => collection.items.length > 0);
  }, [autocompleteState.collections]);
  useEffect(() => {
    if (autocompleteState.query && (autocompleteState.status === AutoCompleteStatusValues.LOADING || autocompleteState.status === AutoCompleteStatusValues.STALLED)) {
      setBodyState(BODY_STATES.LOADING);
    } else if (autocompleteState.status === AutoCompleteStatusValues.ERROR) {
      setBodyState(BODY_STATES.API_FAILURE);
    } else if (autocompleteState.query && hasCollections) {
      setBodyState(BODY_STATES.SEARCH_RESULTS);
    } else if (!autocompleteState.query && hasCollections) {
      setBodyState(BODY_STATES.RECENT_SEARCHES);
    } else if (autocompleteState.query && !hasCollections) {
      setBodyState(BODY_STATES.NO_SUGGESTIONS);
    } else if (!autocompleteState.query && !hasCollections) {
      setBodyState(BODY_STATES.EMPTY_STATE);
    }
  }, [autocompleteState, hasCollections]);
  return {
    bodyState
  };
};