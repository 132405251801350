import { B100, B300, B400, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import styled from '@emotion/styled';

export const HelpDropdownTrigger = styled.a`
  color: ${N0};
  padding: 14px 10px ${token('space.100', '8px')} 10px;
  display: block;
  line-height: 1;

  &:hover {
    color: ${N0};
    background-color: ${B300};
    text-decoration: none;
  }

  &:focus,
  &:active {
    color: ${N0};
    background-color: ${B100};
    text-decoration: none;
  }
`;

export const QuestionIcon = styled.span`
  display: inline-block;
  vertical-align: top;
  margin: ${token('space.negative.075', '-6px')} ${token('space.050', '4px')} 0 0;
  fill: ${B400};

  &:focus,
  &:active {
    fill: ${B400};
  }
`;
