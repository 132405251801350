import _defineProperty from "@babel/runtime/helpers/defineProperty";
const getRecentErrors = (errors, maxMinutesOld) => {
  const maxMillisecondsOld = maxMinutesOld * 60 * 1000;
  const minAge = Date.now() - maxMillisecondsOld;
  return errors.filter(error => error.time >= minAge);
};
class ErrorKeeper {
  static add(errorId) {
    ErrorKeeper.errors.push({
      id: errorId,
      time: Date.now()
    });

    // keeping a stack of 20 error assuming this much stack
    // should be sufficient to debug user issues under last 3 minutes.
    // since use may chose to register a bug some time after the error has actually happened.
    // so not filtering based on time here.
    if (ErrorKeeper.errors.length > 20) {
      ErrorKeeper.errors.shift();
    }
  }
  static getLastNMinutesErrors() {
    let min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
    return getRecentErrors(ErrorKeeper.errors, min).map(error => error.id);
  }
}
_defineProperty(ErrorKeeper, "errors", []);
export default ErrorKeeper;