import { FILTER_KEYS } from '@atlassian/mpac-utils';
import { HostingTypes } from '../../SearchFilters/Filters/HostingFilters';
import { filtersMap as algoliaFacetMap } from './FiltersMap';
const sortOptionIndexConfig = [{
  key: 'topRated',
  indexSuffix: () => {
    return 'desc_ratings.ratingsScore';
  }
}, {
  key: 'trending',
  indexSuffix: () => {
    return 'desc_distribution.deltaInstalls';
  },
  applyOverrides: () => ({
    [algoliaFacetMap[FILTER_KEYS.IS_ATLASSIAN]]: 'false'
  })
}, {
  key: 'topSelling',
  indexSuffix: filters => {
    const hostingFilter = filters === null || filters === void 0 ? void 0 : filters.hosting;
    if (hostingFilter && hostingFilter.length) {
      switch (hostingFilter[0].value) {
        case HostingTypes.CLOUD:
          return 'desc_sales.recentGrossCloud';
        case HostingTypes.SERVER:
          return 'desc_sales.recentGrossServer';
        case HostingTypes.DATA_CENTER:
          return 'desc_sales.recentGrossServer';
        default:
          return 'desc_sales.recentGrossTotal';
      }
    }
    return 'desc_sales.recentGrossTotal';
  },
  applyOverrides: () => ({
    [algoliaFacetMap[FILTER_KEYS.IS_ATLASSIAN]]: 'false',
    [algoliaFacetMap[FILTER_KEYS.MARKETPLACE_TYPE]]: 'Paid via Atlassian'
  })
}, {
  key: 'newest',
  indexSuffix: () => {
    return 'desc_releaseDate';
  }
}];
export const resolveAlgoliaSortIndexAndFilters = (index, sortKey, filters) => {
  const sortOption = sortOptionIndexConfig.find(option => option.key === sortKey);
  let newIndex = index;
  let sortFilters = {};
  if (sortOption) {
    newIndex = index + "_".concat(sortOption === null || sortOption === void 0 ? void 0 : sortOption.indexSuffix(filters));
    if (sortOption.applyOverrides) {
      sortFilters = sortOption.applyOverrides();
    }
  }
  return {
    transformedAlgoliaIndex: newIndex,
    sortFilters
  };
};