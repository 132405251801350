import _memoize from "lodash/memoize";
import { COMPATIBLE_PRODUCT_FILTER_VALUES } from '@atlassian/mpac-utils';
// TODO: Add hosting filter for the search results
export const getFacetFilter = productId => {
  return ["marketplace_plugins_prod.facets.exact_matches.version.compatibilities.applicationKey.value:".concat(COMPATIBLE_PRODUCT_FILTER_VALUES[productId])];
};
const getProductIdsToBeFiltered = _memoize(hostProductId => {
  const productIdsToBeFiltered = Object.values(COMPATIBLE_PRODUCT_FILTER_VALUES).filter(value => {
    if (hostProductId === COMPATIBLE_PRODUCT_FILTER_VALUES.jira || hostProductId === COMPATIBLE_PRODUCT_FILTER_VALUES['jira-service-management']) {
      return value !== COMPATIBLE_PRODUCT_FILTER_VALUES['jira-service-management'] && value !== COMPATIBLE_PRODUCT_FILTER_VALUES.jira;
    }
    return value !== hostProductId;
  });
  return productIdsToBeFiltered;
});
const getHostingToBeFiltered = _memoize(() => {
  return ['server', 'datacenter'];
});

/**
 * Filters search suggestion keywords based on the provided query and host product ID.
 * For MPAC, it filters out hits that exactly match the query.
 * For EMCEE, on top of query match filtering it also filters out hits that contain:
 *   1. the hosting keywords 'datacenter' and 'server'.
 *   2. product keywords that are not same as host product ID.
 *      Like for 'jira' host product ID, it filters out hits that contain 'confluence', 'bitbucket' etc
 *
 * @param {Object} params - The parameters for filtering.
 * @param {Array} params.hits - The array of search suggestion hits.
 * @param {string} params.query - The query string to filter out from the hits.
 * @param {string|null} params.hostProductId - The host product ID to further filter the hits.
 * @returns {Array} - The filtered array of search suggestion hits.
 */

export const filterSearchSuggestionsKeywords = _ref => {
  let {
    hits,
    query,
    hostProductId
  } = _ref;
  // Filter out hits that exactly match the query
  let filteredHits = hits.filter(hit => hit.query !== query);
  if (hostProductId) {
    const productIdsToBeFiltered = getProductIdsToBeFiltered(hostProductId);
    const hostingIdsToBeFiltered = getHostingToBeFiltered();
    filteredHits = filteredHits.filter(hit => !productIdsToBeFiltered.some(product => hit.query.toLowerCase().includes(product)) && !hostingIdsToBeFiltered.some(hosting => hit.query.toLowerCase().includes(hosting)));
  }
  return filteredHits;
};