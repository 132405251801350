import { getAuthorisedSubTab, TabNames } from '@atlassian/mpac-partners';
import { getPartnerNavLinks, PARTNER_NAV_ITEMS } from '@atlassian/mpac-utils';

import {
  ANALYTICS_SUBJECT_ID_PARTNER_APPS_LINK,
  ANALYTICS_SUBJECT_ID_PARTNER_PROMOTIONS_LINK,
  ANALYTICS_SUBJECT_ID_PARTNER_REPORTS_LINK,
} from '../../../../analytics/Events';

import type { PartnerPermissions } from '@atlassian/mpac-api-client';

export type QuickLinkItems = {
  path: string;
  name: string;
  linkActionSubjectId: string;
}[];

export const getQuickLinkItems = (
  permissions: PartnerPermissions,
  partnerId: number
): QuickLinkItems => {
  const partnerRoutes = getPartnerNavLinks();
  return [
    ...(permissions.canManageAppDetails
      ? [
          {
            path: partnerRoutes[PARTNER_NAV_ITEMS.APPS].expand({ id: partnerId }),
            name: TabNames.APPS,
            linkActionSubjectId: ANALYTICS_SUBJECT_ID_PARTNER_APPS_LINK,
          },
        ]
      : []),
    ...(permissions.canManagePromotions
      ? [
          {
            path: partnerRoutes[PARTNER_NAV_ITEMS.PROMOTIONS].expand({ id: partnerId }),
            name: TabNames.PROMOTIONS,
            linkActionSubjectId: ANALYTICS_SUBJECT_ID_PARTNER_PROMOTIONS_LINK,
          },
        ]
      : []),
    ...(permissions.canViewAnyReports
      ? [
          {
            path: partnerRoutes[
              getAuthorisedSubTab(PARTNER_NAV_ITEMS.REPORTS, permissions.canViewSalesReport)
            ].expand({ id: partnerId }),
            name: TabNames.REPORTS,
            linkActionSubjectId: ANALYTICS_SUBJECT_ID_PARTNER_REPORTS_LINK,
          },
        ]
      : []),
  ];
};
