import { useHistory, useLocationHref } from '@atlassian/mpac-ui';
import { useMountPath, useSource } from '@atlassian/mpac-utils';
import { useEffect } from 'react';
import { getSuggestionSource } from '../../../AnalyticsUtils';
import { redirectToNewQuery } from '../../../SearchUtils/search-navigation-utils/SearchNavigationUtils';
export const useSearchDropdown = _ref => {
  let {
    inputRef,
    autocomplete,
    autocompleteState,
    recentSearch,
    onEnterKeyPress,
    additionalQueryParams
  } = _ref;
  // This is needed to refresh the recent searches when the component mounts
  // If we don't do this, the recent searches will not be updated seacrh embedded component
  // when the user searches in overlay component
  useEffect(() => {
    recentSearch.refreshRecentSearches();
  }, []);
  const locationHref = useLocationHref();
  const history = useHistory();
  const handleAppend = hit => {
    var _inputRef$current;
    autocomplete.setQuery(hit.query);
    (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.focus();
  };
  const source = useSource();
  const mountPath = useMountPath();
  const handleEnterKeyPress = query => {
    var _inputRef$current2, _autocompleteState$co;
    if (query) {
      const queryToBeSaved = {
        query,
        objectID: query
      };
      recentSearch.push(queryToBeSaved);
    }
    redirectToNewQuery({
      query,
      locationHref,
      history,
      additionalQueryParams,
      source,
      mountPath
    });
    inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current2 = inputRef.current) === null || _inputRef$current2 === void 0 ? void 0 : _inputRef$current2.blur();

    // HANLDE ANALYTCS
    onEnterKeyPress({
      query,
      suggestionSource: getSuggestionSource(!!query),
      count: query ? (_autocompleteState$co = autocompleteState.collections[0].items.length) !== null && _autocompleteState$co !== void 0 ? _autocompleteState$co : 0 : recentSearch.count()
    });
  };
  const clearRecentSearches = () => {
    var _inputRef$current3;
    recentSearch.clearAll();
    autocomplete.refresh();
    inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current3 = inputRef.current) === null || _inputRef$current3 === void 0 ? void 0 : _inputRef$current3.focus();
  };
  const clearSearchInput = () => {
    if (inputRef.current && inputRef.current.value) {
      autocomplete.setQuery('');
      inputRef.current.focus();
    }
  };
  return {
    handleAppend,
    handleEnterKeyPress,
    clearRecentSearches,
    clearSearchInput
  };
};