import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { Button } from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';
import React from 'react';

import { useResolvedCurrentUser } from '../../../../context/CurrentUserContext';
import { MenuButtonMobile, SignIn } from '../Buttons';
import { HelpDropdown, UserDropdown } from '../Dropdown';
import { AppsForProducts } from '../Menus/AppsForProducts';
import { Categories } from '../Menus/Categories';
import { Resources } from '../Menus/Resources';
import { expandableMenuLabel } from './constants';
import { DropdownMenuTransition, SlidingMenuTransition } from './Transitions';
import { ExpandableMenuType } from './types';

import type { UserProps } from '../userTypes';
import type { ExpandableMenuProps } from './types';

export const OpenedMenuContent = styled.div({
  width: '50%',
});

export const ClosedMenuContent = styled.div({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
});

const ExpandedMenuContainer = styled.div({
  overflowY: 'scroll',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

export const ActionsContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: 'auto',
  gap: token('space.100'),
  padding: `${token('space.100')} ${token('space.300')}`,
  [media.above.xs]: {
    padding: `${token('space.100')} ${token('space.400')}`,
  },
  [media.above.sm]: {
    padding: `${token('space.100')} ${token('space.500')}`,
  },
});

const FeedbackButton = styled(Button)({
  '&&': {
    width: 'fit-content !important',
    margin: `${token('space.300')}`,
  },
});

export const Actions = ({
  userProps,
  openFeedbackDialog,
}: {
  userProps: UserProps;
  openFeedbackDialog: false | (() => void);
}) => {
  const { loggedInUser } = useResolvedCurrentUser({ userProps });

  return (
    <ActionsContainer>
      <HelpDropdown openFeedbackDialog={openFeedbackDialog} />
      {loggedInUser ? <UserDropdown userProps={userProps} /> : <SignIn userProps={userProps} />}
    </ActionsContainer>
  );
};

const ClosedMenu = ({
  openMenu,
  openFeedbackDialog,
}: {
  openMenu: (menuType: ExpandableMenuType) => () => void;
  openFeedbackDialog: false | (() => void);
}) => {
  return (
    <ClosedMenuContent>
      <MenuButtonMobile
        onClick={openMenu(ExpandableMenuType.APPS_FOR_PRODUCTS)}
        label={expandableMenuLabel[ExpandableMenuType.APPS_FOR_PRODUCTS]}
      />
      <MenuButtonMobile
        onClick={openMenu(ExpandableMenuType.CATEGORIES)}
        label={expandableMenuLabel[ExpandableMenuType.CATEGORIES]}
      />
      <MenuButtonMobile
        onClick={openMenu(ExpandableMenuType.RESOURCES)}
        label={expandableMenuLabel[ExpandableMenuType.RESOURCES]}
      />
      {openFeedbackDialog && (
        <FeedbackButton onClick={openFeedbackDialog}>Give Feedback</FeedbackButton>
      )}
    </ClosedMenuContent>
  );
};

const OpenedMenu = ({
  openedMenu,
  closeMenu,
}: {
  openedMenu?: ExpandableMenuType | null;
  closeMenu: () => void;
}) => {
  return (
    <OpenedMenuContent>
      {openedMenu === ExpandableMenuType.APPS_FOR_PRODUCTS && (
        <AppsForProducts closeMenu={closeMenu} />
      )}
      {openedMenu === ExpandableMenuType.CATEGORIES && <Categories closeMenu={closeMenu} />}
      {openedMenu === ExpandableMenuType.RESOURCES && <Resources closeMenu={closeMenu} />}
    </OpenedMenuContent>
  );
};

const ExpandedMenu = ({
  openedMenu,
  openMenu,
  closeMenu,
  userProps,
  openFeedbackDialog,
}: {
  openedMenu: ExpandableMenuType | null;
  openMenu: (menuType: ExpandableMenuType) => () => void;
  closeMenu: () => void;
  userProps: UserProps;
  openFeedbackDialog: false | (() => void);
}) => {
  return (
    <ExpandedMenuContainer>
      <Actions userProps={userProps} openFeedbackDialog={openFeedbackDialog} />
      <SlidingMenuTransition isOpen={openedMenu !== ExpandableMenuType.MOBILE_VIEW}>
        <ClosedMenu openMenu={openMenu} openFeedbackDialog={openFeedbackDialog} />
        <OpenedMenu openedMenu={openedMenu} closeMenu={closeMenu} />
      </SlidingMenuTransition>
    </ExpandedMenuContainer>
  );
};

export const ExpandableMenuMobile = ({
  isOpen,
  openedMenu,
  openMenu,
  closeMenu,
  userProps,
  openFeedbackDialog,
}: ExpandableMenuProps) => {
  return (
    <DropdownMenuTransition isOpen={isOpen}>
      <ExpandedMenu
        openMenu={openMenu}
        openedMenu={openedMenu}
        closeMenu={closeMenu}
        userProps={userProps}
        openFeedbackDialog={openFeedbackDialog}
      />
    </DropdownMenuTransition>
  );
};
