import { fetchPartnersForUser } from '@atlassian/mpac-api-client';
import { hooks, log } from '@atlassian/mpac-utils';
export const usePartnersForUser = mpacBaseUrl => {
  const {
    result,
    error,
    pending,
    refresh
  } = hooks.usePromise(() => fetchPartnersForUser(mpacBaseUrl));
  if (error) {
    log.error('PromotionCodes/hooks', "Failed to retrieve Promotion Codes", {
      error
    });
  }
  return {
    pending,
    partners: result || [],
    refresh,
    error
  };
};