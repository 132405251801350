import * as browserMetricsTransition from 'common/utils/events/browser-metrics-transition';
import * as readyForUser from 'common/utils/events/ready-for-user';

export const RECEIVE_APP_CONFIG = 'APP_RECEIVE_APP_CONFIG';
export const LOADING_COMPLETE = 'APP_LOADING_COMPLETE';
export const DISPLAY_GENERAL_SEARCH = 'APP_DISPLAY_GENERAL_SEARCH';
export const DISPLAY_PARTNER_QUICK_LINKS = 'APP_DISPLAY_PARTNER_QUICK_LINKS';
export const DISPLAY_COLLECTIONS_FOOTER = 'APP_DISPLAY_COLLECTIONS_FOOTER';

export function receiveAppConfig({ appConfig }) {
  return {
    type: RECEIVE_APP_CONFIG,
    appConfig,
  };
}

function loadingComplete() {
  return {
    type: LOADING_COMPLETE,
  };
}

export function onLoadingComplete({ metricsKey }) {
  return (dispatch) => {
    browserMetricsTransition.htmlReady(metricsKey);
    readyForUser.dataReady();
    dispatch(loadingComplete());
  };
}

export function onLoadingStart({ metricsKey }) {
  // @ts-expect-error [MC-2850] - TS6133 - 'dispatch' is declared but its value is never read.
  return (dispatch, getState) => {
    if (getState().app.initialLoadComplete) {
      browserMetricsTransition.transitionStart(metricsKey);
    }
  };
}

export function displayGeneralSearch(show) {
  return {
    type: DISPLAY_GENERAL_SEARCH,
    show,
  };
}

export function displayPartnerQuickLinks(show) {
  return {
    type: DISPLAY_PARTNER_QUICK_LINKS,
    show,
  };
}

export function displayCollectionsFooter(show) {
  return {
    type: DISPLAY_COLLECTIONS_FOOTER,
    show,
  };
}
