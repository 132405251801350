import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { styled } from '@compiled/react';

export const DropdownMenuTransitionContent = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  background: token('elevation.surface.overlay'),
  boxShadow: '0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)',
  top: token('space.800', '64px'),
  [media.above.sm]: {
    top: '72px',
  },
});

export const DropdownMenuTransitionContainer = styled.div({
  zIndex: -1,
  '& .dropdown-menu-enter': {
    transform: 'translateY(-100%)',
    opacity: 0,
  },
  '& .dropdown-menu-enter-active': {
    transform: 'translateY(0)',
    opacity: 1,
    transition: 'opacity 300ms, transform 300ms',
  },
  '& .dropdown-menu-exit': {
    transform: 'translateY(0)',
    opacity: 1,
  },
  '& .dropdown-menu-exit-active': {
    transform: 'translateY(-100%)',
    opacity: 0,
    transition: 'opacity 300ms, transform 300ms',
  },
});

export const BlanketTransitionContainer = styled.div({
  zIndex: -1,
  '& .dropdown-menu-blanket-enter': {
    opacity: 0,
  },
  '& .dropdown-menu-blanket-enter-active': {
    opacity: 1,
    transition: 'opacity 300ms',
  },
  '& .dropdown-menu-blanket-exit': {
    opacity: 1,
  },
  '& .dropdown-menu-blanket-exit-active': {
    opacity: 0,
    transition: 'opacity 300ms',
  },
});

export const BlanketTransitionContent = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  height: '100vh',
  background: token('color.blanket'),
  top: token('space.800', '64px'),
  [media.above.sm]: {
    top: '72px',
  },
});

export const SlidingMenuTransitionContainer = styled.div({
  '& .sliding-menu-enter': {
    transform: 'translateX(0%)',
  },
  '& .sliding-menu-enter-active, & .sliding-menu-enter-done': {
    transform: 'translateX(-50%)',
  },
  '& .sliding-menu-exit': {
    transform: 'translateX(-50%)',
  },
  '& .sliding-menu-exit-active, & .sliding-menu-exit-done': {
    transform: 'translateX(0)',
  },
});

export const SlidingMenuTransitionContent = styled.div({
  display: 'flex',
  width: '200%',
  transition: 'transform 300ms',
  background: token('elevation.surface.overlay'),
  overflowY: 'scroll',
  height: 'calc(100vh - 64px)',
  [media.above.sm]: {
    height: 'calc(100vh - 72px)',
  },
});
