import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import set from 'lodash/fp/set';
import ErrorKeeper from '../ErrorKeeper';
import { objectToPathValuePair } from '../object/object.utils';
import getSentryClient, { setSentryClient } from './client';
function getEmceePath() {
  if (window.location.href.includes('emcee')) {
    return window.location.hash.split('#')[1];
  }
}
const getFormattedEventWithTags = event => {
  let message = '';
  let tags = {};
  const args = event.message;
  const pathname = getEmceePath();
  message = args.filter(arg => typeof arg === 'string' || arg.constructor !== Object).join(' ');
  tags = args.filter(arg => arg.constructor === Object).reduce((arg, tagsAccumulated) => _objectSpread(_objectSpread({}, tagsAccumulated), objectToPathValuePair(arg)), {});
  Sentry.withScope(scope => {
    scope.setExtra('arguments', args);
  });
  message = message || JSON.stringify(args);
  return _objectSpread(_objectSpread(_objectSpread({}, event), pathname && {
    request: _objectSpread(_objectSpread({}, event.request), {}, {
      url: pathname
    })
  }), {}, {
    message,
    // This is display error message in the header
    exception: message,
    tags: _objectSpread(_objectSpread({}, event.tags), tags)
  });
};
const beforeSend = event => {
  const formattedEvent = Array.isArray(event.message) ? getFormattedEventWithTags(event) : event;
  ErrorKeeper.add(formattedEvent.event_id);
  return formattedEvent;
};
const ignoredHTTPTraffic = [/api.segment.io/, /google-analytics.com/, /https:\/\/xid\./, /pingdom.net/, /stats.g.doubleclick.net/, /api-private.atlassian.com\/gasv3/, /hn.inspectlet.com/, /events.launchdarkly.com/, /gateway\/api\/session\/heartbeat/, /logx.optimizely.com\/v1\/events/, /gateway\/api\/gasv3\/api\/v1/];
const beforeBreadcrumb = crumb => {
  if (crumb.type === 'http' && ignoredHTTPTraffic.some(denyUrl => denyUrl.test(crumb.data.url))) return false;
  return crumb;
};
async function SHA256(message) {
  var _window$crypto;
  if (!((_window$crypto = window.crypto) !== null && _window$crypto !== void 0 && _window$crypto.subtle)) {
    return;
  }
  return window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(message));
}
let isCapturingGlobalErrors = false;

/**
 * Adds a prefix to exception messages
 */
function prefixExceptionValue(event) {
  var _event$exception, _event$exception$valu;
  const exceptionValue = (_event$exception = event.exception) === null || _event$exception === void 0 ? void 0 : (_event$exception$valu = _event$exception.values) === null || _event$exception$valu === void 0 ? void 0 : _event$exception$valu[0].value;
  if (!exceptionValue) return null;
  return set(['exception', 'values', '0', 'value'])("[Marketplace] ".concat(exceptionValue))(event);
}
/**
 * Must be called prior to calling any of the methods on the instance.
 * Initializes a new sentry client (optionally attaching global error listeners)
 */
export function initSentry(sentryConfig) {
  let sentryInstance = getSentryClient(false);
  if (sentryInstance) {
    return sentryInstance;
  }
  const integrations = sentryConfig.setupGlobalListeners ? [...Sentry.defaultIntegrations, new TracingIntegrations.BrowserTracing()] : [...Sentry.defaultIntegrations.filter(integration => integration.name !== 'GlobalHandlers'), new TracingIntegrations.BrowserTracing()];
  const tracesSampler = () => {
    if (sentryConfig.environment === 'production') {
      return 0.5;
    }
    return 1;
  };
  const sentryOptions = {
    dsn: sentryConfig.dsn,
    beforeBreadcrumb,
    environment: sentryConfig.environment,
    attachStacktrace: true,
    release: sentryConfig.releaseVersion,
    denyUrls: [/cdn.segment.com/],
    beforeSend,
    integrations,
    tracesSampler,
    normalizeDepth: 5
  };
  if (sentryConfig.setupGlobalListeners) {
    Sentry.init(sentryOptions);
    // sentry init() internally creates a hub
    sentryInstance = setSentryClient(Sentry.getCurrentHub());
  } else {
    const client = new Sentry.BrowserClient(_objectSpread({
      transport: Sentry.makeFetchTransport,
      stackParser: Sentry.defaultStackParser,
      integrations
    }, sentryConfig));
    sentryInstance = setSentryClient(new Sentry.Hub(client), true);
  }
  if (sentryConfig.productVersion) {
    sentryInstance.setTag('productVersion', sentryConfig.productVersion);
  }
  isCapturingGlobalErrors = sentryConfig.setupGlobalListeners;
}
/**
 *
 * Here, our goal is to capture uncaught exceptions that are thrown when a marketplace
 * component is active in a host, and send a copy of these exceptions to marketplace's Sentry instance.
 * These uncaught errors would usually be bubbled up the the host consumers of marketplace components.
 *
 * Here, we're prying on the global event queue, hopefully setup by the products when they call `Sentry.init`
 * and forward events to our instance. However doing so as-is would lead sentry's dedupe integration
 * (which is enabled by default), to think that the event we're forwarding to our hub is a duplicate of
 * the one sent to the host product's sentry.
 *
 * Therefore, we change the exception message slightly, to trick dedupe, and allow sending of errors to our account.
 *
 * @see https://github.com/getsentry/sentry-javascript/blob/master/packages/browser/src/integrations/dedupe.ts
 */
export function attachGlobalSentryListeners(onSentryEvent) {
  isCapturingGlobalErrors = true;
  onSentryEvent(event => {
    var _event$tags, _event$tags2;
    if (!isCapturingGlobalErrors) return;
    const hasBeenPreviouslyProcessed = ((_event$tags = event.tags) === null || _event$tags === void 0 ? void 0 : _event$tags.structuredLog) || ((_event$tags2 = event.tags) === null || _event$tags2 === void 0 ? void 0 : _event$tags2.processedError);
    const rewrittenEvent = prefixExceptionValue(event);
    if (!hasBeenPreviouslyProcessed && rewrittenEvent) {
      rewrittenEvent.tags = _objectSpread(_objectSpread({}, event.tags), {}, {
        processedError: 'true'
      });
      const sentryInstance = getSentryClient(false);
      sentryInstance === null || sentryInstance === void 0 ? void 0 : sentryInstance.captureEvent(rewrittenEvent);
    }
  });
}
export function detachSentryListeners() {
  isCapturingGlobalErrors = false;
}
export async function setSentryUserId(id) {
  const saltedId = "KE3WvP2iYT14lIfa".concat(id, "ATtNdgf1Qhok0D9bhv");
  const userIdHashed = await SHA256(saltedId);
  const sentryInstance = getSentryClient();
  sentryInstance === null || sentryInstance === void 0 ? void 0 : sentryInstance.configureScope(scope => {
    scope.setUser({
      id: userIdHashed
    });
  });
}