import AkButton from '@atlaskit/button/standard-button';
import Flag, { FlagGroup } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import ModalDialog, { ModalBody } from '@atlaskit/modal-dialog';
import TextField from '@atlaskit/textfield';
import { Set } from 'immutable';
import React from 'react';

import { LargeAkButton } from '../../styledComponents';
import mailinglistConfig from './config/SubscribeMailingListConfig';
import {
  CancelButtonDiv,
  CheckboxDiv,
  CheckboxTitleLabel,
  CkeckboxesContainerDiv,
  Container,
  FieldsContainerDiv,
  FieldsDiv,
  FieldSet,
  FieldTextDiv,
  FieldTextWithErrorDiv,
  GreenDiv,
  InputDiv,
  InvalidEmailDiv,
  InvalidEmailTextDiv,
  ModalImage,
  RedDiv,
  SubscribeButtonDiv,
  TitleDiv,
  YellowDiv,
} from './styled/SubscribeMailingListStyled';

import communicationImage from './config/images/communication.svg';

const isValidEmailId = (emailId) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailId);

type propTypes = {
  isOpen: boolean;
  onModalDismissed: () => void;
  onFlagDismissed: () => void;
  onClickSubscribe: (a) => void;
  success?: boolean;
  errorReason?: string;
};

export default class SubscribeMailingListDialog extends React.PureComponent<propTypes> {
  static defaultProps = {
    success: false,
    errorReason: undefined,
  };

  state: any = {
    emailId: '',
    isInvalid: false,
    selectedMailingListIds: Set(),
  };

  onChangeEmailId = (event) => {
    this.setState({
      emailId: event.target.value,
    });
  };

  validateEmailId = () => {
    this.setState((state: any) => {
      const emailId = state.emailId.trim();
      return {
        emailId,
        isInvalid: !isValidEmailId(emailId),
      };
    });
  };

  onClickSubscribe = () => {
    this.props.onClickSubscribe({
      emailId: this.state.emailId,
      listIds: this.state.selectedMailingListIds,
    });
  };

  updateSet = (value, checked) =>
    checked
      ? this.state.selectedMailingListIds.add(value)
      : this.state.selectedMailingListIds.remove(value);

  onSelectingCheckbox = (event) => {
    this.setState({
      selectedMailingListIds: this.updateSet(event.target.value, event.target.checked),
    });
    return true;
  };

  canClickSubscribe = () =>
    this.state.emailId.length > 0 &&
    isValidEmailId(this.state.emailId) &&
    !this.state.isInvalid &&
    this.state.selectedMailingListIds.size > 0;

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        emailId: '',
        isInvalid: false,
        selectedMailingListIds: Set(),
      });
    }
  };

  render() {
    return (
      <div>
        {this.props.isOpen ? (
          <ModalDialog onClose={this.props.onModalDismissed}>
            <ModalBody>
              <Container>
                <div>
                  <ModalImage src={communicationImage} />
                </div>
                <FieldsContainerDiv>
                  <TitleDiv>Choose the subscription that fits your team</TitleDiv>

                  <FieldsDiv>
                    <FieldSet>
                      <CkeckboxesContainerDiv>
                        {mailinglistConfig.map((mailingList, i) => (
                          <CheckboxDiv key={'mailingList-' + i}>
                            <InputDiv
                              type="checkbox"
                              checked={this.state.selectedMailingListIds.has(mailingList.listId)}
                              id={mailingList.key}
                              value={mailingList.listId}
                              onChange={this.onSelectingCheckbox}
                            />
                            <CheckboxTitleLabel htmlFor={mailingList.key}>
                              {mailingList.title}
                            </CheckboxTitleLabel>
                          </CheckboxDiv>
                        ))}
                      </CkeckboxesContainerDiv>

                      <FieldTextWithErrorDiv>
                        <FieldTextDiv onBlur={this.validateEmailId}>
                          <TextField
                            value={this.state.emailId}
                            placeholder="Email address"
                            onChange={this.onChangeEmailId}
                            isInvalid={this.state.isInvalid}
                          />
                        </FieldTextDiv>
                        <InvalidEmailDiv visible={this.state.isInvalid}>
                          <YellowDiv>
                            <WarningIcon size="medium" label="Invalid email address" />
                          </YellowDiv>
                          <InvalidEmailTextDiv>
                            Please enter a valid email address
                          </InvalidEmailTextDiv>
                        </InvalidEmailDiv>
                      </FieldTextWithErrorDiv>
                    </FieldSet>

                    <SubscribeButtonDiv>
                      <LargeAkButton
                        appearance="primary"
                        onClick={this.onClickSubscribe}
                        shouldFitContainer={true}
                        isDisabled={!this.canClickSubscribe()}
                      >
                        Subscribe now
                      </LargeAkButton>
                    </SubscribeButtonDiv>

                    <CancelButtonDiv>
                      <AkButton
                        appearance="subtle-link"
                        onClick={this.props.onModalDismissed}
                        spacing="none"
                      >
                        Cancel
                      </AkButton>
                    </CancelButtonDiv>
                  </FieldsDiv>
                </FieldsContainerDiv>
              </Container>
            </ModalBody>
          </ModalDialog>
        ) : null}
        {this.renderFlags()}
      </div>
    );
  }

  renderFlags = () => {
    if (this.props.success) {
      return (
        <FlagGroup onDismissed={this.props.onFlagDismissed}>
          <Flag
            appearance="normal"
            id="subscribe-flag-success"
            icon={
              <GreenDiv>
                <SuccessIcon label="Success" size="medium" />
              </GreenDiv>
            }
            key="subscribe-flag-success"
            title="Subscription successful!"
          />
        </FlagGroup>
      );
    } else if (this.props.errorReason) {
      let errTitle = 'Something went wrong!';
      let errDescription = 'We could not subscribe you to the email list. Please try again.';

      if (!navigator.onLine) {
        errTitle = 'Internet connection lost!';
        errDescription =
          'We could not subscribe you to the email list. Check your connection and try again.';
      }

      return (
        <FlagGroup onDismissed={this.props.onFlagDismissed}>
          <Flag
            appearance="normal"
            id="subscribe-flag-failure"
            icon={
              <RedDiv>
                <ErrorIcon label="Failure" size="medium" />
              </RedDiv>
            }
            key="subscribe-flag-failure"
            title={errTitle}
            description={errDescription}
          />
        </FlagGroup>
      );
    }
  };
}
