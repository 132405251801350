import mergeAll from 'lodash/fp/mergeAll';
import { MARKETPLACE_ANALYTICS_CONTEXT_NAMESPACE } from '../MarketplaceAnalyticsContext/MarketplaceAnalyticsContext';
export const getAllMarketplaceContexts = analyticsContext => {
  return analyticsContext.map(contextItem => {
    return contextItem[MARKETPLACE_ANALYTICS_CONTEXT_NAMESPACE]; // contextItem[MARKETPLACE_ANALYTICS_CONTEXT_NAMESPACE] should always be of MarketplaceAnalyticsContextItem type.
  }).filter(Boolean);
};
export const getMergedMarketplaceContext = analyticsContext => {
  const allContexts = getAllMarketplaceContexts(analyticsContext);
  return mergeAll([{
    attributes: {},
    properties: {}
  }, ...allContexts]);
};