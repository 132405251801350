import { List } from 'immutable';

import { MailingList } from './SubscribeMailingListRecords';

export default List([
  new MailingList({
    listId: 'atlassian.marketplaceIT',
    title: 'IT teams',
    key: 'it-teams',
  }),
  new MailingList({
    listId: 'atlassian.marketplaceSoftware',
    title: 'Software Teams',
    key: 'software-teams',
  }),
  new MailingList({
    listId: 'atlassian.marketplaceNonTechnical',
    title: 'Non-technical teams (HR, marketing, etc)',
    key: 'non-technical-teams',
  }),
]);
