import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { log } from '@atlassian/mpac-utils';
import { EvaluationReason } from '@atlassiansox/feature-flag-web-client';
import { Map } from 'immutable';
import { getAllFlagsMap } from '../utils';
/**
 * A wrapper over the `FeatureFlagWebClient` from `@atlassiansox/feature-flag-web-client` used in
 * MPAC & Emcee. It:-
 * - fetches all flags & parses them into a simpler shape (key-value)
 * - allows updating or reading the user identifier
 * - logs when there are flags which have used fallbacks and/or had an error in evaluation
 */

export class MarketplaceFeatureFlagsClient {
  // only used for LD

  constructor(ldClient, userIdentifier) {
    // launch darkly
    _defineProperty(this, "_ldClient", void 0);
    _defineProperty(this, "_userIdentifier", void 0);
    // only used for LD
    _defineProperty(this, "_ldFlagNames", void 0);
    // launch darkly
    this._ldClient = ldClient;
    this._userIdentifier = userIdentifier;
    const ldFlags = this._ldClient.getFlags();
    this._ldFlagNames = Object.keys(ldFlags);
    this.logWarningForFallbackValues(ldFlags);
  }
  logWarningForFallbackValues(featureFlags) {
    Object.keys(featureFlags).forEach(key => {
      var _featureFlags$key$eva;
      if (((_featureFlags$key$eva = featureFlags[key].evaluationDetail) === null || _featureFlags$key$eva === void 0 ? void 0 : _featureFlags$key$eva.reason) === EvaluationReason.ERROR) {
        const {
          value,
          evaluationDetail
        } = featureFlags[key];
        log.warn('FeatureFlags', 'Error: Serving the fallback value', {
          extraInfo: {
            name: key,
            fallbackValue: value,
            errorKind: evaluationDetail === null || evaluationDetail === void 0 ? void 0 : evaluationDetail.errorKind
          }
        });
      }
    });
  }
  getFlag(flagName, defaultValue, options) {
    if (this._ldFlagNames.includes(flagName)) {
      const rawFlag = this._ldClient.getFlagDetails(flagName, defaultValue, options);
      this.logWarningForFallbackValues({
        [flagName]: rawFlag
      });
      return rawFlag.value;
    }
    return defaultValue;
  }
  getAllFlags() {
    const rawFlags = this._ldClient.getFlags();
    const allFlags = getAllFlagsMap(rawFlags);
    return Map(_objectSpread({}, allFlags));
  }
  changeUserIdentifier(userId) {
    return this._ldClient.updateFeatureFlagUser(userId);
  }
  resetUserIdentifier() {
    return this._ldClient.updateFeatureFlagUser(this._userIdentifier);
  }
}