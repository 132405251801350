import AkIconSearch from '@atlaskit/icon/glyph/search';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import React, { useEffect, useRef } from 'react';

import { SearchBox, SearchIconContainer, TextSearchWrapper } from './styles';

type TextSearchProps = {
  focusOnMount?: boolean;
  mode?: 'header' | 'hero';
  name: string;
  onChange: (value: string) => void;
  query?: string;
  onClick?: () => void;
};

const TextSearch = (props: TextSearchProps) => {
  const { enableAetherSearchOverlay } = useStatsig();
  const { focusOnMount = false, mode = 'hero', name, onChange, query, onClick } = props;
  const searchBoxRef = useRef<unknown>(null);

  const handleChange = ({ target: { value } }) => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  useEffect(() => {
    if (focusOnMount) {
      // @ts-expect-error [MC-2850] - TS2571 - Object is of type 'unknown'.
      searchBoxRef.current?.focus();
    }
  }, []);

  return (
    <TextSearchWrapper mode={mode} onClick={onClick}>
      <SearchBox
        // @ts-expect-error [MC-2850] - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLInputElement> | undefined'.
        ref={searchBoxRef}
        name={name}
        type="text"
        placeholder="Search for apps"
        onChange={handleChange}
        value={query}
        autoComplete={enableAetherSearchOverlay() ? 'off' : ''}
      />
      <SearchIconContainer>
        <AkIconSearch label="Search" size={mode === 'header' ? 'medium' : 'large'} />
      </SearchIconContainer>
    </TextSearchWrapper>
  );
};

export default TextSearch;
