import React from 'react';

import {
  transformToUserProps,
  useResolvedCurrentUser,
} from '../../../../../../../../../context/CurrentUserContext';
import PartnerQuickLinksContent from '../PartnerQuickLinksContent/PartnerQuickLinksContent';

import type ImmutablePropTypes from '@atlassian/react-immutable-proptypes';

type Props = {
  // @ts-expect-error [MC-2850] - TS2694 - Namespace '"@atlassian/react-immutable-proptypes"' has no exported member 'map'.
  userData: ImmutablePropTypes.map.isRequired;
};

const PartnerQuickLinks = (props: Props) => {
  const { loggedInUser } = useResolvedCurrentUser({
    userProps: transformToUserProps(props.userData),
  });

  const { vendorId: lastVisitedPartnerId, name: lastVisitedPartnerName } =
    loggedInUser?.lastVisitedDeveloperSpace || {};

  if (!lastVisitedPartnerId || !lastVisitedPartnerName) {
    return null;
  }
  return (
    <PartnerQuickLinksContent
      partnerId={parseInt(lastVisitedPartnerId)}
      partnerName={lastVisitedPartnerName}
    />
  );
};

export default PartnerQuickLinks;
