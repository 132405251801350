import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import {
  BodyLarge,
  BodyNormal,
  BodySmall,
  breakpointValues,
  FinePrint,
  GhostButton,
  useResponsiveBreakpoint,
} from '@atlassian/mpac-primitives';
import { LinkUtils } from '@atlassian/mpac-utils';
import { styled } from '@compiled/react';
import React from 'react';

export type BannerProps = {
  title: string;
  body: string;
  cta: {
    text: string;
    url: string;
  };
  closeMenu: () => void;
};

export const BannerContainer = styled.div({
  marginTop: token('space.300'),
  borderRadius: '6px',
  background: token('color.background.accent.teal.subtlest'),
  padding: token('space.300'),
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.200'),
  width: '100%',
  [media.above.md]: {
    marginTop: token('space.400'),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  [media.above.xl]: {
    maxWidth: '1013px',
  },
});
export const Content = styled.div({
  [media.above.md]: {
    width: '70%',
  },
});

const FinePrintText = styled(FinePrint)({
  paddingTop: token('space.100'),
  margin: 0,
  color: token('color.text.subtle'),
});

const BodySmallText = styled(BodySmall)({
  paddingTop: token('space.100'),
  margin: 0,
});

const CtaContainer = styled.div({
  display: 'flex',
  alignItems: 'end',
});

export const Banner = (props: BannerProps) => {
  const { value: breakpoint } = useResponsiveBreakpoint();
  const isDesktopDevice = breakpoint > breakpointValues.sm;

  const onClick = (event) => {
    props.closeMenu();
    LinkUtils.maybeOpenInNewTab(event, () => {
      window.open(props.cta.url);
    });
  };
  return (
    <BannerContainer>
      <Content>
        {isDesktopDevice ? (
          <>
            <BodyNormal role="heading" aria-level={2}>
              {props.title}
            </BodyNormal>
            <FinePrintText>{props.body}</FinePrintText>
          </>
        ) : (
          <>
            <BodyLarge>{props.title}</BodyLarge>
            <BodySmallText>{props.body}</BodySmallText>
          </>
        )}
      </Content>
      <CtaContainer>
        <GhostButton onClick={onClick}>{props.cta.text}</GhostButton>
      </CtaContainer>
    </BannerContainer>
  );
};
