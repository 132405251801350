import { flowUtils } from '@atlassian/mpac-utils';
export const OPERATIONAL_EVENT_TYPE = 'operational';
export const TRACK_EVENT_TYPE = 'track';
export const UI_EVENT_TYPE = 'ui';
export const SCREEN_EVENT_TYPE = 'screen';
export function foldAnalyticsEventType(analyticsEventType) {
  return function foldAnalyticsEventTypeInner(track, ui, operational) {
    switch (analyticsEventType) {
      case 'track':
        return track();
      case 'ui':
        return ui();
      case 'operational':
        return operational();
      default:
        throw flowUtils.unexpectedCase(analyticsEventType);
    }
  };
}