import { getApp } from '@atlassian/mpac-api-client';
import { BaseHandler } from '../../../graphql-orchestrator-link';
export class AppAdminHandler extends BaseHandler {
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    const {
      appKey
    } = operation.variables;
    const response = await getApp(appKey);
    return response;
  }
  responseTransformer(_operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    var _customResponse$admin, _customResponse$admin2, _customResponse$admin3, _customResponse$_embe, _customResponse$_embe2, _customResponse$_embe3, _customResponse$_embe4, _customResponse$_embe5, _customResponse$_embe6, _customResponse$_embe7, _customResponse$_embe8, _customResponse$admin4;
    return {
      marketplaceConsoleManageAppAdmin: customResponse ? {
        __typename: 'MarketplaceConsoleAdminSectionResponse',
        availableOnDemand: customResponse === null || customResponse === void 0 ? void 0 : (_customResponse$admin = customResponse.admin) === null || _customResponse$admin === void 0 ? void 0 : _customResponse$admin.availableOnDemand,
        onDemandPaymentModel: customResponse === null || customResponse === void 0 ? void 0 : (_customResponse$admin2 = customResponse.admin) === null || _customResponse$admin2 === void 0 ? void 0 : _customResponse$admin2.onDemandPaymentModel,
        versionBanner: customResponse === null || customResponse === void 0 ? void 0 : (_customResponse$admin3 = customResponse.admin) === null || _customResponse$admin3 === void 0 ? void 0 : _customResponse$admin3.versionsBanner,
        titleLogoUrl: {
          imageUrl: customResponse === null || customResponse === void 0 ? void 0 : (_customResponse$_embe = customResponse._embedded) === null || _customResponse$_embe === void 0 ? void 0 : (_customResponse$_embe2 = _customResponse$_embe.titleLogo) === null || _customResponse$_embe2 === void 0 ? void 0 : (_customResponse$_embe3 = _customResponse$_embe2._links) === null || _customResponse$_embe3 === void 0 ? void 0 : (_customResponse$_embe4 = _customResponse$_embe3.image) === null || _customResponse$_embe4 === void 0 ? void 0 : _customResponse$_embe4.href,
          selfImageHref: customResponse === null || customResponse === void 0 ? void 0 : (_customResponse$_embe5 = customResponse._embedded) === null || _customResponse$_embe5 === void 0 ? void 0 : (_customResponse$_embe6 = _customResponse$_embe5.titleLogo) === null || _customResponse$_embe6 === void 0 ? void 0 : (_customResponse$_embe7 = _customResponse$_embe6._links) === null || _customResponse$_embe7 === void 0 ? void 0 : (_customResponse$_embe8 = _customResponse$_embe7.self) === null || _customResponse$_embe8 === void 0 ? void 0 : _customResponse$_embe8.href
        },
        distributionProductKey: customResponse === null || customResponse === void 0 ? void 0 : (_customResponse$admin4 = customResponse.admin) === null || _customResponse$admin4 === void 0 ? void 0 : _customResponse$admin4.productKey
      } : null
    };
  }
}