import { fetchOk } from '@atlassian/mpac-fetch-wrapper';

export type MPACUser = {
  _links: {
    alternate: Link;
    picture: Link;
  };
  name: string;
  email: string;
  id: string;
};

type Link = {
  href: string;
  type?: 'text/html' | string;
};

export type AuthenticatedUserLinks = {
  admin?: Link;
  addons?: Link;
  createAddon?: Link;
  atlassianAccount: Link;
  logout: Link;
  switchAccount: Link;
};

export type AnonymousUserLinks = {
  login: Link;
};

export type MPACLastVisitedPartner = {
  lastVisitedPartnerId?: number;
  lastVisitedPartnerName?: string;
};

export type MPACCurrentUserResponse = {
  _embedded: {
    user?: MPACUser;
    lastVisited?: MPACLastVisitedPartner;
    featureDiscovery?: {
      discoveredFeatures?: string[];
    };
  };
  _links: AuthenticatedUserLinks | AnonymousUserLinks;
};

let request;

export default function getUserData(url: string): Promise<MPACCurrentUserResponse> {
  if (!request) {
    request = fetchOk(url).then((response) => response.json());
  }

  return request;
}
