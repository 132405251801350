export const HostingTypes = {
  CLOUD: 'cloud',
  DATA_CENTER: 'dataCenter',
  SERVER: 'server'
};
export const HostingFilters = () => {
  return [{
    label: 'Cloud',
    id: HostingTypes.CLOUD,
    value: HostingTypes.CLOUD
  }, {
    label: 'Data Center',
    id: HostingTypes.DATA_CENTER,
    value: HostingTypes.DATA_CENTER
  }, {
    label: 'Server',
    id: HostingTypes.SERVER,
    value: HostingTypes.SERVER
  }];
};