import { applyMiddleware, compose, createStore } from 'redux';

import reducer from '../reducer';

const applyDevtools = () =>
  // @ts-expect-error [MC-2850] - TS2339 - Property 'devToolsExtension' does not exist on type 'Window & typeof globalThis'.
  process.env.NODE_ENV !== 'production' && window.devToolsExtension
    ? // @ts-expect-error [MC-2850] - TS2339 - Property 'devToolsExtension' does not exist on type 'Window & typeof globalThis'.
      window.devToolsExtension()
    : (f) => f;

const createStoreWithMiddleware = (middleware) => {
  return compose(
    applyMiddleware(...middleware),
    applyDevtools()
    // @ts-expect-error [MC-2850]
  )(createStore);
};

// @ts-ignore MC-2850
export const createReduxStore = ({ initialState, middleware }: any): any =>
  createStoreWithMiddleware(middleware)(reducer, initialState);
