import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { appSearchMergePolicy } from '../SearchResult/AppSearchMergePolicy';
export const collectionMergePolicy = (existing, incoming, _ref) => {
  let {
    args
  } = _ref;
  if ((args === null || args === void 0 ? void 0 : args.after) === 0) return incoming;
  return _objectSpread(_objectSpread({}, existing), {}, {
    apps: appSearchMergePolicy(existing === null || existing === void 0 ? void 0 : existing.apps, incoming === null || incoming === void 0 ? void 0 : incoming.apps, {
      args
    })
  });
};