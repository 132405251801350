import { List, Map, Record } from 'immutable';

import RotatableEntity from '../../../../../../common/records/RotatableEntity';

export const MoreDetails = Record({
  heading: '',
  icon: '',
  content: '',
});

export const HeroImage = Record({
  svg: '',
  png: '',
});

export const UseCases = Record({
  heading: '',
  description: undefined,
  items: List(),
});

export const AtlassianProductDiversion = Record({
  introduction: '',
  productLinks: List(),
});

export const PageMetaDetails = Record({
  title: '',
  description: '',
});

export default Record({
  name: '',
  heroImage: new HeroImage(),
  heading: '',
  tagline: '',
  primaryDescription: '',
  appsSectionTitle: '',
  atlassianProductDiversion: undefined,
  moreDetails: new MoreDetails(),
  useCases: new UseCases(),
  callToActionTitle: '',
  curatedApps: new RotatableEntity(),
  viewMoreActionText: '',
  viewMoreFilters: Map(),
  preferredHosting: undefined,
  pageMetaDetails: new PageMetaDetails(),
});
