import { B50 } from '@atlaskit/theme/colors';
import queryString from 'query-string';
import log from './sentry/log';

/**
 * Scrolls to element if found by selector,
 * and highlights the element by pulsing its background color.
 */
export function scrollToAndFocusElement(selector) {
  const element = document.querySelector(selector);
  if (!element) {
    log.warn('scrollToAndFocusElement', 'Could not find element to scroll to with selector: ' + selector);
    return;
  }
  element.scrollIntoView({
    behavior: 'smooth'
  });

  // Web Animations should be supported in all major browsers
  // But older versions might be of concern
  if (!('animate' in element)) {
    return;
  }
  element.animate([{
    background: B50
  }, {
    background: 'initial'
  }], {
    duration: 1500,
    delay: 700,
    // allow for the smooth scroll to complete
    iterations: 1,
    easing: 'ease-in'
  });
}
export const getElementBySelector = selector => {
  const element = document.querySelector(selector);
  if (!element) {
    log.warn('getElementBySelector', 'Could not find element to scroll to with selector: ' + selector);
    return;
  }
  return element;
};
export const scrollToDocumentTop = () => {
  if (document.body) document.body.scrollTop = 0;
};
export const scrollWindowToTop = () => {
  window.scrollTo({
    top: 0
  });
};
export const getQueryParamsFromLocation = () => {
  return queryString.parse(window.location.search || '');
};
export const isSynthetic = () => new URLSearchParams(window.location.search).get('source') === 'pollinator';
export const isCrawler = function isCrawler() {
  let userAgent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return /bot|spider|webscraper|Acunetix|crawl|slurp|curl|wget|^facebook/i.test(userAgent);
};