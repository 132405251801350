export const Analytics = {
  source: 'categoriesScreen',
  subjectId: {
    screenRendered: 'categoryScreenRendered',
    appTilesRendered: 'categoryAppTilesRendered',
    appTileClicked: 'categoryAppTileClicked',
    loadMoreClicked: 'categoryLoadMoreClicked',
    clearAllClicked: 'categoryClearAllFiltersClicked',
    sortOptionSelected: 'categorySortOptionSelected',
  },
  event: {
    globalError: 'global',
    initialError: 'initial',
    loadMoreError: 'load more',
  },
};
