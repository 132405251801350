import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * =============================
 *      CACHE MIDDLEWARE
 * =============================
 *
 * ## Description:
 * Adds response caching support for either primary or custom response(or both), based on the given TTL.
 *
 * ## Cache clearing mechanism:
 * Instead of being JS timer based, it relies on a next call to check and clear the expired cache, this prevents
 * unnecessary timers from polluting the event loop.
 *
 * ## Supported middleware configuration:
 * ```JS
 * {
 *   "cache": {
 *       primary: {
 *           ttlMs: <number in ms>,
 *           cacheKey: (operation) => unique key for cache as string
 *       }
 *       custom: {
 *           ttlMs: <number in ms>,
 *           cacheKey: (operation) => unique key for cache as string
 *       }
 *   }
 * }
 * ```
 *
 * ## Example usage:
 *
 * Installing the middleware:
 * ```JS
 * // gqlOrchestratorLinkOptions
 * {
 *  isEnabled: true,
 *  config: <Orchestration config>
 *  middlewares: [
 *   new CacheMiddleware() // create a new instance of cache middleware here...
 *  ]
 * }
 * ```
 *
 * Configuring the middleware:
 * ```JS
 * // Orchestration config
 * {
 *  GetMarketplaceCategory: {
 *    middlewaresConfig: {
 *     cache: {
 *       primary: {
 *           // only cache primary response for 60 seconds.
 *           ttlMs: <number in ms>,
 *           // uniquely cache based on the slug name
 *           cacheKey: (operation) => unique key for cache as string
 *       }
 *     }
 *    },
 *    config: () => ({
 *      sequence: SequenceTypes.PRIMARY_FIRST,
 *      handler: new CategoryHandler(),
 *    }),
 *  },
 * }
 * ```
 *
 * To check if the received response was from cache or not:
 * ```
 * const status = CacheMiddleware.isDataFromCache(context, operation);
 * const primaryCallStatus = status[Path.PRIMARY];
 * const customCallStatus = status[Path.CUSTOM];
 * // the above can be either boolean or null, for more info check the docs below;
 * ```
 */
import { Observable } from '@apollo/client';
import { BaseMiddleware, Path } from '../graphql-orchestrator-link';

// Middleware configuration

/**
 * null means cache is not set
 * false means cache is set but data was fetched from somewhere
 * true means cache is set and data is served from the cache.
 */

class CacheMiddleware extends BaseMiddleware {
  constructor() {
    super(...arguments);
    _defineProperty(this, "cacheMap", new Map());
    _defineProperty(this, "cacheStatusMap", new WeakMap());
  }
  static isDataFromCache(context, operation) {
    const cacheMiddlewareContext = context[CacheMiddleware.key];
    return cacheMiddlewareContext.cacheStatusMap.get(operation);
  }
  init(context) {
    this.cacheMap = new Map();
    context[this.getKey()] = {
      cacheMap: this.cacheMap,
      cacheStatusMap: this.cacheStatusMap
    };
  }
  getKey() {
    return CacheMiddleware.key;
  }
  withCache(onCacheHit, onCacheMiss, defaultHandling, config, operation, path) {
    // after cleanup only ttlMs compliant data would be left
    this.cleanup();
    this.setCacheStatus(operation, path);
    if (config && typeof config.ttlMs === 'number' && typeof config.cacheKey === 'function') {
      const {
        cacheKey
      } = config;
      const key = this.getCacheKey(operation, cacheKey, path);
      const cachedStore = this.cache.get(key);
      if (cachedStore) {
        // cache hit
        this.setCacheStatus(operation, path, true);
        return onCacheHit(cachedStore, key, config);
      }

      // cache miss
      this.setCacheStatus(operation, path, false);
      return onCacheMiss(undefined, key, config);
    }
    return defaultHandling();
  }
  onFetchPrimary(args, callbackFn, _context, middlewareConfig) {
    const {
      primary
    } = middlewareConfig;
    const [operation] = args;
    return this.withCache(
    // on cache hit
    cachedStore => Observable.of(cachedStore === null || cachedStore === void 0 ? void 0 : cachedStore.primaryData),
    // on cache miss
    (_, key, config) => {
      const observable = callbackFn(...args);
      return new Observable(observer => {
        observable.subscribe({
          // evict own and custom cache on API failure.
          error: err => {
            this.forceCleanRelatedCache(operation, middlewareConfig);
            observer.error(err);
          },
          next: primaryData => {
            this.cache.set(key, {
              primaryData,
              endTime: Date.now() + config.ttlMs
            });
            observer.next(primaryData);
          },
          complete() {
            observer.complete();
          }
        });
      });
    },
    // default handling
    () => callbackFn(...args), primary, operation, Path.PRIMARY);
  }
  onFetchCustom(args, callbackFn, _context, middlewareConfig) {
    const {
      custom
    } = middlewareConfig;
    const [operation] = args;
    return this.withCache(
    // on cache hit
    cachedStore => Promise.resolve(cachedStore === null || cachedStore === void 0 ? void 0 : cachedStore.customData),
    // on cache miss
    (_, key, config) => {
      const promise = callbackFn(...args);
      promise.then(customData => {
        this.cache.set(key, {
          customData,
          endTime: Date.now() + config.ttlMs
        });
        return customData;
      }, () => this.forceCleanRelatedCache(operation, middlewareConfig));
      return promise;
    },
    // default handling
    () => callbackFn(...args), custom, operation, Path.CUSTOM);
  }
  get cache() {
    return this.cacheMap;
  }
  forceCleanRelatedCache(operation, middlewareConfig) {
    const primary = middlewareConfig.primary;
    if (primary && typeof primary.ttlMs === 'number' && typeof primary.cacheKey === 'function') {
      const key = this.getCacheKey(operation, primary.cacheKey, Path.PRIMARY);
      this.cache.delete(key);
    }
    const custom = middlewareConfig.custom;
    if (custom && typeof custom.ttlMs === 'number' && typeof custom.cacheKey === 'function') {
      const key = this.getCacheKey(operation, custom.cacheKey, Path.CUSTOM);
      this.cache.delete(key);
    }
  }
  setCacheStatus(operation, type) {
    let status = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let statusMap = this.cacheStatusMap.get(operation);
    if (!statusMap) {
      statusMap = {
        [Path.PRIMARY]: null,
        [Path.CUSTOM]: null
      };
    }
    statusMap[type] = status;
    this.cacheStatusMap.set(operation, statusMap);
  }
  cleanup() {
    if (this.cache.size === 0) return;
    const toBeDeleted = [];
    this.cache.forEach((value, key) => {
      if (value.endTime < Date.now()) {
        toBeDeleted.push(key);
      }
    });
    toBeDeleted.forEach(key => this.cache.delete(key));
  }
  getCacheKey(operation, cacheKey, type) {
    return "".concat(operation.operationName, "_:").concat(type, ":_").concat(cacheKey(operation));
  }
}
_defineProperty(CacheMiddleware, "key", 'cache');
export { CacheMiddleware };