import { Record } from 'immutable';

import Link from '../../../../../../common/records/Link';

export class VendorDetailsLinks extends Record({
  edit: undefined,
}) {}

export class VendorDetailsLogoLinks extends Record({
  image: new Link(),
}) {}

export class VendorDetailsLogo extends Record({
  _links: new VendorDetailsLogoLinks(),
}) {}

export class VendorDetailsEmbedded extends Record({
  logo: new VendorDetailsLogo(),
}) {}

export class VendorDetailsVendorProvidedLinks extends Record({
  homePage: undefined,
}) {}

export class VendorDetailsSupportDetails extends Record({
  supportOrg: undefined,
}) {}

export class VendorDetailsSupportDetailsSupportOrg extends Record({
  name: '',
  supportEmail: undefined,
  supportUrl: undefined,
  supportPhone: undefined,
}) {}

export class VendorDetailsAddress extends Record({
  line1: '',
  line2: undefined,
  city: undefined,
  state: undefined,
  postCode: undefined,
  country: undefined,
}) {}

export class VendorDetailsSolutionPartner extends Record({
  partnerLevel: '',
}) {}

export class VendorDetailsCloudSecurity extends Record({
  approved: false,
}) {}

export class TopVendorStatus extends Record({
  status: '',
}) {}

export class VendorDetailsPrograms extends Record({
  topVendor: new TopVendorStatus(),
}) {}

export default class VendorDetails extends Record({
  _links: new VendorDetailsLinks(),
  _embedded: new VendorDetailsEmbedded(),
  vendorLinks: new VendorDetailsVendorProvidedLinks(),
  name: '',
  description: undefined,
  email: '',
  phone: undefined,
  address: undefined,
  otherContactDetails: undefined,
  supportDetails: new VendorDetailsSupportDetails(),
  solutionPartner: undefined,
  partnerTier: undefined,
  isAtlassian: false,
  // should be one of:
  // - "not-requested"
  // - "requested",
  // - "verified",
  // - "needs-verification
  // - "flagged",
  // - "rejected"
  // or undefined
  programs: new VendorDetailsPrograms(),
}) {}
