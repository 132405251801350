import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { styled } from '@compiled/react';
import React from 'react';

const Wrapper = styled.div({
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  [media.above.md]: {
    flexDirection: 'row',
  },
});

const PrimaryContentContainer = styled.div({
  flex: '65%',
  backgroundColor: token('elevation.surface.overlay'),
  width: '100%',
  marginBottom: token('space.200', '16px'),
  padding: `0 ${token('space.300')}`,
  [media.above.xs]: {
    padding: `0 ${token('space.400')}`,
  },
  [media.above.sm]: {
    padding: `0 ${token('space.500')}`,
  },
  [media.above.md]: {
    padding: `${token('space.400')} ${token('space.400')} ${token('space.500')} 64px`,
  },
  [media.above.lg]: {
    padding: `${token('space.400')} ${token('space.400')} ${token('space.500')} 144px`,
  },
  [media.above.xl]: {
    padding: `${token('space.400')} ${token('space.400')} ${token('space.500')} 192px`,
  },
});

const SecondaryContentContainer = styled.div({
  flex: '35%',
  width: '100%',
  padding: `0 ${token('space.300')} 102px ${token('space.300')}`,
  [media.above.xs]: {
    padding: `0 ${token('space.400')} 102px ${token('space.400')}`,
  },
  [media.above.sm]: {
    padding: `0 ${token('space.500')} 102px ${token('space.500')}`,
  },
  [media.above.md]: {
    backgroundColor: token('color.background.neutral'),
    padding: `${token('space.400')} 64px ${token('space.500')} ${token('space.400')}`,
  },
  [media.above.lg]: {
    padding: `${token('space.400')} 144px ${token('space.500')} ${token('space.400')}`,
  },
  [media.above.xl]: {
    padding: `${token('space.400')} 192px ${token('space.500')} ${token('space.400')}`,
  },
});

const Separator = styled.div({
  height: '1px',
  background: `${token('color.border')}`,
  [media.above.md]: {
    display: 'none',
  },
});

type ExpandedNavItemLayoutProps = {
  primaryContent: React.ReactNode;
  secondaryContent: React.ReactNode;
  dataTestId?: string;
};

export const ExpandedNavItemLayout = ({
  primaryContent,
  secondaryContent,
  dataTestId,
}: ExpandedNavItemLayoutProps) => {
  return (
    <Wrapper data-testid={dataTestId}>
      <PrimaryContentContainer>{primaryContent}</PrimaryContentContainer>
      <SecondaryContentContainer>
        <Separator />
        {secondaryContent}
      </SecondaryContentContainer>
    </Wrapper>
  );
};
