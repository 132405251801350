import { PARTNER_NAV_ITEMS } from '@atlassian/mpac-utils';
const getTabsPermissionConfig = displayAuditTabInManagePartnerPage => [{
  permission: 'canViewManagedApps',
  tabs: [PARTNER_NAV_ITEMS.APPS]
}, {
  permission: 'canManagePartnerDetails',
  tabs: [PARTNER_NAV_ITEMS.DETAILS]
}, {
  permission: 'canViewPartnerContacts',
  tabs: [PARTNER_NAV_ITEMS.TEAM]
}, {
  permission: 'canViewPartnerPaymentDetails',
  tabs: [PARTNER_NAV_ITEMS.PAYMENT_INFO]
}, {
  permission: 'canManagePromotions',
  tabs: [PARTNER_NAV_ITEMS.PROMOTIONS]
}, {
  permission: 'canManagePartnerSecurity',
  tabs: [PARTNER_NAV_ITEMS.SECURITY]
}, {
  permission: 'canViewAnyReports',
  tabs: [PARTNER_NAV_ITEMS.REPORTS]
}, {
  permission: 'isSiteAdmin',
  tabs: [PARTNER_NAV_ITEMS.ADMIN]
}, {
  permission: 'isSiteAdmin',
  condition: () => displayAuditTabInManagePartnerPage,
  tabs: [PARTNER_NAV_ITEMS.AUDIT_LOGS]
}, {
  permission: 'canViewUsageReports',
  tabs: [PARTNER_NAV_ITEMS.FEEDBACK_REPORTS, PARTNER_NAV_ITEMS.FEEDBACK_DETAILS_REPORTS]
}, {
  permission: 'canViewSalesReport',
  tabs: [PARTNER_NAV_ITEMS.SALE_REPORTS, PARTNER_NAV_ITEMS.EVALUATIONS_REPORTS, PARTNER_NAV_ITEMS.CLOUD_CHURN_REPORTS, PARTNER_NAV_ITEMS.CLOUD_CONVERSIONS_REPORTS, PARTNER_NAV_ITEMS.CLOUD_RENEWALS_REPORTS, PARTNER_NAV_ITEMS.TRANSACTIONS_REPORTS, PARTNER_NAV_ITEMS.LICENSES_REPORTS]
}];
export const getAuthorisedTabs = (permissions, displayAuditTabInManagePartnerPage) => {
  const tabPermissionsConfig = getTabsPermissionConfig(displayAuditTabInManagePartnerPage);
  return tabPermissionsConfig.filter(config => {
    var _config$condition, _config$condition2;
    return permissions[config.permission] && ((_config$condition = (_config$condition2 = config.condition) === null || _config$condition2 === void 0 ? void 0 : _config$condition2.call(config)) !== null && _config$condition !== void 0 ? _config$condition : true);
  }).map(config => config.tabs).flat();
};
export const getAuthorisedSubTab = (tab, canViewSalesReports) => {
  if (tab !== PARTNER_NAV_ITEMS.REPORTS) {
    return tab;
  }
  return canViewSalesReports ? PARTNER_NAV_ITEMS.SALE_REPORTS : PARTNER_NAV_ITEMS.FEEDBACK_REPORTS;
};