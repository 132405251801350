import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Identifiers } from '@atlassiansox/feature-flag-web-client';
export const getFeatureFlagUser = function getFeatureFlagUser(userId, additionalIds) {
  let customAttributes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let user;
  const tenantId = additionalIds === null || additionalIds === void 0 ? void 0 : additionalIds.tenantId;
  if (userId) {
    user = {
      identifier: {
        type: Identifiers.ATLASSIAN_ACCOUNT_ID,
        value: userId
      },
      isAnonymous: false,
      additionalIdentifiers: {},
      custom: customAttributes
    };
  } else {
    user = {
      identifier: {
        type: Identifiers.FF_CLIENT_ANONYMOUS_ID,
        value: 'marketplace-anonymous'
      },
      isAnonymous: true,
      additionalIdentifiers: {},
      custom: customAttributes
    };
  }
  if (tenantId) {
    user.additionalIdentifiers = _objectSpread(_objectSpread({}, user.additionalIdentifiers), {}, {
      tenantId
    });
  }
  return user;
};