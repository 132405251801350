import { media } from '@atlaskit/primitives/responsive';
import { fontFamily } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { LinkUtils } from '@atlassian/mpac-utils';
import { styled } from '@compiled/react';
import React from 'react';

import { useResolvedCurrentUser } from '../../../../context/CurrentUserContext';
import { BaseButton } from './Button.styles';

import type { UserProps } from '../userTypes';

type SignInProps = {
  userProps: UserProps;
  onClick?: () => void;
};

const SignInButton = styled(BaseButton)({
  height: '40px',
  padding: `0px ${token('space.150')}`,
  gap: token('space.050'),
  '&:hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },
  [media.above.md]: {
    marginLeft: token('space.100'),
  },
});

const Text = styled.span({
  fontFamily: `Charlie Text, ${fontFamily()}`,
  color: token('color.text.brand'),
  fontSize: '1rem',
  fontWeight: '600',
  lineHeight: '20px',
  [media.above.md]: {
    lineHeight: '24px',
  },
});

const SignInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-hidden={true}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 13.009H9.5C8.12 13.009 7 14.129 7 15.512V19.49C8.47848 20.4824 10.2193 21.0113 12 21.009C13.7807 21.0113 15.5216 20.4825 17 19.49V15.512C17.0003 14.8486 16.7371 14.2123 16.2682 13.7429C15.7994 13.2735 15.1634 13.0095 14.5 13.009Z"
        fill={token('color.icon.brand')}
      />
      <path
        d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
        fill={token('color.icon.brand')}
      />
      <path
        d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z"
        fill={token('color.icon.brand')}
      />
    </svg>
  );
};

const setWindowLocation = (location: string) => {
  window.location.assign(location);
};

export const SignIn = ({ userProps, onClick }: SignInProps) => {
  const { anonymousUser, loading } = useResolvedCurrentUser({ userProps });

  const handleClick = (e) => {
    onClick?.();
    LinkUtils.maybeOpenInNewTab(e, () => {
      if (!loading && anonymousUser && anonymousUser.anonymousUserLinks.login) {
        setWindowLocation(
          `${anonymousUser.anonymousUserLinks.login}?redirect=${
            window.location.pathname
          }${encodeURIComponent(window.location.search)}`
        );
      }
    });
  };

  return (
    <SignInButton onClick={handleClick}>
      <SignInIcon />
      <Text>Sign in</Text>
    </SignInButton>
  );
};
