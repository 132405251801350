import { useSelector } from 'react-redux';

import { transformToUserProps, useResolvedCurrentUser } from '../context/CurrentUserContext';

export default function useLoggedInUser() {
  // @ts-expect-error [MC-2850] - TS2339 - Property 'user' does not exist on type 'DefaultRootState'.
  const userImmutable = useSelector((state) => state.user);
  const { loggedInUser } = useResolvedCurrentUser({
    userProps: transformToUserProps(userImmutable),
  });

  return {
    isLoggedIn: !!loggedInUser,
    isAdmin: !!loggedInUser?.loggedInUserLinks.admin,
    userAvatarUrl: loggedInUser?.picture,
    user: loggedInUser,
  };
}
