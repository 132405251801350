import _defineProperty from "@babel/runtime/helpers/defineProperty";
// An countdown timer implementation
export default class CountdownTimer {
  constructor(duration, onEndCallback) {
    _defineProperty(this, "duration", void 0);
    _defineProperty(this, "onExpire", void 0);
    _defineProperty(this, "timerID", void 0);
    this.duration = duration;
    this.onExpire = onEndCallback;
  }
  start() {
    if (this.timerID) {
      clearTimeout(this.timerID);
    }
    this.timerID = setTimeout(() => {
      this.onExpire();
    }, this.duration);
  }
  restart() {
    this.start();
  }
  stop() {
    if (this.timerID) {
      clearTimeout(this.timerID);
    }
  }
}