import React from 'react';

import ServerError from './ServerError';

export const HomeLink = '/';

/**
 * The fallback Server Error component to be rendered when there's an
 * error at the app level. Used as a fallback component for error boundary
 * in amkt-frontend.
 */
const AppErrorFallback = () => <ServerError homeLink={HomeLink} />;

export default AppErrorFallback;
