import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../Routes/components/common/routeHandlers/BaseRouteHandler';

const VersionHistoryPageCodeSplit = lazyForPaint(() =>
  import(/* webpackChunkName: "chunk-version-history-page" */ './VersionHistoryPage').then(
    (module) => module.VersionHistoryPage
  )
);

const RouteHandler = () => {
  return (
    <BaseRouteHandler scrollToTopOnPathChange>
      <LazySuspense fallback={<div />}>
        <VersionHistoryPageCodeSplit />
      </LazySuspense>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
