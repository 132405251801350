import type { UserProps } from '../userTypes';

export enum ExpandableMenuType {
  APPS_FOR_PRODUCTS = 'apps_for_products',
  CATEGORIES = 'categories',
  RESOURCES = 'resources',
  MOBILE_VIEW = 'mobile_view',
  FIRST_LINK_TO_FOCUS = 'firstLinkToFocusUnderGlobalNav',
}

export type ExpandableMenuProps = {
  isOpen: boolean;
  openedMenu: ExpandableMenuType | null;
  openMenu: (menuType: ExpandableMenuType) => () => void;
  closeMenu: () => void;
  userProps: UserProps;
  openFeedbackDialog: false | (() => void);
};

export type ExpandableMenuRef = {
  triggers: {
    [ExpandableMenuType.APPS_FOR_PRODUCTS]: HTMLButtonElement | null;
    [ExpandableMenuType.CATEGORIES]: HTMLButtonElement | null;
    [ExpandableMenuType.RESOURCES]: HTMLButtonElement | null;
    [ExpandableMenuType.FIRST_LINK_TO_FOCUS]?: HTMLAnchorElement | null;
  };
};
